import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { datadogRum } from "@datadog/browser-rum";

if (window.process?.env?.REACT_APP_ENV !== "development") {
	datadogRum.init({
		applicationId: "90a761ab-1451-48d7-a026-a93305382505",
		clientToken: "pubabd7b50eaffcb3fc78c09726d2ab1480",
		// `site` refers to the Datadog site parameter of your organization
		// see https://docs.datadoghq.com/getting_started/site/
		site: "datadoghq.com",
		service: "platform",
		env: process.env.REACT_APP_ENV,
		// Specify a version number to identify the deployed version of your application in Datadog
		// version: '1.0.0',
		sessionSampleRate: 100,
		sessionReplaySampleRate: 20,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: "mask-user-input",
	});
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
