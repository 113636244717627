import { useCallback, useState } from "react";

const useConfetti = () => {
	const [running, setRunning] = useState(false);
	const runConfetti = useCallback((timeout = 2000) => {
		return new Promise((resolve) => {
			setRunning(true);
			setTimeout(() => {
				resolve();
			}, timeout);
		});
	}, []);

	return { running, runConfetti };
};

export default useConfetti;
