import bigcommerce from "../img/icons/bigcommerce-big-new.png";
import woocommerce from "../img/icons/woocommerce-big-new.png";
import shopify from "../img/icons/shopify-big.png";
import other from "../img/icons/other-big.png";
import { PLATFORM_IDS } from "../conf";
import { GET } from "./GET";

const getPlatformList = () => {
	const shouldShowOtherPlatform = GET("other_platform") === "true";
	let platformList = [
		{ img: shopify, name: "Shopify", id: PLATFORM_IDS.shopify },
		{ img: bigcommerce, name: "BigCommerce", id: PLATFORM_IDS.bigcommerce },
		{ img: woocommerce, name: "WooCommerce", id: PLATFORM_IDS.woocommerce },
	];

	if (shouldShowOtherPlatform) {
		platformList = [
			...platformList,
			{
				img: other,
				name: "Other",
				id: PLATFORM_IDS.other,
			},
		];
	}

	return platformList;
};

export default getPlatformList;
