import { Button } from "@disco/disco_core";
import React, { memo, useCallback, useEffect, useState } from "react";
import { FaChevronCircleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { PRIVACY_POLICY_URL, TOS_OPT_IN_URL, TOS_URL } from "../../conf";
import {
	PLATFORM_PRIVACY_UPDATE_ACCEPT_CLICK,
	PLATFORM_PRIVACY_UPDATE_PRIVACY_LINK_CLICK,
	PLATFORM_PRIVACY_UPDATE_TOS_LINK_CLICK,
} from "../../events";
import useIdentity from "../../hooks/useIdentity";
import useResource from "../../hooks/useResource";
import useTrack from "../../hooks/useTrack";
import getPlatformPath from "../../utils/getPlatformPath";
import isFunction from "../../utils/isFunction";
import parseError from "../../utils/parseError";
import windowRedirect from "../../utils/windowRedirect";
import { Checkbox, FormStatus, Loader } from "../CoreUI";
import TOSUpdateModal from "../modals/TOSUpdateModal";

import { tokenStorage } from "@disco/disco_core";
import "./policy-migration-flow.css";

export const PolicyMigrationFlowFooter = memo(({ isIaNav = true, onClose }) => {
	const [checked, setChecked] = useState(false);
	const { user, setUser } = useIdentity();
	const navigate = useNavigate();

	const track = useTrack();

	const [{ data, error, loading }, save, reset] = useResource(
		{
			url: TOS_OPT_IN_URL,
			method: "PUT",
			data: {
				opt_in: checked,
			},
		},
		false
	);

	const handleChange = useCallback(
		({ target: { checked } }) => (loading ? null : setChecked(checked)),
		[loading]
	);

	const handleClick = useCallback(() => {
		if (!checked) {
			return;
		}
		track(PLATFORM_PRIVACY_UPDATE_ACCEPT_CLICK, {
			isIaNav,
		});
		save();
	}, [checked, save, track, isIaNav]);

	const { logout } = useIdentity();

	const handleLogout = useCallback(() => {
		tokenStorage.clear();
		logout();
		navigate("/");
	}, [logout]);

	const handleTOS = useCallback(() => {
		track(PLATFORM_PRIVACY_UPDATE_TOS_LINK_CLICK, {
			isIaNav,
		});
		windowRedirect(TOS_URL);
	}, [track, isIaNav]);
	const handlePrivacy = useCallback(() => {
		track(PLATFORM_PRIVACY_UPDATE_PRIVACY_LINK_CLICK, {
			isIaNav,
		});
		windowRedirect(PRIVACY_POLICY_URL);
	}, [track, isIaNav]);

	useEffect(() => {
		if (!data) {
			return;
		}
		setUser((user) => ({
			...user,
			publisher: {
				...user.publisher,
				accepted_latest_privacy_tos_updates: true,
			},
			inbox_beta: true,
			isNewIa: true,
		}));
		reset();
		if (isIaNav) {
			navigate({
				pathname: getPlatformPath("home", {
					...user,
					isNewIa: true,
				}),
			});
		}
		if (isFunction(onClose)) {
			onClose({ isIaNav });
		}
	}, [data, setUser, reset, onClose, navigate, user, isIaNav]);

	return (
		<section className="policy-migration-flow-footer">
			<section>
				<Checkbox checked={checked} onChange={handleChange} />I agree to
				Disco's <span onClick={handleTOS}>Terms of Service</span> and
				acknowledge its{" "}
				<span onClick={handlePrivacy}>Privacy Policy</span>.
			</section>
			{loading ? (
				<Loader />
			) : (
				<section className="policy-migration-flow-footer-actions">
					<Button
						rounded
						large
						disabled={loading}
						secondary
						onClick={handleLogout}
					>
						{isIaNav ? (
							<>
								Let's do this
								<FaChevronCircleRight />
							</>
						) : (
							"Logout"
						)}
					</Button>

					<Button
						rounded
						large
						disabled={!checked || loading}
						onClick={handleClick}
					>
						{isIaNav ? (
							<>
								Let's do this
								<FaChevronCircleRight />
							</>
						) : (
							"Accept & Proceed"
						)}
					</Button>
				</section>
			)}

			{error && <FormStatus>{parseError(error, true)}</FormStatus>}
		</section>
	);
});

const PolicyMigrationFlow = memo(({ onClose, open }) => {
	return <TOSUpdateModal open={open} onClose={onClose} />;
});
export default PolicyMigrationFlow;
