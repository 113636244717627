import React, { memo } from "react";
import { EmailsModule, SurveyModule } from "../../components/feedModules";
import TopLevelPage from "../TopLevelPage";

const subPages = {
	surveys: SurveyModule,
	emails: EmailsModule,
};

const MoreToolsPage = memo(() => {
	return <TopLevelPage subPages={subPages} pageProps={{}}></TopLevelPage>;
});

export default MoreToolsPage;
