import React, { memo, useEffect, useState, useMemo } from "react";
import SetView from "../SetView";
import { FINAL_SETUP_STATUS, SHOPIFY_PAYMENT_URL } from "../../conf";
import { AiFillCreditCard } from "react-icons/ai";
import useResource from "../../hooks/useResource";
import "./payment-set-view.css";
import getSubscriptionText from "../../utils/getSubscriptionText";

export default memo(({ user, setUser, className = "", inModal = false }) => {
	const [{ loading, error, data }, reload, reset] = useResource(
		{ url: SHOPIFY_PAYMENT_URL, method: "POST" },
		false
	);

	const [redirect, setRedirect] = useState(false);

	const { premium } = getSubscriptionText(user);

	const hasNotSetupOCUPayments = useMemo(
		() =>
			user?.publisher.one_click_upsells &&
			!user?.publisher.extension_payments_configured,
		[user]
	);

	useEffect(() => {
		if (!data) {
			return;
		}

		if (data.url) {
			setRedirect(true);
			window.location.href = data.url;
		}
		reset();
	}, [data, reset]);

	return (
		<SetView
			name="Payment"
			direct={true}
			className={`payment-set-view ${className} ${
				inModal ? "payment-set-view-modal" : ""
			} `}
			onEdit={reload}
			edit={!premium || hasNotSetupOCUPayments}
			editText={
				premium && hasNotSetupOCUPayments
					? "Update Payment"
					: "Setup Payment"
			}
			editIcon={<AiFillCreditCard />}
			wide={user.setup_status < FINAL_SETUP_STATUS}
			loading={loading || redirect}
			error={error}
			data={
				premium && !hasNotSetupOCUPayments ? (
					"Enabled"
				) : premium && hasNotSetupOCUPayments ? (
					"Needs Update"
				) : (
					<span className="profile-card-not-set">Not setup</span>
				)
			}
		/>
	);
});
