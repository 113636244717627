import React, { memo } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NotFoundPage from "./pages/NotFoundPage";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AnimatePresence } from "framer-motion";

import { BASE_URL, REFRESH_TOKEN_URL, STRIPE_SECRET } from "./conf";
import MobilePage from "./pages/MobilePage";

import ContentWrapper from "./components/ContentWrapper";

import PingManager from "./components/PingManager";
import makeRoutes from "./utils/makeRoutes";
import UserManager from "./components/UserManager";
import Tracker from "./components/Tracker";
import DimensionsManager from "./components/DimensionsManager";
import {
	AxiosManager,
	ModalManager,
	THEME,
	ThemeManager,
} from "@disco/disco_core";
import { axiosRequestInterceptor } from "./utils/createApiAxios";
import { useMemo } from "react";
import TraitManager from "./managers/TraitManager";

const stripePromise = loadStripe(STRIPE_SECRET);

const IN_MAINTENANCE = false;

export const ContextWrapper = memo(({ children }) => {
	return (
		<AxiosManager
			BASE_URL={BASE_URL}
			REFRESH_TOKEN_URL={REFRESH_TOKEN_URL}
			requestInterceptor={axiosRequestInterceptor}
		>
			<ModalManager>
				<UserManager>
					<Elements stripe={stripePromise}>
						<ThemeManager defaultTheme={THEME.PLATFORM}>
							<Router>
								<ContentWrapper>
									<PingManager>
										<DimensionsManager>
											<TraitManager>
												<AnimatePresence>
													{children}
												</AnimatePresence>
											</TraitManager>
										</DimensionsManager>
									</PingManager>
								</ContentWrapper>
							</Router>
						</ThemeManager>
					</Elements>
				</UserManager>
			</ModalManager>
		</AxiosManager>
	);
});

export const MockedContextWrapper = memo(({ children }) => {
	return (
		<AxiosManager BASE_URL={BASE_URL} REFRESH_TOKEN_URL={REFRESH_TOKEN_URL}>
			<ModalManager>
				<UserManager>
					<ThemeManager defaultTheme={THEME.PLATFORM}>
						<Router>
							<ContentWrapper>
								<PingManager>
									<DimensionsManager>
										<TraitManager>
											<AnimatePresence>
												{children}
											</AnimatePresence>
										</TraitManager>
									</DimensionsManager>
								</PingManager>
							</ContentWrapper>
						</Router>
					</ThemeManager>
				</UserManager>
			</ModalManager>
		</AxiosManager>
	);
});

const App = memo(({ mock }) => {
	const main = useMemo(
		() => (
			<Routes>
				{makeRoutes(mock)}
				<Route path="*" element={<NotFoundPage />}></Route>
			</Routes>
		),
		[mock]
	);

	if (IN_MAINTENANCE) {
		return (
			<DimensionsManager>
				<Tracker>
					<MobilePage maintenance={IN_MAINTENANCE} />
				</Tracker>
			</DimensionsManager>
		);
	}

	return mock ? (
		<MockedContextWrapper>{main}</MockedContextWrapper>
	) : (
		<ContextWrapper>{main}</ContextWrapper>
	);
});

export default App;
