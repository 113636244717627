import axios from "axios";
import { tokenStorage, tokenHasExpired, isFunction } from ".";

const getNewAccessToken = async ({
	refreshToken,
	REFRESH_TOKEN_URL = "",
} = {}) => {
	if (!refreshToken) {
		tokenStorage.clear();
		return Promise.reject({ logout: true });
	}

	const data = {
		refresh: refreshToken,
	};

	let resp;
	try {
		resp = await axios.post(REFRESH_TOKEN_URL, data);
	} catch (error) {
		if (error.response) {
			tokenStorage.clear();
			return Promise.reject({ logout: true });
		} else {
			return Promise.reject({ logout: false });
		}
	}

	if (resp.data.access) {
		tokenStorage.set(resp.data.access);
		return resp.data.access;
	} else {
		tokenStorage.clear();
		return Promise.reject({ status: "logout" });
	}
};

export const getValidTokens = async ({ REFRESH_TOKEN_URL = "" } = {}) => {
	const { accessToken, refreshToken } = tokenStorage.get();
	if (!accessToken && !refreshToken) {
		tokenStorage.clear();
		return Promise.reject({ logout: true });
	}

	let token = accessToken;

	if (tokenHasExpired(accessToken)) {
		try {
			token = await getNewAccessToken({
				refreshToken,
				REFRESH_TOKEN_URL,
			});
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return { token };
};

const createApiAxios = ({
	BASE_URL = "",
	REFRESH_TOKEN_URL = "",
	requestInterceptor = null,
} = {}) => {
	const apiAxios = axios.create({
		baseURL: BASE_URL,
	});

	let interceptor = null;

	if (isFunction(requestInterceptor)) {
		interceptor = requestInterceptor;
	} else {
		interceptor = async (config) => {
			const { token } = await getValidTokens({ REFRESH_TOKEN_URL });
			config.headers.Authorization = `Bearer ${token}`;
			return config;
		};
	}

	apiAxios.interceptors.request.use(interceptor);

	apiAxios.interceptors.response.use(
		async (response) => response,
		(error) => {
			// if (error.response && (error.response.status === 401 || error.response.status === 403)) {
			if (error.response && error.response.status === 401) {
				tokenStorage.clear();
			}
			return Promise.reject(error);
		}
	);

	return apiAxios;
};

export default createApiAxios;
