import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthPage, FormStatus, Loader } from "../../components/CoreUI";
import Modal from "../../components/modals/Modal";
import { META_OAUTH_URL } from "../../conf";
import { PLATFORM_AUDIENCES_FACEBOOK_CONNECTED_SUCCESSFULLY } from "../../events";
import useResource from "../../hooks/useResource";
import useTrack from "../../hooks/useTrack";
import { userUpdateZendesk } from "../../utils/zendesk";

import "./processing-page.css";

const ProcessingPage = ({ user, setUser }) => {
	const params = new URLSearchParams(
		`?${window.location.hash.substring(1).trim()}`
	);
	const [modalOpen, setModalOpen] = useState(false);
	const [formStatus, setFormStatus] = useState({
		type: "error",
		data: `Whoops! An error occurred during processing. Please try again later.`,
	});
	const [accessToken] = useState(params.get("access_token"));
	const navigate = useNavigate();
	const [{ data, error }, trigger] = useResource(
		{
			url: META_OAUTH_URL,
			method: "POST",
			data: {
				access_token: accessToken,
			},
		},
		false
	);
	const track = useTrack();

	const handleModal = useCallback(() => {
		setModalOpen(false);
		navigate({
			pathname: user.isNewIa ? "/performance" : "/widget",
		});
	}, [setModalOpen, navigate, user]);

	useEffect(() => {
		userUpdateZendesk(user);
	}, [user]);

	useEffect(() => {
		if (!accessToken) {
			setModalOpen(true);
			return;
		}
		trigger();
	}, [accessToken, trigger]);

	useEffect(() => {
		if (error) {
			setModalOpen(true);
			return;
		}
		if (data) {
			setFormStatus({
				type: "success",
				data: "Facebook account linked successfully.",
			});
			track(PLATFORM_AUDIENCES_FACEBOOK_CONNECTED_SUCCESSFULLY, {});
			setUser((currUser) => ({
				...currUser,
				publisher: {
					...currUser.publisher,
					facebook_connected: true,
				},
			}));
			setModalOpen(true);
		}
	}, [data, error, accessToken, setUser, track]);

	return (
		<AuthPage className="processing-page" user={user} setUser={setUser}>
			<Loader />
			<h2>Hang tight while we crunch your data!</h2>
			<Modal
				open={modalOpen}
				onClose={handleModal}
				heading="Facebook Ad account"
			>
				<FormStatus type={formStatus.type}>
					{formStatus.data}
					<br />
					You'll be taken to the widget page
				</FormStatus>
			</Modal>
		</AuthPage>
	);
};

export default ProcessingPage;
