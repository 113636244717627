import React, { memo, forwardRef } from "react";
import genPackageClassName from "../../utils/genPackageClassName";
import { FONT_COLOR, FONT_SIZE, Text, TextProps } from "../Typography";

import "./label.css";
import { PaddedContainer, SPACING } from "../PaddedContainer";
import {
	GenericRef,
	Extend,
	StandardComponentWithChildren,
} from "../../shared.types";

type NativeLabelProps = {
	required?: boolean;
	inReview?: boolean;
	uppercase?: boolean;
	flex?: boolean;
};

export type LabelProps = Extend<TextProps, NativeLabelProps>;
type LabelComponent = StandardComponentWithChildren<LabelProps>;
export const Label: LabelComponent = memo(
	forwardRef(
		(
			{
				children,
				className = "",
				required = false,
				inReview = false,
				uppercase = false,
				flex = false,
				...props
			},
			ref?: GenericRef
		) => {
			return (
				<Text
					className={genPackageClassName({
						base: "label",
						conditionals: {
							"label-uppercase": uppercase,
							"label-flex": flex,
						},
						additional: className,
					})}
					color={FONT_COLOR.DARK}
					size={FONT_SIZE.BODY}
					Element="label"
					ref={ref}
					{...props}
				>
					{inReview && (
						<PaddedContainer
							Element="span"
							className={genPackageClassName({
								base: "label-review-badge",
							})}
							marginRight={SPACING.TINY}
							marginBottom={1.5}
						/>
					)}
					{required && !inReview && (
						<Text
							inline
							className={genPackageClassName({
								base: "label-required-badge",
							})}
							hPadding={SPACING.TINY}
							tightLeft
						>
							*
						</Text>
					)}
					{children}
				</Text>
			);
		}
	)
);
