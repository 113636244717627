import React from "react";
import { Link } from "react-router-dom";
import { BadgeButton, Page } from "../../components/CoreUI";
import "./not-found-page.css";

const NotFoundPage = React.memo(() => {
	return (
		<Page className="not-found-page">
			<h1>404</h1>
			<h2>
				<span role="img" aria-label="embarassed">
					😳
				</span>{" "}
				Oops
			</h2>
			<p>
				<br />
				It seems like we could not find the page for which you're
				looking.
				<br /> Let's take you back to the homescreen!
			</p>
			<Link to="/">
				<BadgeButton light>Back home</BadgeButton>
			</Link>
		</Page>
	);
});

export default NotFoundPage;
