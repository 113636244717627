import React, {
	forwardRef,
	memo,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import SettingsModule, { SettingsModuleSection } from "./SettingsModule";
import NewEditView from "../NewEditView";
import {
	SAVE_BRAND_URL,
	SAVE_NAME_URL,
	SAVE_DESCRIPTION_URL,
	SAVE_LONG_DESCRIPTION_URL,
	PROFILE_IMAGE_UPLOAD_URL,
	BRAND_INFO_URL,
	TOOL_OPTIONS,
	IMS_OPTIONS,
} from "../../conf";
import BrandSetupModalSelector from "../BrandSetupModalSelector";
import useResource from "../../hooks/useResource";
import BadgesModalSelector from "../BadgesModalSelector";
import CategoryOptInModal from "../CategoryOptInModalSelector";
import {
	PaddedContainer,
	SPACING,
	RemoteSelect,
	Select,
	Loader,
	Button,
} from "@disco/disco_core";

import useIdentity from "../../hooks/useIdentity";

import "./brand-module.css";
import NewEditImage from "../NewEditImage";

const BrandModule = memo(
	forwardRef((_, ref) => {
		const { user, setUser } = useIdentity();

		const mounted = useRef(true);

		useEffect(() => {
			mounted.current = true;
			return () => (mounted.current = false);
		}, []);

		const imageSelector = useCallback(
			(user) => user?.publisher?.logo_url,
			[]
		);
		const draftImageSelector = useCallback(
			(user) => user?.draft?.logo_url,
			[]
		);

		const handleDone = useCallback(
			(img) =>
				setUser((user) => ({
					...user,
					draft: { ...user.draft, logo_url: img },
				})),
			[setUser]
		);
		const [tools, setTools] = useState(
			user.publisher.third_party_tools ?? []
		);
		const [imsSuccess, setImsSuccess] = useState(false);

		const handleToolsChange = useCallback(
			({ target: { value } }) => {
				setTools(value);
				setUser((user) => ({
					...user,
					publisher: {
						...user.publisher,
						third_party_tools: value,
					},
				}));
			},
			[setUser]
		);

		const [ims, setIms] = useState(user.publisher.inventory_manager ?? "");
		const handleImsChange = useCallback(({ target: { value } }) => {
			setIms(value);
		}, []);

		const [{ loading, data }, saveIms, resetIms] = useResource(
			{
				url: BRAND_INFO_URL,
				method: "PUT",
				data: { inventory_manager: ims },
			},
			false
		);

		useEffect(() => {
			if (!data) return;
			setUser((user) => ({
				...user,
				publisher: {
					...user.publisher,
					inventory_manager: ims,
				},
			}));
			setImsSuccess(true);
			setTimeout(() => {
				if (mounted.current) {
					setImsSuccess(false);
				}
			}, 2000);
			resetIms();
		}, [data, ims, setUser, resetIms]);

		return (
			<SettingsModule ref={ref} className="brand-module">
				<SettingsModuleSection
					title="About your shop"
					tightTop
					tooltip="Change your shop settings to customize the look of your shop across Disco's network"
				>
					<PaddedContainer
						className="brand-module-grid"
						vPadding={SPACING.MEDIUM}
						tightBottom
					>
						<PaddedContainer className="brand-module-grid-row">
							<NewEditView
								user={user}
								setUser={setUser}
								name="Shop Name"
								placeholder="Edit your shop name"
								url={SAVE_NAME_URL}
								remoteKey="name"
								editable={false}
							/>
							<NewEditView
								draftable
								user={user}
								setUser={setUser}
								name="Shop URL"
								placeholder="Edit Shop URL"
								url={SAVE_BRAND_URL}
								remoteKey="hero_url"
								tooltipTitle="Enter your site's URL"
								tooltipText="We need your store's website address to redirect potential shoppers to your store."
							/>
						</PaddedContainer>
						<PaddedContainer className="brand-module-grid-row">
							<NewEditImage
								className="brand-module-edit-image"
								url={PROFILE_IMAGE_UPLOAD_URL}
								remoteKey="logo_url"
								user={user}
								updateMethod="POST"
								editText="Change Logo"
								selector={imageSelector}
								draftSelector={draftImageSelector}
								underReview={
									user?.draft?.logo_url &&
									user.draft.logo_url !==
										user.publisher.logo_url
								}
								onDone={handleDone}
								heading="Change Shop Logo"
								circular
							/>
						</PaddedContainer>
					</PaddedContainer>
					<PaddedContainer vPadding={SPACING.SMALL} />
					<NewEditView
						user={user}
						setUser={setUser}
						name="Monthly Order Volume"
						placeholder="Edit Monthly Order Volume"
						url={SAVE_BRAND_URL}
						remoteKey="monthly_order_volume"
						tooltipTitle="What is it? Why do you need it?"
						tooltipText="Enter an estimate of how many orders you receive each month. This will help you estimate the number of Disco give to get credits you'll receive."
					/>
					<PaddedContainer vPadding={SPACING.SMALL} />
					<NewEditView
						draftable
						user={user}
						setUser={setUser}
						name="Media Kit URL"
						placeholder="Edit Media Kit URL"
						url={SAVE_BRAND_URL}
						remoteKey="media_kit"
					/>
					<PaddedContainer vPadding={SPACING.SMALL} />
					<BadgesModalSelector
						user={user}
						setUser={setUser}
						editMode={user.publisher.under_review}
						designSystem
					/>
					<PaddedContainer vPadding={SPACING.SMALL} />
					<NewEditView
						draftable
						user={user}
						setUser={setUser}
						name="Long Shop Description"
						placeholder="Describe your shop in more detail"
						url={SAVE_LONG_DESCRIPTION_URL}
						remoteKey="description"
						className="brand-module-long-desc"
						textarea
					/>
				</SettingsModuleSection>
				<SettingsModuleSection title="Social Media">
					<PaddedContainer
						className="brand-module-grid"
						vPadding={SPACING.MEDIUM}
						tightBottom
					>
						<PaddedContainer>
							<NewEditView
								draftable
								user={user}
								setUser={setUser}
								name="Instagram Handle"
								placeholder="Edit Instagram Handle"
								url={SAVE_BRAND_URL}
								remoteKey="instagram"
							/>
						</PaddedContainer>
						<PaddedContainer>
							<NewEditView
								draftable
								user={user}
								setUser={setUser}
								name="Twitter Handle"
								placeholder="Edit Twitter Handle"
								url={SAVE_BRAND_URL}
								remoteKey="twitter"
							/>
						</PaddedContainer>
					</PaddedContainer>
				</SettingsModuleSection>
				<SettingsModuleSection title="Shop Categories">
					<PaddedContainer
						className="brand-module-grid"
						vPadding={SPACING.MEDIUM}
						tightBottom
					>
						<PaddedContainer>
							<BrandSetupModalSelector
								user={user}
								setUser={setUser}
								key="brand_subcategory_modal_selector"
								editMode
								designSystem
								onlyCategories
							/>
						</PaddedContainer>
						<PaddedContainer>
							<BrandSetupModalSelector
								user={user}
								setUser={setUser}
								key="brand_subcategory_modal_selector"
								editMode
								designSystem
								onlySubcategories
							/>
						</PaddedContainer>
						<CategoryOptInModal
							user={user}
							setUser={setUser}
							editMode={user.publisher.under_review}
							className="brand-module-category-opt-in"
							designSystem
						/>
					</PaddedContainer>
				</SettingsModuleSection>
				<SettingsModuleSection title="Toolset">
					<PaddedContainer
						className="brand-module-grid"
						vPadding={SPACING.MEDIUM}
						tightBottom
					>
						<RemoteSelect
							placeholder="Select third party tools"
							localOptions={TOOL_OPTIONS}
							saveUrl={BRAND_INFO_URL}
							remoteKey="third_party_tools"
							label="What third-party tools do you use"
							value={tools}
							onChange={handleToolsChange}
							setUser={setUser}
						/>
						<PaddedContainer>
							<Select
								placeholder="Select inventory management system"
								options={IMS_OPTIONS}
								label="Which inventory management system do you use"
								value={ims}
								onChange={handleImsChange}
								loading={loading}
								containerClassName={
									"brand-module-ims-container"
								}
								className={"brand-module-ims"}
							>
								{loading ? (
									<Loader />
								) : (
									<Button
										small
										disabled={
											user.publisher.inventory_manager ===
												ims || imsSuccess
										}
										onClick={saveIms}
										// icon={imsSuccess ? <CheckIcon /> : null}
										className="brand-module-ims-btn"
										rounded={false}
									>
										{imsSuccess ? "Saved" : "Save"}
									</Button>
								)}
							</Select>
						</PaddedContainer>
					</PaddedContainer>
				</SettingsModuleSection>
			</SettingsModule>
		);
	})
);

export default BrandModule;
