import React, {
	PaddedContainer,
	RemoteSelect,
	SPACING,
	Text,
} from "@disco/disco_core";
import { forwardRef, memo, useCallback } from "react";
import { AUDIENCES_PROVIDERS, AUDIENCES_PROVIDER_DATA } from "../../conf";
import { PLATFORM_AUDIENCES_SELECT_AD_ACCOUNT } from "../../events";
import useIdentity from "../../hooks/useIdentity";
import useModal from "../../hooks/useModal";
import useTrack from "../../hooks/useTrack";
import { keyMapperFactory } from "../../utils/selectMappers";
import { FacebookAdAccountConfiguratorModal } from "../FacebookAudienceManager";
import "./audiences-ad-account.css";

const mapper = keyMapperFactory("id");

export const AudiencesAdAccount = memo(
	forwardRef(({ provider, account, setAccount, disabled, ...rest }, ref) => {
		const { fetchAdAccountsUrl, connectionKey, name } =
			AUDIENCES_PROVIDER_DATA[provider];

		const { user, setUser } = useIdentity();

		const track = useTrack();

		const handleChange = useCallback(
			({ target: { value } }) => {
				track(PLATFORM_AUDIENCES_SELECT_AD_ACCOUNT, {
					integration: name,
					account: value,
				});
				setAccount(value);
			},
			[setAccount, name, track]
		);

		const { open, handleOpen, handleClose } = useModal();

		const connected = user.publisher[connectionKey];

		return (
			<>
				<PaddedContainer className="audiences-ad-account">
					<RemoteSelect
						value={account}
						fetchUrl={fetchAdAccountsUrl}
						mapper={mapper}
						onChange={handleChange}
						disabled={!connected || disabled}
						placeholder={`${name} Ad Account`}
						searchable={false}
						multiple={false}
						emptyMessage="No accounts found"
						ref={ref}
						{...rest}
					/>
					{provider === AUDIENCES_PROVIDERS.META && (
						<Text
							inline
							marginTop={SPACING.SMALL}
							className="audiences-ad-accounts-configure"
						>
							Don't see your ad account?{" "}
							<Text inline thick onClick={handleOpen}>
								Configure.
							</Text>
						</Text>
					)}
				</PaddedContainer>
				<FacebookAdAccountConfiguratorModal
					open={open}
					onClose={handleClose}
					user={user}
					setUser={setUser}
				/>
			</>
		);
	})
);

export default AudiencesAdAccount;
