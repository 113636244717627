import React, { memo, forwardRef, useState, useCallback, useMemo } from "react";
import SettingsModule, { SettingsModuleSection } from "./SettingsModule";

import "./script-module.css";
import {
	GTMSetup,
	RechargeSetup,
	SquarespaceSetup,
	WooCommerceSetup,
} from "../ScriptSetup";
import {
	PaddedContainer,
	SPACING,
	TabSwitcher,
	Tag,
	TAG_TYPE,
} from "@disco/disco_core";

const SCRIPT_MODULE_TAB_DATA = [
	{ text: "Recharge", component: RechargeSetup },
	{ text: "Squarespace", component: SquarespaceSetup },
	{ text: "GTM", component: GTMSetup },
	{ text: "WooCommerce", component: WooCommerceSetup },
];
const ScriptModule = memo(
	forwardRef(({ user }, parentRef) => {
		const [activeTab, setActiveTab] = useState(0);
		const handleTabChange = useCallback(
			({ target: { value } }) => setActiveTab(value),
			[]
		);

		const Component = useMemo(
			() => SCRIPT_MODULE_TAB_DATA[activeTab].component,
			[activeTab]
		);
		const scriptType = useMemo(
			() => SCRIPT_MODULE_TAB_DATA[activeTab].text,
			[activeTab]
		);

		const headerChildren = useMemo(
			() => (
				<PaddedContainer
					className="script-module-header-children"
					flexContent
					marginLeft={SPACING.SMALL}
				>
					<TabSwitcher
						value={activeTab}
						options={SCRIPT_MODULE_TAB_DATA}
						onChange={handleTabChange}
						small
						resizeOnMount
						curved
					/>
				</PaddedContainer>
			),
			[user.publisher, activeTab, handleTabChange]
		);

		return (
			<SettingsModule className="script-module">
				<SettingsModuleSection
					tightTop
					tooltip="Setup Disco's script to start seeing the DiscoFeed on
					your order status page."
					title="Your Script"
					headerChildren={headerChildren}
				>
					<PaddedContainer
						className="script-setup"
						marginTop={SPACING.REGULAR}
					>
						<Component user={user} script scriptType={scriptType} />
					</PaddedContainer>
				</SettingsModuleSection>
			</SettingsModule>
		);
	})
);

export default ScriptModule;
