import React, {
	useEffect,
	useState,
	useCallback,
	useRef,
	memo,
	useMemo,
} from "react";
import { AnimatePresence, motion } from "framer-motion";
import { MdClose } from "react-icons/md";
import anim from "../../utils/anim";
import {
	FormStatus,
	Input,
	Loader,
	MiniBadge,
	ModalSelectBottomNavigator,
	ModalSelectHLine,
	ModalSelectWarningOverlay,
	ProgressBar,
	Select,
} from "../CoreUI";
import Modal from "../modals/Modal";
import useResource from "../../hooks/useResource";
import checkValueEquality from "../../utils/checkValueEquality";
import parseError from "../../utils/parseError";
import isFunction from "../../utils/isFunction";
import { BsFilter } from "react-icons/bs";

import "./modal-select.css";

export const BaseFinalizeModalSelectRowEmpty = memo(({ children, ...rest }) => (
	<span className="finalize-modal-row-empty" {...rest}>
		{children}
	</span>
));
export const BaseFinalizeModalSelectRow = memo(
	({ heading, children, ...props }) => {
		return (
			<div className="finalize-modal-row" {...props}>
				{heading && (
					<div className="finalize-modal-row-heading">{heading}</div>
				)}
				<div className="finalize-modal-row-content">{children}</div>
				<ModalSelectHLine className="finalize-modal-row-line" full />
			</div>
		);
	}
);

const FinalizeModalSelectRow = memo(
	({ sourceData, value, exclusion, ...props }) => {
		const filteredList = useMemo(
			() =>
				sourceData.subcategories.filter(
					(option) =>
						value.findIndex(
							(selectedOption) =>
								selectedOption.remote_id === option.remote_id
						) !== -1
				),
			[sourceData, value]
		);
		const heading = useMemo(
			() => (
				<>
					<span style={exclusion ? { color: "var(--red)" } : {}}>
						{exclusion ? "Excluded" : "Subcategories"}
					</span>
					{exclusion ? " from " : " in "}
					<b>{sourceData.name}</b>
				</>
			),
			[sourceData, exclusion]
		);

		return (
			<BaseFinalizeModalSelectRow heading={heading} {...props}>
				{filteredList.length !== 0 ? (
					filteredList.map((subCat, idx) => {
						return (
							<MiniBadge key={`${subCat.name.replace(/ /g, "")}`}>
								{subCat.name}
							</MiniBadge>
						);
					})
				) : (
					<BaseFinalizeModalSelectRowEmpty>
						{exclusion
							? "You have not excluded any subcategories from this category"
							: "You have not selected any subcategories from this category"}
					</BaseFinalizeModalSelectRowEmpty>
				)}
			</BaseFinalizeModalSelectRow>
		);
	}
);

export const BaseFinalizeModalSelect = memo(
	({ text, rows, children, className = "" }) => {
		return (
			<section className={`finalize-modal ${className}`}>
				<div className="finalize-modal-text">{text}</div>
				{children}
				{rows && (
					<>
						<ModalSelectHLine full />
						<div className="finalize-modal-rows">{rows}</div>
					</>
				)}
			</section>
		);
	}
);

export const FinalizeModalSelect = memo(
	({ value, allSubCategories, categories, exclusion = false }) => {
		const genericText = useMemo(() => {
			return (
				<>
					Your{" "}
					{allSubCategories.length === 1
						? "category is "
						: "categories are "}
					{allSubCategories.map((subCat, id) => {
						if (id !== allSubCategories.length - 1) {
							return (
								<b
									key={`${subCat.name.replace(
										/ /g,
										""
									)}_inline`}
								>
									{subCat.name},{" "}
								</b>
							);
						}
						if (id === 0) {
							return (
								<b
									key={`${subCat.name.replace(
										/ /g,
										""
									)}_inline`}
								>
									{subCat.name}
								</b>
							);
						}

						return (
							<>
								and{" "}
								<b
									key={`${subCat.name.replace(
										/ /g,
										""
									)}_inline`}
								>
									{subCat.name}
								</b>
							</>
						);
					})}
					. Within{" "}
					{allSubCategories.length === 1
						? "that category"
						: "those categories"}
					,
				</>
			);
		}, [allSubCategories]);

		const text = useMemo(
			() =>
				!exclusion ? (
					<>
						It's time to finalize your shop's subcategory
						selections. {genericText} your subcategories are
					</>
				) : (
					<>
						{genericText} you have excluded the following
						subcategories that are not a good fit for your shop
					</>
				),
			[exclusion, genericText]
		);

		const rows = useMemo(
			() =>
				allSubCategories.map((subCat, id) => (
					<FinalizeModalSelectRow
						sourceData={subCat}
						value={value}
						exclusion={exclusion}
						key={subCat.remote}
					/>
				)),

			[allSubCategories, value, exclusion]
		);

		return <BaseFinalizeModalSelect text={text} rows={rows} />;
	}
);

const ModalSelect = memo(
	({
		open = false,
		explicitLoading = false,
		user,
		setUser,
		closeModal,
		stepProps,
		setStepProps,
		specificOpen,
		setSpecificOpen,
		className = "",
		onboarding = false,
		onboardingLeft = false,
		onboardingFull = false,
		inlineModal = false,
		showNext,
		interStepBack,
		onFinish,
	}) => {
		const [currStep, setCurrStep] = useState(0);
		const [currSubStep, setCurrSubStep] = useState(0);

		const [userError, setUserError] = useState(false);
		const [showWarningOverlay, setShowWarningOverlay] = useState(false);

		const [hoveredItem, setHoveredItem] = useState(null);

		// used for opening a particular step when a subcategory is clicked
		const [searchValue, setSearchValue] = useState(false);

		// used in search bar
		const [searchBarValue, setSearchBarValue] = useState("");

		const modalInitialValue = useRef(null);

		const currStepProps = useMemo(() => {
			if (!Array.isArray(stepProps)) {
				return;
			}
			return stepProps[currStep];
		}, [stepProps, currStep]);

		const {
			title,
			hideAsideTitle = false,
			asideTitle,
			generateAsideTitles,
			mainTitle,

			buttonText,
			mainFooter,

			stepped,
			stepDataMapper,
			stepDataContent,

			badgeColor,
			badgeModifier,

			mainChildren,
			initialValue,
			requestValueMapper, // for morphing the value that will be sent in api request
			addValueMapper, // for morphing the value that will be saved in the value state at the time of adding a badge
			stepValueMapper, // for filtering out the value's that will be used in particular sub-step
			value,

			onDone, // when user changes the step entirely
			onClose, // when user exits manually
			onNext, // when user changes substep internally
			onBack, // when user changes step/substep internally, but to go back
			onSave, // when a save action is called [can be called along with onDone]

			split,
			required,
			validator,
			getSearchIndex,

			exitWarning,
			exitWarningTitle,
			exitWarningBody,

			fetchUrl,
			fetchRequestMethod,
			requestBody,
			dependantStates,
			bypassDraftDependantStates,
			requestBodyModifier,
			renderMainContentEmpty,

			saveUrl,
			remoteKey,
			directSave, // this props saves with remoteKey: value mapping and overrides the saveBody
			saveRequestMethod,
			draftable,

			onHover,
			preview,
			searchable,
			filters,
			sorter,
			defaultFilter,
		} = currStepProps;

		const [filterValue, setFilterValue] = useState(defaultFilter);
		useEffect(() => setFilterValue(defaultFilter), [open, defaultFilter]);

		const [{ data, error, loading }, fetch, reset] = useResource(
			{
				url: fetchUrl,
				method: fetchRequestMethod || "GET",
				data: { ...requestBody },
			},
			false
		);

		const requestValue = useMemo(
			() =>
				isFunction(requestValueMapper) && value
					? requestValueMapper({ value })
					: value,
			[value, requestValueMapper]
		);

		const [
			{ data: saveData, error: saveError, loading: saveLoading },
			save,
			resetSave,
		] = useResource(
			{
				url: saveUrl,
				method: saveRequestMethod || "PUT",
				data: directSave
					? {
							[remoteKey]: requestValue,
					  }
					: {
							...requestBody,
							[remoteKey]: requestValue,
					  },
			},
			false
		);

		const handleSearchBarChange = useCallback(
			({ target: { value } }) => setSearchBarValue(value),
			[]
		);
		const handleFilterChange = useCallback(
			({ target: { value } }) => setFilterValue(value),
			[]
		);
		const filterOptions = useMemo(
			() =>
				!filters || typeof filters !== "object"
					? {}
					: Object.entries(filters)
							.map(([key, { name }]) => ({
								[key]: name,
							}))
							.reduce((acc, curr) => ({ ...acc, ...curr }), {}),
			[filters]
		);
		const renderInlineFilterValue = useCallback(
			({ value }) => (
				<>
					<BsFilter />
					<span>Filter: </span>
					{value}
				</>
			),
			[]
		);

		const parsedStepDataContent = useMemo(
			() =>
				!isFunction(stepDataContent) || !stepped || !data || !value
					? []
					: stepDataContent({ data, value }),
			[stepDataContent, data, value, stepped]
		);

		const subStepData = useMemo(() => {
			if (stepDataMapper && isFunction(stepDataMapper) && data) {
				return stepDataMapper({ data });
			}
			return [];
		}, [stepDataMapper, data]);

		const currSubStepData = useMemo(() => {
			if (subStepData.length > 0) {
				return subStepData[currSubStep];
			}
			return {};
		}, [currSubStep, subStepData]);

		const parsedSourceData = useMemo(
			() => (!stepped ? data : parsedStepDataContent[currSubStep]),
			[stepped, data, parsedStepDataContent, currSubStep]
		);

		// parsed value is primarily used when you want different values
		// corresponding to each sub-step
		const parsedValue = useMemo(
			() =>
				!stepped || !isFunction(stepValueMapper) || !value || !data
					? value
					: stepValueMapper({
							value,
							parsedSourceData,
							user,
							currSubStep,
					  }),
			[
				value,
				stepped,
				stepValueMapper,
				currSubStep,
				data,
				user,
				parsedSourceData,
			]
		);

		const resetSearchValue = useCallback(() => setSearchValue(false), []);
		const handleClose = useCallback(
			(manualClose = false) => {
				resetSearchValue();
				if (isFunction(onFinish)) return onFinish();
				if (manualClose && isFunction(onClose)) {
					onClose();
				}
				if (isFunction(closeModal)) {
					closeModal();
				}
				setCurrStep(0);
				setCurrSubStep(0);
				setStepProps((stepProps) =>
					stepProps.map((stepProp) => ({
						...stepProp,
						requestBody: {},
					}))
				);
			},
			[resetSearchValue, setStepProps, onClose, closeModal, onFinish]
		);

		const handleManualClose = useCallback(() => {
			// TODO: UPDATE
			if (
				exitWarning &&
				!checkValueEquality(modalInitialValue.current, value)
			) {
				return setShowWarningOverlay(true);
			}
			handleClose(true);
		}, [exitWarning, handleClose, value]);

		const dismissWarning = useCallback(
			() => setShowWarningOverlay(false),
			[]
		);

		const confirmWarningExit = useCallback(() => {
			setShowWarningOverlay(false);
			handleClose(true);
		}, [handleClose]);

		const updateRequestBody = useCallback(
			(step) => {
				setStepProps((stepProps) => [
					...stepProps.slice(0, step),
					{
						...stepProps[step],
						requestBody: {
							...(step > 0
								? stepProps[step - 1].requestBody
								: {}),
							[remoteKey]: requestValue,
							...(step === currStep &&
							isFunction(requestBodyModifier)
								? requestBodyModifier({ value })
								: {}),
						},
					},
					...stepProps.slice(step + 1),
				]);
			},
			[
				remoteKey,
				value,
				requestBodyModifier,
				requestValue,
				setStepProps,
				currStep,
			]
		);

		const handleStepChange = useCallback(() => {
			if (isFunction(onDone)) {
				onDone({ currStep, requestBody, requestValue, remoteKey });
			}
			if (currStep === stepProps.length - 1) {
				handleClose();
			} else {
				setCurrSubStep(0);
				updateRequestBody(currStep + 1);
				setCurrStep((currStep) => currStep + 1);
			}
		}, [
			currStep,
			setCurrStep,
			onDone,
			handleClose,
			stepProps,
			updateRequestBody,
			requestBody,
			requestValue,
			remoteKey,
		]);

		const handleBack = useCallback(() => {
			setUserError(false);
			if (isFunction(onBack)) {
				onBack({ currSubStep });
			}
			if (currSubStep === 0 && currStep > 0) {
				setCurrStep((currStep) => currStep - 1);
			} else if (currSubStep > 0) {
				setCurrSubStep((currSubStep) => currSubStep - 1);
			}
		}, [currSubStep, currStep, onBack]);

		const handleNext = useCallback(() => {
			resetSearchValue();
			if (
				(required || validator) &&
				(!stepped || currSubStep < subStepData.length)
			) {
				const selectedValues = parsedSourceData.filter(
					(option) =>
						parsedValue.findIndex(
							(selectedOption) =>
								selectedOption.remote_id === option.remote_id
						) !== -1
				);
				if (required && selectedValues.length === 0) {
					return setUserError("Please select at least one");
				}
				if (isFunction(validator)) {
					const { isValid, errMessage } = validator({
						value: selectedValues,
						currSubStep,
					});
					if (!isValid) {
						return setUserError(errMessage);
					}
				}
			}
			if (stepped && currSubStep !== subStepData.length) {
				if (isFunction(onNext)) {
					onNext({ value, currSubStep });
				}
				setCurrSubStep((currSubStep) => currSubStep + 1);
				return;
			}
			updateRequestBody(currStep);
			if (saveUrl) {
				return save();
			}
			return handleStepChange();
		}, [
			validator,
			parsedSourceData,
			resetSearchValue,
			required,
			stepped,
			onNext,
			currSubStep,
			subStepData,
			saveUrl,
			save,
			value,
			handleStepChange,
			parsedValue,
			currStep,
			updateRequestBody,
		]);

		const handleBadgeAdd = useCallback(
			(badgeData) => {
				setUserError(false);
				setStepProps((stepProps) => [
					...stepProps.slice(0, currStep),
					{
						...stepProps[currStep],
						value: isFunction(addValueMapper)
							? addValueMapper({
									addValue: badgeData,
									currValue: [...stepProps[currStep].value],
									currSubStep,
							  })
							: [...stepProps[currStep].value, badgeData],
					},
					...stepProps.slice(currStep + 1),
				]);
			},
			[currStep, setStepProps, addValueMapper, currSubStep]
		);

		const handleBadgeRemove = useCallback(
			(badgeData) => {
				setUserError(false);
				setStepProps((stepProps) => [
					...stepProps.slice(0, currStep),
					{
						...stepProps[currStep],
						value: [...stepProps[currStep].value].filter(
							(selectedBadge) =>
								selectedBadge.remote_id !== badgeData.remote_id
						),
					},
					...stepProps.slice(currStep + 1),
				]);
			},
			[currStep, setStepProps]
		);

		const updateUserState = useCallback(
			(data) => {
				const filterObj = {
					...requestBody,
					...dependantStates,
					[remoteKey]: "",
				};
				const newValues = Object.entries(data)
					.filter(
						(saveDataItem) =>
							Object.entries(filterObj)
								.map((item) => item[0])
								.indexOf(saveDataItem[0]) !== -1
					)
					.reduce(
						(prev, [key, val]) => ({
							...prev,
							[key]: val,
						}),
						{}
					);

				setUser((user) => {
					const newUser = { ...user };
					if (draftable) {
						newUser.draft = {
							...newUser.draft,
							...newValues,
						};
					} else {
						newUser.publisher = {
							...newUser.publisher,
							...newValues,
						};
					}
					if (
						bypassDraftDependantStates &&
						typeof bypassDraftDependantStates === "object"
					) {
						const filteredNewValues = Object.entries(newValues)
							.filter(
								(newValue) =>
									Object.entries(bypassDraftDependantStates)
										.map((item) => item[0])
										.indexOf(newValue[0]) !== -1
							)
							.reduce(
								(prev, [key, val]) => ({
									...prev,
									[key]: val,
								}),
								{}
							);
						newUser.publisher = {
							...newUser.publisher,
							...filteredNewValues,
						};
					}
					if (data.under_review && draftable) {
						newUser.publisher.under_review = true;
					}
					return newUser;
				});
			},
			[
				draftable,
				requestBody,
				setUser,
				remoteKey,
				dependantStates,
				bypassDraftDependantStates,
			]
		);

		const handleSave = useCallback(
			(data) => {
				resetSave();
				if (isFunction(onSave)) {
					onSave(data);
				}
				updateUserState(data);
				handleStepChange();
			},
			[onSave, updateUserState, resetSave, handleStepChange]
		);

		const handleBadgeMouseEnter = useCallback(
			(value) => {
				if (isFunction(onHover)) {
					onHover(value);
				}
				setHoveredItem(value);
			},
			[onHover]
		);

		useEffect(() => {
			if (saveError) {
				setUserError(parseError(saveError, true));
				return;
			}
			if (saveData) {
				handleSave(saveData);
				return;
			}
		}, [saveData, handleSave, saveError]);

		useEffect(() => {
			if (!open) {
				return;
			}
			setStepProps((stepProps) => {
				let retValue = (
					draftable && user?.draft[remoteKey]
						? user.draft
						: user.publisher
				)[remoteKey];

				if (initialValue) {
					retValue = initialValue;
				}
				modalInitialValue.current = [...retValue];

				return [
					...stepProps.slice(0, currStep),
					{
						...stepProps[currStep],
						value: [...retValue],
					},
					...stepProps.slice(currStep + 1),
				];
			});
		}, [
			open,
			draftable,
			currStep,
			setStepProps,
			user,
			initialValue,
			remoteKey,
		]);

		useEffect(() => {
			reset();
		}, [currStep, reset]);

		useEffect(() => {
			if (!open) {
				modalInitialValue.current = null;
				setUserError(false);
				reset();
			}
		}, [open, reset]);

		useEffect(() => {
			if (!data && open) {
				setShowWarningOverlay(false);
				fetch();
			}
		}, [fetch, data, open]);

		useEffect(() => {
			if (
				!data ||
				!searchValue ||
				!stepped ||
				!isFunction(getSearchIndex)
			) {
				return;
			}
			setCurrSubStep(getSearchIndex({ data, searchValue }));
			setSearchValue(false);
		}, [searchValue, data, stepped, getSearchIndex, setSpecificOpen]);

		useEffect(() => {
			if (!specificOpen) {
				return;
			}
			const { currStep, searchValue } = specificOpen;
			// reset();
			setCurrStep(currStep);
			if (searchValue) {
				setSearchValue(searchValue);
			}
			setSpecificOpen(false);
		}, [setSpecificOpen, fetch, specificOpen]);

		// const selectedValues = useMemo(() => {
		// 	if (
		// 		!data ||
		// 		(stepped && currSubStep >= parsedStepDataContent.length) ||
		// 		!value
		// 	) {
		// 		return [];
		// 	}
		// 	console.log({ parsedSourceData, value });
		// 	return parsedSourceData.filter(
		// 		(option) => value.indexOf(option.remote_id) !== -1
		// 	);
		// }, [
		// 	stepped,
		// 	parsedSourceData,
		// 	data,
		// 	parsedStepDataContent,
		// 	currSubStep,
		// 	value,
		// ]);

		const renderBadgeList = useCallback(
			({ source = false }) => {
				if (
					!Array.isArray(parsedSourceData) ||
					!Array.isArray(parsedValue)
				) {
					return null;
				}

				const searchFilter = ({ name }) =>
					!name || !searchable || !source
						? true
						: name
								.toLowerCase()
								.indexOf(searchBarValue.toLowerCase()) !== -1;

				const dropDownFilter = (option) =>
					!filters ||
					!filterValue ||
					!filters?.[filterValue]?.filter ||
					!source
						? true
						: filters[filterValue].filter({ option });

				let filteredList = parsedSourceData
					.filter(searchFilter)
					.filter(dropDownFilter)
					.filter((option) => {
						const searchIdx = parsedValue.findIndex(
							(selectedOption) =>
								selectedOption.remote_id === option.remote_id
						);
						return source ? searchIdx === -1 : searchIdx !== -1;
					});

				if (isFunction(sorter)) {
					filteredList = [...filteredList].sort((a, b) =>
						sorter({ a, b, source })
					);
				}

				if (filteredList.length === 0) {
					if (source) {
						return (
							<div className="modal-select-aside-empty">
								No results found
							</div>
						);
					}
					return (
						<div className="modal-select-main-content-empty">
							{(isFunction(renderMainContentEmpty) &&
								renderMainContentEmpty({
									handleNext,
									currSubStep,
								})) ||
								"Not Selected"}
						</div>
					);
				}

				return filteredList.map((option, idx, list) => {
					const { name, remote_id } = option;
					const handleClick = () =>
						(source ? handleBadgeAdd : handleBadgeRemove)(option);
					const handleMouseEnter = () =>
						handleBadgeMouseEnter(option);

					const title =
						source && isFunction(generateAsideTitles)
							? generateAsideTitles({ option, idx, list })
							: null;

					const renderBadge = !isFunction(badgeModifier)
						? name
						: badgeModifier({ source, option, idx, list });

					return (
						<>
							{title && (
								<div className="modal-select-aside-title">
									{title}
								</div>
							)}
							<MiniBadge
								key={`${remote_id}`}
								onClick={handleClick}
								onMouseEnter={handleMouseEnter}
								icon={source ? "add" : "cross"}
								{...(source ? { theme: true } : badgeColor)}
							>
								{renderBadge}
							</MiniBadge>
						</>
					);
				});
			},
			[
				searchable,
				searchBarValue,
				badgeColor,
				handleBadgeAdd,
				handleBadgeRemove,
				handleBadgeMouseEnter,
				parsedSourceData,
				sorter,
				parsedValue,
				filters,
				generateAsideTitles,
				filterValue,
				badgeModifier,
				renderMainContentEmpty,
				handleNext,
				currSubStep,
			]
		);

		if (!Array.isArray(stepProps)) {
			return;
		}

		return (
			<Modal
				inline={inlineModal}
				open={open}
				customModal
				onClose={handleManualClose}
				onboarding={onboarding}
				onboardingLeft={onboardingLeft}
				onboardingFull={onboardingFull}
				preview={preview}
				hoveredItem={hoveredItem}
			>
				<section className={`modal-select-container ${className}`}>
					<section className="modal-select-header">
						<div className="modal-select-header-basic">
							<h2 className="modal-select-title">{title}</h2>
							{!inlineModal && (
								<div
									className="modal-select-closer"
									onClick={handleManualClose}
								>
									<MdClose />
								</div>
							)}
						</div>
						{data && stepped && currStepProps.stepDataMapper && (
							<motion.div
								className="modal-select-stepper"
								variants={anim.staggeredFade}
								initial="initial"
								animate="animate"
								exit="exit"
							>
								<ProgressBar
									stepData={subStepData}
									activeStep={currSubStep}
									setActiveStep={setCurrSubStep}
								/>
							</motion.div>
						)}
						{searchable && (
							<section className="modal-select-utils">
								{searchable && (
									<Input
										onChange={handleSearchBarChange}
										placeholder="Search"
										value={searchBarValue}
									/>
								)}
								{filters && (
									<Select
										padded
										options={filterOptions}
										onChange={handleFilterChange}
										value={filterValue}
										renderInlineValue={
											renderInlineFilterValue
										}
									/>
								)}
							</section>
						)}
					</section>
					<section className="modal-select-content">
						{(loading || explicitLoading) && (
							<Loader className="modal-select-loader" />
						)}
						{!explicitLoading && data && parsedValue && (
							<>
								{split &&
									(!stepped ||
										currSubStep !== subStepData.length) && (
										<motion.aside
											className="modal-select-aside"
											variants={anim.horizontalSlide}
											initial="initial"
											animate="animate"
											exit="exit"
											layout
											key="modal-select-aside"
										>
											{!hideAsideTitle && (
												<div className="modal-select-aside-title">
													{asideTitle
														? isFunction(asideTitle)
															? asideTitle({
																	currSubStepData,
																	currSubStep,
															  })
															: asideTitle
														: "Please select from the following"}
												</div>
											)}
											<div className="modal-select-aside-content">
												{renderBadgeList({
													source: true,
												})}
											</div>
										</motion.aside>
									)}
								<motion.main
									className="modal-select-main"
									layout
									transition={{ duration: 0.2 }}
									variants={anim.horizontalSlide}
									initial="initial"
									animate="animate"
									exit="exit"
									key="modal-select-main"
								>
									{stepped &&
									currSubStep === subStepData.length
										? currStepProps.finalizeComponent({
												value,
												data,
												subStepData,
										  })
										: mainChildren || (
												<React.Fragment
													key={
														"modal-select-main-children-default"
													}
												>
													<div className="modal-select-main-title">
														{mainTitle
															? isFunction(
																	mainTitle
															  )
																? mainTitle({
																		currSubStepData,
																  })
																: mainTitle
															: "You have selected the following"}
													</div>
													<div className="modal-select-main-content">
														{renderBadgeList({
															source: false,
														})}
													</div>
													<ModalSelectHLine full />
													{mainFooter && (
														<div className="modal-select-main-footer">
															{isFunction(
																mainFooter
															)
																? mainFooter({
																		value,
																		currSubStepData,
																  })
																: mainFooter}
														</div>
													)}
												</React.Fragment>
										  )}
									{userError && (
										<FormStatus className="modal-select-user-err">
											{userError}
										</FormStatus>
									)}
									<ModalSelectBottomNavigator
										stepped={stepped}
										currSubStep={currSubStep}
										currStep={currStep}
										stepProps={stepProps}
										handleBack={handleBack}
										handleNext={handleNext}
										subStepData={subStepData}
										buttonText={buttonText}
										loading={saveLoading}
										showNext={showNext}
										interStepBack={interStepBack}
									/>
								</motion.main>
							</>
						)}
					</section>
					<AnimatePresence>
						{showWarningOverlay && (
							<ModalSelectWarningOverlay
								warningTitle={exitWarningTitle}
								warningBody={exitWarningBody}
								handleDismiss={dismissWarning}
								handleExit={confirmWarningExit}
							/>
						)}
					</AnimatePresence>
				</section>
			</Modal>
		);
	}
);

export default ModalSelect;
