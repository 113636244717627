import React, {
	forwardRef,
	memo,
	useLayoutEffect,
	useRef,
	useState,
	useMemo,
	useEffect,
	useCallback,
} from "react";
import PropTypes from "prop-types";
import genPackageClassName from "../../utils/genPackageClassName";

import "./tab-switcher.css";
import { PaddedContainer, SPACING } from "../PaddedContainer";
import { FONT_SIZE, Text } from "../Typography";
import { Loader } from "../Loader";
import {
	childrenPropTypes,
	easeInTransitionFactory,
	isFunction,
	mergeRefs,
} from "../../utils";

export const TabSwitcher = memo(
	forwardRef(
		(
			{
				className = "",
				value = 0,
				options,
				onChange,
				onFocus,
				name,

				curved = true,
				disabled = false,
				loading = false,
				small = false,
				compact = false,

				animateModifier,
				optionProps,

				resizeOnMount = false,

				...props
			},
			ref
		) => {
			const optionsRef = useRef([]);
			const parentRef = useRef();
			const resizeTracker = useRef(false);

			const [activeBox, setActiveBox] = useState(null);

			const updateActiveBox = useCallback(() => {
				if (!optionsRef.current[value]) return null;
				setActiveBox(optionsRef.current[value].getBoundingClientRect());
			}, [value]);

			useLayoutEffect(() => {
				updateActiveBox();
			}, [value, options, updateActiveBox]);

			useEffect(() => {
				// All of this logic is dynamically resize tab if it is being animated on mount
				if (!resizeOnMount || resizeTracker.current) return;
				if (typeof resizeOnMount === "boolean") {
					let elapsedTime = 0;

					const delta = 50;
					const LIMIT = 5000;

					let prevDims =
						optionsRef?.current?.[value]?.getBoundingClientRect();

					const ref = setInterval(() => {
						if (elapsedTime > LIMIT || resizeTracker.current)
							clearInterval(ref);
						if (prevDims) {
							const newDims =
								optionsRef?.current?.[
									value
								]?.getBoundingClientRect();
							const isChanging = (param) =>
								Math.abs(newDims?.[param] - prevDims?.[param]) >
								0.05;
							if (isChanging("height") || isChanging("width")) {
								updateActiveBox();
								prevDims = newDims;
							} else {
								resizeTracker.current = true;
							}
						}
						elapsedTime += delta;
					}, delta);

					return;
				}
				setTimeout(() => updateActiveBox(), resizeOnMount);
			}, [updateActiveBox, resizeOnMount, value]);

			const handleChange = (value) => {
				if (!isFunction(onChange) || disabled || loading) return;
				onChange({ target: { name, value } });
			};

			const modifier = useMemo(() => {
				const {
					left = -6,
					width = 10,
					height = 2,
				} = animateModifier || {};

				return { left, width, height };
			}, [animateModifier]);

			const animate = useMemo(
				() =>
					activeBox
						? {
								left:
									activeBox.left -
									(parentRef.current
										? parentRef.current.getBoundingClientRect()
												.x
										: 0) +
									modifier.left,
								width: activeBox.width + modifier.width,
								height: activeBox.height + modifier.height,
						  }
						: {},
				[activeBox, parentRef, modifier]
			);

			return (
				<PaddedContainer
					className={genPackageClassName({
						base: "tab-switcher",
						conditionals: {
							"tab-switcher-curved": curved,
							"tab-switcher-disabled": disabled,
							"tab-switcher-small": small,
							"tab-switcher-compact": compact,
						},
						additional: className,
					})}
					ref={mergeRefs(ref, parentRef)}
					onFocus={onFocus}
					hPadding={SPACING.MICRO}
					{...props}
				>
					<PaddedContainer
						motionElement
						Element="div"
						className={genPackageClassName({
							base: "tab-switcher-highlight",
						})}
						transition={easeInTransitionFactory(0.3)}
						animate={animate}
					/>
					{options.map(
						(
							{
								before,
								after,
								text,
								textProps,
								beforeProps,
								afterProps,
							},
							idx
						) => {
							const isActive = idx === value;
							return (
								<PaddedContainer
									className={genPackageClassName({
										base: "tab-switcher-option",
										conditionals: {
											"tab-switcher-option-active":
												isActive,
										},
									})}
									ref={(el) => {
										optionsRef.current[idx] = el;
									}}
									key={idx}
									onClick={() => handleChange(idx)}
									hPadding={
										SPACING[compact ? "SMALL" : "REGULAR"]
									}
									vPadding={SPACING.TINY}
									{...optionProps}
								>
									{before && (
										<PaddedContainer
											Element="div"
											className={genPackageClassName({
												base: "tab-switcher-tab-option-before",
											})}
											marginRight={SPACING.TINY}
											{...beforeProps}
										>
											{isFunction(before)
												? before({ active: isActive })
												: before}
										</PaddedContainer>
									)}
									<Text
										size={
											FONT_SIZE[small ? "LABEL" : "BODY"]
										}
										thick={!small}
										className={genPackageClassName({
											base: "tab-switcher-tab-option-text",
										})}
										{...textProps}
									>
										{text}
									</Text>
									{after && (
										<PaddedContainer
											Element="div"
											className={genPackageClassName({
												base: "tab-switcher-tab-option-after",
											})}
											marginLeft={SPACING.TINY}
											{...afterProps}
										>
											{isFunction(after)
												? after({ active: isActive })
												: after}
										</PaddedContainer>
									)}
									{isActive && loading && (
										<PaddedContainer
											className={genPackageClassName({
												base: "tab-switcher-tab-loader",
											})}
										>
											<Loader center small />
										</PaddedContainer>
									)}
								</PaddedContainer>
							);
						}
					)}
				</PaddedContainer>
			);
		}
	)
);

export const tabSwitcherOptionPropTypesShape = {
	text: childrenPropTypes,
	before: childrenPropTypes,
	after: childrenPropTypes,
	textProps: PropTypes.object,
	beforeProps: PropTypes.object,
	afterProps: PropTypes.object,
};
TabSwitcher.displayName = "TabSwitcher";
TabSwitcher.propTypes = {
	className: PropTypes.string,
	value: PropTypes.number,
	options: PropTypes.arrayOf(
		PropTypes.shape(tabSwitcherOptionPropTypesShape)
	),
	curved: PropTypes.bool,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	small: PropTypes.bool,
	compact: PropTypes.bool,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	name: PropTypes.string,
	animateModifier: PropTypes.object,
	optionProps: PropTypes.object,
	resizeOnMount: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};
