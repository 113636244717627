import React, {
	memo,
	useState,
	forwardRef,
	ChangeEventHandler,
	ChangeEvent,
	ComponentPropsWithoutRef,
	ForwardedRef,
} from "react";

import "./file-input.css";
import { PaddedContainer, SPACING } from "../PaddedContainer";
import { Button } from "../Button";
import { FONT_COLOR, FONT_SIZE, Text } from "../Typography";
import genPackageClassName from "../../utils/genPackageClassName";
import { Extend } from "../../shared.types";

export type NativeFileInputProps = {
	onChange?: ChangeEventHandler<HTMLInputElement>;
	className?: string;
};

export type FileInputProps = Extend<
	ComponentPropsWithoutRef<"input">,
	NativeFileInputProps
>;

type FileInputComponent = (
	props: FileInputProps & { ref?: ForwardedRef<HTMLInputElement> }
) => React.ReactNode | null;

export const FileInput: FileInputComponent = memo(
	forwardRef(
		(
			{ onChange, className = "", ...rest },
			ref?: ForwardedRef<HTMLInputElement>
		) => {
			const [file, setFile] = useState<File | null | undefined>(null);

			const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
				e.target.files && setFile(e.target.files[0]);
				if (typeof onChange !== "function") return;
				onChange(e);
			};

			return (
				<PaddedContainer
					className={genPackageClassName({
						base: "file-input",
						additional: className,
					})}
				>
					<Text
						className={genPackageClassName({
							base: "file-input-selection",
						})}
						size={FONT_SIZE.BODY}
						color={FONT_COLOR.MID}
						centerContent
						Element="section"
					>
						Selected:{" "}
						<Text inline thick hPadding={SPACING.MICRO} ellipsis>
							{file ? file?.name || "" : "None"}
						</Text>
					</Text>

					<PaddedContainer
						className={genPackageClassName({
							base: "file-input-action",
						})}
						Element="label"
						marginLeft="auto"
					>
						<Button
							secondary
							disabled
							vPadding={SPACING.TINY}
							Element="p"
							thick
						>
							{file ? "Replace file" : "Select a file"}
						</Button>
						<input
							type="file"
							multiple={false}
							onChange={handleChange}
							ref={ref}
							{...rest}
						/>
					</PaddedContainer>
				</PaddedContainer>
			);
		}
	)
);
