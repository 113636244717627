export const getFileType = (file) => {
	if (!file) {
		return null;
	}
	const parts =
		typeof file === "object" && typeof file.name === "string"
			? file.name.split(".")
			: file.split(".");

	const type = parts[parts.length - 1].toLowerCase();
	return type;
};
export const isImageGIF = (image) => {
	if (!image) {
		return false;
	}
	const type = getFileType(image);
	return type === "gif";
};

export const isImage = (image, validTypes) => {
	if (!image) {
		return false;
	}
	const type = getFileType(image);
	return validTypes.indexOf(type) !== -1;
};
