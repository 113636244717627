import React, { memo } from "react";
import AlertBarPaymentModule from "../AlertBarModules/AlertBarPaymentModule";

import "./alert-bar.css";

const AlertBar = memo(({ user, setUser }) => {
	return (
		<section className="alert-bar">
			<AlertBarPaymentModule user={user} setUser={setUser} />
		</section>
	);
});

export default AlertBar;
