import React, { useCallback, useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import useResource from "../../hooks/useResource";
import useTrack from "../../hooks/useTrack";
import {
    BadgeButton,
    BrandPreview,
    Button,
    FormStatus,
    Loader,
} from "../CoreUI";
import Modal from "./Modal";
import "./edit-select-list.css";

const EditSelectedModal = React.memo(
	({
		selected,
		open,
		heading = "Edit",
		fetchUrl,
		saveUrl,
		remoteKey,
		eventName,
		empty = false,
		onSave,
		preview,
		previewTitle,
		refresh,
		subCategory,
		onlyOne,
		...rest
	}) => {
		const [shouldLoad, setShouldLoad] = useState(true);
		const [newSelected, setNewSelected] = useState([]);
		const [saveFormError, setSaveFormError] = useState("");
		const [searchValue, setSearchValue] = useState("");

		// For preview item
		const [previewItem, setPreviewItem] = useState(null);
		const track = useTrack();

		const [saveOptions, setSaveOptions] = useState({
			url: saveUrl,
			method: "PUT",
			data: {},
		});

		const [
			{ loading: fetchLoading, data: allItems, fetchError },
			load,
		] = useResource(
			{
				url: fetchUrl,
				method: "GET",
			},
			false
		);

		const [
			{ loading: saveLoading, data: saved, error: saveError },
			save,
			reset,
		] = useResource(saveOptions, false);

		useEffect(() => {
			if (open) {
				setNewSelected(selected);
			}
		}, [open, selected]);

		useEffect(() => {
			if ((!allItems || refresh) && open && shouldLoad) {
				setShouldLoad(false);
				load();
			}
		}, [allItems, open, shouldLoad, load, refresh]);

		useEffect(() => {
			if (open && refresh) {
				setShouldLoad(true);
			}
		}, [refresh, open]);

		useEffect(() => {
			if (saved && open) {
				onSave(saved);
				track(eventName, {
					[remoteKey]: saved[remoteKey],
				});
				reset();
			}
		}, [saved, onSave, allItems, remoteKey, open, reset, track, eventName]);

		const search = useCallback(
			({ name }) => {
				if (!searchValue) {
					return true;
				}

				return (
					name
						.replace(/ /g, "")
						.toLowerCase()
						.indexOf(
							searchValue.replace(/ /g, "").toLowerCase()
						) !== -1
				);
			},
			[searchValue]
		);

		const handleSave = () => {
			if (newSelected.length < 1 && !empty) {
				return setSaveFormError("Please select at least one item");
			}
			if (onlyOne && newSelected.length > 1) {
				return setSaveFormError("Please select only one item");
			}
			setSaveOptions((saveOptions) => ({
				...saveOptions,
				data: {
					[remoteKey]: newSelected.map((item) => item.remote_id),
				},
			}));
			save();
		};
		return (
			<Modal
				className="edit-select-list"
				open={open}
				{...rest}
				heading={heading}
				search
				onSearch={setSearchValue}
				extended={preview}
				customWidth={subCategory ? "1000px" : false}
			>
				{fetchLoading && <Loader center />}
				{fetchError && (
					<div>
						Whoops! Could not fetch categories. Try again later{" "}
					</div>
				)}
				{Array.isArray(allItems) && newSelected && (
					<>
						<section
							className={`${
								preview ? "modal-content-split" : ""
							}`}
						>
							<div
								className={`${
									preview ? "modal-group-split" : ""
								}`}
							>
								<div className="modal-group">
									<h2>Unselected</h2>
									{!subCategory
										? allItems.length > 0
											? allItems
													.filter(
														(item) =>
															newSelected.findIndex(
																(
																	selectedItem
																) =>
																	selectedItem.remote_id ===
																	item.remote_id
															) === -1 &&
															item.remote_id
													)
													.filter(search)
													.map((item) => (
														<BadgeButton
															listItem
															key={item.remote_id}
															onClick={() => {
																setNewSelected(
																	(
																		oldSelected
																	) => [
																		...oldSelected,
																		item,
																	]
																);
															}}
															onMouseEnter={() => {
																setPreviewItem(
																	item
																);
															}}
														>
															{item.name}
															<b>
																<BsPlus />
															</b>
														</BadgeButton>
													))
											: "No items found"
										: allItems.length > 0
										? allItems.map((category) => (
												<section key={category.name}>
													<h3>{category.name}</h3>
													{category.subcategories
														.filter(
															(item) =>
																newSelected.findIndex(
																	(
																		selectedItem
																	) =>
																		selectedItem.remote_id ===
																		item.remote_id
																) === -1 &&
																item.remote_id
														)
														.filter(search)
														.map((item) => (
															<BadgeButton
																listItem
																key={
																	item.remote_id
																}
																onClick={() => {
																	setNewSelected(
																		(
																			oldSelected
																		) => [
																			...oldSelected,
																			item,
																		]
																	);
																}}
																onMouseEnter={() => {
																	setPreviewItem(
																		item
																	);
																}}
															>
																{item.name}
																<b>
																	<BsPlus />
																</b>
															</BadgeButton>
														))}
												</section>
										  ))
										: "No items found"}
								</div>
								<div className="modal-group">
									<h2>Selected</h2>
									{newSelected.map((item) => (
										<BadgeButton
											listItem
											key={item.remote_id}
											onClick={() =>
												setNewSelected((oldSelected) =>
													oldSelected.filter(
														(oldItem) =>
															oldItem.remote_id !==
															item.remote_id
													)
												)
											}
											onMouseEnter={() => {
												setPreviewItem(item);
											}}
										>
											{item.name}
											<b>
												<MdClose />
											</b>
										</BadgeButton>
									))}
								</div>
							</div>
							{preview && (
								<div
									className={`${
										preview
											? "modal-group-split modal-group-split-preview"
											: ""
									}`}
								>
									<BrandPreview
										previewItem={previewItem}
										previewTitle={previewTitle}
									/>
								</div>
							)}
						</section>
						{!saveLoading && (
							<Button
								className="modal-action"
								onClick={handleSave}
							>
								Save
							</Button>
						)}
						{saveLoading && (
							<Loader className="modal-action-loader" />
						)}
						{saveFormError && (
							<FormStatus>Whoop! {saveFormError}</FormStatus>
						)}
						{saveError && (
							<FormStatus>
								Whoop!{" "}
								{Array.isArray(saveError.response.data.error) &&
								saveError.response.data.error.length > 0
									? `${saveError.response.data.error[0]}`
									: "Could not save. Try again later"}
							</FormStatus>
						)}
					</>
				)}
			</Modal>
		);
	}
);
export default EditSelectedModal;
