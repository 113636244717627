// TODO: Ensure you check typescript in genPackageClassName when modifying THEME
export const THEME = {
	PLATFORM: "PLATFORM",
	SHOP_DISCO: "SHOP_DISCO",
};

export const THEME_CLASS_NAME_MAP = {
	[THEME.PLATFORM]: "platform",
	[THEME.SHOP_DISCO]: "shop",
};

// Refresh token is it expires in < TOKEN_REFRESH_DELTA seconds
export const TOKEN_REFRESH_DELTA =
	process.env.REACT_APP_TOKEN_REFRESH_DELTA || 5;

export const IMAGE_TYPES = ["jpg", "jpeg", "png", "gif"];

const getExtension = (contentType) => {
	const parts = contentType.split("/");
	return `${parts[parts.length - 1]}` || "png";
};

export const MAX_IMG_SIZE = process.env.REACT_APP_MAX_IMG_SIZE || 15;

const BASE_IMAGE_UPLOAD_URL =
	process.env.REACT_APP_IMAGE_UPLOAD ||
	"https://pb2oaz46oe.execute-api.us-west-1.amazonaws.com/uploads";

const BUCKET = process.env.REACT_APP_BUCKET || "coopcommerce-staging";

export const BASE_IMG_URL =
	process.env.REACT_APP_BASE_IMG_URL ||
	`https://${BUCKET}.s3-us-west-1.amazonaws.com`;

// Must be included in any image name - Used by the backend to identify image files
const UNIQUE_IDENTIFIER =
	process.env.REACT_APP_UNIQUE_IDENTIFIER || "c13b-4c5c";

export const FETCH_IMAGE_DATA = (uid, contentType) => {
	const name = `${uid}_${new Date().getTime()}_${UNIQUE_IDENTIFIER}.${getExtension(
		contentType
	)}`;

	return [
		`${BASE_IMAGE_UPLOAD_URL}?key=${encodeURI(
			name
		)}&content_type=${encodeURI(getExtension(contentType))}`,
		`${BASE_IMG_URL}/${name}`,
	];
};
