import React, { useCallback, useState } from "react";
import { Label, WidgetModuleBadge } from "../../components/CoreUI";

import {
	FETCH_SURVEY_QUESTIONS_URL,
	REORDER_SURVEY_URL,
	SAVE_BRAND_URL,
	SAVE_SURVEY_QUESTION_URL,
} from "../../conf";
import ListView from "../ListView";
import SurveyQuestion from "../SurveyQuestion";
import SurveyQuestionModal from "../modals/SurveyQuestionModal";

import { PLATFORM_SURVEY_CLICK_ON_ADD_QUESTION } from "../../events";
import EditView from "../EditView";
import "./survey-builder.css";

const SurveyBuilder = React.memo(
	({
		user,
		setUser,
		onUpdate,
		handleSurveyOpen,
		handleSurveyClose,
		minimal = false,
	}) => {
		const [refresh, setRefresh] = useState(false);

		const renderHead = useCallback((question, id) => {
			return (
				<>
					<Label className="survey-closer-label">
						{/* {question.active && "✅ "} Survey */}
						Survey{" "}
					</Label>
					<h3 className="survey-question-text">
						{question.question_text}{" "}
						<WidgetModuleBadge
							filled
							type={question.active ? "success" : ""}
							inactive={!question.active}
							rounded
						>
							{question.active ? "Active" : "Inactive"}
						</WidgetModuleBadge>
					</h3>
				</>
			);
		}, []);

		const handleListUpdate = useCallback(
			(list) => {
				const active = list.reduce(
					(active, survey) => active || survey.active,
					false
				);

				setUser((user) => ({
					...user,
					publisher: { ...user.publisher, has_active_survey: active },
				}));
			},
			[setUser]
		);

		const renderContent = useCallback(
			(question, idx) => {
				return <SurveyQuestion user={user} question={question} />;
			},
			[user]
		);

		const reorderSaveOptionMapper = useCallback(
			(list) =>
				list.map((obj, idx) => ({ survey: obj.remote_id, order: idx })),
			[]
		);

		return (
			<ListView
				user={user}
				setUser={setUser}
				onUpdate={onUpdate}
				fetchUrl={FETCH_SURVEY_QUESTIONS_URL}
				toggleUrl={SAVE_SURVEY_QUESTION_URL}
				remoteKey="questions"
				objectRemoteKey="question"
				Modal={SurveyQuestionModal}
				objName="Question"
				onListUpdate={handleListUpdate}
				serviceName="Survey"
				renderHead={renderHead}
				renderContent={renderContent}
				refresh={refresh}
				setRefresh={setRefresh}
				onOpen={handleSurveyOpen}
				onClose={handleSurveyClose}
				dynamicList
				survey
				singleOpen
				minListRenderValue={2}
				disableDelete={minimal}
				reorder
				reorderOptionClassName="survey-builder-reorder-option"
				reorderRequestUrl={REORDER_SURVEY_URL}
				reorderSaveOptionMapper={reorderSaveOptionMapper}
				event={PLATFORM_SURVEY_CLICK_ON_ADD_QUESTION}
			>
				<section className="survey-builder-config">
					{!minimal && (
						<EditView
							user={user}
							setUser={setUser}
							url={SAVE_BRAND_URL}
							remoteKey="randomize_survey"
							label="Randomize your survey display order"
							toggle
						/>
					)}
				</section>
			</ListView>
		);
	}
);

export default SurveyBuilder;
