import { useContext, useEffect, useRef } from "react";
import { trackContext } from "../components/Tracker";

export default function useTrack(viewEvent = undefined, viewProperties = {}) {
	const track = useContext(trackContext);
	const viewFired = useRef(false);
	useEffect(() => {
		if (viewFired.current || !viewEvent) return;
		track(viewEvent, viewProperties);
		viewFired.current = true;
	}, [track, viewEvent]);
	return track;
}
