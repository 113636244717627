import React from "react";
import { AuthPage } from "../components/CoreUI";
import ShopifyCard from "../components/shopifyCards/ShopifyCard";
import ShopifyConnectCard from "../components/shopifyCards/ShopifyConnectCard";

const ShopifyPage = ({ user, setUser }) => {
	return (
		<AuthPage
			active="shopify"
			className="profile-page"
			user={user}
			setUser={setUser}
		>
			{user.shopify_shop && <ShopifyCard user={user} setUser={setUser} />}
			{!user.shopify_shop && <ShopifyConnectCard />}
		</AuthPage>
	);
};

export default React.memo(ShopifyPage);
