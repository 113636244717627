import React, { memo } from "react";
import { TOGGLE_PERMACART_URL } from "../../conf";
import EditView from "../EditView";
import FloatingModal from "./FloatingModal";
import "./permalink-modal.css";

const PermalinkModal = memo(({ user, setUser, ...rest }) => {
	return (
		<FloatingModal
			{...rest}
			className="permalink-settings-modal"
			heading="Cart Permalink Settings"
		>
			<EditView
				url={TOGGLE_PERMACART_URL}
				remoteKey="cross_sell_populate_cart"
				name="Direct User's to cart permalink"
				user={user}
				setUser={setUser}
				toggle
				// tooltipTitle="What does this do?"
				// tooltipText="Enable this to show prices on your upsell row"
			/>
		</FloatingModal>
	);
});

export default PermalinkModal;
