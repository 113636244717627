export default function validPhone(number) {
  if (!number || typeof number !== "string") {
    return false;
  }
  let stripped = number.replace("-", "").replace("(", "").replace(")", "");
  while (
    stripped.indexOf("-") !== -1 ||
    stripped.indexOf("(") !== -1 ||
    stripped.indexOf(")") !== -1
  ) {
    stripped = stripped.replace("-", "").replace("(", "").replace(")", "");
  }
  if (
    (number.length === 10 && !isNaN(number)) ||
    (stripped.length === 10 && !isNaN(stripped))
  ) {
    return isNaN(number) ? stripped : number;
  }
}
