import React, { createRef, memo, useState } from "react";
import { FETCH_BADGES_URL, SAVE_BADGES_URL } from "../../conf";
import { ModalSelectListInput } from "../CoreUI";

const BadgesModalSelector = memo(
	({
		user,
		setUser,
		focusFunction,
		parentRef,
		onboarding = false,
		onClose,
		customStepProps,
		...props
	}) => {
		const [stepProps, setStepProps] = useState([
			{
				label: "Your brand values",
				inlineName: "values",
				inputProps: {
					ref: parentRef || createRef(),
					onMouseEnter: focusFunction,
					"data-name": "optOut",
				},
				remoteKey: "badges",
				tooltipName: "values",

				title: "Your brand values",

				asideTitle: "Select values",
				mainTitle: "Your brand values",
				buttonText: "Save",

				mainFooter: () => (
					<>
						{/* We use the categories selected above to choose which
							partners your brand might be displayed on. */}
					</>
				),

				split: true,
				stepped: false,
				fetchUrl: FETCH_BADGES_URL,
				saveUrl: SAVE_BADGES_URL,
				directSave: true,
				requestValueMapper: ({ value }) =>
					Array.isArray(value)
						? value.map((badge) => badge.remote_id)
						: [],

				draftable: !onboarding,
				onSave: onClose,
				...(customStepProps?.[0] || {}),
			},
		]);

		return (
			<>
				<ModalSelectListInput
					stepProps={stepProps}
					user={user}
					setUser={setUser}
					setStepProps={setStepProps}
					onboarding={onboarding}
					{...props}
				/>
			</>
		);
	}
);

export default BadgesModalSelector;
