import React, {
	useRef,
	useMemo,
	useEffect,
	useCallback,
	useState,
} from "react";
import { Select, Input, MiniBadge, Checkbox } from "../CoreUI";
import { motion } from "framer-motion";
import "./module-rule.css";
import { BsFillTrashFill } from "react-icons/bs";
import ProductSelector from "../ProductSelector";
import { FETCH_PRODUCTS_URL } from "../../conf";
import isShopifyShop from "../../utils/isShopifyShop";
import { Label } from "recharts";

const operatorOptions = {
	gt: "is Greater than",
	gte: "is Greater than or equal to",
	eq: "is Equal to",
	neq: "is Not Equal to",
	lt: "is Less than",
	lte: "is Less than or equal to",
};

const productListOperatorOptions = {
	in: "any of",
	all: "all of",
};

// in
// all

// dictionary inisde clause -> products: [remote_id of product]

// for not => is_not

const customerOptions = {
	returning: "Returning Customer",
	new: "New Customer",
};

const shippingOptions = {
	Express: "Express",
	Standard: "Standard",
	Priority: "Priority",
};

const ModuleRule = React.memo(
	({
		user,
		rule,
		idx,
		setRule,
		removeRule,
		edit,
		selectedProduct,
		moduleType = "",
		...props
	}) => {
		const entityOptions = useMemo(() => {
			const retValue = {
				product_count: "Number of Products",
				order_value: "Order Cost (in USD)",
				product_list: "Customer Purchased",
			};

			if (moduleType === "survey" && !isShopifyShop(user)) {
				return retValue;
			}
			retValue["customer_type"] = "Customer Type";
			retValue["shipping_method"] = "Shipping Method";
			return retValue;
		}, [user, moduleType]);

		const initialRuleProductList = useRef(null);

		const claus = useMemo(() => rule.clauses[0], [rule]);

		const productSelectorValues = useMemo(() => {
			if (claus?.rule_entity !== "product_list") {
				return [];
			}
			return claus.products
				.filter((product) => {
					if (!selectedProduct) {
						return true;
					}
					return (
						selectedProduct.remote_id !==
						(product.remote_id || product)
					);
				})
				.map((product) => product.remote_id || product);
		}, [claus, selectedProduct]);

		const setOperator = useCallback(
			({ target: { value } }) =>
				setRule((rule) => {
					if (value === "neq") {
						return {
							...rule,
							clauses: [
								{
									...rule.clauses[0],
									operator: "eq",
									is_not: true,
								},
							],
						};
					}
					return {
						...rule,
						clauses: [
							{
								...rule.clauses[0],
								is_not: false,
								operator:
									rule.clauses[0].rule_entity !==
										"customer_type" &&
									rule.clauses[0].rule_entity !==
										"shipping_method"
										? value
										: "eq",
							},
						],
					};
				}, idx),
			[setRule, idx]
		);

		const handleProductSelectorChange = useCallback(
			(value) => {
				setRule(
					(rule) => ({
						...rule,
						clauses: [
							{
								...rule.clauses[0],
								products: value,
							},
						],
					}),
					idx
				);
			},
			[setRule, idx]
		);
		const setEntity = useCallback(
			({ target: { value } }) => {
				return setRule((rule) => {
					if (
						(value === "customer_type" &&
							rule.clauses[0].rule_entity !== "customer_type") ||
						(value === "shipping_method" &&
							rule.clauses[0].rule_entity !== "shipping_method")
					) {
						return {
							...rule,
							clauses: [
								{
									...rule.clauses[0],
									rule_entity: value,
									operand: "",
									operator: "eq",
								},
							],
						};
					}
					return {
						...rule,
						clauses: [
							{
								...rule.clauses[0],
								rule_entity: value,
								operator: "",
								is_not: false,
							},
						],
					};
				}, idx);
			},
			[setRule, idx]
		);

		const setOperand = useCallback(
			({ target: { value } }) =>
				setRule(
					(rule) => ({
						...rule,
						clauses: [{ ...rule.clauses[0], operand: value }],
					}),
					idx
				),
			[setRule, idx]
		);
		const [customShipping, setCustomShipping] = useState(
			rule.clauses[0].rule_entity === "shipping_method" &&
				rule.clauses[0].rule_entity &&
				!shippingOptions[rule.clauses[0].operand]
		);

		const handleCustomShipping = useCallback(
			({ target: { checked } }) => {
				setOperand({ target: { value: "" } });
				setCustomShipping(checked);
			},
			[setOperand]
		);

		useEffect(() => {
			if (!initialRuleProductList.current && productSelectorValues) {
				handleProductSelectorChange(productSelectorValues);
				initialRuleProductList.current = productSelectorValues;
			}
		}, [handleProductSelectorChange, productSelectorValues]);

		if (!claus) {
			return null;
		}

		return (
			<motion.main
				className={`module-rule ${edit ? "module-rule-edit" : ""}`}
				layout
				{...props}
			>
				{/* <section
					className="module-rule-text"
					style={edit ? { width: "100px" } : {}}
				>
					When
				</section> */}
				{edit && (
					<>
						<Select
							options={entityOptions}
							value={claus.rule_entity}
							onChange={setEntity}
							placeholder="Find people whose"
							multiple={false}
						/>
						<section
							className={`module-rule-row ${
								claus.rule_entity === "product_list" ||
								claus.rule_entity === "customer_type" ||
								claus.rule_entity === "shipping_method"
									? "module-rule-row-vertical"
									: ""
							}`}
						>
							<Select
								options={
									claus.rule_entity === "customer_type" ||
									claus.rule_entity === "shipping_method"
										? { eq: operatorOptions.eq }
										: claus.rule_entity === "product_list"
										? productListOperatorOptions
										: operatorOptions
								}
								value={
									claus?.is_not ? "neq" : claus.operator || ""
								}
								onChange={setOperator}
								placeholder="Choose a comparison"
								multiple={false}
								className="module-rule-row-large"
							/>
							{claus.rule_entity === "customer_type" ? (
								<Select
									multiple={false}
									options={customerOptions}
									value={claus.operand}
									onChange={setOperand}
									placeholder="Customer Type"
									className="module-rule-row-small"
								/>
							) : claus.rule_entity === "shipping_method" ? (
								<>
									{customShipping ? (
										<Input
											placeholder="Shipping Method"
											containerClassName="module-rule-row-inp-large"
											onChange={setOperand}
											value={claus.operand}
										/>
									) : (
										<Select
											multiple={false}
											options={shippingOptions}
											value={claus.operand}
											onChange={setOperand}
											placeholder="Shipping Method"
											className="module-rule-row-small"
										/>
									)}
									<section className="module-rule-row-checkbox">
										<Checkbox
											checked={customShipping}
											onChange={handleCustomShipping}
										/>
										Custom value
									</section>
								</>
							) : claus.rule_entity === "product_list" ? (
								<ProductSelector
									className="module-rule-product-selector"
									value={productSelectorValues}
									hideLabel
									placeholder="the selected products"
									multiple
									onChange={handleProductSelectorChange}
									filterProduct={selectedProduct}
									fetchUrl={FETCH_PRODUCTS_URL}
								/>
							) : (
								<Input
									value={claus.operand}
									onChange={(value) => setOperand(value)}
									placeholder={
										claus.rule_entity === "order_value"
											? "Dollars"
											: "Quantity"
									}
									className="module-rule-row-small"
									type="number"
								/>
							)}
						</section>

						<div
							className="module-rule-remove"
							onClick={() => removeRule(idx)}
						>
							<BsFillTrashFill /> Remove
						</div>
					</>
				)}
				{!edit && (
					<section className="module-rule-static">
						{claus.rule_entity === "product_list" ? (
							<div className="module-rule-static-inline">
								{typeof claus?.products?.[0] === "string" ? (
									"Customer has purchased specific products from your store"
								) : (
									<>
										{entityOptions[claus.rule_entity]}{" "}
										{
											productListOperatorOptions[
												claus.operator
											]
										}{" "}
										of the following products:
										{claus?.products.map((product) => (
											<MiniBadge theme>
												{product?.name || ""}
											</MiniBadge>
										))}
									</>
								)}
							</div>
						) : (
							<>
								{entityOptions[claus.rule_entity]}{" "}
								{operatorOptions[claus.operator]}{" "}
								{claus.operand in customerOptions
									? customerOptions[claus.operand]
									: claus.operand}
							</>
						)}
					</section>
				)}
			</motion.main>
		);
	}
);
export default ModuleRule;
