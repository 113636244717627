import React, { forwardRef, memo } from "react";
import PropTypes from "prop-types";

import genPackageClassName from "../../utils/genPackageClassName";

import "./title-row.css";
import { InformationTooltip } from "../Tooltip";
import { PaddedContainer, SPACING } from "../PaddedContainer";
import { FONT_COLOR, FONT_SIZE, Text } from "../Typography";
import { childrenPropTypes } from "../../utils";

export const TitleRow = memo(
	forwardRef(
		(
			{
				title,
				subtitle = "",
				tooltip,
				className = "",
				children,
				headerChildren,
				...rest
			},
			ref
		) => {
			return (
				<PaddedContainer
					ref={ref}
					className={genPackageClassName({
						base: "title-row",
						additional: className,
					})}
					{...rest}
				>
					<PaddedContainer
						className={genPackageClassName({
							base: "title-row-header",
						})}
					>
						<Text
							inline
							thick
							size={FONT_SIZE.SUB_TITLE}
							className={genPackageClassName({
								base: "title-row-title",
							})}
						>
							{title}
						</Text>
						{tooltip && (
							<PaddedContainer tightRight hPadding={SPACING.TINY}>
								<InformationTooltip
									activatorClassName={genPackageClassName({
										base: "title-row-tooltip",
									})}
									activatorSize={20}
									persistent={true}
								>
									{tooltip}
								</InformationTooltip>
							</PaddedContainer>
						)}
						{headerChildren}
					</PaddedContainer>
					{subtitle && (
						<Text
							tightBottom
							vPadding={SPACING.MICRO}
							thin
							size={FONT_SIZE.BODY}
							color={FONT_COLOR.MID}
							className={genPackageClassName({
								base: "title-row-subtitle",
							})}
						>
							{subtitle}
						</Text>
					)}
					{children}
				</PaddedContainer>
			);
		}
	)
);

TitleRow.displayName = "TitleRow";
TitleRow.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	tooltip: PropTypes.string,
	children: childrenPropTypes,
	headerChildren: childrenPropTypes,
};
