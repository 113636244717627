import React, {
	memo,
	useState,
	useEffect,
	useCallback,
	useMemo,
	ReactNode,
	CSSProperties,
	MouseEvent,
	MouseEventHandler,
} from "react";

import { motion, AnimatePresence } from "framer-motion";
import { anim, subtleTransition } from "../../utils/anim";
import genPackageClassName from "../../utils/genPackageClassName";
import ReactDOM from "react-dom";
import useModalCount from "../../hooks/useModalCount";
import useTheme from "../../hooks/useTheme";
import {
	PaddedContainer,
	SPACING,
	PaddedContainerProps,
} from "../PaddedContainer";
import { FONT_SIZE, Asset, Text, TextProps } from "../Typography";
import {
	StandardComponentWithChildren,
	ObjectValuesAsTypes,
	Extend,
} from "../../shared.types";
import { Card, CardProps } from "../Card";
import isFunction from "../../utils/isFunction";
import genClassName from "../../utils/genClassName";
import { CrossIcon } from "../../Icons";
import "./modal.css";

export const MODAL_OVERLAY_TYPE = {
	DEFAULT: "default",
	DARK: "dark",
	TRANSPARENT: "transparent",
} as const;
export const MODAL_HEADER_TYPE = {
	BASIC: "basic",
} as const;

export type OverlayType = ObjectValuesAsTypes<typeof MODAL_OVERLAY_TYPE>;
export type HeaderType = ObjectValuesAsTypes<typeof MODAL_HEADER_TYPE>;

export type NativeModalHeaderProps = {
	className?: string;
	title?: ReactNode;
	onClose?: MouseEventHandler<MouseEvent<HTMLElement>> | (() => void);
	showCloser?: boolean;
	closer?: ReactNode;
	type?: HeaderType;
	titleProps?: TextProps;
};

export type ModalHeaderProps = Extend<
	PaddedContainerProps,
	NativeModalHeaderProps
>;

type ModalHeaderComponent = (props: ModalHeaderProps) => React.ReactNode | null;

export const ModalHeader: ModalHeaderComponent = memo(
	({
		title,
		onClose,
		className = "",
		showCloser = false,
		closer,
		type = MODAL_HEADER_TYPE.BASIC,
		titleProps,
		...rest
	}) => {
		if (type === MODAL_HEADER_TYPE.BASIC) {
			return (
				<PaddedContainer
					Element="header"
					className={genPackageClassName({
						base: "modal-header",
						conditionals: {
							[`modal-header-${type}`]: type,
						},
						additional: className,
					})}
					{...rest}
				>
					{title && (
						<Text size={FONT_SIZE.TITLE} {...titleProps}>
							{title}
						</Text>
					)}
					{showCloser &&
						onClose &&
						(closer ? (
							closer
						) : (
							<Asset
								onClick={onClose}
								className={genPackageClassName({
									base: "modal-header-closer",
								})}
							>
								<CrossIcon />
							</Asset>
						))}
				</PaddedContainer>
			);
		}
		return null;
	}
);

type RenderContent =
	| ((onClose?: MouseEventHandler<MouseEvent<HTMLElement>>) => ReactNode)
	| ReactNode
	| null;

export type NativeModalProps = {
	className?: string;
	title?: ReactNode;
	onClose?: () => void;
	showCloser?: boolean;
	closer?: ReactNode;
	type?: HeaderType;
	titleProps?: TextProps;
	open?: boolean;
	overlayClassName?: string;
	overlayType?: OverlayType;
	contentOverlay?: ReactNode;
	sideContent?: RenderContent;
	sideContentProps?: CardProps;
	overlayCloseIdx?: string;
	disableOverlayClose?: boolean;
	overlayContent?: RenderContent;
	inline?: boolean;
	showModalOverflow?: boolean;
	autoHeight?: boolean;
	hideModal?: boolean;
	sheet?: boolean;
	fixedHeader?: boolean;
	customVariant?: CSSProperties;
};

export type ModalProps = Extend<PaddedContainerProps, NativeModalProps>;
type ModalComponent = StandardComponentWithChildren<ModalProps>;

export const Modal: ModalComponent = memo(
	({
		open,

		closer = null,
		onClose,

		children,
		className = "",
		overlayClassName = "",
		overlayType = MODAL_OVERLAY_TYPE.DEFAULT,

		contentOverlay,
		sideContent,
		sideContentProps,

		title = "",

		overlayCloseIdx = "",
		disableOverlayClose = false,
		overlayContent = null,

		inline = false,
		showModalOverflow = false,
		autoHeight = false,
		hideModal = false,
		sheet = false,
		titleProps,
		fixedHeader = false,
		customVariant,

		...rest
	}) => {
		const [root, setRoot] = useState<Element | null>(null);
		const { theme } = useTheme();

		useModalCount(open);

		const handleClose = useCallback(() => {
			if (!onClose) return;

			if (isFunction(onClose)) {
				onClose();
			}
		}, [onClose]);

		const handleOverlayClick = (e: MouseEvent) => {
			if (disableOverlayClose) return null;
			if (
				!(e.target as Element).classList.contains(
					overlayCloseIdx
						? `${overlayCloseIdx}-idx`
						: "disco-modal-overlay"
				)
			)
				return;

			handleClose();
		};

		useEffect(() => {
			const handleKeyUp = (e: KeyboardEvent) =>
				e.keyCode === 27 ? handleClose() : null;

			document.addEventListener("keyup", handleKeyUp);
			const cleanup = () =>
				document.removeEventListener("keyup", handleKeyUp);

			if (root) return cleanup;
			let newRoot = document.querySelector("#disco-modal-root");
			if (!newRoot) {
				newRoot = document.createElement("div");
				newRoot.id = "disco-modal-root";
				document.body.appendChild(newRoot);
			}
			setRoot(newRoot);
			return cleanup;
		}, [root, handleClose]);

		const modalCardVariants = useMemo(() => {
			if (customVariant) return customVariant;

			return anim.variantFactory({
				y: 80,
				transitionProps: subtleTransition,
				opacity: 0.5,
				exit: {
					opacity: 0,
				},
			});
		}, [customVariant]);

		const renderElementFactory = useCallback(
			(element?: RenderContent) =>
				element &&
				(typeof element === "function"
					? element(handleClose)
					: element),
			[handleClose]
		);

		const renderChildren = useMemo(
			() => renderElementFactory(children),
			[children, renderElementFactory]
		);
		const renderSide = useMemo(
			() =>
				sideContent && (
					<Card
						motionElement
						className={genPackageClassName({ base: "modal-side" })}
						variants={modalCardVariants}
						initial="initial"
						animate="animate"
						exit="exit"
						{...sideContentProps}
					>
						{renderElementFactory(sideContent)}
					</Card>
				),
			[
				renderElementFactory,
				sideContent,
				sideContentProps,
				modalCardVariants,
			]
		);
		const renderOverlayContent = useMemo(
			() => renderElementFactory(overlayContent),
			[renderElementFactory, overlayContent]
		);

		const renderHeader = useMemo(
			() => (
				<ModalHeader
					title={title}
					onClose={handleClose}
					closer={closer}
					showCloser={!inline && isFunction(onClose)}
					titleProps={titleProps}
				/>
			),
			[title, onClose, handleClose, inline, closer, titleProps]
		);

		const renderModal = useMemo(
			() =>
				hideModal ? null : (
					<>
						<Card
							className={genPackageClassName({
								base: "modal",
								conditionals: {
									"modal-sheet": sheet,
									"modal-inline": inline,
									"modal-show-overflow": showModalOverflow,
								},
								additional: className,
							})}
							motionElement
							variants={modalCardVariants}
							initial="initial"
							animate="animate"
							exit="exit"
							key="modal"
							overlay={contentOverlay}
							hPadding={SPACING.LARGE}
							vPadding={SPACING.LARGE}
							flatBottom={sheet}
							noBorder={!inline}
							header={fixedHeader && renderHeader}
							{...rest}
						>
							{!fixedHeader && renderHeader}
							{renderChildren}
						</Card>
						{renderSide}
					</>
				),
			[
				renderChildren,
				modalCardVariants,
				className,
				hideModal,
				inline,
				contentOverlay,
				renderSide,
				rest,
				sheet,
				showModalOverflow,
				fixedHeader,
				renderHeader,
			]
		);

		if (!root) {
			return null;
		}

		if (inline) {
			return (
				<PaddedContainer
					className={genPackageClassName({ base: "modal-container" })}
				>
					{renderModal}
				</PaddedContainer>
			);
		}

		return ReactDOM.createPortal(
			<AnimatePresence>
				{open && (
					<motion.section
						className={genPackageClassName({
							theme,
							base: "modal-overlay modal-container",
							conditionals: {
								[`modal-overlay-${overlayType}`]: overlayType,
								"modal-inline-overlay": inline,
								"modal-auto-height-container": autoHeight,
							},

							additional: genClassName({
								base: overlayClassName,
								conditionals: {
									// This can't go in normal conditional as it would add the "disco" prefix
									[`${overlayCloseIdx.trim()}-idx`]:
										overlayCloseIdx,
								},
							}),
						})}
						onClick={handleOverlayClick}
						initial="initial"
						animate="animate"
						exit="exit"
						variants={anim.variantFactory({
							opacity: 0,
							transitionProps: subtleTransition,
							exit: {
								opacity: 0,
							},
						})}
						key="modal-overlay"
					>
						{renderOverlayContent}
						{renderModal}
					</motion.section>
				)}
			</AnimatePresence>,
			root
		);
	}
);
