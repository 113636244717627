import React, { memo, useMemo, useState, useCallback, useEffect } from "react";
import comingSoon from "../../img/onboarding/coming-soon.png";
import "./mobile-onboarding-view.css";

import CentralView from "../centralViews/CentralView";
import { Button, FormStatus, Loader } from "../CoreUI";
import useResource from "../../hooks/useResource";
import { SEND_MOBILE_TO_DESKTOP_ONBOARDING_EMAIL_URL } from "../../conf";
import parseError from "../../utils/parseError";

const MobileOnboardingView = memo(({ onLogout }) => {
	const [sent, setSent] = useState(false);

	const [{ data, loading, error }, send] = useResource(
		{
			url: SEND_MOBILE_TO_DESKTOP_ONBOARDING_EMAIL_URL,
		},
		false
	);

	const handleBtnClick = useCallback(
		() => (sent ? onLogout() : send()),
		[sent, onLogout, send]
	);

	useEffect(() => {
		if (!data) {
			return;
		}
		setSent(true);
	}, [data]);

	const footer = useMemo(() => {
		return (
			<>
				{loading ? (
					<Loader />
				) : (
					<section className="mobile-onboarding-view-footer-column">
						<Button
							onClick={handleBtnClick}
							large
							grayStroke={sent}
							gradient={!sent}
						>
							{sent
								? "Back to sign in page"
								: "Send me a link to complete onboarding"}
						</Button>
						{error && <FormStatus>{parseError(error)}</FormStatus>}
					</section>
				)}
			</>
		);
	}, [handleBtnClick, loading, sent, error]);

	const headerImg = useMemo(() => {
		return (
			<img
				src={comingSoon}
				alt="Coming Soon"
				className="mobile-onboarding-view-header-img"
			/>
		);
	}, []);

	return (
		<CentralView
			showLogo={false}
			className="mobile-onboarding-view"
			heading="Mobile Onboarding Coming Soon"
			subHeading="Please click on the following button to receive a link to your email so you can complete onboarding on a larger device."
			headerImg={headerImg}
			footer={footer}
			showMobileLogo
			showMobileHeading
		></CentralView>
	);
});

export default MobileOnboardingView;
