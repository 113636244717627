import {
    Button,
    Input,
    Loader,
    Modal,
    SPACING,
    StatusLabel,
} from "@disco/disco_core";
import React, { useEffect, useState } from "react";
import useResource from "../../hooks/useResource";
import useTrack from "../../hooks/useTrack";
import parseError from "../../utils/parseError";
import "./edit-text-modal.css";

const EditTextModal = React.memo(
	({
		initial = "",
		remoteKey,
		eventName = "",
		placeholder = "Text",
		children,
		open,
		onClose,
		heading = "Edit",
		saveUrl,
		empty = false,
		onSave,
		long = false,
		descriptorModal = false,
		bypassDraft = false,
		...rest
	}) => {
		const [text, setText] = useState(initial || "");
		const [saveFormError, setSaveFormError] = useState("");
		const track = useTrack();

		const [saveOptions, setSaveOptions] = useState({
			url: saveUrl,
			method: "PUT",
			data: {},
		});

		const [
			{ loading: saveLoading, data: saved, error: saveError },
			save,
			reset,
		] = useResource(saveOptions, false);

		useEffect(() => {
			if (saved && open) {
				bypassDraft ? onSave(saved, remoteKey, true) : onSave(saved);
				track(eventName, {
					[remoteKey]: saved[remoteKey],
				});
				reset();
			}
		}, [
			saved,
			onSave,
			remoteKey,
			open,
			reset,
			track,
			eventName,
			bypassDraft,
		]);

		const handleSave = () => {
			if (text.trim().length < 1 && !empty) {
				return setSaveFormError("Please enter some text");
			}
			if (text.trim().length > 72 && descriptorModal) {
				return setSaveFormError("Please enter a shorter description");
			}
			setSaveOptions((saveOptions) => ({
				...saveOptions,
				data: { [remoteKey]: text.trim() },
			}));
			setSaveFormError("");
			save();
		};

		return (
			<Modal
				open={open}
				{...rest}
				title={heading}
				onClose={onClose}
				className="edit-text-modal"
			>
				{children}
				<Input
					placeholder={placeholder}
					value={text}
					textarea={long}
					onChange={(e) => setText(e.target.value)}
					containerProps={{
						marginTop: SPACING.REGULAR,
					}}
				/>
				{!saveLoading && (
					<Button className="modal-action" onClick={handleSave}>
						Save
					</Button>
				)}
				{saveLoading && <Loader className="modal-action-loader" />}
				{saveFormError && (
					<StatusLabel>Whoop! {saveFormError}</StatusLabel>
				)}
				{saveError && (
					<StatusLabel>{parseError(saveError)}</StatusLabel>
				)}
			</Modal>
		);
	}
);

export default EditTextModal;
