import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { Page } from "../../components/CoreUI";
import ResetConfirmationStep from "../../components/onboardingSteps/ResetConfirmationStep";
import isAuth from "../../utils/isAuth";

const PasswordResetConfirmationPage = React.memo(function () {
	const [auth, setAuth] = useState(isAuth()[0]);

	if (auth) {
		return <Navigate to="/" />;
	}

	return (
		<Page className="form-page">
			<ResetConfirmationStep setAuth={setAuth} />
		</Page>
	);
});

export default PasswordResetConfirmationPage;
