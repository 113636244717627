const getParsedTimeDiff = (
	refTime,
	useAbsoluteValue = false,
	useFloor = false
) => {
	if (!refTime) {
		return null;
	}

	// ADDING REGEX EXPRESSION TO SUPPORT SAFARI AND FIREFOX BROWSERS
	const modRefTime = refTime.replace(/\s/, "T");

	let days =
		(new Date(modRefTime).getTime() - new Date().getTime()) /
		1000 /
		3600 /
		24;
	if (useAbsoluteValue) {
		days = Math.abs(days);
	}
	if (useFloor) {
		days = Math.floor(days);
	} else {
		days = Math.round(days);
	}

	let hours =
		(new Date(modRefTime).getTime() - new Date().getTime()) / 1000 / 3600;

	if (useAbsoluteValue) {
		hours = Math.abs(hours);
	}
	if (useFloor) {
		hours = Math.floor(hours);
	} else {
		hours = Math.round(hours);
	}

	let text = "";

	if (days <= 0 && hours <= 0) {
		text = null;
	} else if (days >= 3) {
		text = `${days} days`;
	} else {
		text = `${hours} ${hours === 1 ? "hour" : "hours"}`;
	}

	return { days, hours, text };
};

export default getParsedTimeDiff;
