import React, { memo, useState, useEffect } from "react";
import { ThemeContext } from ".";
import { THEME } from "../../conf";
import { childrenPropTypes } from "../../utils";

import PropTypes from "prop-types";

const ThemeManager = memo(
	({ children, defaultTheme = THEME.PLATFORM, storyTheme }) => {
		const [theme, setTheme] = useState(defaultTheme);

		useEffect(() => {
			if (!storyTheme || !(storyTheme in THEME)) return;
			setTheme(storyTheme);
		}, [storyTheme]);

		return (
			<ThemeContext.Provider value={{ theme, setTheme }}>
				{children}
			</ThemeContext.Provider>
		);
	}
);

ThemeManager.displayName = "ThemeManger";
ThemeManager.propTypes = {
	children: childrenPropTypes,
	defaultTheme: PropTypes.oneOf(Object.values(THEME)),
	storyTheme: PropTypes.oneOf(Object.values(THEME)),
};

export default ThemeManager;
