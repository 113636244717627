import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";

const options = {
	renderNode: {
		[BLOCKS.PARAGRAPH]: (node, children) => children,
	},
	renderText: (text) => {
		const parts = text.split("\n");
		return parts.reduce(
			(children, el, index) => [
				...children,
				<>
					{el}
					{index !== parts.length - 1 ? <br /> : null}
				</>,
			],
			[]
		);
	},
};

const parseRichContent = (content) => {
	return documentToReactComponents(content, options);
};

export default parseRichContent;
