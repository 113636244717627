import React, { memo } from "react";
import yellowIndicator from "../../img/alertbar/yellow-indicator.svg";
import blueIndicator from "../../img/alertbar/blue-indicator.svg";
import greenIndicator from "../../img/alertbar/green-indicator.svg";
import redIndicator from "../../img/alertbar/red-indicator.svg";
import "./alert-bar-modules.css";
import { MiniBadge } from "../CoreUI";

export const AlertBarIndicator = memo(({ type = "blue" }) => {
	return (
		<div
			className={`alert-bar-indicator ${
				type === "blue"
					? "alert-bar-indicator-blue"
					: type === "green"
					? "alert-bar-indicator-green"
					: type === "red"
					? "alert-bar-indicator-red"
					: type === "yellow"
					? "alert-bar-indicator-yello"
					: ""
			}`}
		>
			<img
				src={
					type === "blue"
						? blueIndicator
						: type === "green"
						? greenIndicator
						: type === "red"
						? redIndicator
						: type === "yellow"
						? yellowIndicator
						: ""
				}
				alt=""
			/>
		</div>
	);
});

export const AlertBarModule = memo(({ children, className = "" }) => (
	<section className={`alert-bar-module ${className}`}>{children}</section>
));
export const AlertBarModuleSection = memo(({ children, className = "" }) => (
	<section className={`alert-bar-module-section ${className}`}>
		{children}
	</section>
));

export const AlertBarGradientText = memo(({ children, className = "" }) => (
	<span className={`alert-bar-gradient-text ${className}`}>{children}</span>
));

export const AlertBarButton = memo(({ children, className = "", ...props }) => (
	<MiniBadge className={`alert-bar-button ${className}`} theme {...props}>
		{children}
	</MiniBadge>
));
