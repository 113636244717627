import React, {
	ComponentPropsWithoutRef,
	forwardRef,
	memo,
	ReactNode,
} from "react";
import genPackageClassName from "../../utils/genPackageClassName";
import { PaddedContainer, SPACING } from "../PaddedContainer";
import {
	StandardComponentWithChildren,
	GenericRef,
	Extend,
} from "../../shared.types";

import "./labeled-input.css";
import { InformationTooltip, TooltipProps } from "../Tooltip";
import { Label, LabelProps } from "../Label";
import { Input } from "../Input";

export type NativeLabeledInputProps = {
	className?: string;
	tooltip?: TooltipProps;
	inputChildren?: ReactNode;
	label?: ReactNode;
	labelProps?: LabelProps;
};

export type LabeledInputProps = Extend<
	ComponentPropsWithoutRef<"input" | "textarea">,
	NativeLabeledInputProps
>;
type LabeledInputComponent = StandardComponentWithChildren<LabeledInputProps>;
export const LabeledInput: LabeledInputComponent = memo(
	forwardRef(
		(
			{
				className = "",
				tooltip,
				children,
				inputChildren,
				label,
				labelProps,
				...rest
			},
			ref?: GenericRef
		) => {
			return (
				<PaddedContainer
					className={genPackageClassName({
						base: "labeled-inp",
						additional: className,
					})}
					ref={ref}
				>
					{(label || tooltip) && (
						<Label flex marginBottom={SPACING.TINY} {...labelProps}>
							{label}
							{tooltip && (
								<InformationTooltip
									activatorSize={20}
									{...tooltip}
									activatorProps={{
										marginLeft: SPACING.TINY,
										...(tooltip?.activatorProps || {}),
									}}
								/>
							)}
							{labelProps?.children || null}
						</Label>
					)}
					<Input ref={ref} {...rest}>
						{inputChildren}
					</Input>
					{children}
				</PaddedContainer>
			);
		}
	)
);
