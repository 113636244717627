import React, { memo, useCallback, useEffect } from "react";
import EmailsStep from "./EmailsStep";
import previewBackground from "../../img/emails/preview-background.jpg";
import { PREVIEW_TYPES } from "./EmailsPreview";
import { useState } from "react";
import "./emails-setup-step.css";
import getEmailConfigs from "./emailsConfigs";
import { SHOPIFY_EMAIL_TYPES } from "../../conf";

const ConfigOrchestrator = memo(({ setPreviewType, user, ...props }) => {
	const [open, setOpen] = useState(-1);
	const handleOpen = useCallback((index) => {
		setOpen(index);
	}, []);
	const handleClose = useCallback(() => {
		setOpen(-1);
	}, []);

	useEffect(() => {
		if (open === -1) return setPreviewType(PREVIEW_TYPES.CLOSED);
		setPreviewType(getEmailConfigs(user)[open].previewType);
	}, [open, setPreviewType, user]);

	return (
		<>
			{getEmailConfigs(user).map((config, index) => (
				<config.component
					open={open === index}
					onOpen={handleOpen}
					onClose={handleClose}
					index={index}
					user={user}
					{...props}
				/>
			))}
		</>
	);
});

const EmailsSetupStep = memo(
	({ onNext, user, setUser, children, ...props }) => {
		const [previewType, setPreviewType] = useState(PREVIEW_TYPES.CLOSED);
		const [previewSubType, setPreviewSubType] = useState(
			SHOPIFY_EMAIL_TYPES.DELIVERY
		);

		return (
			<EmailsStep
				onNext={onNext}
				className="emails-setup-step"
				preview={{
					type: previewType,
					background: previewBackground,
					shopifyType: previewSubType,
				}}
				{...props}
			>
				<ConfigOrchestrator
					user={user}
					setUser={setUser}
					setPreviewType={setPreviewType}
					setPreviewSubType={setPreviewSubType}
				/>
				{children}
			</EmailsStep>
		);
	}
);
export default EmailsSetupStep;
