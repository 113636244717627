import React, { memo, useState } from "react";
import { PingContext } from ".";
import { childrenPropTypes } from "../../utils";

const PingManager = memo(({ children }) => {
	const [pings, setPings] = useState({});

	return (
		<PingContext.Provider value={{ pings, setPings }}>
			{children}
		</PingContext.Provider>
	);
});

PingManager.displayName = "PingManger";
PingManager.propTypes = {
	children: childrenPropTypes,
};

export default PingManager;
