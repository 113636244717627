import React, {
	forwardRef,
	memo,
	ForwardedRef,
	ComponentPropsWithoutRef,
	ChangeEventHandler,
	ReactNode,
} from "react";

import useTheme from "../../hooks/useTheme";
import genPackageClassName from "../../utils/genPackageClassName";
import {
	PaddedContainer,
	SPACING,
	PaddedContainerProps,
} from "../PaddedContainer";
import { FONT_COLOR, FONT_SIZE, Text } from "../Typography";
import { CheckIcon, MinusIcon } from "../../Icons";
import { Extend, StandardComponentWithChildren } from "../../shared.types";

import "./checkbox.css";

type NativeCheckboxProps = {
	className?: string;
	indeterminate?: boolean;
};

export type CheckboxProps = Extend<
	ComponentPropsWithoutRef<"input">,
	NativeCheckboxProps
>;
type CheckboxComponent = (
	props: CheckboxProps & { ref?: ForwardedRef<HTMLLabelElement> }
) => React.ReactNode | null;

export const Checkbox: CheckboxComponent = memo(
	forwardRef<HTMLLabelElement>(
		(
			{ indeterminate = false, className, ...rest }: CheckboxProps,
			ref?: ForwardedRef<HTMLLabelElement>
		) => {
			const { theme } = useTheme();
			return (
				<label
					ref={ref}
					className={genPackageClassName({
						theme,
						base: "checkbox",
						additional: className,
					})}
				>
					<input type="checkbox" {...rest} />
					<PaddedContainer
						className={genPackageClassName({
							base: "checkbox-icon absolute-center-container",
						})}
					>
						{indeterminate ? <MinusIcon /> : <CheckIcon />}
					</PaddedContainer>
				</label>
			);
		}
	)
);

type NativeCheckboxElementProps = {
	className?: string;
	name?: string;
	disabled?: boolean;
	title?: ReactNode;
	subtitle?: ReactNode;
	border?: boolean;
	underline?: boolean;
	checked?: boolean;
	onChange?: ChangeEventHandler<HTMLInputElement>;
};

export type CheckboxElementProps = Extend<
	PaddedContainerProps,
	NativeCheckboxElementProps
>;

type CheckboxElementComponent =
	StandardComponentWithChildren<CheckboxElementProps>;

export const CheckboxElement: CheckboxElementComponent = memo(
	forwardRef<HTMLLabelElement>(
		(
			{
				className = "",
				name = "",
				disabled = false,
				title = "",
				subtitle = "",
				children = "",
				border = false,
				underline = false,
				checked = false,
				onChange,
				...rest
			}: CheckboxElementProps,
			ref?: ForwardedRef<HTMLLabelElement>
		) => {
			return (
				<PaddedContainer
					vPadding={SPACING.REGULAR}
					hPadding={SPACING.REGULAR}
					className={genPackageClassName({
						base: "checkbox-element",
						additional: className,
						conditionals: {
							"checkbox-element-border": border,
							"checkbox-element-border-bottom": underline,
							"checkbox-element-disabled": disabled,
							"checkbox-element-checked": checked,
						},
					})}
					{...rest}
				>
					<Checkbox
						ref={ref}
						name={name}
						checked={checked}
						disabled={disabled}
						onChange={onChange}
					/>
					<PaddedContainer
						marginLeft={SPACING.REGULAR}
						className={genPackageClassName({
							base: "checkbox-element-content",
						})}
					>
						{title && (
							<Text
								thick
								className={genPackageClassName({
									base: "checkbox-element-title",
								})}
								size={FONT_SIZE.LG_BODY}
							>
								{title}
							</Text>
						)}

						{subtitle && (
							<Text
								marginTop={title ? SPACING.MICRO : undefined}
								size={FONT_SIZE.BODY}
								color={FONT_COLOR.MID}
								className={genPackageClassName({
									base: "checkbox-element-subtitle",
								})}
							>
								{subtitle}
							</Text>
						)}
						{children && (
							<Text
								size={FONT_SIZE.LABEL}
								marginTop={
									title || subtitle ? SPACING.TINY : undefined
								}
								color={FONT_COLOR.MID}
								thin
								className={genPackageClassName({
									base: "checkbox-element-children",
								})}
							>
								{children}
							</Text>
						)}
					</PaddedContainer>
				</PaddedContainer>
			);
		}
	)
);
