import React, { useState, useCallback } from "react";
import { Button } from "../CoreUI";
import { DEFAULT_OPTION_IMG } from "../../conf";
import { motion } from "framer-motion";
import SurveyOption from "../SurveyOption";
import Reorder from "../Reorder";
import "./survey-options.css";
import { uuid } from "@disco/disco_core";

const SurveyOptions = React.memo(({ options, setOptions, user, isNew }) => {
	const [focussed, setFocussed] = useState(-1);

	const reorderOptions = (newOptions) => setOptions(() => newOptions); // Need to comply with Reorder.Group's requirements for a setter

	const handleChange = (option, id) => {
		setOptions((options) => {
			return [
				...options.slice(0, id),
				{ ...options[id], ...option },
				...options.slice(id + 1),
			];
		});
	};

	const addOption = useCallback(
		(e) => {
			e.preventDefault();
			setOptions((options) => [
				...options,
				{
					text: "",
					active: true,
					icon_url: DEFAULT_OPTION_IMG,
					remote_id: uuid(),
				},
			]);
		},
		[setOptions]
	);

	const removeOption = useCallback(
		(id) => {
			setOptions((options) => {
				return [...options.slice(0, id), ...options.slice(id + 1)];
			});
		},
		[setOptions]
	);

	const dragEnd = useCallback((id) => {
		setFocussed(id);
	}, []);

	return (
		<motion.section className="survey-options" layout>
			<Reorder
				list={options}
				setList={reorderOptions}
				optionClassName="survey-option"
				dragEnd={dragEnd}
				itemMotionElement="section"
				renderOption={(id) => (
					<SurveyOption
						id={id}
						option={options[id]}
						isNew={isNew}
						handleChange={handleChange}
						removeOption={removeOption}
						focussed={focussed === id}
						user={user}
						onFocus={(id) => setFocussed(id)}
					/>
				)}
			/>

			<Button light onClick={addOption} className="survey-options-add">
				Add Another Answer
			</Button>
		</motion.section>
	);
});

export default SurveyOptions;
