import {
	Accordion,
	Button,
	Checkbox,
	FormStatus,
	LabeledInput,
	Loader,
	RadioInput,
	CopyBlock,
	TitledCard,
} from "../CoreUI";
import shopifyIcon from "../../img/shopify-icon.svg";
import klaviyoIcon from "../../img/klaviyo-icon.svg";
import mailchimpIcon from "../../img/emails/mailchimp-icon.svg";
import otherIcon from "../../img/emails/other-icon.svg";
import useTrack from "../../hooks/useTrack";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { PREVIEW_TYPES } from "./EmailsPreview";
import parseError from "../../utils/parseError";
import useResource from "../../hooks/useResource";
import {
	EXTERNAL_KLAVIYO_TEMPLATES_URL,
	FETCH_SHOPIFY_TEMPLATES_URL,
	FETCH_KLAVIYO_TEMPLATE_URL,
	SAVE_KLAVIYO_API_URL,
	BRAND_INFO_URL,
	SHOPIFY_CUSTOM_INTEGRATION_URl,
	SHOPIFY_LINKS,
	SHOPIFY_EMAIL_TYPES,
} from "../../conf";

import "./emails-config.css";
import { useMemo } from "react";
import { ImMagicWand } from "react-icons/im";
import { MdChevronLeft } from "react-icons/md";
import isShopifyShop from "../../utils/isShopifyShop";
import { AiFillEye } from "react-icons/ai";
import useContent from "../../hooks/useContent";

// IMPORTANT - value = null is not the same as value = false
// value = false -> Unchecked
// value = null  -> Do not render a check!

const EmailsConfigRow = memo(
	({
		children,
		linkText,
		linkTo,
		checked = null,
		hoverType,
		setSelected,
		...rest
	}) => {
		return (
			<section className="emails-config-row">
				<section className="emails-config-row-hero">
					{checked !== null && (
						<Checkbox checked={checked} {...rest} />
					)}
					{children}
				</section>
				{linkText && (
					<section className="emails-config-row-action">
						{typeof setSelected === "function" && (
							<span
								onMouseEnter={() => setSelected(hoverType)}
								onMouseLeave={() => setSelected(null)}
							>
								<AiFillEye />
							</span>
						)}
						<a
							href={linkTo}
							target="_blank"
							rel="noopener noreferrer"
						>
							{linkText}
						</a>
					</section>
				)}
			</section>
		);
	}
);

const ShopifyTemplates = memo(
	({ error, templates, handleChange, setSelected, user }) => {
		return (
			<main className="shopify-templates">
				{error && <FormStatus>{parseError(error)}</FormStatus>}

				{Array.isArray(templates) && (
					<>
						{templates.map((template) => (
							<>
								<EmailsConfigRow
									linkText={
										template.selected
											? "Open in Shopify"
											: null
									}
									linkTo={SHOPIFY_LINKS[
										remoteTypes[template.type]
									](user)}
									checked={template.selected}
									name={template.name}
									onChange={handleChange}
									setSelected={setSelected}
									hoverType={template.type}
								>
									{template.type}
								</EmailsConfigRow>
								{template.selected && (
									<CopyBlock>
										{template.rendered_content}
									</CopyBlock>
								)}
							</>
						))}
					</>
				)}
			</main>
		);
	}
);

const remoteTypes = {
	"Order Confirmation": SHOPIFY_EMAIL_TYPES.ORDER_CONFIRMATION,
	"Shipping Confirmation": SHOPIFY_EMAIL_TYPES.SHIP_CONFIRMATION,
	"Shipping Updates": SHOPIFY_EMAIL_TYPES.SHIP_UPDATE,
	"Out for Delivery": SHOPIFY_EMAIL_TYPES.DELIVERY,
};

const ShopifyConfig = memo(
	({ open, onOpen, onClose, index, user, setUser, setPreviewSubType }) => {
		const edited =
			Object.keys(user.publisher.templates_configured).length > 0;

		const radioOptions = useMemo(() => {
			return [
				{
					name: "Yes, I customize my Shopify email templates",
					renderChildren: () => {
						return (
							<TitledCard
								title="Get the Disco white glove service"
								icon={<ImMagicWand />}
							>
								Give us a bit more information using this quick
								form and a member of our team will reach out to
								help you configure your custom templates.
								<a
									href={SHOPIFY_CUSTOM_INTEGRATION_URl}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Button
										className="shopify-emails-config-service-btn"
										gradient
									>
										Tell us about your templates
									</Button>
								</a>
							</TitledCard>
						);
					},
				},
				{ name: "No, I’m using standard templates for all emails" },
			];
		}, []);

		const [selectedRadio, setSelectedRadio] = useState(edited ? 1 : 0);
		const [templates, setTemplates] = useState(null);

		const handleRadioChange = useCallback((index) => {
			setSelectedRadio(index);

			setTemplates((templates) => {
				if (!Array.isArray(templates)) return templates;
				return templates.map((template) => ({
					...template,
					selected: false,
				}));
			});
		}, []);

		const [{ loading, data, error }, , reset] = useResource({
			url: FETCH_SHOPIFY_TEMPLATES_URL,
			method: "GET",
		});

		const defaultPreview = useMemo(() => {
			if (!Array.isArray(templates))
				return SHOPIFY_EMAIL_TYPES.ORDER_CONFIRMATION;

			let last = null;
			templates.forEach((template) => {
				if (template.selected) last = template;
			});
			if (!last) return SHOPIFY_EMAIL_TYPES.ORDER_CONFIRMATION;
			return remoteTypes[last.type];
		}, [templates]);

		const [selected, setSelected] = useState(null);

		useEffect(() => {
			setPreviewSubType(
				selected ? remoteTypes[selected] : defaultPreview
			);
		}, [selected, defaultPreview, setPreviewSubType]);

		const [
			{ loading: saveLoading, data: saveData, error: saveError },
			save,
			saveReset,
		] = useResource(
			{
				url: BRAND_INFO_URL,
				method: "PUT",
				data: {
					templates_configured: Array.isArray(templates)
						? templates.reduce((acc, template) => {
								return {
									...acc,
									[template.type]: template.selected,
								};
						  }, {})
						: {},
				},
			},
			false
		);

		useEffect(() => {
			if (!data) return;
			setTemplates(
				data
					.filter((template) => template.theme === 1)
					.map((template) => ({
						...template,
						selected:
							user.publisher.templates_configured[
								template.type
							] ?? false,
					}))
			);
			reset();
		}, [data, reset, user]);

		const handleTemplateChange = useCallback(
			({ target: { name, checked } }) => {
				setTemplates((templates) =>
					templates.map((template) =>
						template.name === name && !template.selected
							? { ...template, selected: checked }
							: template
					)
				);
			},
			[]
		);

		const closeRef = useRef(false);

		const handleSave = useCallback(() => {
			closeRef.current = false;
			save();
		}, [save]);

		const handleClose = useCallback(() => {
			closeRef.current = true;
			save();
		}, [save]);

		useEffect(() => {
			if (!saveData) return;
			setUser((user) => {
				return {
					...user,
					publisher: {
						...user.publisher,
						...saveData.brand,
					},
				};
			});
			saveReset();
			if (closeRef.current) onClose();
		}, [saveData, saveReset, setUser, onClose]);

		return (
			<Accordion
				open={open}
				heading="Shopify"
				icon={shopifyIcon}
				openText={"Save"}
				closeText={edited ? "Edit" : "+Add"}
				onOpen={() => onOpen(index)}
				onClose={handleClose}
				onSave={handleSave}
				loading={saveLoading}
			>
				{selectedRadio === 0 ? (
					<RadioInput
						data={radioOptions}
						selected={selectedRadio}
						onChange={handleRadioChange}
					/>
				) : (
					<>
						<section
							className="shopify-emails-config-back"
							onClick={() => handleRadioChange(0)}
						>
							<span>
								<MdChevronLeft />
							</span>
							Use customized Shopify templates
						</section>
						<ShopifyTemplates
							templates={templates}
							error={error}
							handleChange={handleTemplateChange}
							setSelected={setSelected}
							user={user}
						/>
					</>
				)}
				{(error || saveError) && (
					<FormStatus>
						{parseError(error ? error : saveError)}
					</FormStatus>
				)}
			</Accordion>
		);
	}
);

const KlaviyoConfig = memo(
	({ open, onOpen, onClose, index, user, setUser, ...rest }) => {
		const [formError, setFormError] = useState();
		const [apiKeys, setApiKeys] = useState(
			{
				public: user.publisher.klaviyo_public_token,
				private: user.publisher.klaviyo_private_key,
			} ?? ""
		);

		const handleApiChange = useCallback(
			({ target: { value, name } }) =>
				setApiKeys((apiKeys) => ({ ...apiKeys, [name]: value })),
			[]
		);

		const [{ loading, error, data }] = useResource({
			url: FETCH_KLAVIYO_TEMPLATE_URL,
			method: "GET",
		});

		const [
			{ loading: apiLoading, error: apiError, data: apiData },
			saveApi,
			resetApi,
		] = useResource(
			{
				url: SAVE_KLAVIYO_API_URL,
				method: "PUT",
				data: {
					klaviyo_public_token: apiKeys.public,
					klaviyo_private_key: apiKeys.private,
				},
			},
			false
		);

		const closeRef = useRef(false);
		useEffect(() => {
			if (!apiData) return;
			setUser((user) => {
				return {
					...user,
					publisher: {
						...user.publisher,
						...apiData.brand,
					},
				};
			});
			resetApi();
			if (closeRef.current) onClose();
		}, [apiData, resetApi, setUser, onClose]);

		const saveKeys = (close) => {
			closeRef.current = close;
			if (
				apiKeys.public === user.publisher.klaviyo_public_token &&
				apiKeys.private === user.publisher.klaviyo_private_key
			) {
				resetApi();
				setFormError(false);
				if (close) onClose();
				return;
			}
			setFormError(false);
			saveApi();
		};

		const handleSave = () => {
			saveKeys(false);
		};

		const handleClose = () => {
			saveKeys(true);
		};

		// const handleClose = () => {
		// 	if (
		// 		apiKeys.public === user.publisher.klaviyo_public_token &&
		// 		apiKeys.private === user.publisher.klaviyo_private_key
		// 	) {
		// 		resetApi();
		// 		setFormError(false);
		// 		return onClose();
		// 	}
		// 	setFormError(false);
		// 	saveApi();
		// };

		return (
			<Accordion
				open={open}
				heading="Klaviyo"
				icon={klaviyoIcon}
				openText="Save"
				closeText={
					!!user.publisher.klaviyo_private_key ? "Edit" : "+ Add"
				}
				onOpen={() => onOpen(index)}
				onClose={handleClose}
				onSave={handleSave}
				loading={apiLoading}
				className="klaviyo-emails-config"
				{...rest}
			>
				<form
					autoComplete="new-password"
					onSubmit={(e) => {
						saveApi();
						e.preventDefault();
					}}
				>
					<LabeledInput
						label="Public Klaviyo API key"
						placeholder="Public Klaviyo API key"
						value={apiKeys.public}
						name="public"
						type="text"
						autoComplete="new-password"
						onChange={handleApiChange}
					/>

					<LabeledInput
						label="Private Klaviyo API key"
						placeholder="Private Klaviyo API key"
						value={apiKeys.private}
						type="password"
						name="private"
						autoComplete="new-password"
						onChange={handleApiChange}
					/>
					{(formError || apiError) && (
						<FormStatus>
							{" "}
							{apiError ? parseError(apiError, true) : formError}
						</FormStatus>
					)}
				</form>
				{loading ? (
					<Loader />
				) : data?.email_block ? (
					<>
						<EmailsConfigRow
							linkTo={EXTERNAL_KLAVIYO_TEMPLATES_URL}
							linkText="Open them here"
						>
							Copy & Paste Into A Template
						</EmailsConfigRow>
						<CopyBlock>{data.email_block}</CopyBlock>
					</>
				) : error ? (
					<FormStatus>{parseError(error)}</FormStatus>
				) : null}
			</Accordion>
		);
	}
);

const UnimplementedConfig = memo(
	({
		onOpen,
		onClose,
		index,
		children,
		eventName,
		details = {},
		inputProps = null,
		buttonText,
		...rest
	}) => {
		const [value, setValue] = useState("");
		const [submitted, setSubmitted] = useState(false);
		const [formError, setFormError] = useState(false);
		const track = useTrack();

		const handleClick = () => {
			if (inputProps && value.length < 1)
				return setFormError("Please enter an email provider");

			setFormError(false);

			track(
				eventName,
				inputProps === null ? null : { [inputProps.name]: value }
			);
			setSubmitted(true);
		};

		return (
			<Accordion
				onOpen={() => onOpen(index)}
				onClose={() => onClose(index)}
				openText="Close"
				closeText="+ Add"
				{...rest}
			>
				<main className="unimplemented-emails-config">
					<h3>{details.heading}</h3>
					<p>{details.subText}</p>

					{submitted ? (
						<section className="unimplemented-emails-config-message">
							✅ Thanks for the feedback!
						</section>
					) : (
						<>
							{inputProps !== null && (
								<LabeledInput
									{...inputProps}
									onChange={({ target: { value } }) =>
										setValue(value)
									}
									value={value}
								/>
							)}
							<Button gradient onClick={handleClick}>
								{buttonText}
							</Button>
							{formError && <FormStatus>{formError}</FormStatus>}
						</>
					)}
				</main>
			</Accordion>
		);
	}
);

const MailchimpConfig = memo(({ ...props }) => {
	const content = useContent().platformEmailsPage;

	return (
		<UnimplementedConfig
			heading="Mailchimp"
			icon={mailchimpIcon}
			details={{
				heading: content.tutStep2MailchimpHeading,
				subText: content.tutStep2MailchimpSubHeading,
			}}
			buttonText={content.tutStep2MailchimpCTA}
			{...props}
		/>
	);
});

const OtherConfig = memo(({ ...props }) => {
	const content = useContent().platformEmailsPage;
	return (
		<UnimplementedConfig
			heading="Other"
			icon={otherIcon}
			details={{
				heading: content.tutStep2OtherHeading,
				subText: content.tutStep2OtherSubHeading,
			}}
			buttonText={content.tutStep2OtherHeading}
			inputProps={{
				label: "Which provider do you use?",
				placeholder: "Which provider do you use?",
				name: "email_provider",
			}}
			{...props}
		/>
	);
});

const getEmailConfigs = (user) => [
	...(isShopifyShop(user)
		? [
				{
					component: ShopifyConfig,
					previewType: PREVIEW_TYPES.SHOPIFY,
				},
		  ]
		: []),
	{
		component: KlaviyoConfig,
		previewType: PREVIEW_TYPES.KLAVIYO,
	},
	{
		component: MailchimpConfig,
		previewType: PREVIEW_TYPES.CLOSED,
	},
	{
		component: OtherConfig,
		previewType: PREVIEW_TYPES.CLOSED,
	},
];

export default getEmailConfigs;
