import React, { memo, useCallback, useMemo } from "react";
import {
	Navigate,
	Route,
	useNavigate,
	useLocation,
	useParams,
	Routes,
} from "react-router-dom";
import { AuthPage, PageHeader } from "../../components/CoreUI";
import {
	PLATFORM_CLICK_ON_SETTINGS_ACCOUNT,
	PLATFORM_CLICK_ON_SETTINGS_BRAND,
	PLATFORM_CLICK_ON_SETTINGS_INTEGRATIONS,
	PLATFORM_CLICK_ON_SETTINGS_PAGE_SETUP,
	PLATFORM_CLICK_ON_SETTINGS_PAYMENT,
	PLATFORM_CLICK_ON_SETTINGS_TEAM,
	PLATFORM_VIEW_SETTINGS,
} from "../../events";
import useTrack from "../../hooks/useTrack";

import { PaddedContainer, SPACING, genClassName } from "@disco/disco_core";
import {
	AccountModule,
	BrandModule,
	IntegrationsModule,
	PageSetupModule,
	PaymentModule,
	ScriptModule,
	TeamModule,
} from "../../components/settingsModules";
import useIdentity from "../../hooks/useIdentity";
import getPlatformPath from "../../utils/getPlatformPath";
import getSubpath from "../../utils/getSubpath";
import "./settings-page.css";

const SETTINGS_BRAND_URL_KEY = "brand";
const SETTINGS_ACCOUNT_URL_KEY = "account";
const SETTINGS_PAYMENT_URL_KEY = "payment";
const SETTINGS_TEAMS_URL_KEY = "team";
const SETTINGS_PAGE_SETUP_URL_KEY = "pageSetup";
const SETTINGS_INTEGRATIONS_URL_KEY = "integrations";

const SettingsPageHeader = memo(({ tabOptions }) => {
	const navigate = useNavigate();
	const track = useTrack();

	const { user } = useIdentity();

	const handleTabChange = useCallback(
		(_, idx) => {
			track(tabOptions[idx].event, { from_sidebar: false });
			navigate({
				pathname: getPlatformPath(tabOptions[idx].urlKey, user),
			});
		},
		[navigate, tabOptions, user, track]
	);

	return (
		<PageHeader
			className="settings-page-header"
			heading={"Settings"}
			tabOptions={tabOptions}
			handleTabChange={handleTabChange}
			action
		/>
	);
});

const SettingsPageRenderer = memo(({ user, setUser }) => {
	const settingsModules = useMemo(() => {
		return {
			[SETTINGS_BRAND_URL_KEY]: [
				{
					component: BrandModule,
				},
			],
			[SETTINGS_INTEGRATIONS_URL_KEY]: [
				{
					component: ScriptModule,
				},
				{
					component: IntegrationsModule,
				},
			],

			[SETTINGS_PAYMENT_URL_KEY]: [
				{
					component: PaymentModule,
				},
			],
			[SETTINGS_TEAMS_URL_KEY]: [
				{
					component: TeamModule,
				},
			],
			[SETTINGS_PAGE_SETUP_URL_KEY]: [
				{
					component: PageSetupModule,
				},
			],
			[SETTINGS_ACCOUNT_URL_KEY]: [
				{
					component: AccountModule,
				},
			],
		};
	}, [user]);

	const { subPage } = useParams();

	return (
		<PaddedContainer
			vPadding={SPACING.LARGE}
			className={genClassName({
				base: "settings-page-container",
				conditionals: {
					[`settings-page-container-${subPage}`]: !!subPage,
				},
			})}
			Element="main"
			tightTop
		>
			{settingsModules[subPage]
				? settingsModules[subPage]
						.filter((settingsModule) => !settingsModule.hidden)
						.map((settingsModule, idx) => (
							<settingsModule.component
								user={user}
								setUser={setUser}
								key={idx}
								href={idx}
							/>
						))
				: null}
		</PaddedContainer>
	);
});

const SettingsPage = memo(({ user, setUser }) => {
	const location = useLocation().pathname;

	const subPage = useMemo(() => {
		return getSubpath(location);
	}, [location]);

	const tabOptions = useMemo(() => {
		return [
			{
				name: "Brand",
				active: subPage === SETTINGS_BRAND_URL_KEY,
				urlKey: SETTINGS_BRAND_URL_KEY,
				event: PLATFORM_CLICK_ON_SETTINGS_BRAND,
			},
			{
				name: "Integrations",
				active: subPage === SETTINGS_INTEGRATIONS_URL_KEY,
				urlKey: SETTINGS_INTEGRATIONS_URL_KEY,
				event: PLATFORM_CLICK_ON_SETTINGS_INTEGRATIONS,
			},
			{
				name: "Payment",
				active: subPage === SETTINGS_PAYMENT_URL_KEY,
				urlKey: SETTINGS_PAYMENT_URL_KEY,
				event: PLATFORM_CLICK_ON_SETTINGS_PAYMENT,
			},
			{
				name: "Team",
				active: subPage === SETTINGS_TEAMS_URL_KEY,
				urlKey: SETTINGS_TEAMS_URL_KEY,
				event: PLATFORM_CLICK_ON_SETTINGS_TEAM,
			},
			{
				name: "Page Setup",
				active: subPage === SETTINGS_PAGE_SETUP_URL_KEY,
				urlKey: SETTINGS_PAGE_SETUP_URL_KEY,
				event: PLATFORM_CLICK_ON_SETTINGS_PAGE_SETUP,
			},
			{
				name: "Your Account",
				active: subPage === SETTINGS_ACCOUNT_URL_KEY,
				urlKey: SETTINGS_ACCOUNT_URL_KEY,
				event: PLATFORM_CLICK_ON_SETTINGS_ACCOUNT,
			},
		];
	}, [subPage]);

	const sectionName = useMemo(() => {
		return getSubpath(location) === "" ? "settings" : getSubpath(location);
	}, [location]);

	if (!subPage) {
		return <Navigate to={getPlatformPath(SETTINGS_BRAND_URL_KEY, user)} />;
	}

	return (
		<AuthPage
			active={sectionName}
			className="settings-page"
			user={user}
			setUser={setUser}
			viewEvent={PLATFORM_VIEW_SETTINGS}
		>
			<Routes>
				<Route
					path={`:subPage`}
					element={
						<>
							<SettingsPageHeader
								user={user}
								setUser={setUser}
								tabOptions={tabOptions}
							/>
							<SettingsPageRenderer
								user={user}
								setUser={setUser}
							/>
						</>
					}
				/>
			</Routes>
		</AuthPage>
	);
});

export default SettingsPage;
