import {
	Button,
	FONT_COLOR,
	FONT_SIZE,
	InformationTooltip,
	Loader,
	PaddedContainer,
	SPACING,
	StatusLabel,
	Text,
} from "@disco/disco_core";
import { motion } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	EditModeToggle,
	FlexBr,
	QuestionTooltip,
} from "../../components/CoreUI";
import ProductRow from "../../components/ProductRow";
import {
	FETCH_PRODUCTS_URL,
	FINAL_SETUP_STATUS,
	PREVIEW_TYPES_IDX,
	PRODUCT_BULK_UPDATE_URL,
	SAVE_BRAND_URL,
	SAVE_CODE_URL,
	SAVE_PRODUCT_ORDER_URL,
} from "../../conf";
import useModal from "../../hooks/useModal";
import useResource from "../../hooks/useResource";
import manualIcon from "../../img/product-manual.png";
import anim from "../../utils/anim";
import getPlatformPath from "../../utils/getPlatformPath";
import EditView from "../EditView";
import Reorder from "../Reorder";
import UTMParam from "../UTMParam";
import EditTextModal from "../modals/EditTextModal";
import ProductModal from "../modals/ProductModal";
import VariantsModal from "../modals/VariantsModal";
import "./products-container.css";

const discountValidator = (num) => {
	if (!num.trim()) {
		return [true, ""];
	}
	if (isNaN(num) || Number(num) < 0 || Number(num) > 100) {
		return [false, "Please enter a valid discount percentage"];
	}
	return [true, num];
};

const ProductInput = React.memo(
	({ image, heading, text, buttonText, onClick }) => {
		return (
			<PaddedContainer
				className="products-container-input"
				hPadding={SPACING.MEDIUM}
				vPadding={SPACING.LARGE}
				marginLeft={SPACING.MEDIUM}
				marginRight={SPACING.MEDIUM}
			>
				<img src={image} alt={heading} />
				<Text
					Element="h2"
					size={FONT_SIZE.SUB_TITLE}
					marginTop={SPACING.LARGE}
					thick
				>
					{heading}
				</Text>
				<Text
					marginTop={SPACING.MEDIUM}
					marginBottom={SPACING.MEDIUM}
					size={FONT_SIZE.BODY}
					color={FONT_COLOR.MID}
				>
					{text}
				</Text>
				<Button onClick={onClick}>{buttonText}</Button>
			</PaddedContainer>
		);
	}
);

const ProductsContainer = React.memo(
	({
		setRefresh,
		user,
		onSelect,
		setUser,
		editMode,
		setEditMode,
		setPreviewType,
		onboarding = false,
		selectOnly = false,
		upsellOnly = false,
		crossSellOnly = false,
		upsellEmailOnly = false,
	}) => {
		const [{ data, loading, error }, reload, reset] = useResource({
			url: `${FETCH_PRODUCTS_URL}${
				!crossSellOnly && !upsellOnly
					? ""
					: crossSellOnly
					? "?type=cross_sell"
					: "?type=upsell"
			}`,
			method: "GET",
		});

		const navigate = useNavigate();

		const {
			open: add,
			handleOpen: handleAddOpen,
			handleClose: handleAddClose,
		} = useModal();

		const handleAdd = useCallback(() => {
			if (typeof setRefresh === "function") {
				setRefresh(true);
			}
			setUser((user) => ({
				...user,
				publisher: { ...user.publisher, num_products: 1 },
			}));
			reload();
		}, [setUser, setRefresh, reload]);

		const [disabled, setDisabled] = useState(false);
		const [products, setProducts] = useState(null);
		const [draftProducts, setDraftProducts] = useState(null);
		const [inDraft, setInDraft] = useState(false);
		const [orderChanged, setOrderChanged] = useState(false);
		// const [visibleFilter, setVisibleFilter] = useState(true);
		// const [hiddenFilter, setHiddenFilter] = useState(true);
		const [productEditOpen, setProductEditOpen] = useState(null);

		const [codeEditOpen, setCodeEditOpen] = useState(false);

		const [bulkActive, setBulkActive] = useState(true);
		const [bulkUpsell, setBulkUpsell] = useState(true);
		const [bulkRequestPayload, setBulkRequestPayload] = useState({});

		const [orderPayload, setOrderPayload] = useState();

		const [variantsOpen, setVariantsOpen] = useState(false);

		const refreshRef = useRef(null);
		const [
			{ data: orderData, loading: orderLoading, error: orderError },
			changeOrder,
			resetOrder,
		] = useResource(
			{
				url: SAVE_PRODUCT_ORDER_URL,
				method: "PUT",
				data: orderPayload,
			},
			false
		);

		const [
			{
				data: bulkRequestData,
				loading: bulkRequestLoading,
				error: bulkRequestError,
			},
			updateInBulk,
			resetBulkRequest,
		] = useResource(
			{
				url: PRODUCT_BULK_UPDATE_URL,
				method: "PUT",
				data: bulkRequestPayload,
			},
			false
		);

		const updateUser = useCallback(
			(products) => {
				let active = false,
					upsell = false,
					i = 0;

				// Old-school while loop for not iterating over products twice
				// Because products can be 1000s of elements long
				while ((!active || !upsell) && i < products.length) {
					active = active || products[i].active;
					upsell = upsell || products[i].upsell;
					i++;
				}
				setUser((user) => ({
					...user,
					publisher: {
						...user.publisher,
						has_upsell_products: upsell,
						has_cross_sell_products: active,
					},
				}));
			},
			[setUser]
		);

		const saveOrder = useCallback(() => {
			setOrderPayload({
				product_order: (editMode ? draftProducts : products).map(
					(product, id) => ({
						product: product.remote_id,
						order: id,
					})
				),
				order_field: crossSellOnly
					? "cross_sell_order"
					: "upsell_order",
			});
			if (disabled) {
				return;
			}
			changeOrder();
		}, [
			products,
			changeOrder,
			draftProducts,
			editMode,
			disabled,
			crossSellOnly,
		]);

		const handleReorder = useCallback(() => {
			setOrderChanged(true);
		}, []);

		const savedRef = useRef(false);

		useEffect(() => {
			if (!orderData && !orderError) {
				return;
			}
			setOrderChanged(false);
			if (orderError) return;
			if (typeof setRefresh === "function") {
				setRefresh(true);
			}
			resetOrder();
		}, [orderData, orderError, resetOrder, setRefresh]);

		useEffect(() => {
			if (!orderChanged) savedRef.current = false;
		}, [orderChanged]);

		const handleCodeSave = useCallback(
			(draft) => {
				if (user.setup_status < FINAL_SETUP_STATUS) {
					setUser((user) => ({
						...user,
						publisher: { ...user.publisher, code: draft["code"] },
					}));
				} else {
					setUser((user) => ({
						...user,
						publisher: { ...user.publisher, under_review: true },
						draft,
					}));
					if (setEditMode === "function") {
						setEditMode(true);
						setInDraft(true);
					}
				}
				setCodeEditOpen(false);
			},
			[setUser, user.setup_status, setEditMode]
		);

		const handleVariantsOpen = useCallback(
			(id) => {
				let product = products.find(
					(product) => product.remote_id === id
				);
				if (!product) {
					product = draftProducts.find(
						(product) => product.remote_id === id
					);
				}
				if (!product) {
					return;
				}
				setVariantsOpen(product);
			},
			[products, draftProducts]
		);

		const handleVariantsClose = useCallback(() => {
			setVariantsOpen(false);
		}, []);

		const handleEdit = useCallback(
			(id) => {
				let found = draftProducts.find(
					({ remote_id }) => remote_id === id
				);

				if (!found) {
					found = products.find(({ remote_id }) => remote_id === id);
				}
				setProductEditOpen(found);
			},
			[products, draftProducts]
		);

		const handleDelete = useCallback(() => {
			reload();
			if (typeof setRefresh === "function") {
				setRefresh(true);
			}
		}, [setRefresh, reload]);

		useEffect(() => {
			if (!Array.isArray(products) || !Array.isArray(draftProducts)) {
				return;
			}
			if (inDraft) {
				if (typeof setEditMode === "function") {
					setEditMode(true);
				}
			}
		}, [products, draftProducts, setEditMode, inDraft]);

		const handleProductSelect = useCallback(
			(id) => {
				if (typeof onSelect === "function") {
					onSelect(products[id]);
				}
			},
			[onSelect, products]
		);

		const handleProfileRedirect = useCallback(() => {
			navigate({
				pathname: "/settings",
			});
		}, [navigate]);

		const updateActive = useCallback(
			(productId, active, allActiveSelected) => {
				if (setPreviewType && typeof setPreviewType === "function") {
					setPreviewType(PREVIEW_TYPES_IDX.CROSS_SELL_PRODUCTS);
				}
				const setProductsActive = (products) => {
					if (!products) {
						return products;
					}

					let index = products.findIndex(
						(product) => product.remote_id === productId
					);
					if (index === -1) {
						return products;
					}
					const newProducts = [
						...products.slice(0, index),
						{ ...products[index], active },
						...products.slice(index + 1),
					];
					updateUser(newProducts);
					return newProducts;
				};

				setBulkActive(allActiveSelected);
				setDraftProducts(setProductsActive);
				setProducts(setProductsActive);
			},
			[updateUser, setPreviewType]
		);

		const updateUpsellEmail = useCallback(
			(productId, upsellEmail) => {
				const setProductsUpsellEmail = (products) => {
					if (!products) {
						return products;
					}

					let index = products.findIndex(
						(product) => product.remote_id === productId
					);
					if (index === -1) {
						return products;
					}
					const newProducts = [
						...products.slice(0, index),
						{ ...products[index], upsell_email: upsellEmail },
						...products.slice(index + 1),
					];
					updateUser(newProducts);
					return newProducts;
				};

				setDraftProducts(setProductsUpsellEmail);
				setProducts(setProductsUpsellEmail);
			},
			[updateUser]
		);

		const updateUpsell = useCallback(
			(productId, upsell, allUpsellSelected) => {
				if (setPreviewType && typeof setPreviewType === "function") {
					setPreviewType(PREVIEW_TYPES_IDX.UPSELL);
				}
				const setProductsUpsell = (products) => {
					if (!products) {
						return products;
					}

					let index = products.findIndex(
						(product) => product.remote_id === productId
					);
					if (index === -1) {
						return products;
					}
					const newProducts = [
						...products.slice(0, index),
						{ ...products[index], upsell },
						...products.slice(index + 1),
					];
					updateUser(newProducts);
					return newProducts;
				};

				setBulkUpsell(allUpsellSelected);
				setDraftProducts(setProductsUpsell);
				setProducts(setProductsUpsell);
			},
			[updateUser, setPreviewType]
		);

		const handleBulkActive = useCallback(() => {
			if (loading) {
				return;
			}
			if (setPreviewType && typeof setPreviewType === "function") {
				setPreviewType(PREVIEW_TYPES_IDX.CROSS_SELL_PRODUCTS);
			}
			setBulkRequestPayload({
				active: !bulkActive,
			});
			updateInBulk();
		}, [
			bulkActive,
			setBulkRequestPayload,
			loading,
			updateInBulk,
			setPreviewType,
		]);

		const handleBulkUpsell = useCallback(() => {
			if (loading) {
				return;
			}
			if (setPreviewType && typeof setPreviewType === "function") {
				setPreviewType(PREVIEW_TYPES_IDX.UPSELL);
			}
			setBulkRequestPayload({
				upsell: !bulkUpsell,
			});
			updateInBulk();
		}, [
			bulkUpsell,
			setBulkRequestPayload,
			loading,
			updateInBulk,
			setPreviewType,
		]);

		const handleDefaultDiscountUpdate = useCallback(() => {
			reload();
			if (typeof setRefresh === "function") {
				setRefresh(true);
			}
		}, [reload, setRefresh]);

		const handleProductEdit = useCallback(() => {
			if (user.setup_status >= FINAL_SETUP_STATUS) {
				if (setEditMode === "function") {
					setEditMode(true);
				}
			}
			reload();
			if (typeof setRefresh === "function") {
				setRefresh(true);
			}
		}, [user.setup_status, setEditMode, reload, setRefresh]);

		const handleProductAdd = useCallback(() => {
			navigate({
				pathname: getPlatformPath("products", user),
			});
		}, [navigate, user]);

		useEffect(() => {
			if (bulkRequestData) {
				const { products, all_upsell, all_active } = bulkRequestData;
				updateUser(products);
				setProducts(products);
				setBulkActive(all_active);
				setBulkUpsell(all_upsell);
				if (typeof setRefresh === "function") {
					setRefresh(true);
				}
				resetBulkRequest();
			}
		}, [bulkRequestData, reload, resetBulkRequest, setRefresh, updateUser]);

		// Copy products to local state, to edit (if needed)
		useEffect(() => {
			if (data) {
				if (data.product_sync_complete && refreshRef.current) {
					clearInterval(refreshRef.current);
					setUser((user) => ({
						...user,
						product_sync_complete: false,
					}));
				}

				setBulkUpsell(data.all_upsell || false);
				setBulkActive(data.all_active || false);
				setProducts(data.products);

				setUser((user) => ({
					...user,
					publisher: {
						...user.publisher,
						num_products: data.products.length,
					},
				}));
				setDraftProducts(data["draft_products"]);
				setInDraft(data["in_draft"]);
				if (
					!selectOnly &&
					!upsellEmailOnly &&
					!upsellOnly &&
					!crossSellOnly &&
					(data["in_draft"] ||
						(user.draft.code !== user.publisher.code &&
							user.setup_status >= FINAL_SETUP_STATUS &&
							user.publisher.under_review))
				) {
					if (setEditMode === "function") {
						setEditMode(true);
					}
				}
				reset();
			}
		}, [
			data,
			reset,
			updateUser,
			selectOnly,
			upsellOnly,
			crossSellOnly,
			upsellEmailOnly,
			user,
			setUser,
			setEditMode,
		]);

		useEffect(() => {
			if (user.product_sync_complete && refreshRef.current === null) {
				refreshRef.current = setInterval(() => {
					reload();
				}, 20000);
			}
		}, [user.product_sync_complete, reload]);

		useEffect(() => {
			if (selectOnly || !orderChanged || savedRef.current) return;
			saveOrder();
			console.log("SAVIN'");
			savedRef.current = true;
		}, [selectOnly, orderChanged, orderLoading, saveOrder]);

		return (
			<>
				{!selectOnly &&
					!upsellOnly &&
					!crossSellOnly &&
					(inDraft ||
						(user &&
							user.publisher &&
							user.publisher.draft_product_exists)) &&
					typeof setEditMode === "function" && (
						<EditModeToggle
							className="products-container-edit-mode-toggle"
							editMode={editMode}
							setEditMode={setEditMode}
						/>
					)}
				{(error || orderError || bulkRequestError) && (
					<StatusLabel>
						{error
							? "Could not fetch products. Refresh to try again."
							: orderError
							? "Could not save the ordered products. Try again later."
							: bulkRequestError
							? "Could not process all your products. Try again later"
							: "An unexpected error occurred. Please try again later"}
					</StatusLabel>
				)}
				{user.product_sync_complete && (
					<PaddedContainer
						className="products-container-sync"
						marginTop={SPACING.REGULAR}
						hMarginAuto
						vPadding={SPACING.MEDIUM}
						hPadding={SPACING.MEDIUM}
					>
						<Loader />
						<Text
							Element="h3"
							size={FONT_SIZE.BODY}
							marginLeft={SPACING.MEDIUM}
						>
							Hang tight. We’re syncing your products. It might
							take a moment.
						</Text>
					</PaddedContainer>
				)}
				{!selectOnly &&
					!upsellEmailOnly &&
					!upsellOnly &&
					!onboarding &&
					!crossSellOnly &&
					Array.isArray(editMode ? draftProducts : products) &&
					(editMode ? draftProducts : products).length > 0 && (
						<PaddedContainer
							className="products-container-add"
							marginTop={SPACING.MEDIUM}
							hMarginAuto
						>
							<Button
								onClick={handleAddOpen}
								marginLeft={SPACING.MICRO}
								marginRight={SPACING.MICRO}
							>
								Add Products
							</Button>
						</PaddedContainer>
					)}
				{!selectOnly &&
					!upsellEmailOnly &&
					!upsellOnly &&
					!onboarding &&
					!crossSellOnly &&
					Array.isArray(editMode ? draftProducts : products) &&
					(editMode ? draftProducts : products).length > 0 && (
						<>
							<PaddedContainer
								className="products-container-config-container"
								vPadding={SPACING.MEDIUM}
								hMarginAuto
								vMarginAuto
							>
								<EditView
									className="products-container-config-item"
									name="Shop Discount code"
									user={user}
									setUser={setUser}
									placeholder="Enter a shop discount code"
									remoteKey="code"
									tooltipTitle="Shop discount code"
									tooltipText='Enter the message you’d like customers to see when viewing your offer (i.e. "Enter DISCO15 at checkout for 15% off" or "Subscribe & Save!")'
									url={SAVE_CODE_URL}
									draftable={!onboarding}
									empty
									designSystem
								/>

								<EditView
									className="products-container-config-item"
									name="Default Product discount"
									user={user}
									setUser={setUser}
									remoteKey="product_discount"
									placeholder="Enter a default product discount (%)"
									tooltipTitle="Default product discount"
									tooltipText={
										<>
											Enter the percent discount you're
											offering to be auto-applied to all
											your products exclude the "%"
										</>
									}
									validator={discountValidator}
									url={SAVE_BRAND_URL}
									empty
									onEdit={handleDefaultDiscountUpdate}
									designSystem
								/>
							</PaddedContainer>
							<UTMParam user={user} setUser={setUser} />
						</>
					)}
				{!selectOnly &&
					onboarding &&
					!loading &&
					user.publisher.num_products > 0 && (
						<PaddedContainer
							motionElement
							marginTop={SPACING.MEDIUM}
							className="products-container-bulk-btns"
						>
							{bulkRequestLoading ? (
								<Loader />
							) : (
								<>
									{!upsellOnly && (
										<Button
											light
											marginTop={SPACING.MICRO}
											marginRight={SPACING.MICRO}
											marginBottom={SPACING.MICRO}
											marginLeft={SPACING.MICRO}
											onClick={handleBulkActive}
										>
											{bulkActive ? "Deselect" : "Select"}{" "}
											all for Cross-sell
										</Button>
									)}
									{!crossSellOnly && (
										<Button
											light
											onClick={handleBulkUpsell}
										>
											{" "}
											{bulkUpsell
												? "Deselect"
												: "Select"}{" "}
											all for Upsell
										</Button>
									)}
								</>
							)}
						</PaddedContainer>
					)}

				{/* {!selectOnly && orderChanged && !orderLoading && (
					<>
						<FlexBr />
						<Button
							className="products-container-save-order"
							onClick={saveOrder}
						>
							Save Order
						</Button>
						<FlexBr />
					</>
				)} */}

				<PaddedContainer
					motionElement
					Element="div"
					animate={{
						opacity: loading || orderLoading ? 1 : 0,
					}}
				>
					<FlexBr />
					<Loader small marginBottom={SPACING.SMALL} hMarginAuto />
					<FlexBr />
				</PaddedContainer>

				{Array.isArray(editMode ? draftProducts : products) &&
				(editMode ? draftProducts : products).length > 0 ? (
					<PaddedContainer
						motionElement
						marginTop={SPACING.SMALL}
						className="products-table-container"
					>
						<PaddedContainer
							motionElement
							Element="table"
							layout
							className="core-table products-table"
						>
							<PaddedContainer
								motionElement
								Element="thead"
								layout
							>
								<PaddedContainer
									motionElement
									Element="tr"
									layout
								>
									<PaddedContainer
										motionElement
										Element="th"
										layout
										className="products-table-number"
									>
										<Text
											inline
											vPadding={SPACING.TINY}
											hPadding={SPACING.MEDIUM}
										>
											#
										</Text>
									</PaddedContainer>
									<PaddedContainer
										motionElement
										Element="th"
										layout
									>
										Image
									</PaddedContainer>
									<PaddedContainer
										motionElement
										Element="th"
										layout
									>
										Name
									</PaddedContainer>
									<PaddedContainer
										motionElement
										Element="th"
										layout
									>
										Price
									</PaddedContainer>
									{!onboarding && (
										<PaddedContainer
											motionElement
											Element="th"
											layout
										>
											Discount
										</PaddedContainer>
									)}
									{!(selectOnly || upsellEmailOnly) ? (
										upsellOnly || crossSellOnly ? (
											<>
												<PaddedContainer
													motionElement
													Element="th"
													layout
													vPadding={SPACING.MICRO}
													tightTop
													className="products-table-upsell"
												>
													{upsellOnly
														? "Upsell"
														: "Cross-Sell"}
													<InformationTooltip
														activatorSize={16}
														persistent={true}
													>
														{upsellOnly
															? `Your selected products
														will be visible in the
														first tile of your own
														widget`
															: `Your selected products
														will be visible on other shops across the network`}
													</InformationTooltip>
												</PaddedContainer>
											</>
										) : (
											<>
												<PaddedContainer
													motionElement
													Element="th"
													layout
													vPadding={SPACING.MICRO}
													tightTop
													className="products-table-crosssell"
												>
													Cross-sell
													<QuestionTooltip
														className="question-tooltip-icon"
														tooltipClassName="question-tooltip"
													>
														Your selected products
														will be visible on shops
														across our network
													</QuestionTooltip>
												</PaddedContainer>
												<PaddedContainer
													motionElement
													Element="th"
													layout
													className="products-table-upsell"
												>
													Upsell
													<QuestionTooltip
														className="question-tooltip-icon"
														tooltipClassName="question-tooltip"
													>
														Your selected products
														will be visible in the
														first row of your own
														widget
													</QuestionTooltip>
												</PaddedContainer>
												{!onboarding && (
													<PaddedContainer
														motionElement
														Element="th"
														layout
													>
														Actions
													</PaddedContainer>
												)}
											</>
										)
									) : upsellEmailOnly ? (
										<PaddedContainer
											motionElement
											Element="th"
											layout
											vPadding={SPACING.MICRO}
											tightTop
											className="products-table-upsell-email"
										>
											Email Upsell
											{/* <QuestionTooltip
												className="question-tooltip-icon"
												tooltipClassName="question-tooltip"
											>
												Your selected products will be
												visible in your shopify email
												notifications
											</QuestionTooltip> */}
										</PaddedContainer>
									) : null}
								</PaddedContainer>
							</PaddedContainer>
							<Reorder
								list={editMode ? draftProducts : products}
								setList={
									editMode ? setDraftProducts : setProducts
								}
								groupMotionElement="tbody"
								itemMotionElement="tr"
								optionClassName="product-row"
								disabled={orderLoading || selectOnly}
								onChildClick={handleProductSelect}
								productRow
								dragEnd={handleReorder}
								renderOption={(id) => {
									const product = (
										editMode ? draftProducts : products
									)[id];
									return (
										<ProductRow
											product={product}
											productID={id}
											onActiveUpdate={(...args) => {
												updateActive(...args);
												if (
													typeof setRefresh ===
													"function"
												) {
													setRefresh(true);
												}
											}}
											selectOnly={selectOnly}
											upsellOnly={upsellOnly}
											crossSellOnly={crossSellOnly}
											editMode={editMode}
											onVariantsOpen={handleVariantsOpen}
											onUpsellUpdate={(...args) => {
												updateUpsell(...args);
												if (
													typeof setRefresh ===
													"function"
												) {
													setRefresh(true);
												}
											}}
											onUpsellEmailUpdate={
												updateUpsellEmail
											}
											key={product.remote_id}
											onEdit={handleEdit}
											onDelete={handleDelete}
											onboarding={onboarding}
											upsellEmailOnly={upsellEmailOnly}
										/>
									);
								}}
							/>
							{disabled && (
								<PaddedContainer
									motionElement
									className="products-table-overlay"
									variants={anim.dialogOverlay}
									initial="initial"
									animate="animate"
								>
									<PaddedContainer
										motionElement
										Element="div"
										className="products-table-overlay-content"
										marginTop={SPACING.JUMBO}
										vPadding={SPACING.LARGE}
										hPadding={SPACING.LARGE}
										variants={anim.dialog}
									>
										<Text
											motionElement
											thick
											size={FONT_SIZE.SUB_TITLE}
											variants={anim.dialog}
										>
											Your subscription has expired.
										</Text>
										<PaddedContainer
											motionElement
											Element="div"
											marginTop={SPACING.REGULAR}
											marginBottom={SPACING.REGULAR}
											hMarginAuto
											className="products-table-overlay-line"
										/>
										<Text
											motionElement
											thin
											size={FONT_SIZE.LABEL}
											variants={anim.dialog}
										>
											Please renew your subscription to
											avail all the features offered by{" "}
											<b>Disco</b>
										</Text>
										<Button
											secondary
											variants={anim.dialog}
											hMarginAuto
											marginTop={SPACING.MEDIUM}
											onClick={handleProfileRedirect}
											small
										>
											Renew
										</Button>
									</PaddedContainer>
								</PaddedContainer>
							)}
						</PaddedContainer>
					</PaddedContainer>
				) : Array.isArray(editMode ? draftProducts : products) &&
				  (editMode ? draftProducts : products).length === 0 ? (
					<PaddedContainer
						className="products-container-inputs"
						marginTop={SPACING.MEDIUM}
					>
						<ProductInput
							image={manualIcon}
							heading="Add Your Products"
							text="Click here to add your favorite products to Disco"
							buttonText="Add Product"
							onClick={handleProductAdd}
						/>
					</PaddedContainer>
				) : null}
				{!(selectOnly || upsellEmailOnly) && (
					<>
						{" "}
						<EditTextModal
							open={codeEditOpen}
							heading="Edit Discount code"
							placeholder="Discount code"
							saveUrl={SAVE_CODE_URL}
							initial={user.publisher.code}
							eventName="PLATFORM_CODE_CHANGE"
							empty={true}
							remoteKey="code"
							onClose={() => setCodeEditOpen(false)}
							onSave={handleCodeSave}
						>
							<p>
								Use for offering discount codes like “use
								disco15 at checkout” or “15% off with disco15”
								or "subscribe & save!"
							</p>
						</EditTextModal>
						{productEditOpen !== null && (
							<ProductModal
								onDone={handleProductEdit}
								user={user}
								disabled={disabled}
								open={productEditOpen}
								onClose={() => setProductEditOpen(null)}
								product={productEditOpen}
							/>
						)}
					</>
				)}
				<ProductModal
					onDone={handleAdd}
					open={add}
					user={user}
					onClose={handleAddClose}
				/>
				<VariantsModal
					open={!!variantsOpen}
					onClose={handleVariantsClose}
					product={variantsOpen}
					setProducts={setProducts}
					user={user}
				/>
			</>
		);
	}
);

export default ProductsContainer;
