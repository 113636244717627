import React, { memo } from "react";
import useIdentity from "../../hooks/useIdentity";
import Emails from "../emails/Emails";
import EmailsTutorial from "../emails/EmailsTutorial";

import "./emails-module.css";

const EmailsModule = memo(() => {
	const { user, setUser } = useIdentity();
	const tutorial =
		!user.publisher.emails_tutorial_complete ||
		user.publisher.emailsFinalStep;
	return (
		<main className="emails-module-content">
			{tutorial ? (
				<EmailsTutorial user={user} setUser={setUser} />
			) : (
				<Emails user={user} setUser={setUser} />
			)}
		</main>
	);
});

export default EmailsModule;
