import React from "react";
import { Navigate } from "react-router-dom";
import useIdentity from "../hooks/useIdentity";
import { GET } from "../utils/GET";
import getPlatformPath from "../utils/getPlatformPath";
import isAuth from "../utils/isAuth";
import makeRedirectURL from "../utils/makeRedirectURL";

export default function RootPage() {
	const { user } = useIdentity();

	return (
		<Navigate
			to={makeRedirectURL(
				isAuth()[0]
					? GET("next")
						? GET("next")
						: getPlatformPath("home", user)
					: `/login`
			)}
		/>
	);
}
