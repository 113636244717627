import axios from "axios";
import React, { createRef, memo, useCallback, useState } from "react";
import { Checkbox, GuidedForm } from "../../components/CoreUI";
import { DESIGNATIONS, FULL_ACCEPT_INVITE_URL, TOS_URL } from "../../conf";
import tokenStorage from "../../utils/tokenStorage";
import OnboardingStep from "./OnboardingStep";
import "./register-step.css";

import parseError from "../../utils/parseError";

import hand from "../../img/hand.png";

import getFirstError from "../../utils/getFirstError";
import getJoinData, { USER_TYPES } from "../../utils/getJoinData";

import { Navigate } from "react-router-dom";
import validPhone from "../../utils/validPhone";
import "./register-team-step.css";

import makeRedirectURL from "../../utils/makeRedirectURL";

const joinData = getJoinData();

const errKeyMapping = {
	hero_url: "heroUrl",
	brand_name: "brandName",
	email: "email",
	password: "password",
	password2: "password2",
};

const initialFormError = { error: false, ref: null };

const RegisterTeamStep = memo(({ setAuth }) => {
	const [loading, setLoading] = useState(false);
	const [tos, setTos] = useState(false);
	const [error, setError] = useState("");
	const [showError, setShowError] = useState(false);
	const [formError, setFormError] = useState(initialFormError);

	const [data, setData] = useState({
		email: {
			props: {
				disabled: true,
				value: joinData?.email,
				label: "Your email",
			},
		},
		first_name: {
			tooltip: {
				heading: "Your first name",
				text: "Enter your first name",
				type: "email",

				ref: createRef(),
			},
			errorName: "First name",
			props: {
				value: "",
				placeholder: "First name",
				type: "text",
				label: "Your first name",
				required: true,
				ref: createRef(),
			},
		},
		last_name: {
			tooltip: {
				heading: "Your last name",
				text: "Enter your last name",
			},
			errorName: "Last name",
			props: {
				value: "",
				placeholder: "Last name",
				type: "text",
				label: "Your last name",

				ref: createRef(),
			},
		},

		phone_number: {
			tooltip: {
				heading: "Your phone number",
				text: "Enter your phone number",
			},
			errorName: "Phone number",
			props: {
				value: "",
				placeholder: "Phone number",
				type: "text",
				label: "Your phone number",

				ref: createRef(),
			},
		},

		designation: {
			tooltip: {
				heading: "Your designation",
				text: "Your designation at " + joinData.brand,
			},
			errorName: "Designation",
			props: {
				value: "",
				placeholder: "Designation",
				type: "select",
				label: "Your designation",
				options: DESIGNATIONS,
				required: true,
				ref: createRef(),
			},
		},

		password: {
			tooltip: {
				heading: "Your password must include:",
				text: (
					<ul>
						<li>One uppercase letter</li>
						<li>One lowercase letter</li>
						<li>One special character</li>
						<li>Minimum length of 8 characters</li>
					</ul>
				),
			},
			errorName: "Password",
			props: {
				value: "",
				placeholder: "Password",
				type: "password",
				label: "Your Password",
				required: true,
				ref: createRef(),
			},
		},
		password2: {
			tooltip: {
				heading: "Confirm your password",
				text: "Type your password again to make sure it's correct.",
			},
			errorName: "Confirm your password",
			props: {
				value: "",
				placeholder: "Confirm password",
				type: "password",
				label: "Confirm your password",
				required: true,
				ref: createRef(),
			},
		},
	});

	const register = useCallback(async () => {
		setLoading(true);
		let resp;
		const {
			first_name: {
				props: { value: first_name },
			},
			last_name: {
				props: { value: last_name },
			},
			phone_number: {
				props: { value: phone_number },
			},
			password: {
				props: { value: password },
			},
			password2: {
				props: { value: password2 },
			},
			designation: {
				props: { value: designation },
			},
		} = data;
		const payload = {
			phone_number,
			first_name,
			last_name,
			password,
			password2,
			token: joinData.token,
			email: joinData.email,
			designation,
		};

		try {
			resp = await axios.post(FULL_ACCEPT_INVITE_URL, payload);
		} catch (error) {
			setLoading(false);
			setShowError(false);
			let err = getFirstError(error);
			if (!err || !errKeyMapping[err[0]]) {
				setError(parseError(error));
				return setShowError(true);
			}
			const dataKey = errKeyMapping[err[0]];
			return setFormError({
				error: {
					heading: data[dataKey].errorName,
					text: err[1],
				},
				ref: data[dataKey].props.ref,
			});
		}
		tokenStorage.set({
			accessToken: resp.data.access,
			refreshToken: resp.data.refresh,
			publisher: resp.data.publisher_id,
		});
		setAuth(true);
	}, [data, setAuth]);

	const handleFormError = useCallback(
		(text, objKey) => {
			if (!data[objKey]) {
				return setShowError(true);
			}
			setFormError({
				error: {
					heading: data[objKey].errorName,
					text,
				},
				ref: data[objKey].props.ref,
			});
		},
		[data]
	);

	const handleRegister = useCallback(() => {
		const {
			phone_number: {
				props: { value: phone_number },
			},
			password: {
				props: { value: password },
			},
			password2: {
				props: { value: password2 },
			},
		} = data;

		const error = Object.entries(data).reduce(
			(
				error,
				[
					key,
					{
						errorName,
						props: { value, required },
					},
				]
			) => {
				if (error) return error;
				if (required && !value)
					return [`${errorName} is required`, key];
				return null;
			},
			null
		);
		if (error !== null) return handleFormError(...error);

		if (phone_number.trim() && !validPhone(phone_number)) {
			return handleFormError(
				"Please enter valid phone number",
				"phone_number"
			);
		} else if (password.trim().length < 1) {
			return handleFormError("Please enter a password", "password");
		} else if (password2.trim().length < 1) {
			return handleFormError("Please confirm you password", "password2");
		} else if (password !== password2) {
			return handleFormError(
				"Your confirmation password does not match the original one",
				"password2"
			);
		} else if (password.length < 8) {
			return handleFormError(
				"The password must be at least 8 characters long",
				"password"
			);
		} else if (!tos) {
			setShowError(true);
			return setError("Please accept the terms of service to register");
		}
		setError("");
		register();
	}, [data, tos, register, handleFormError]);

	// useEffect(() => {
	// triggerIntercom();
	// }, []);

	if (!joinData) {
		return <Navigate to="/" />;
	} else if (joinData.type === USER_TYPES.EXISTING) {
		return <Navigate to={makeRedirectURL(`/loginTeam`)} />;
	}

	return (
		<OnboardingStep
			split
			steps={null}
			heading={`You have been invited to join ${joinData?.brand}!`}
			subText={
				<>
					You have been invited to join {joinData?.brand}! Please
					create a new account with your email address to accept the
					invite and join their team.
				</>
			}
			nextText="Create account"
			aside={{
				heading: "Let’s go.",
				image: hand,
				logo: true,
				imageWidth: "95%",
				imageUp: true,
			}}
			showLogout={false}
			// requiredLegend
			className="register-team-step"
			onNext={handleRegister}
			nextLoading={loading}
			showBack={false}
			error={showError && error ? error : false}
		>
			<GuidedForm
				data={data}
				setData={setData}
				formError={formError}
				setFormError={setFormError}
				initialHighlight="first_name"
				resetHighlight={showError && error}
			>
				<label className="register-step-tos">
					<Checkbox
						checked={tos}
						onChange={(e) => setTos(e.target.checked)}
					/>
					I agree to the
					<a href={TOS_URL} target="_blank" rel="noopener noreferrer">
						Terms of Service
					</a>
				</label>
			</GuidedForm>
		</OnboardingStep>
	);
});

export default RegisterTeamStep;
