import React, {
	forwardRef,
	memo,
	useCallback,
	useEffect,
	useState,
} from "react";
import PropTypes from "prop-types";

import "./remote-tab-switcher.css";
import { TabSwitcher, tabSwitcherOptionPropTypesShape } from "../TabSwitcher";
import useResource from "../../hooks/useResource";
import { isFunction } from "../../utils";

export const RemoteTabSwitcher = memo(
	forwardRef(({ options, onChange, value, name, onError, ...rest }, ref) => {
		const [reqOptions, setReqOptions] = useState({});

		const [{ data: remoteData, loading, error }, save, reset] = useResource(
			reqOptions,
			false
		);

		const handleChange = useCallback(
			({ target: { value: idx } }) => {
				if (idx === value) return;

				const { validator, remoteOptions } = options?.[idx] || {};

				if (isFunction(validator)) {
					const isValid = validator();
					if (!isValid) {
						return;
					}
				}

				if (remoteOptions) {
					setReqOptions({
						...options[idx].remoteOptions,
						tabIndex: idx,
					});
					save();
				} else if (isFunction(onChange)) {
					onChange({ target: { name, value: idx } });
				}
			},
			[onChange, options, save, value, name]
		);

		useEffect(() => {
			if (!remoteData) {
				return;
			}
			if (isFunction(onChange)) {
				onChange({
					target: { name, value: reqOptions?.tabIndex, remoteData },
				});
			}
			setReqOptions({});
			reset();
		}, [reset, remoteData, reqOptions, onChange, name]);

		useEffect(() => {
			if (!error || !isFunction(onError)) return;

			onError({ error });
		}, [error, onError]);

		return (
			<TabSwitcher
				ref={ref}
				options={options}
				onChange={handleChange}
				value={value}
				name={name}
				loading={loading}
				{...rest}
			/>
		);
	})
);

RemoteTabSwitcher.displayName = "RemoteTabSwitcher";
RemoteTabSwitcher.propTypes = {
	value: PropTypes.number,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			...tabSwitcherOptionPropTypesShape,
			remoteOptions: PropTypes.object,
			validator: PropTypes.func,
		})
	),
	name: PropTypes.string,
	onChange: PropTypes.func,
	onError: PropTypes.func,
};
