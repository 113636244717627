import React, { useCallback, useMemo } from "react";
import NewEditImage from "../NewEditImage";
import useIdentity from "../../hooks/useIdentity";
import { BANNER_IMAGE_UPLOAD_URL } from "../../conf";
import { FONT_COLOR, Text } from "@disco/disco_core";

const BannerEditView = ({ className = "", onDone, ...rest }) => {
	const { user, setUser } = useIdentity();

	const bannerSelector = useCallback((user) => user.publisher.banner_url, []);
	const handleBannerDone = useCallback(
		(img) => {
			if (typeof onDone === "function") onDone();
			setUser((user) => ({
				...user,
				publisher: {
					...user.publisher,
					banner_url: img,
				},
			}));
		},
		[setUser, onDone]
	);

	const inputProps = useMemo(() => {
		return {
			requirementDescription: (
				<Text>
					For best results, attach a 16:9 image:{" "}
					<Text thick inline color={FONT_COLOR.DARK}>
						1064 x 600 pixels
					</Text>
				</Text>
			),
		};
	}, []);

	// {/* 667 / 376 */}
	return (
		<NewEditImage
			user={user}
			aspect={1.77}
			remoteKey="banner_url"
			url={BANNER_IMAGE_UPLOAD_URL}
			editText="Change Banner"
			heading="Change Banner Image"
			onDone={handleBannerDone}
			selector={bannerSelector}
			className={className}
			inputProps={inputProps}
			{...rest}
		/>
	);
};
export default BannerEditView;
