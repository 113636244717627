import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { BadgeButton, Page } from "../../components/CoreUI";
import ProfileStep from "../../components/onboardingSteps/ProfileStep";
import { FINAL_SETUP_STATUS, ONBOARDING_SETUP_STATUSES } from "../../conf";
import { logoutSegment } from "../../utils/segment";
import tokenStorage from "../../utils/tokenStorage";

import CategorizationStep from "../../components/onboardingSteps/CategorisationStep";
import InstallStep from "../../components/onboardingSteps/InstallStep";
import MobileOnboardingStep from "../../components/onboardingSteps/MobileOnboardingStep";
import PlatformsStep from "../../components/onboardingSteps/PlatformsStep";
import useDimensions from "../../hooks/useDimensions";
import { userUpdateZendesk } from "../../utils/zendesk";
import "./onboarding-page.css";

const stepComponentsMap = {
	[ONBOARDING_SETUP_STATUSES.PLATFORM_SELECTION]: PlatformsStep,
	[ONBOARDING_SETUP_STATUSES.REGISTRATION_SUCCESS]: InstallStep,
	[ONBOARDING_SETUP_STATUSES.PROFILE_SETUP]: ProfileStep,
	[ONBOARDING_SETUP_STATUSES.CATEGORY_SETUP]: CategorizationStep,
};

const OnboardingPage = React.memo(({ user }) => {
	const navigate = useNavigate();
	const { isMobile } = useDimensions();

	useEffect(() => {
		userUpdateZendesk(user, true);
	}, [user]);

	useEffect(() => {
		document.documentElement.scrollTop = 0;
		// triggerIntercom();
	}, [user.setup_status]);

	const handleLogout = () => {
		tokenStorage.clear();
		logoutSegment();
		navigate("/login");
	};

	if (user.setup_status === FINAL_SETUP_STATUS) {
		return <Navigate to="/settings" />;
	}

	const Step = stepComponentsMap[user.setup_status];

	return (
		<Page className="onboarding-page" auth>
			<BadgeButton
				onClick={handleLogout}
				className="onboarding-page-logout"
			>
				Logout
			</BadgeButton>
			<AnimatePresence>
				{isMobile ? <MobileOnboardingStep /> : <Step />}
			</AnimatePresence>
		</Page>
	);
});

export default OnboardingPage;
