import { useCallback, useEffect, useMemo, useState } from "react";
import persistentStateFactory from "../utils/persistentStateFactory";
import useIdentity from "./useIdentity";
import useResource from "./useResource";

const useSteps = (options = {}) => {
	const {
		key = "",
		maxSteps = 0,
		nextUrl = "",
		backUrl = "",
		remote = false,
		rootLevelProperty = false,
		onNext,
		onBack,
	} = useMemo(() => options, [options]);

	const { user, setUser } = useIdentity();

	const persistentState = useMemo(() => persistentStateFactory(key), [key]);
	const [step, setStep] = useState(persistentState.status);

	const [directStep, setDirectStep] = useState(null);

	const remoteStep = useMemo(
		() =>
			remote
				? rootLevelProperty
					? user?.[key] || 0
					: user?.publisher?.[key] || 0
				: 0,
		[remote, rootLevelProperty, user, key]
	);

	const [{ data: nextData, loading: nextLoading, error: nextError }, next] =
		useResource(
			{
				url: nextUrl,
				method: "PUT",
				data: {
					step_value: directStep,
				},
			},
			false
		);

	const [{ data: backData, loading: backLoading, error: backError }, back] =
		useResource(
			{
				url: backUrl,
				method: "PUT",
			},
			false
		);

	const handleNext = useCallback(() => {
		if (remote) {
			setDirectStep(null);
			return next();
		}
		setStep((step) => (step + 1 < maxSteps ? step + 1 : step));
	}, [maxSteps, remote, next]);

	const handleBack = useCallback(() => {
		if (remote) {
			return back();
		}
		setStep((step) => (step - 1 >= 0 ? step - 1 : 0));
	}, [remote, back]);

	const handleStepUpdate = useCallback(
		(updateData) => {
			if (typeof setUser !== "function") {
				return;
			}
			setUser((user) => {
				let newUser = { ...user };
				if (rootLevelProperty) {
					newUser = { ...newUser, ...updateData };
				} else {
					newUser = {
						...newUser,
						publisher: {
							...newUser.publisher,
							...updateData,
						},
					};
				}

				return newUser;
			});
		},
		[setUser, rootLevelProperty]
	);

	const setRemoteStep = useCallback(
		(step) => {
			setDirectStep(step);
			next();
		},
		[next]
	);

	useEffect(() => {
		if (remote) {
			return;
		}
		persistentState.status = step;
	}, [remote, step, persistentState]);

	useEffect(() => {
		if (!nextData) {
			return;
		}
		handleStepUpdate(nextData);
		if (typeof onNext === "function") {
			onNext(nextData);
		}
	}, [nextData, handleStepUpdate, onNext]);

	useEffect(() => {
		if (!backData) {
			return;
		}
		handleStepUpdate(backData);
		if (typeof onBack === "function") {
			onBack(backData);
		}
	}, [backData, handleStepUpdate, onBack]);

	return {
		step: remote ? remoteStep : step,
		setStep: remote ? setRemoteStep : setStep,
		handleNext,
		handleBack,
		nextData,
		nextError,
		backError,
		error: nextError || backError,
		loading: nextLoading || backLoading,
		backLoading,
		nextLoading,
	};
};

export default useSteps;
