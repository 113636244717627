import React, { forwardRef, memo } from "react";
import PropTypes from "prop-types";

import useTheme from "../../hooks/useTheme";
import genPackageClassName from "../../utils/genPackageClassName";

import { PaddedContainer, SPACING } from "../PaddedContainer";
import { FONT_COLOR, FONT_SIZE, Text } from "../Typography";
import "./radio.css";
import { anim, childrenPropTypes } from "../../utils";
import { AnimatePresence, motion } from "framer-motion";

export const Radio = memo(
	forwardRef(
		({ selected = false, className = "", fillerProps, ...rest }, ref) => {
			const { theme } = useTheme();
			return (
				<PaddedContainer
					Element="div"
					ref={ref}
					className={genPackageClassName({
						base: "radio flex-center",
						additional: className,
					})}
					{...rest}
				>
					<AnimatePresence>
						{selected && (
							<motion.div
								className={genPackageClassName({
									theme,
									base: "radio-filler",
								})}
								initial="initial"
								animate="animate"
								exit="exit"
								variants={anim.variantFactory({
									scale: 0,
									exit: { scale: 0, opacity: 0 },
								})}
								{...fillerProps}
							/>
						)}
					</AnimatePresence>
				</PaddedContainer>
			);
		}
	)
);
Radio.displayName = "Radio";
Radio.propTypes = {
	className: PropTypes.string,
	selected: PropTypes.bool,
	fillerProps: PropTypes.bool,
};

export const RadioElement = memo(
	forwardRef(
		(
			{
				className = "",
				disabled = false,
				border = false,
				underline = false,
				title,
				subtitle,
				selected,
				children,
				...rest
			},
			ref
		) => {
			return (
				<PaddedContainer
					hPadding={SPACING.REGULAR}
					vPadding={SPACING.REGULAR}
					className={genPackageClassName({
						base: "radio-element",
						additional: className,
						conditionals: {
							"radio-element-border": border,
							"radio-element-border-bottom": underline,
							"radio-element-disabled": disabled,
							"radio-element-selected": selected,
						},
					})}
					{...rest}
				>
					<Radio ref={ref} selected={selected} />
					<PaddedContainer
						marginLeft={SPACING.REGULAR}
						className={genPackageClassName({
							base: "radio-element-content",
						})}
					>
						{title && (
							<Text thick size={FONT_SIZE.LG_BODY}>
								{title}
							</Text>
						)}
						{subtitle && (
							<Text
								marginTop={title && SPACING.MICRO}
								size={FONT_SIZE.BODY}
								color={FONT_COLOR.MID}
							>
								{subtitle}
							</Text>
						)}
						{children && (
							<Text
								marginTop={(title || subtitle) && SPACING.TINY}
								size={FONT_SIZE.LABEL}
								color={FONT_COLOR.MID}
								thin
							>
								{children}
							</Text>
						)}
					</PaddedContainer>
				</PaddedContainer>
			);
		}
	)
);

RadioElement.displayName = "RadioElement";

RadioElement.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	title: childrenPropTypes,
	subtitle: childrenPropTypes,
	children: childrenPropTypes,
	selected: PropTypes.bool,
	underline: PropTypes.bool,
	border: PropTypes.bool,
};
