import getSubscriptionText from "./getSubscriptionText";
import isBigCommerceShop from "./isBigCommerceShop";
import isShopifyShop from "./isShopifyShop";
import isWooCommerceShop from "./isWooCommerceShop";

const showAlertBar = (user) => {
	const userSubscriptionInfo = getSubscriptionText(user);
	return (
		user &&
		(isShopifyShop(user) ||
			isWooCommerceShop(user) ||
			isBigCommerceShop(user)) &&
		((userSubscriptionInfo.inTrial && !userSubscriptionInfo.premium) ||
			!userSubscriptionInfo.premium ||
			(user.publisher.one_click_upsells &&
				!user.publisher.extension_payments_configured))
	);
};

export default showAlertBar;
