// This file is still called segment.js but the underlying code is now directly hooking into the mixpanel SDK
// Segment has been deprecated
// TODO: Update filename and corresponding imports
export const initInstance = (user) => {
	let trimmedUser = JSON.parse(JSON.stringify(user));
	delete trimmedUser.draft;
	delete trimmedUser.publisher.blacklist;
	delete trimmedUser.publisher.email_blacklist;
	delete trimmedUser.publisher.offer_blacklist;

	// TODO: Initialize the tracker SDK
};

export const logoutSegment = () => {
	// TODO: Reset / logout / clear the tracker SDK
};
