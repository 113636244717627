import React, { forwardRef, memo, useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";

import useTheme from "../../hooks/useTheme";
import genPackageClassName from "../../utils/genPackageClassName";

import "./table.css";
import { SearchIcon } from "../../Icons";
import { PaddedContainer, SPACING } from "../PaddedContainer";
import { FONT_COLOR, FONT_SIZE, Asset, Text } from "../Typography";
import { Loader } from "../Loader";
import { STATUS, StatusLabel } from "../StatusLabel";
import { INPUT_EL_POSITION, TextInput } from "../Input";

const SORT_DIRECTIONS = {
	ASCENDING: "ascending",
	DESCENDING: "descending",
};

export const Table = memo(
	forwardRef(
		(
			{
				className = "",
				title = "",
				headers,
				data,
				sortable = false,
				loading = false,
				searchable = false,
				searchKeys,
				noDataMessage = "No data found",
				renderRow = null,
			},
			ref
		) => {
			const { theme } = useTheme();
			const [searchKey, setSearchKey] = useState("");
			const [sortConfig, setSortConfig] = useState(null);

			const filteredData = useMemo(() => {
				if (!data) return [];
				let sortedData = [...data];
				if (sortConfig) {
					sortedData.sort((a, b) => {
						if (a[sortConfig.key] < b[sortConfig.key]) {
							return sortConfig.direction ===
								SORT_DIRECTIONS.ASCENDING
								? -1
								: 1;
						}
						if (a[sortConfig.key] > b[sortConfig.key]) {
							return sortConfig.direction ===
								SORT_DIRECTIONS.ASCENDING
								? 1
								: -1;
						}
						return 0;
					});
				}

				if (!searchKey || !searchKeys) return sortedData;
				return sortedData.filter((item) => {
					return searchKeys.some((key) => {
						return item[key]
							.toLowerCase()
							.includes(searchKey.toLowerCase());
					});
				});
			}, [data, sortConfig, searchKey, searchKeys]);

			const requestSort = useCallback(
				(key) => {
					const direction =
						sortConfig?.key === key &&
						sortConfig?.direction === SORT_DIRECTIONS.ASCENDING
							? SORT_DIRECTIONS.DESCENDING
							: SORT_DIRECTIONS.ASCENDING;

					console.log(key, direction);

					setSortConfig({ key, direction });
				},
				[sortConfig]
			);

			const getClassNamesFor = useCallback(
				(name) => {
					if (sortConfig) {
						if (sortConfig.key === name) {
							return `table-header-item-${name} table-header-item-${sortConfig.direction}`;
						}
					}
					return `table-header-item-${name}`;
				},
				[sortConfig]
			);

			return (
				<PaddedContainer
					className={genPackageClassName({
						base: "table-container",
						additional: className,
					})}
				>
					<PaddedContainer
						vPadding={SPACING.REGULAR}
						className={genPackageClassName({
							base: "table-header",
						})}
					>
						{title && (
							<Text thick size={FONT_SIZE.SUB_TITLE}>
								{title}
							</Text>
						)}
						{searchable && (
							<TextInput
								placeholder="Optional Search"
								value={searchKey}
								onChange={(e) => setSearchKey(e.target.value)}
								icon={
									<Asset color={FONT_COLOR.MID}>
										<SearchIcon />
									</Asset>
								}
								iconPosition={INPUT_EL_POSITION.RIGHT}
							/>
						)}
					</PaddedContainer>
					{loading ? (
						<Loader center />
					) : filteredData.length === 0 ? (
						<StatusLabel type={STATUS.WARNING}>
							{noDataMessage}
						</StatusLabel>
					) : (
						<table
							ref={ref}
							className={genPackageClassName({
								theme,
								base: "table",
							})}
						>
							<thead>
								<tr>
									{(headers || []).map((column) => (
										<th key={column.dataField}>
											<div
												onClick={() => {
													if (sortable) {
														requestSort(
															column.dataField
														);
													}
												}}
												className={genPackageClassName({
													theme,
													base: "table-header-item text-body",
													additional:
														getClassNamesFor(
															column.dataField
														),
												})}
											>
												{column.icon}
												<Text
													thick
													size={FONT_SIZE.LG_BODY}
												>
													{column.text}
												</Text>
											</div>
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{filteredData.map((row, index) =>
									renderRow ? (
										renderRow(row, index)
									) : (
										<tr key={index.toString()}>
											{(headers || []).map((column) => (
												<td
													key={column.dataField}
													className={genPackageClassName(
														{
															theme,
															base: "table-cell text-body",
														}
													)}
												>
													{row[column.dataField]}
												</td>
											))}
										</tr>
									)
								)}
							</tbody>
						</table>
					)}
				</PaddedContainer>
			);
		}
	)
);

Table.displayName = "Table";

Table.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	sortable: PropTypes.bool,
	loading: PropTypes.bool,
	headers: PropTypes.arrayOf(
		PropTypes.shape({
			dataField: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
			icon: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.node,
				PropTypes.element,
				PropTypes.number,
			]),
		})
	),
	data: PropTypes.array,
	searchable: PropTypes.bool,
	searchKeys: PropTypes.arrayOf(PropTypes.string),
	noDataMessage: PropTypes.string,
	renderRow: PropTypes.func,
};
