import React from "react";
import { Link } from "react-router-dom";
import { BadgeButton, Page } from "../../components/CoreUI";
import DISCO_LOGO_IMG from "../../img/disco-logo-color.svg";
import LEARN_GROW_SELL_IMG from "../../img/learn-grow-sell.svg";
import { GET } from "../../utils/GET";
import "./oauth-embed-page.css";

const OAuthEmbedPage = React.memo(() => {
	const hash = GET("store_hash");
	const accessToken = GET("access");
	const refreshToken = GET("refresh");
	const publisher = GET("publisher_id");
	const signup_email = GET("signup_email");

	// If there is no HASH, then the user has completed onboarding
	const status = hash !== null;

	return (
		<Page className="oauth-embed-page">
			<div className="oauth-embed-page-head">
				<img src={DISCO_LOGO_IMG} alt="Disco" />
				<h1>Win, Together.</h1>
				<h3>
					iOS 15 and rising acquisition costs have changed the game.
					It's time to <strong>#BrandTogether</strong>.
				</h3>
			</div>

			<div className="oauth-embed-page-body">
				<div className="redirect-btn">
					<Link
						to={
							accessToken && refreshToken && publisher
								? `/bigcommerceLogin?access=${accessToken}&refresh=${refreshToken}&publisher_id=${publisher}`
								: signup_email && status
								? `/register?store_hash=${hash}&signup_email=${signup_email}`
								: status
								? `/register?store_hash=${hash}`
								: "/login/"
						}
						target={"_blank"}
					>
						<BadgeButton className={"platform-redirect-btn"} light>
							{status
								? "Continue Onboarding"
								: "View Disco Settings"}
						</BadgeButton>
					</Link>
				</div>
			</div>

			<div className={"oauth-embed-page-footer"}>
				<h4>
					Learn, grow & sell together with{" "}
					<span id={"num-of-brands"}>600+ brands</span> like Rhone,
					Lovevery & Caraway.
				</h4>
			</div>

			<div className="oauth-embed-page-footer-img">
				<img src={LEARN_GROW_SELL_IMG} alt="Learn, Grow, Sell" />
			</div>
		</Page>
	);
});

export default OAuthEmbedPage;
