import {
	Button,
	Modal,
	PaddedContainer,
	SPACING,
	Text,
} from "@disco/disco_core";
import React from "react";

const PauseDiscoFeedConfirmationModal = ({ onConfirm, onClose, ...rest }) => {
	return (
		<Modal title="Need to Pause Disco?" onClose={onClose} {...rest}>
			<Text size={"subtitle"} color="mid" marginTop={SPACING.SMALL}>
				Disabling the DiscoFeed will pause ads on your page. To pause
				advertising only, please reach out to our support team for
				further assistance.
			</Text>
			<PaddedContainer flexContent marginTop={SPACING.MEDIUM}>
				<Button secondary onClick={onConfirm}>
					Disable DiscoFeed
				</Button>
				<Button
					marginLeft={"auto"}
					Element={"a"}
					onClick={onClose}
					href="mailto:support@disconetwork.com"
				>
					Pause Ads via Support
				</Button>
			</PaddedContainer>
		</Modal>
	);
};

export default PauseDiscoFeedConfirmationModal;
