import { useState, useEffect, useCallback, useRef } from "react";
import useAxios from "./useAxios";
import axios, { Method } from "axios";

type useResourceResult<T> = {
	loading: boolean;
	error: unknown;
	data?: T | null;
	headers?: HeadersInit | null;
};

const initialResource: useResourceResult<null> = {
	loading: false,
	error: false,
	data: null,
	headers: null,
};

// Using "interface" instead of "type" because RequestInit is an "interface"
export interface useResourceOptions extends RequestInit {
	url: RequestInfo | URL;
	data?: unknown;
	method?: Method;
}

// T represents structure of the response payload expected from the backend;
const useResource = <T,>(
	options: useResourceOptions,
	autoLoad = true,
	auth = true,
	debug = false
): [useResourceResult<T | null>, () => void, () => void] => {
	const { apiAxios } = useAxios();
	const [shouldLoad, setShouldLoad] = useState(autoLoad);
	const [resource, setResource] =
		useState<useResourceResult<T | null>>(initialResource);
	const reset = useCallback(() => {
		setResource(initialResource);
		setShouldLoad(false);
	}, []);

	const log = useCallback(
		(msg: string) => {
			if (debug) console.log(msg);
		},
		[debug]
	);

	const mounted = useRef<boolean>(true);

	useEffect(() => {
		mounted.current = true;
		return () => {
			mounted.current = false;
		};
	}, []);

	const startLoad = useCallback(() => setShouldLoad(true), []);

	const load = useCallback(async () => {
		log("[uR]: Load called ");
		if (
			resource.loading ||
			!shouldLoad ||
			(!Array.isArray(options) && !options?.url)
		) {
			log("[uR]: Load stopped ");
			return;
		}
		log("[uR]: Load started ");
		setShouldLoad(false);
		let resp: Promise<typeof axiosInstance> | typeof axiosInstance;
		setResource({ loading: true, error: false, data: null, headers: null });
		const axiosInstance = auth && apiAxios ? apiAxios : axios;
		const multiple = Array.isArray(options);
		try {
			if (multiple) {
				resp = await Promise.all(
					options.map((req) => axiosInstance(req))
				);
			} else {
				resp = await axiosInstance(options);
			}
		} catch (error: unknown) {
			log("[uR]: Load error! ");
			return setResource({
				loading: false,
				error: error,
				data: null,
				headers: null,
			});
		}

		if (mounted.current) {
			log("[uR]: Load complete:");
			setResource({
				loading: false,
				error: false,
				data: multiple
					? resp.map(
							(
								resp:
									| Promise<typeof axiosInstance>
									| typeof axiosInstance
							) => resp.data ?? true
					  )
					: resp.data || true,
				headers: resp?.headers || false,
			});
		} else {
			log("[uR]: Load complete but NOT MOUNTED ");
		}
	}, [options, resource.loading, shouldLoad, auth, log, apiAxios]);

	useEffect(() => {
		if (!resource.loading && shouldLoad) {
			load();
		}
	}, [resource.loading, shouldLoad, load]);

	return [resource, startLoad, reset];
};

export default useResource;
