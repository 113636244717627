import React, { memo, useState } from "react";
import { Collapsable } from "../CoreUI";
import EditView from "../EditView";
import "./toggle-group.css";

const ToggleGroup = memo(({ toggles, user, setUser, className = "" }) => {
	const [open, setOpen] = useState(false);

	return (
		<Collapsable
			open={open}
			setOpen={setOpen}
			className={`toggle-group ${className}`}
			heading={
				<main className="toggle-group-heading">
					Your email settings
				</main>
			}
		>
			{toggles.map(({ remoteKey, subToggles, ...rest }) => (
				<section
					className={
						user.publisher[remoteKey] && Array.isArray(subToggles)
							? "toggle-group-expanded"
							: ""
					}
				>
					<EditView
						{...rest}
						user={user}
						setUser={setUser}
						remoteKey={remoteKey}
						hideDefaultFeedbackText
						toggle
					></EditView>
					{user.publisher[remoteKey] && Array.isArray(subToggles) && (
						<>
							{subToggles.map((toggle) => (
								<section className="toggle-group-nested">
									<div className="toggle-group-nested-line"></div>
									<EditView
										{...toggle}
										user={user}
										setUser={setUser}
										hideDefaultFeedbackText
										className="nested-edit-view"
										toggle
									/>
								</section>
							))}
						</>
					)}
				</section>
			))}
		</Collapsable>
	);
});

export default ToggleGroup;
