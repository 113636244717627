import { useCallback, useState } from "react";
import { SAVE_PRODUCT_URL } from "../conf";
import useResource from "./useResource";

const useProductSave = () => {
	const [payload, setPayload] = useState({});
	const [resource, remoteSave, ...rest] = useResource(payload, false);

	const save = useCallback(
		(product, changes) => {
			setPayload({
				url: SAVE_PRODUCT_URL(product),
				method: "PUT",
				data: changes,
			});
			remoteSave();
		},
		[remoteSave]
	);

	return [resource, save, ...rest];
};

export default useProductSave;
