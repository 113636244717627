import { Input, Radio, parseError, useResource } from "@disco/disco_core";
import React, { memo, useCallback, useEffect, useState } from "react";
import OnboardingStep from "./OnboardingStep";
import "./platforms-step.css";

import { MdCheck } from "react-icons/md";
import {
	BRAND_INFO_URL,
	FETCH_BIGCOMMERCE_OAUTH_URL,
	FETCH_WOOCOMMERCE_OAUTH_URL,
	PLATFORM_IDS,
	SHOPIFY_CONNECT_URL,
} from "../../conf";
import {
	PLATFORM_ONBOARDING_CLICK_ANY_PLATFORM,
	PLATFORM_ONBOARDING_CLICK_PLATFORM_BACK,
	PLATFORM_ONBOARDING_CLICK_PLATFORM_CONTINUE,
	PLATFORM_ONBOARDING_CLICK_PLATFORM_SAVE_LOG_OUT,
	PLATFORM_ONBOARDING_PAGE_VIEW_PLATFORM,
} from "../../events";
import useIdentity from "../../hooks/useIdentity";
import useTrack from "../../hooks/useTrack";
import { GET } from "../../utils/GET";
import isBigCommerceShop from "../../utils/isBigCommerceShop";
import isShopifyShop from "../../utils/isShopifyShop";
import isWooCommerceShop from "../../utils/isWooCommerceShop";

import getPlatformList from "../../utils/getPlatformList";

const Platform = memo(
	({
		platform,
		onSelect,
		selected,
		shopify,
		woocommerce,
		bigcommerce,
		shop,
		handleShopChange,
		shopifyDone,
		woocommerceDone,
		bigcommerceDone,
	}) => {
		const { user } = useIdentity();

		return (
			<section
				className={`platforms-step-platform ${
					selected === platform.id
						? "platforms-step-platform-selected"
						: ""
				} ${shopify ? "platforms-step-platform-shopify" : ""}`}
				onClick={() =>
					!shopifyDone && !woocommerceDone && !bigcommerceDone
						? onSelect(platform.id)
						: null
				}
			>
				{shopifyDone && shopify ? (
					<span className="platforms-step-platform-done">
						<MdCheck />{" "}
					</span>
				) : woocommerceDone && woocommerce ? (
					<span className="platforms-step-platform-done">
						<MdCheck />{" "}
					</span>
				) : bigcommerceDone && bigcommerce ? (
					<span className="platforms-step-platform-done">
						<MdCheck />{" "}
					</span>
				) : (
					<Radio selected={selected === platform.id} />
				)}
				<img src={platform.img} alt="" />
				{shopify && (
					<Input
						placeholder="mysite.myshopify.com"
						value={shopifyDone ? user.shopify_shop : shop}
						readOnly={shopifyDone || bigcommerceDone}
						// onChange={handleShopChange}
					/>
				)}
			</section>
		);
	}
);

const PlatformsStep = memo(() => {
	const hash = GET("store_hash");
	const platformList = getPlatformList();

	const [opened, setOpened] = useState(false);
	const [error, setError] = useState(false);
	const [next, setNext] = useState(false);

	const [redirLoading, setRedirLoading] = useState(false);
	const track = useTrack();

	const [shop, setShop] = useState("");
	const { user, setUser } = useIdentity();
	const [platform, setPlatform] = useState(
		isShopifyShop(user)
			? PLATFORM_IDS.shopify
			: isWooCommerceShop(user)
			? PLATFORM_IDS.woocommerce
			: isBigCommerceShop(user)
			? PLATFORM_IDS.bigcommerce
			: user.publisher.platforms?.length
			? user.publisher.platforms[0].id
			: -1
	);

	const handleSelect = useCallback(
		(platform) => {
			setPlatform(platform);
			track(PLATFORM_ONBOARDING_CLICK_ANY_PLATFORM, { platform });
		},
		[track]
	);

	const [
		{ loading: shopLoading, error: shopError, data: shopData },
		connect,
		shopReset,
	] = useResource(
		{
			url: SHOPIFY_CONNECT_URL(shop),
			method: "GET",
		},
		false
	);

	// ********** START OF OAUTH **********

	// ********** WooCommerce OAuth **********

	const [
		{ loading: wooLoading, error: wooError, data: wooData },
		woocommerceConnect,
		woocommerceReset,
	] = useResource(
		{
			url: FETCH_WOOCOMMERCE_OAUTH_URL,
			method: "GET",
		},
		false
	);

	useEffect(() => {
		if (!wooData) {
			return;
		}

		setRedirLoading(true);
		window.location.href = wooData.redirect_url;
		woocommerceReset();
	}, [wooData, woocommerceReset]);

	// ********** BigCommerce OAuth **********

	const [
		{ loading: bigLoading, error: bigError, data: bigData },
		bigcommerceConnect,
		bigcommerceReset,
	] = useResource(
		{
			url: `${FETCH_BIGCOMMERCE_OAUTH_URL}?store_hash=${hash}`,
			method: "GET",
		},
		false
	);

	useEffect(() => {
		if (!bigData) {
			return;
		}
		if (bigData.redirect_url) {
			setRedirLoading(true);
			window.location.href = bigData.redirect_url;
			bigcommerceReset();
		}
		bigcommerceReset();
	}, [bigData, bigcommerceReset]);

	useEffect(() => {
		if (hash && !isBigCommerceShop(user)) {
			bigcommerceConnect();
			window.location.reload();
		}
	}, [hash, setUser, bigcommerceConnect, user]);

	// ********** END OF OAUTH **********

	const [
		{ loading: saveLoading, error: saveError, data: saveData },
		save,
		resetSave,
	] = useResource(
		{
			url: BRAND_INFO_URL,
			method: "PUT",
			data: { platforms: [platform] },
		},
		false
	);

	useEffect(() => {
		if (!saveData) return;
		resetSave();
		setUser((user) => ({
			...user,
			publisher: { ...user.publisher, ...saveData.brand },
		}));
		setNext(true);
	}, [saveData, resetSave, setUser]);

	useEffect(() => {
		if (!shopData) {
			return;
		}
		setRedirLoading(true);
		window.location.href = shopData.redirect_url;
		shopReset();
	}, [shopData, shopReset]);

	const handleShopChange = useCallback(({ target: { value } }) => {
		setShop(value);
	}, []);

	const handleNext = useCallback(
		(e) => {
			if (platform === -1) return setError("Please select a platform");

			if (
				!shop &&
				platform === PLATFORM_IDS.shopify &&
				!isShopifyShop(user)
			)
				return setError("Please enter your shopify shop URL");

			// Appropriately handle OAuth / Credential cases based on platform
			if (platform === PLATFORM_IDS.shopify && !isShopifyShop(user)) {
				connect();
			} else if (
				platform === PLATFORM_IDS.woocommerce &&
				!isWooCommerceShop(user)
			) {
				woocommerceConnect();
			} else if (
				platform === PLATFORM_IDS.bigcommerce &&
				!isBigCommerceShop(user)
			) {
				bigcommerceConnect();
			} else {
				save();
			}
		},
		[
			platform,
			shop,
			user,
			connect,
			save,
			bigcommerceConnect,
			woocommerceConnect,
		]
	);

	const shopifyDone = isShopifyShop(user);
	const woocommerceDone = isWooCommerceShop(user);
	const bigcommerceDone = isBigCommerceShop(user);

	// Define the helper functions to check platform types
	const isShopify = (platformData) =>
		platformData.id === PLATFORM_IDS.shopify;
	const isWooCommerce = (platformData) =>
		platformData.id === PLATFORM_IDS.woocommerce;
	const isBigCommerce = (platformData) =>
		platformData.id === PLATFORM_IDS.bigcommerce;

	// Filter the platforms based on the given conditions
	const filteredPlatformList = platformList.filter((platformData) => {
		return (
			!!shopifyDone ||
			!!bigcommerceDone ||
			(!isShopify(platformData) && !isBigCommerce(platformData))
		);
	});

	return (
		<>
			<OnboardingStep
				nextLoading={
					redirLoading ||
					shopLoading ||
					saveLoading ||
					wooLoading ||
					bigLoading
				}
				heading="Select your platform"
				subText="What does your shop run on?"
				nextText="Continue"
				onNext={handleNext}
				next={next}
				setNext={setNext}
				activeStep={0}
				aside={{
					heading: "Platform",
					imageUp: true,
					logo: true,
				}}
				error={
					error
						? error
						: shopError
						? parseError(shopError)
						: bigError
						? parseError(bigError)
						: saveError
						? parseError(saveError)
						: null
				}
				nextEvent={PLATFORM_ONBOARDING_CLICK_PLATFORM_CONTINUE}
				logoutEvent={PLATFORM_ONBOARDING_CLICK_PLATFORM_SAVE_LOG_OUT}
				backEvent={PLATFORM_ONBOARDING_CLICK_PLATFORM_BACK}
				viewEvent={PLATFORM_ONBOARDING_PAGE_VIEW_PLATFORM}
				data-testid="onboarding-platforms"
			>
				<main className="platforms-step-platforms">
					{filteredPlatformList.map((platformData) => (
						<Platform
							platform={platformData}
							selected={platform}
							onSelect={handleSelect}
							handleShopChange={handleShopChange}
							shop={shop}
							shopify={isShopify(platformData)}
							woocommerce={isWooCommerce(platformData)}
							bigcommerce={isBigCommerce(platformData)}
							shopifyDone={shopifyDone}
							woocommerceDone={woocommerceDone}
							bigcommerceDone={bigcommerceDone}
						/>
					))}{" "}
				</main>
			</OnboardingStep>
		</>
	);
});

export default PlatformsStep;
