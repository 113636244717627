import React, { memo, useState, useRef } from "react";
import { CacheContext } from ".";
import { childrenPropTypes } from "../../utils";

import PropTypes from "prop-types";

const CacheManager = memo(({ children, defaultCache }) => {
	const [cache, setCache] = useState(defaultCache || {});

	// For strictly sync communication
	const refStore = useRef({});

	return (
		<CacheContext.Provider value={{ cache, setCache, refStore }}>
			{children}
		</CacheContext.Provider>
	);
});

CacheManager.displayName = "CacheManager";
CacheManager.propTypes = {
	children: childrenPropTypes,
	defaultCache: PropTypes.object,
};

export default CacheManager;
