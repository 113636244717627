const extractFilenameFromReqHeaders = (headers, matcher = "attachment") => {
	if (!headers || !headers["content-disposition"]) {
		return false;
	}

	const disposition = headers["content-disposition"];
	let filename = "";
	if (disposition && disposition.indexOf(matcher) !== -1) {
		let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
		let matches = filenameRegex.exec(disposition);
		if (matches != null && matches[1]) {
			filename = matches[1].replace(/['"]/g, "");
		}
	}

	return filename;
};

export default extractFilenameFromReqHeaders;
