import React, { memo, useCallback } from "react";
import { PRO_PLAN_FEATURES } from "../../conf";
// import isShopifyShop from "../../utils/isShopifyShop";
// import { FilledButton } from "../CoreUI";
import PaymentPlanCard, {
	PaymentPlanPrice,
	PaymentPlanPriceSubtext,
	// PaymentPlanCardFeature,
} from "./PaymentPlanCard";

const ProPaymentPlanCard = memo(({ user }) => {
	const paymentPlanPrice = useCallback(
		() => (
			<>
				<PaymentPlanPrice subscript="/mo">$50</PaymentPlanPrice>
				<PaymentPlanPriceSubtext>+</PaymentPlanPriceSubtext>
				<PaymentPlanPrice subscript="/order">5¢</PaymentPlanPrice>
			</>
		),
		[]
	);
	return (
		<PaymentPlanCard
			heading="Pro"
			user={user}
			features={PRO_PLAN_FEATURES}
			priceDetails={paymentPlanPrice}
			className="pro-payment-plan-card"
		>
			{/* {isShopifyShop(user) && (
				<section className="pro-payment-plan-card-callout">
					We provide all our tools and features free as long as your
					monthly order volume is less than a 100.
				</section>
			)} */}
			{/* <FilledButton forward>Upgrade Now</FilledButton> */}
		</PaymentPlanCard>
	);
});

export default ProPaymentPlanCard;
