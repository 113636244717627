import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { Page } from "../../components/CoreUI";
import RegisterTeamStep from "../../components/onboardingSteps/RegisterTeamStep";

import "./register-team-page.css";

const RegisterTeamPage = React.memo(function () {
	const [auth, setAuth] = useState(false);
	if (auth) {
		return <Navigate to={"/"} />;
	}
	return (
		<Page className="register-team-page">
			<RegisterTeamStep setAuth={setAuth} />
		</Page>
	);
});

export default RegisterTeamPage;
