const validateArguments = ({ data, key }) => {
	if (typeof data !== "object" || !key) return false;
	return true;
};

export const getRootOrBrandOrUserValue = ({ data, key }) => {
	if (!validateArguments({ data, key })) {
		return undefined;
	}
	if (key in data) return data?.[key];
	if (key in (data?.brand || {})) return data?.brand?.[key];
	if (key in (data?.user || {})) return data?.user?.[key];
	return undefined;
};

export const getRootOrBrandValue = ({ data, key } = {}) => {
	if (!validateArguments({ data, key })) {
		return undefined;
	}
	return typeof data[key] === "undefined" ? data.brand[key] : data[key];
};

const getUpdatedUser = ({
	user,
	data,
	dataMapper = getRootOrBrandOrUserValue,
	key,
	draftable = false,
	rootLevel = false,
	updateReviewFlag = false,
} = {}) => {
	let newUser = { ...user };
	const value = dataMapper({ data, key });

	if (rootLevel) {
		return { ...newUser, [key]: value };
	}

	const targetKey = draftable ? "draft" : "publisher";

	newUser = {
		...newUser,
		[targetKey]: {
			...newUser[targetKey],
			[key]: value,
		},
	};

	if (updateReviewFlag && data?.under_review) {
		newUser = {
			...newUser,
			publisher: {
				...newUser.publisher,
				under_review: true,
			},
		};
	}

	return newUser;
};
export const updateUser = ({
	setUser,
	key,
	data,
	dataMapper = getRootOrBrandOrUserValue,
	rootLevel = false,
	draftable = false,
	updateReviewFlag = false,
	dependentKeys = {},
} = {}) => {
	let finalUser = null;

	setUser((user) => {
		let newUser = { ...user };

		newUser = getUpdatedUser({
			user: newUser,
			draftable,
			rootLevel,
			dataMapper,
			data,
			key,
			updateReviewFlag,
		});

		Object.entries(dependentKeys).forEach(
			([dependentKey, { draftable, rootLevel, dataMapper }]) => {
				newUser = getUpdatedUser({
					user: newUser,
					draftable,
					rootLevel,
					dataMapper,
					key: dependentKey,
					data,
				});
			}
		);

		finalUser = newUser;
		return newUser;
	});

	return finalUser;
};
