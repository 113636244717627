import React, {
	forwardRef,
	memo,
	useState,
	useMemo,
	useCallback,
	useEffect,
	Dispatch,
	SetStateAction,
	MouseEvent,
	ForwardedRef,
} from "react";

import { motion } from "framer-motion";

import useTheme from "../../hooks/useTheme";
import genPackageClassName from "../../utils/genPackageClassName";

import "./toggle.css";
import { Loader } from "../Loader";
import { CheckIcon, CrossIcon } from "../../Icons";

export type beforeToggleInitType = () => boolean;

export type NativeToggleProps = {
	className?: string;
	toggle?: boolean;
	setToggle?: Dispatch<SetStateAction<boolean>>;
	beforeToggleInit?: beforeToggleInitType;
	disabled?: boolean;
	stopPropagation?: boolean;
	dummy?: boolean;
	inverse?: boolean;
	loading?: boolean;
	marked?: boolean;
	small?: boolean;
};

type ToggleComponent = (props: NativeToggleProps) => React.ReactNode | null;

export const Toggle: ToggleComponent = memo(
	forwardRef(
		(
			{
				toggle,
				setToggle,
				className = "",
				beforeToggleInit,
				disabled = false,
				stopPropagation = false,
				dummy = false,
				inverse = false,
				loading = false,
				marked = false,
				small = false,
			}: NativeToggleProps,
			ref?: ForwardedRef<HTMLDivElement>
		) => {
			const { theme } = useTheme();

			const [mounted, setMounted] = useState(false);

			const parsedToggleValue = useMemo(
				() => (inverse ? !toggle : toggle),
				[inverse, toggle]
			);

			const toggleSwitch = useCallback(
				(e: MouseEvent<HTMLDivElement>) => {
					if (dummy || loading) {
						return;
					}
					if (typeof beforeToggleInit === "function") {
						const canToggle =
							beforeToggleInit && beforeToggleInit();
						if (!canToggle) {
							return;
						}
					}
					if (stopPropagation) {
						e.stopPropagation();
					}
					if (disabled) return;
					if (typeof setToggle === "function") {
						setToggle && setToggle(!toggle);
					}
				},
				[
					dummy,
					beforeToggleInit,
					stopPropagation,
					disabled,
					setToggle,
					toggle,
					loading,
				]
			);

			const translateX = useMemo(
				() =>
					parsedToggleValue
						? "var(--toggle-handle-pos-on)"
						: "var(--toggle-handle-pos-off)",
				[parsedToggleValue]
			);

			useEffect(() => {
				setTimeout(() => setMounted(true), 200);
				return () => {
					setMounted(false);
				};
			}, []);

			return (
				<div
					ref={ref}
					className={genPackageClassName({
						theme,
						base: "toggle",
						conditionals: {
							"toggle-dummy": dummy,
							"toggle-loading": loading,
							"toggle-mounted": mounted,
							"toggle-small": small,
							"toggle-marked": marked,
							"toggle-disabled": disabled,
						},
						additional: className,
					})}
					data-toggle={parsedToggleValue}
					onClick={toggleSwitch}
				>
					<span>
						{dummy ? (parsedToggleValue ? "on" : "off") : null}
					</span>
					<motion.div
						className={genPackageClassName({
							theme,
							base: "toggle-handle",
						})}
						animate={{
							transform: `translateX(${translateX})`,
						}}
						transition={{
							type: "spring",
							stiffness: 700,
							damping: 30,
						}}
					>
						{loading ? (
							<Loader small light />
						) : (
							<>
								{dummy ? (
									"?"
								) : marked ? (
									parsedToggleValue ? (
										<CheckIcon />
									) : (
										<CrossIcon />
									)
								) : null}
							</>
						)}
					</motion.div>
				</div>
			);
		}
	)
);
