import React, { useEffect, useMemo, useState } from "react";
import {
	PaddedContainer,
	SPACING,
	Label,
	StatusLabel,
	Loader,
	parseError,
	anim,
} from "@disco/disco_core";

import { FETCH_LEAD_OFFERS_URL } from "../../conf";

import useResource from "../../hooks/useResource";
import LeadOffersInlineView from "./LeadOffersInlineView";

import "./lead-offers-configurator.css";
import useIdentity from "../../hooks/useIdentity";

const LeadOffersConfiguratorWarning = ({ areOffersEmpty = false, ...rest }) => {
	const { user } = useIdentity();
	const isAdvertisingEnabled = !!user?.publisher?.is_active_lead_gen;
	const shouldShowWarning = isAdvertisingEnabled && areOffersEmpty;

	if (!shouldShowWarning) return null;

	return (
		<StatusLabel
			type="warning"
			noBorder
			noShadow
			motionElement
			variants={anim.variantFactory({ y: -12 })}
			{...rest}
		>
			Create an offer to enable this feature
		</StatusLabel>
	);
};

const LeadOffersConfigurator = () => {
	const [offers, setOffers] = useState([]);

	const [{ loading, data, error }] = useResource({
		url: FETCH_LEAD_OFFERS_URL,
		method: "GET",
	});

	const areOffersEmpty = useMemo(() => {
		if (!data) return false;
		if (loading) return false;
		if (error) return false;
		if (!Array.isArray(offers)) return false;
		return offers.length === 0;
	}, [loading, error, offers, data]);

	useEffect(() => {
		if (!data) return;
		setOffers(data.offers);
	}, [data]);

	return (
		<>
			<LeadOffersConfiguratorWarning
				areOffersEmpty={areOffersEmpty}
				marginTop={SPACING.REGULAR}
			/>
			<PaddedContainer marginTop={SPACING.MEDIUM}>
				<Label>Configure offer</Label>
				{loading && (
					<PaddedContainer vPadding={SPACING.MEDIUM}>
						<Loader middle />
					</PaddedContainer>
				)}
				<PaddedContainer marginTop={SPACING.SMALL}>
					{error && (
						<StatusLabel type="error">
							{parseError(error)}
						</StatusLabel>
					)}
					{data && (
						<LeadOffersInlineView
							offers={offers}
							setOffers={setOffers}
						/>
					)}
				</PaddedContainer>
			</PaddedContainer>
		</>
	);
};
export default LeadOffersConfigurator;
