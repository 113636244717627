import React, { useState, useEffect } from "react";
import { Button, BaseForm, FormStatus, ImageInput, Loader } from "../CoreUI";
import parseError from "../../utils/parseError";
import Modal from "./Modal";

import "./base-image-modal.css";
import { useCallback } from "react";
import useImageResource from "../../hooks/useImageResource";
import useResource from "../../hooks/useResource";
import useDimensions from "../../hooks/useDimensions";
import ModalSheetHeader from "./ModalSheetHeader";

const BaseImageModal = ({
	open,
	onClose,
	onDone,
	defaultValue,
	heading,
	remoteKey,
	imageNameSelector,
	value,
	url,
	fileName,
	user,
	updateMethod = "POST",
	aspect = 1,
	...props
}) => {
	const { isMobile } = useDimensions();
	const [imageFormData, setImageFormData] = useState(value || defaultValue);
	const [imageName, setImageName] = useState(false);

	// useEffect(() => {
	// 	if(typ)
	// 	const name = imageNameSelector(user);
	// }, [user, imageNameSelector]);

	const [payload, setPayload] = useState({
		url,
		method: updateMethod,
		data: {},
	});

	const [
		{ loading: imageLoading, error: imageError, data: imageData },
		upload,
		reset,
	] = useImageResource(user, imageFormData);

	const [{ loading, data, error }, setImage, resetImage] = useResource(
		payload,
		false
	);

	const handleChange = useCallback((image, name) => {
		setImageFormData(image);
		setImageName(name);
	}, []);

	useEffect(() => {
		if (!data) {
			return;
		}
		onClose();
		let newValue = data[remoteKey];
		if (typeof newValue === "undefined") {
			if (data.brand) {
				newValue = data.brand[remoteKey];
			} else if (data.user) {
				newValue = data.user[remoteKey];
			}
		}
		onDone(newValue, imageName);
		resetImage();
	}, [data, onClose, onDone, resetImage, remoteKey, imageName]);

	useEffect(() => {}, [imageError, imageData]);

	useEffect(() => {
		if (!imageData.url) {
			return;
		}

		if (url) {
			setPayload((payload) => ({
				...payload,
				data: { [remoteKey]: imageData.url },
			}));
			setImage();
		} else {
			onClose();
			onDone(imageData.url, imageName);
		}

		reset();
	}, [
		imageData,
		imageName,
		remoteKey,
		reset,
		setImage,
		onClose,
		onDone,
		url,
	]);

	const add = useCallback(
		(e) => {
			e.preventDefault();
			if (typeof imageFormData === "string") {
				onClose();
				onDone(imageFormData, imageName);
			} else {
				upload(fileName);
			}
		},
		[imageFormData, fileName, onClose, onDone, upload, imageName]
	);

	return (
		<Modal
			className="base-image-modal"
			open={open}
			onClose={onClose}
			heading={heading}
			{...props}
		>
			{isMobile && (
				<ModalSheetHeader
					cancelLabel={"Cancel"}
					handleCancel={onClose}
				></ModalSheetHeader>
			)}
			<BaseForm>
				<ImageInput
					onChange={handleChange}
					aspect={aspect}
					value={value || defaultValue}
				/>
				{!(loading || imageLoading) && (
					<Button onClick={add}>Save</Button>
				)}
				{(loading || imageLoading) && <Loader />}
				{!(loading || imageLoading) && (imageError || error) && (
					<FormStatus>{parseError(imageError || error)}</FormStatus>
				)}
			</BaseForm>
		</Modal>
	);
};

export default React.memo(BaseImageModal);
