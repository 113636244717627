import React, { memo } from "react";
import isShopifyShop from "../../utils/isShopifyShop";
import { ModalSelectHLine } from "../CoreUI";
import "./payment-plan-card.css";

export const PaymentPlanAddonCard = memo(
	({ title = "", priceDetails, children, className = "" }) => (
		<section
			className={`payment-plan-card payment-plan-card-addon ${className}`}
		>
			<div className="payment-plan-card-addon-row">
				<h2>{title}</h2>
				{priceDetails && (
					<PaymentPlanPrice {...priceDetails}>
						{priceDetails.children}
					</PaymentPlanPrice>
				)}
			</div>
			{children}
		</section>
	)
);
export const PaymentPlanCardFeature = memo(({ children, striked = false }) => (
	<div
		className={`payment-plan-card-feature ${
			striked ? "payment-plan-card-feature-striked" : ""
		}`}
	>
		{children}
	</div>
));

export const PaymentPlanPrice = memo(
	({ light = false, subscript = "", small = false, children }) => (
		<div
			className={`payment-plan-price ${
				light ? "payment-plan-price-light" : ""
			} ${small ? "payment-plan-price-small" : ""}`}
		>
			{children} {subscript && <sub>{subscript}</sub>}
		</div>
	)
);

export const PaymentPlanPriceSubtext = memo(
	({ children, theme = false, className = "" }) => (
		<span
			className={`payment-plan-card-price-subtext ${
				theme ? "payment-plan-card-price-subtext-theme" : ""
			} ${className}`}
		>
			{children}
		</span>
	)
);
const PaymentPlanCard = memo(
	({
		user,
		heading = "",
		className = "",
		features,
		priceDetails,
		children,
	}) => {
		return (
			<section
				className={`payment-plan-card ${
					!isShopifyShop(user) ? "payment-plan-card-no-shopify" : ""
				} ${className}`}
			>
				{typeof priceDetails === "function" && (
					<section className="payment-plan-card-price">
						{priceDetails()}
					</section>
				)}
				{/* <h2>{heading}</h2> */}
				{/* <div className={`payment-plan-card-subheading`}>
				<span>You current plan</span>
			</div> */}
				<section className="payment-plan-card-features">
					{features.map(
						(feature, idx) =>
							(!feature.shopifyOnly || isShopifyShop(user)) && (
								<PaymentPlanCardFeature
									{...feature}
									key={feature?.text || idx}
								>
									{feature.text}
								</PaymentPlanCardFeature>
							)
					)}
				</section>
				{/* <ModalSelectHLine /> */}
				<section className="payment-plan-card-children">
					{children}
				</section>
			</section>
		);
	}
);

export default PaymentPlanCard;
