import { PUBLISHER_TRAIT, TRAIT_STATUS } from "../hooks/useTrait";

const hasMigratedToExtensions = ({ user, considerOverride = false } = {}) => {
	let hasOverride = false;
	if (considerOverride) {
		hasOverride = user?.publisher?.is_eligible_osp_checkout;
	}

	const userTrait = user?.publisher?.[PUBLISHER_TRAIT.IS_OSP_CHECKOUT_ACTIVE];
	if (userTrait?.status !== TRAIT_STATUS.SUCCESS) return false || hasOverride;
	return userTrait.value || hasOverride;
};

export default hasMigratedToExtensions;
