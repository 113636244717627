import { AnimatePresence, motion } from "framer-motion";
import React, { memo, useState } from "react";
import { useCallback } from "react";
import anim from "../../utils/anim";
import { LargeList, TabSwitcher } from "../CoreUI";
import "./emails-preview.css";

import orderConfirmation from "../../img/emails/shopify-confirmation.png";
import shipConfirmation from "../../img/emails/shopify-ship-confirmation.png";
import delivery from "../../img/emails/shopify-delivery.png";
import update from "../../img/emails/shopify-update.png";
import klaviyoPreview from "../../img/emails/klaviyo-preview.jpg";

import {
	EXTERNAL_KLAVIYO_KEYS_URL,
	EXTERNAL_KLAVIYO_TEMPLATES_URL,
	INSTALL_VIDEO_URL,
	KLAVIYO_VIDEO_URL,
	SHOPIFY_VIDEO_URL,
} from "../../conf";

const tabOptions = [{ name: "Instructions" }, { name: "Preview" }];

export const PREVIEW_TYPES = {
	CLOSED: "CLOSED",
	SHOPIFY: "SHOPIFY",
	KLAVIYO: "KLAVIYO",
};

const previewData = {
	[PREVIEW_TYPES.SHOPIFY]: {
		steps: [
			{ text: "Select each email you’d like to earn Disco credits from" },
			{ text: "Copy & paste the code provided into each template" },
			{ text: "Save & that’s it! You’re all set." },
		],
		previewImage: [orderConfirmation, shipConfirmation, update, delivery],
		videoUrl: SHOPIFY_VIDEO_URL,
	},
	[PREVIEW_TYPES.KLAVIYO]: {
		steps: [
			{
				text: (
					<>
						Copy and paste your private and public API keys found{" "}
						<a
							href={EXTERNAL_KLAVIYO_KEYS_URL}
							target="_blank"
							rel="noopener noreferrer"
						>
							here
						</a>
						.
					</>
				),
			},
			{
				text: (
					<>
						Go to your Klaviyo templates found{" "}
						<a
							href={EXTERNAL_KLAVIYO_TEMPLATES_URL}
							target="_blank"
							rel="noopener noreferrer"
						>
							here
						</a>
						.
					</>
				),
			},
			{ text: "Drag a text block onto your template." },
			{ text: <>Select source & paste your code</> },
			{
				text: (
					<>
						Click ★ to save and reuse this block in any of your
						templates. Name the block Disco.
					</>
				),
			},
			{
				text: (
					<>
						Drag and drop the Disco block onto each template.
						Remember, each email open earns credits.
					</>
				),
			},
		],
		previewImage: klaviyoPreview,
		videoUrl: KLAVIYO_VIDEO_URL,
	},
};

const Preview = memo(({ type, shopifyType = 0 }) => {
	const [tab, setTab] = useState(0);

	const handleTabChange = useCallback((index) => {
		setTab(index);
	}, []);

	return (
		<motion.main
			className="emails-preview-main"
			alt=""
			variants={anim.staggeredFade}
			initial="initial"
			animate="animate"
			exit="exit"
		>
			<TabSwitcher
				data={tabOptions}
				tabActive={tab}
				onChange={handleTabChange}
				curved
				dynamicWidth
			/>
			{tab === 0 ? (
				<>
					<video
						src={previewData[type].videoUrl}
						className="emails-preview-video"
						controls
					/>
					<LargeList list={previewData[type].steps} />
				</>
			) : (
				<motion.img
					className="email-preview-image"
					src={
						type !== PREVIEW_TYPES.SHOPIFY
							? previewData[type].previewImage
							: previewData[type].previewImage[shopifyType]
					}
					alt=""
					variants={anim.staggeredFade}
				/>
			)}
		</motion.main>
	);
});

const EmailsPreview = memo(({ background, type = -1, ...rest }) => {
	return (
		<section className="emails-preview">
			<AnimatePresence>
				{type === PREVIEW_TYPES.CLOSED ? (
					<motion.img
						className="emails-preview-background"
						src={background}
						alt=""
						variants={anim.staggeredFade}
						initial="initial"
						animate="animate"
						exit="exit"
					/>
				) : (
					<Preview type={type} {...rest} />
				)}
			</AnimatePresence>
		</section>
	);
});

export default EmailsPreview;
