import { PREVIEW_TYPES_IDX } from "../conf";

const getPreviewOptions = () => {
	let _previewData = [
		{
			title: "DiscoFeed",
			type: PREVIEW_TYPES_IDX.LEAD_GEN,
		},
		{
			title: "Legacy DiscoFeed",
			type: PREVIEW_TYPES_IDX.CROSS_SELL_PRODUCTS,
		},
		{
			title: "Upsells",
			type: PREVIEW_TYPES_IDX.UPSELL,
		},
		{ title: "Surveys", type: PREVIEW_TYPES_IDX.ALL_SURVEY },
	];

	return _previewData.filter((_previewDataObj) => !_previewDataObj.hidden);
};

export default getPreviewOptions;
