import { Asset, SPACING, TargetIcon } from "@disco/disco_core";
import React, { createRef, memo, useMemo, useState } from "react";
import {
	FETCH_CATEGORIES_URL,
	FETCH_SPECIFIC_SUB_CATEGORIES_URL,
	SAVE_SHOP_CATEGORIES_URL,
} from "../../conf";
import { MiniBadge, ModalSelectListInput } from "../CoreUI";
import {
	BaseFinalizeModalSelect,
	BaseFinalizeModalSelectRow,
	FinalizeModalSelect,
} from "../modals/ModalSelect";

import "./brand-setup-modal-selector.css";

const BrandSetupCategoryFinalizeModalSelect = memo(({ value }) => {
	const rows = useMemo(
		() =>
			value
				.sort((a) => (a.primary ? -1 : 0))
				.map(({ name, primary }) => (
					<BaseFinalizeModalSelectRow>
						<MiniBadge>{name}</MiniBadge>{" "}
						{primary ? (
							<>
								is your brand's
								<span>primary category</span>
							</>
						) : (
							"is a category that describes your brand"
						)}
					</BaseFinalizeModalSelectRow>
				)),
		[value]
	);
	return (
		<BaseFinalizeModalSelect
			text={"It's time to finalize your shop's category selections."}
			rows={rows}
			className="brand-setup-category-finalize-modal-select"
		/>
	);
});

export const BRAND_SETUP_MODAL_SELECTOR_DEPENDANT_STATES = {
	subcategory_blacklist: "",
	blacklist: "",
	email_subcategory_blacklist: "",
	email_blacklist: "",
	offer_subcategory_blacklist: "",
	offer_blacklist: "",
};

const BrandSetupModalSelector = memo(
	({
		user,
		setUser,
		focusFunction,
		parentRef,
		onboarding = false,
		onlyCategories = false,
		onlySubcategories = false,
		onSave,
		customStepProps,
		designSystem = false,
		...props
	}) => {
		const [stepProps, setStepProps] = useState([
			{
				label: "Our shop is in these categories",
				inlineName: "categories",
				inputProps: {
					ref: (parentRef && parentRef[0]) || createRef(),
					onMouseEnter: focusFunction,
					"data-name": "shopCategories",
					"data-customid": 0,
				},
				remoteKey: "categories",
				title: "Category Selection",
				asideTitle: ({ currSubStep }) =>
					currSubStep === 0 ? (
						<>
							Select <u>one</u> category
						</>
					) : (
						"Select categories"
					),

				mainTitle: ({ currSubStepData: { remote_id: step } }) =>
					step === 0 ? (
						<>
							Which category best describes your brand?{" "}
							<span
								style={{
									color: "var(--text-light-3)",
									fontWeight: 400,
								}}
							>
								(select one)
							</span>
						</>
					) : (
						"Select any other categories that describe your brand"
					),
				buttonText: "Next, select subcategories",
				split: true,
				stepped: true,
				mainFooter: ({ currSubStepData: { remote_id: step } }) => {
					if (step === 0) {
						return "Choose the category that best fits your brand. In a moment, you'll be able to select more categories.";
					}
					return null;
				},
				renderMainContentEmpty: ({ handleNext, currSubStep }) => {
					if (currSubStep === 0) {
						return null;
					}
					return (
						<section className="brand-setup-modal-main-empty">
							Fit into only one category? Feel free to{" "}
							<u onClick={handleNext}>skip</u> this step.
							Otherwise, add all other categories that apply to
							your brand and the products you sell.
						</section>
					);
				},
				onDone: () => {},
				fetchUrl: FETCH_CATEGORIES_URL,

				draftable: !onboarding,
				hideInput: onlySubcategories,
				labelRequired: true,

				validator: ({ value, currSubStep }) => {
					if (currSubStep === 0 && value.length !== 1) {
						return {
							isValid: false,
							errMessage: "Please select a primary category",
						};
					}
					return { isValid: true };
				},
				requestBodyModifier: ({ value }) => ({
					primary_category: value.find(
						(category) => category?.primary
					)?.remote_id,
				}),
				requestValueMapper: ({ value }) =>
					value.map((category) => category.remote_id),
				addValueMapper: ({ addValue, currValue, currSubStep }) => {
					if (currSubStep === 0) {
						const hasPrimaryCategory = currValue.find(
							(currOption) => currOption?.primary
						);
						if (hasPrimaryCategory) {
							return currValue;
						}

						const search = currValue.find(
							(currOption) =>
								currOption?.remote_id === addValue?.remote_id
						);
						if (!search) {
							return [
								...currValue,
								{ ...addValue, primary: true },
							];
						}
						return [
							...currValue.filter(
								(currOption) =>
									currOption?.remote_id !==
									addValue?.remote_id
							),
							{ ...addValue, primary: true },
						];
					}
					if (currSubStep === 1) {
						return [...currValue, addValue];
					}
					return currValue;
				},
				stepDataMapper: () => [
					{ remote_id: 0, name: "Primary Category" },
					{ remote_id: 1, name: "Secondary Categories" },
				],
				stepDataContent: ({ data, value }) => {
					const primaryCategory = value.find(
						(category) => category?.primary
					);
					return [
						data,
						data.filter(
							(option) =>
								option.remote_id !== primaryCategory?.remote_id
						),
					];
				},
				stepValueMapper: ({ value, currSubStep }) => {
					if (currSubStep === 0) {
						return value.filter((option) => option.primary);
					}
					if (currSubStep === 1) {
						return value.filter((option) => !option.primary);
					}
					return value;
				},
				finalizeComponent: ({ value, data, subStepData }) => (
					<BrandSetupCategoryFinalizeModalSelect
						value={value}
						allSubCategories={data}
						categories={subStepData}
					/>
				),
				liveValueFilter: ({ value, currValue }) => {
					if (!Array.isArray(currValue)) {
						return true;
					}
					return (
						currValue.find(
							(curr) => curr.remote_id === value.remote_id
						)?.primary === value?.primary
					);
				},
				currValueFilterModifier: ({ value, liveValue }) => {
					if (!Array.isArray(liveValue)) {
						return false;
					}
					const liveListValue = liveValue.find(
						(live) => live?.remote_id === value?.remote_id
					);
					if (value?.primary && !liveListValue?.primary) {
						return true;
					}
					return false;
				},
				inlineBadgeModifier: ({ option, list, draft }) => {
					const { name, primary } = option;

					if (!primary) {
						return name;
					}
					const listOption = list.find(
						(listOption) =>
							listOption.remote_id === option.remote_id
					);
					if (!listOption?.primary) {
						return name;
					}
					if (designSystem) {
						return (
							<>
								<Asset
									flexCenter
									size={13}
									marginRight={SPACING.MICRO}
								>
									<TargetIcon />
								</Asset>
								{name}
							</>
						);
					}
					return (
						<>
							{name}
							<span
								className={`brand-setup-modal-selector-inline-primary-badge ${
									draft
										? "brand-setup-modal-selector-inline-primary-badge-draft"
										: ""
								}`}
							>
								Primary
							</span>
						</>
					);
				},
				...(customStepProps?.[0] || {}),
			},
			{
				label: "Specifically in these subcategories",
				inlineName: "subcategories",
				inputProps: {
					ref: (parentRef && parentRef[1]) || createRef(),
					onMouseEnter: focusFunction,
					"data-name": "shopCategories",
					"data-customid": 1,
				},

				title: "Choose Your Subcategories",
				asideTitle: ({ currSubStepData: { name } }) => {
					return (
						<>
							Subcategories in <b>{name}</b>
						</>
					);
				},
				mainTitle: ({ currSubStepData: { name } }) => {
					return (
						<>
							Your subcategories in{" "}
							<span style={{ color: "var(--theme)" }}>
								{name}
							</span>
						</>
					);
				},
				buttonText: "Done! This all looks correct",
				split: true,
				stepped: true,
				stepDataMapper: ({ data }) =>
					data.map((dataItem) => ({
						remote_id: dataItem.remote_id,
						name: dataItem.name,
					})),
				stepDataContent: ({ data }) =>
					data.map((dataItem) => dataItem.subcategories),
				mainFooter: () => {
					return (
						<>
							Choose the subcategories that best describe your
							shop.
							<br />
							<br />
							You must select at least one subcategory per
							category.
						</>
					);
				},
				finalizeComponent: ({ value, data, subStepData }) => (
					<FinalizeModalSelect
						value={value}
						allSubCategories={data}
						categories={subStepData}
					/>
				),
				onDone: () => {},
				onSave: () => {
					if (onSave && typeof onSave === "function") {
						onSave(true);
					}
				},
				onNext: () => {},
				required: true,
				requestValueMapper: ({ value }) =>
					value.map((subCategory) => subCategory.remote_id),
				getSearchIndex: ({ data, searchValue }) => {
					let index = 0;
					data.forEach((dataItem, dataId) => {
						if (
							dataItem.subcategories
								.map((item) => item.remote_id)
								.indexOf(searchValue) !== -1
						) {
							index = dataId;
						}
					});
					return index;
				},
				navToStart: (value) => {
					return value.length === 0;
				},
				saveUrl: SAVE_SHOP_CATEGORIES_URL,
				remoteKey: "subcategories",
				fetchUrl: FETCH_SPECIFIC_SUB_CATEGORIES_URL,
				fetchRequestMethod: "POST",
				saveRequestMethod: "PUT",
				requestBody: {},
				dependantStates: BRAND_SETUP_MODAL_SELECTOR_DEPENDANT_STATES,

				exitWarning: true,

				draftable: !onboarding,
				hideInput: onlyCategories,

				inlineBadgeModifier: ({ option, list }) => {
					const { name, category_name } = option;
					if (!Array.isArray(list) || list.length === 0) {
						return name;
					}
					let count = 0;
					for (const { name: otherName } of list) {
						if (otherName.toLowerCase() === name.toLowerCase()) {
							count += 1;
						}
					}
					if (count > 1) {
						return (
							<>
								<b>{category_name}:</b> {name}
							</>
						);
					}
					return name;
				},
				...(customStepProps?.[1] || {}),
			},
		]);

		return (
			<>
				<ModalSelectListInput
					stepProps={stepProps}
					user={user}
					setUser={setUser}
					setStepProps={setStepProps}
					onboarding={onboarding}
					designSystem={designSystem}
					{...props}
				/>
			</>
		);
	}
);

export default BrandSetupModalSelector;
