import React, { memo, useEffect, useMemo, useState } from "react";
import { FiCheck } from "react-icons/fi";
import { BRAND_INFO_URL } from "../../conf";
import useResource from "../../hooks/useResource";
import parseError from "../../utils/parseError";
import { Button, FormStatus } from "../CoreUI";
import "./emails-transactional-step.css";
import EmailsFeatureStep from "./EmailsFeatureStep";
import finalBackground from "../../img/emails/final-background.jpg";
import { PREVIEW_TYPES } from "./EmailsPreview";

import transactionBackground from "../../img/emails/transaction-background.jpg";
import { motion } from "framer-motion";
import anim from "../../utils/anim";
import useContent from "../../hooks/useContent";

const EmailsTransactionalStep = memo(({ user, setUser, onNext }) => {
	const [active, setActive] = useState(false);

	const [
		{ loading: transLoading, error: transError, data: transData },
		save,
		resetTrans,
	] = useResource(
		{
			url: BRAND_INFO_URL,
			method: "PUT",
			data: {
				transactional_emails: active,
				emails_tutorial_complete: true,
			},
		},
		false
	);
	useEffect(() => {
		if (!transData) return;
		setUser((user) => {
			const newU = {
				...user,
				publisher: {
					...user.publisher,
					...transData.brand,
					emailsFinalStep: true,
					emails_tutorial_complete: true,
				},
			};

			return newU;
		});

		resetTrans();
	}, [transData, resetTrans, setUser, onNext]);

	const setupTransaction = (status) => {
		setActive(active);
		save();
	};

	const handleProceed = () => {
		setUser((user) => ({
			...user,
			publisher: { ...user.publisher, emailsFinalStep: false },
		}));
	};

	const content = useContent().platformEmailsPage;
	const features = useMemo(
		() => content.tutStep3List.map((text) => ({ text })),
		[content]
	);

	const inFinal =
		user.publisher.emails_tutorial_complete &&
		user.publisher.emailsFinalStep;

	return (
		<EmailsFeatureStep
			features={features}
			subHeading={content.tutStep3SubHeading}
			heading={content.tutStep3Heading}
			background={finalBackground}
			icon={<FiCheck />}
			className={inFinal ? "" : "emails-transactional-step"}
			finalStep={inFinal}
			extraActions={
				<Button
					className="emails-transactional-step-skip"
					light
					large
					onClick={() => setupTransaction(false)}
				>
					Skip
				</Button>
			}
			onNext={() => setupTransaction(false)}
			nextText={content.tutStep3Next}
			preview={{
				type: PREVIEW_TYPES.CLOSED,
				background: transactionBackground,
			}}
			loading={transLoading}
		>
			{!inFinal && transError && (
				<FormStatus>{parseError(transError)}</FormStatus>
			)}
			{inFinal && (
				<motion.section
					className="emails-step-final-content"
					variants={anim.rowItem}
					initial="initial"
					animate="animate"
					exit="exit"
				>
					<h1>Rock on, {user.first_name}!</h1>
					<h3>Your emails will start earning Disco credits.</h3>
					<section className="emails-step-final-content-action">
						<Button xLarge gradient onClick={handleProceed}>
							Take me to my dashboard
						</Button>
					</section>
				</motion.section>
			)}
		</EmailsFeatureStep>
	);
});

export default EmailsTransactionalStep;
