import { createContext } from "react";

export const ThemeContext = createContext();
export const PingContext = createContext();
export const CacheContext = createContext();
export const AxiosContext = createContext();
export const ModalContext = createContext();

export { default as AxiosManager } from "./AxiosManager";
export { default as CacheManager } from "./CacheManager";
export { default as ModalManager } from "./ModalManager";
export { default as PingManager } from "./PingManager";
export { default as ThemeManager } from "./ThemeManager";
