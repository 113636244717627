import React, {
	memo,
	useState,
	useCallback,
	forwardRef,
	useMemo,
	useEffect,
} from "react";
import SettingsModule, { SettingsModuleSection } from "./SettingsModule";
import NewEditView from "../NewEditView";
import NewEditImage from "../NewEditImage";
import {
	CHANGE_PASSWORD_URL,
	DEFAULT_USER_IMAGE,
	SAVE_USER_URL,
} from "../../conf";
import { PLATFORM_CHANGE_PASSWORD } from "../../events";
import "./account-module.css";
import validPhone from "../../utils/validPhone";
import {
	Button,
	LabeledInput,
	Loader,
	Modal,
	PaddedContainer,
	parseError,
	SPACING,
	StatusLabel,
	Text,
	useModal,
	useResource,
} from "@disco/disco_core";
import useTrack from "../../hooks/useTrack";

const validator = (num) => {
	const cleaned = validPhone(num);
	if (!cleaned) {
		return [false, "Please enter a valid 10 digit number"];
	}
	return [true, cleaned];
};

const ChangePasswordModal = memo(({ open, onClose }) => {
	const [fields, setFields] = useState({});

	const track = useTrack();

	const handleChange = useCallback(({ target: { value, name } }) => {
		setFields((fields) => ({ ...fields, [name]: value }));
	}, []);

	const [saveFormError, setSaveFormError] = useState("");

	const [saveOptions, setSaveOptions] = useState({
		url: CHANGE_PASSWORD_URL,
		method: "PUT",
		data: {},
	});

	const [
		{ loading: saveLoading, data: saved, error: saveError },
		save,
		reset,
	] = useResource(saveOptions, false);

	useEffect(() => {
		if (saved && open) {
			onClose();
			reset();
			track(PLATFORM_CHANGE_PASSWORD, {});
		}
	}, [saved, onClose, open, reset, track]);

	const handleSave = useCallback(
		(e) => {
			e.preventDefault();
			const { password, new_password } = fields;
			if (!password || !new_password) {
				return setSaveFormError("Please enter all the fields");
			}
			setSaveFormError("");
			const payload = {
				password,
				new_password,
			};
			setSaveOptions((saveOptions) => ({
				...saveOptions,
				data: payload,
			}));
			save();
		},
		[fields, save]
	);

	return (
		<Modal
			open={open}
			title="Change Password"
			onClose={onClose}
			className="change-password-modal"
		>
			<PaddedContainer
				className="change-password-modal-inputs"
				vPadding={SPACING.SMALL}
			>
				<LabeledInput
					placeholder="Existing Password"
					label="Existing Password"
					name="password"
					type="password"
					onChange={handleChange}
					value={fields?.password || ""}
				/>
				<LabeledInput
					placeholder="New Password"
					label="New Password"
					name="new_password"
					type="password"
					onChange={handleChange}
					value={fields?.new_password || ""}
				/>
			</PaddedContainer>
			{(saveFormError || saveError) && (
				<StatusLabel>
					{saveError ? parseError(saveError) : saveFormError}
				</StatusLabel>
			)}
			<PaddedContainer tightBottom vPadding={SPACING.REGULAR}>
				{saveLoading ? (
					<Loader middle />
				) : (
					<Button onClick={handleSave} middle>
						Save
					</Button>
				)}
			</PaddedContainer>
		</Modal>
	);
});

const AccountModule = memo(
	forwardRef(({ user, setUser }, ref) => {
		const {
			open: passwordEditOpen,
			handleOpen: openPasswordEdit,
			handleClose: closePasswordEdit,
		} = useModal(false);
		const imageSelector = useCallback((user) => {
			return user.user_image;
		}, []);
		const handleDone = useCallback(
			(img) => {
				setUser((user) => ({ ...user, user_image: img }));
			},
			[setUser]
		);
		const passwordLabelProps = useMemo(
			() => ({
				children: (
					<Text
						color={"var(--disco-theme-mid)"}
						onClick={openPasswordEdit}
						className="account-module-password-action"
					>
						Change Password
					</Text>
				),
			}),
			[openPasswordEdit]
		);

		return (
			<>
				<SettingsModule ref={ref}>
					<SettingsModuleSection title="Account Contact" tightTop>
						<PaddedContainer className="account-module-grid">
							<PaddedContainer className="account-module-grid-fields">
								<NewEditView
									user={user}
									setUser={setUser}
									remoteKey="email"
									name="Email"
									userLevel
									editable={false}
								/>
								<NewEditView
									user={user}
									setUser={setUser}
									name="First Name"
									remoteKey="first_name"
									placeholder="Your first name"
									userLevel
									url={SAVE_USER_URL}
									tooltipTitle="What should we call you?"
									tooltipText="What's your first name"
								/>
								<NewEditView
									user={user}
									setUser={setUser}
									name="Last Name"
									remoteKey="last_name"
									placeholder="Your first name"
									userLevel
									url={SAVE_USER_URL}
									tooltipTitle="What should we call you?"
									tooltipText="What's your last name"
								/>
								<NewEditView
									user={user}
									setUser={setUser}
									name="Phone Number"
									placeholder="Edit Phone number"
									url={SAVE_USER_URL}
									validator={validator}
									userLevel
									remoteKey="phone_number"
									tooltipTitle="Enter a phone number"
									tooltipText="In case we need to get in touch with you, please enter a phone number (with area code)."
								/>
								<LabeledInput
									readOnly
									value="********"
									labelProps={passwordLabelProps}
									label="Password"
								/>
							</PaddedContainer>
							<PaddedContainer>
								<NewEditImage
									url={SAVE_USER_URL}
									remoteKey="user_image"
									circular
									user={user}
									updateMethod="PUT"
									editText="Change User Image"
									defaultValue={DEFAULT_USER_IMAGE}
									selector={imageSelector}
									onDone={handleDone}
									heading="Change User Image"
								/>
							</PaddedContainer>
						</PaddedContainer>
					</SettingsModuleSection>
				</SettingsModule>
				<ChangePasswordModal
					open={passwordEditOpen}
					onClose={closePasswordEdit}
				/>
			</>
		);
	})
);

export default AccountModule;
