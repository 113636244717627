import {
	BaseForm,
	Button,
	ImageInput,
	Label,
	LabeledInput,
	Loader,
	Modal,
	PaddedContainer,
	SPACING,
	StatusLabel,
} from "@disco/disco_core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PRODUCTS_ADD_URL, SAVE_PRODUCT_URL } from "../../conf";
import {
	PLATFORM_ADD_PRODUCT,
	PLATFORM_ADD_PRODUCT_ERROR,
	PLATFORM_ADD_PRODUCT_IMAGE_ERROR,
	PLATFORM_EDIT_PRODUCT,
} from "../../events";
import useImageResource from "../../hooks/useImageResource";
import useResource from "../../hooks/useResource";
import useTrack from "../../hooks/useTrack";
import parseError from "../../utils/parseError";
import "./product-modal.css";

const ProductModal = ({
	user,
	open,
	onClose,
	onDone,
	disabled = false,
	product = null,
}) => {
	const initialData = useMemo(
		() => ({
			remote_id: product === null ? null : product.remote_id || "",
			name: product === null ? "" : product.name || "",
			discount:
				product === null
					? ""
					: (product.price && !isNaN(product.price)
							? (
									(1 -
										Number(product.discounted_price) /
											Number(product.price)) *
									100
							  ).toFixed(0)
							: "") || "",
			discounted_price:
				product === null
					? ""
					: product.price
					? product.price
					: product.discounted_price || "",
			photo_url: product === null ? "" : product.photo_url || "",
			landing_page: product === null ? "" : product.landing_page || "",
		}),
		[product]
	);

	const [addData, setAddData] = useState(initialData);
	const [formError, setFormError] = useState("");
	const track = useTrack();
	const [{ loading, data, error }, startAdd, reset] = useResource(
		{
			url:
				product === null ? PRODUCTS_ADD_URL : SAVE_PRODUCT_URL(product),
			method: product === null ? "POST" : "PUT",
			data: addData,
		},
		false
	);

	const [newImage, setNewImage] = useState(false);

	const [
		{ loading: imageLoading, data: imageData, error: imageError },
		imageUpload,
		imageReset,
	] = useImageResource(user, addData.photo_url);

	useEffect(() => {
		if (error) {
			track(PLATFORM_ADD_PRODUCT_ERROR, { error });
		}
	}, [error, track]);

	useEffect(() => {
		if (imageError) {
			track(PLATFORM_ADD_PRODUCT_IMAGE_ERROR, { error: imageError });
		}
	}, [imageError, track]);

	const handleChange = useCallback(
		(e) => {
			if (!e.target) {
				setAddData((addData) => ({
					...addData,
					photo_url: e ? e : product ? product.photo_url : null,
				}));

				return;
			}
			const {
				target: { name, ...rest },
			} = e;

			setAddData((data) => ({
				...data,
				[name]: rest.value,
			}));
		},
		[product]
	);

	const performAdd = useCallback(
		(imageUrl) => {
			const { name, discounted_price, discount, landing_page } = addData;
			setAddData((data) => ({
				name: name.trim(),
				discounted_price: discount
					? ((1 - Number(discount) / 100) * discounted_price).toFixed(
							2
					  )
					: discounted_price,
				price: discount ? discounted_price : null,
				landing_page: landing_page,
				photo_url: imageUrl,
			}));
			if (disabled) {
				return;
			}
			startAdd();
		},
		[addData, startAdd, disabled]
	);

	useEffect(() => {
		if (!imageData.url) {
			return;
		}
		imageReset();
		performAdd(imageData.url);
	}, [imageData.url, performAdd, imageReset]);

	const add = useCallback(
		(e) => {
			e.preventDefault();
			const {
				name,
				discounted_price,
				discount,
				photo_url,
				landing_page,
			} = addData;
			if (
				!name.trim() ||
				!landing_page.trim() ||
				(isNaN(discounted_price) && !discounted_price)
			) {
				return setFormError("Name, price, landing page are needed!");
			}
			if (isNaN(discounted_price)) {
				return setFormError("Price should be a number");
			}
			if (discount && isNaN(discount)) {
				return setFormError("Discount should be a number");
			}
			if (discount && (Number(discount) > 100 || Number(discount) < 0)) {
				return setFormError("Discount should be between 0 and 100");
			}

			if (!photo_url) {
				return setFormError("Please select a product image");
			}

			setFormError("");

			if (typeof photo_url === "string") {
				performAdd(photo_url);
			} else {
				imageUpload();
			}
		},
		[setFormError, addData, performAdd, imageUpload]
	);

	useEffect(() => {
		if (data) {
			onClose();
			onDone(product !== null ? product.remote_id : null);
			track(
				product !== null ? PLATFORM_EDIT_PRODUCT : PLATFORM_ADD_PRODUCT,
				{
					...data,
				}
			);
			reset();
			setAddData(initialData);
		}
	}, [data, onClose, reset, onDone, product, initialData, track]);

	return (
		<Modal
			className="product-modal"
			open={open}
			onClose={onClose}
			title={product === null ? "Add Products" : "Edit Product"}
		>
			<BaseForm>
				<LabeledInput
					name="name"
					label="Product Name"
					placeholder="Product Name"
					value={addData.name || ""}
					onChange={handleChange}
				/>
				<LabeledInput
					label="The price of the product"
					name="discounted_price"
					placeholder="Price"
					type="number"
					value={addData.discounted_price || ""}
					onChange={handleChange}
				/>
				<LabeledInput
					name="discount"
					placeholder="Discount in percent"
					type="number"
					label="Discount applied to the product in percent (if applicable)"
					value={addData.discount || ""}
					onChange={handleChange}
				/>
				<LabeledInput
					label="Landing page URL of the product (with https://)"
					name="landing_page"
					placeholder="Landing Page URL (with https://)"
					value={addData.landing_page || ""}
					onChange={handleChange}
				/>
				<Label>Product Image</Label>
				<ImageInput
					name="photo"
					placeholder="Landing Page URL (with https://)"
					onChange={handleChange}
					newValue={newImage}
					setNewValue={setNewImage}
					value={addData.photo_url || ""}
				/>

				{addData &&
					addData.supplementary_images &&
					addData.supplementary_images.length > 0 && (
						<>
							<Label>Select a hero product image</Label>
							<PaddedContainer className="product-modal-image-picker">
								{product.supplementary_images.map(
									(img, idx) => (
										<img
											onClick={() => {
												setAddData((addData) => ({
													...addData,
													photo_url: img.photo_url,
												}));
												setNewImage(img.photo_url);
											}}
											src={img.photo_url}
											alt="Product"
											key={idx}
											className={
												img.photo_url ===
												addData.photo_url
													? "product-modal-image-picker-active"
													: ""
											}
										/>
									)
								)}
							</PaddedContainer>
						</>
					)}
				{!loading && !imageLoading && (
					<Button onClick={add} marginTop={SPACING.LARGE}>
						{product === null ? "Add" : "Save"}
					</Button>
				)}
				{(loading || imageLoading) && (
					<Loader marginTop={SPACING.REGULAR} />
				)}
				{!loading &&
					!imageLoading &&
					(formError || imageError || error) && (
						<StatusLabel>
							{formError ||
								(imageError && parseError(imageError)) ||
								(error && parseError(error))}
						</StatusLabel>
					)}
			</BaseForm>
		</Modal>
	);
};

export default React.memo(ProductModal);
