const validateModuleRules = (module) => {
	for (const rule of module.rules) {
		const claus = rule.clauses[0];
		if (
			((claus.rule_entity === "product_list"
				? claus?.products?.length === 0
				: !claus.operand) ||
				!claus.operator ||
				!claus.rule_entity) &&
			module.apply_rules
		) {
			return [false, "Please enter all rule details"];
		}
	}

	for (const rule of module.rules) {
		const claus = rule.clauses[0];

		if (claus.operand && claus.operand < 0) {
			return [false, "Please enter valid rule values"];
		}
	}
	return [true, ""];
};

export default validateModuleRules;
