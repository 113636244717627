import React, { memo, useRef } from "react";
import { ModalContext } from ".";
import { childrenPropTypes } from "../../utils";

const ModalManager = memo(({ children }) => {
	const modalCountRef = useRef(0);
	return (
		<ModalContext.Provider value={modalCountRef}>
			{children}
		</ModalContext.Provider>
	);
});
ModalManager.displayName = "ModalManager";
ModalManager.propTypes = {
	children: childrenPropTypes,
};

export default ModalManager;
