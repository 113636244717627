import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import useResource from "../hooks/useResource";
import tokenStorage from "../utils/tokenStorage";
import isAuth from "../utils/isAuth";
import {
	FETCH_USER_URL,
	IN_ONBOARDING,
	ONBOARDING_SETUP_STATUSES,
} from "../conf";
import { Loader } from "./CoreUI";
import log from "../utils/log";
import makeRedirectURL from "../utils/makeRedirectURL";
import useIdentity from "../hooks/useIdentity";
import { memo } from "react";
import { userUpdateZendesk } from "../utils/zendesk";

const LEGACY_STEP_MAPPING = [
	ONBOARDING_SETUP_STATUSES.PLATFORM_SELECTION,
	ONBOARDING_SETUP_STATUSES.REGISTRATION_SUCCESS,
	ONBOARDING_SETUP_STATUSES.PROFILE_SETUP,
	ONBOARDING_SETUP_STATUSES.CATEGORY_SETUP,
];

const RouteRenderer = memo(
	({
		// Clears the localStorage tokens, but only ONCE.
		// Renders the page only AFTER the tokens are cleared.
		// Hence, the rendering page can set some other tokens
		// (like tokens received via GET() )
		resetLogin,

		// Renders LOGGED IN pages.
		loggedIn,

		// Renders LOGGED OUT pages.
		loggedOut,

		// Renders (LOGGED IN && SETUP) pages only.
		setupPage,

		// Note 1: If both loggedIn and loggedOut are passed (without resetLogin),
		// the page will always render EXCEPT when its a setup page AND the user
		// is logged in to a non-setup account

		// Note 2: Order of importance of props:
		// resetLogin > ( loggedIn === loggedOut )

		render,
		logoutRedirect,
		path,
		mock = false,
	}) => {
		const { user, setUser } = useIdentity();

		const [auth, setAuth] = useState(isAuth());

		const [cleared, setCleared] = useState(false);

		const [{ loading, data, error }, fetch, reset] = useResource(
			{
				method: "GET",
				url: FETCH_USER_URL,
			},
			false
		);

		useEffect(() => {
			if (resetLogin && !cleared) {
				tokenStorage.clear();

				setUser(null);
				setAuth(isAuth());
				setCleared(true);
			} else if (!!auth[0] && !user) {
				fetch();
			}
		}, [user, fetch, resetLogin, path, setUser, auth, cleared]);

		useEffect(() => {
			if (!data) {
				return;
			}
			if (!data.publisher) {
				log("Could not find publisher", data);
				tokenStorage.clear();
			} else {
				userUpdateZendesk(data);
				setUser(() => {
					const user = {
						...data,
						// firstTime: true,
						isNewIa: data?.inbox_beta,
						isSuper: isAuth()[1],
						// publisher: {
						// 	...data.publisher,
						// 	accepted_latest_privacy_tos_updates: false,
						// },
						mock,
					};
					if (!isNaN(user.setup_status))
						user.setup_status =
							LEGACY_STEP_MAPPING[user.setup_status - 1];
					return user;
				});
			}

			reset();
		}, [data, reset, setUser, mock]);

		// log("RestrictedRoute rendered for", path);

		const onboarding = IN_ONBOARDING(user);

		if (resetLogin && (isAuth()[0] || !cleared)) {
			return null;
		} else if ((loggedOut || resetLogin) && !isAuth()[0]) {
			return render({});
		} else if (loggedIn && isAuth()[0]) {
			if (user) {
				if ((!onboarding && !setupPage) || (onboarding && setupPage)) {
					return render({ user, setUser });
				} else if (!onboarding && setupPage) {
					return (
						<Navigate
							to={makeRedirectURL(
								user.isNewIa ? `/performance` : `/widget`
							)}
						/>
					);
				} else {
					return <Navigate to={makeRedirectURL(`/onboarding`)} />;
				}
			} else if (loading) {
				return <Loader light center />;
			} else if (error && !error.logout) {
				return <div> Whoops! Can't fetch user</div>;
			} else {
				return null;
			}
		} else {
			return (
				<Navigate
					to={makeRedirectURL(
						`${
							!isAuth()[0] && loggedIn && logoutRedirect
								? logoutRedirect
								: "/"
						}`
					)}
				/>
			);
		}
	}
);
export default RouteRenderer;
