import React, { memo, useCallback, useMemo, useState } from "react";
import { UTM_PARAMS } from "../../conf";
import { initialParam } from "../../utils/paramUtils";
import uuid4 from "../../utils/uuid4";
import UTMParam from "../UTMParam";
import {
	Button,
	Input,
	Text,
	PaddedContainer,
	Select,
	SPACING,
	FONT_SIZE,
	StatusLabel,
	STATUS,
} from "@disco/disco_core";

import "./utm-param-group.css";

const UTMParamGroup = memo(({ setParams, params, disabled, surface, name }) => {
	const [UTMValue, setUTMValue] = useState("");
	const [UTMType, setUTMType] = useState("");
	const [formErrors, setFormErrors] = useState({});

	const options = useMemo(() => {
		const done = new Set(params.map((newParam) => newParam.param_name));

		const options = {};

		for (let i = 0; i < UTM_PARAMS.length; i++) {
			if (done.has(Number(i) + 1) || done.has(Number(i) + 1 + "")) {
				continue;
			}
			options[i + 1] = UTM_PARAMS[i];
		}

		return options;
	}, [params]);

	const handleUTMNameChange = useCallback(
		({ target: { value } }) => {
			setUTMType(value);
			setUTMValue("");
			setFormErrors({});
		},

		[setUTMType, setUTMValue]
	);

	const handleUTMValueChange = useCallback(
		({ target: { value } }) => {
			setUTMValue(value);
			setFormErrors({});
		},
		[setUTMValue]
	);

	const handleDelete = useCallback(
		(id) => {
			setParams((allParams) =>
				allParams.filter((param) => param.id !== id)
			);
		},
		[setParams]
	);

	const validateUTMParamGroup = useCallback(() => {
		setFormErrors({});

		let errors = {};

		if (!UTMType) {
			errors.utm_type = "A UTM Type must be selected";
		}

		if (!UTMValue) {
			errors.utm_value = "A UTM Value must be entered";
		}

		if (errors?.utm_type || errors?.utm_value) {
			setFormErrors(errors);
			return false;
		}

		return true;
	}, [UTMType, UTMValue]);

	const handleAdd = useCallback(() => {
		const valid = validateUTMParamGroup();
		if (!valid) return;

		setParams((params) => [
			...params,
			{
				...initialParam,
				id: uuid4(),
				isNew: true,
				surface_type: surface,
				param_name: UTMType,
				param_value: UTMValue,
			},
		]);

		setUTMType("");
		setUTMValue("");
	}, [setParams, surface, UTMType, UTMValue, validateUTMParamGroup]);

	return (
		<PaddedContainer className="utm-param-group" marginTop={SPACING.MEDIUM}>
			<PaddedContainer
				hPadding={SPACING.REGULAR}
				vPadding={SPACING.REGULAR}
			>
				<Text size={FONT_SIZE.BODY}>{name}</Text>

				<PaddedContainer
					flexContent
					marginTop={SPACING.TINY}
					className="utm-param-group-controls"
				>
					<Select
						className="utm-param-group-select"
						options={options}
						value={UTMType}
						emptyOptionsMessage="No UTM parameters left"
						placeholder="UTM Parameter"
						onChange={handleUTMNameChange}
					/>
					<Input
						className="utm-param-group-input"
						value={UTMValue}
						placeholder="Enter a value"
						onChange={handleUTMValueChange}
						disabled={disabled}
					/>

					<Button
						className="utm-param-group-btn"
						disabled={disabled}
						onClick={handleAdd}
						light
						small
					>
						Add UTM Parameter
					</Button>
				</PaddedContainer>
				<Text marginTop={SPACING.LARGE} size={FONT_SIZE.BODY}>
					Selected UTMs
				</Text>
				<PaddedContainer
					flexContent
					className="utm-param-group-params-container"
					marginBottom={SPACING.REGULAR}
				>
					{params.map((param) => (
						<UTMParam
							name={UTM_PARAMS?.[Number(param.param_name) - 1]}
							value={param.param_value}
							key={param.id}
							disabled={disabled}
							onDelete={handleDelete}
							id={param.id}
						/>
					))}
					{params.length === 0 && (
						<PaddedContainer marginTop={SPACING.TINY}>
							No {name} Parameters added
						</PaddedContainer>
					)}
				</PaddedContainer>
				{formErrors["utm_type"] && (
					<StatusLabel type={STATUS.ERROR} marginTop={SPACING.TINY}>
						{formErrors["utm_type"]}
					</StatusLabel>
				)}
				{formErrors["utm_value"] && (
					<StatusLabel type={STATUS.ERROR} marginTop={SPACING.TINY}>
						{formErrors["utm_value"]}
					</StatusLabel>
				)}
			</PaddedContainer>
		</PaddedContainer>
	);
});

export default UTMParamGroup;
