import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { GET } from "../utils/GET";
import tokenStorage from "../utils/tokenStorage";

const ShopifyLoginPage = React.memo(() => {
	const [canRedirect, setCanRedirect] = useState(false);
	useEffect(() => {
		const accessToken = GET("access");
		const refreshToken = GET("refresh");
		const publisher = GET("publisher_id");

		if (accessToken && refreshToken && publisher) {
			tokenStorage.set({
				accessToken: accessToken,
				refreshToken: refreshToken,
				publisher: publisher,
			});
		}

		setCanRedirect(true);
	}, []);

	return <>{canRedirect ? <Navigate to="/" /> : null}</>;
});

export default ShopifyLoginPage;
