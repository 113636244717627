import React, { useState, useCallback, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import StripeCardSetup from "../StripeCardSetup";
// import "./card-setup.css";
import { SAVE_PAYMENT_URL, SUBSCRIPTION_ID } from "../../conf";
import useResource from "../../hooks/useResource";

const CardSetup = React.memo(({ setUser, onDone }) => {
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const stripe = useStripe();
	const elements = useElements();

	const [subscriptionPayload, setSubscriptionPayload] = useState({
		url: SAVE_PAYMENT_URL,
		method: "POST",
		data: {},
	});

	const [
		{
			error: subscriptionError,
			data: subscriptionData,
			loading: subscriptionLoading,
		},
		subscribe,
		resetSubscription,
	] = useResource(subscriptionPayload, false);

	const placeSubscription = useCallback(
		(paymentMethod) => {
			setSubscriptionPayload((payload) => ({
				...payload,
				data: {
					price_id: SUBSCRIPTION_ID,
					payment_method: paymentMethod,
				},
			}));
			subscribe();
		},
		[subscribe]
	);

	const setUserSubscription = useCallback(() => {
		setUser((user) => ({
			...user,
			publisher: {
				...user.publisher,
				card: subscriptionData.payment_info.card,
				subscriptions: subscriptionData.subscription_info
					? [subscriptionData.subscription_info]
					: user.publisher.subscriptions,
			},
		}));
		if (typeof onDone === "function") {
			onDone();
		}
		resetSubscription();
	}, [setUser, resetSubscription, subscriptionData, onDone]);

	const finalizeSubscription = useCallback(async () => {
		if (!subscriptionData) {
			return;
		}
		setLoading(true);
		setError(false);
		try {
			await stripe.confirmCardPayment(subscriptionData.next_action, {
				payment_method: {
					card: elements.getElement(CardElement),
				},
			});
		} catch (err) {
			setLoading(false);
			setError("Could not setup your credit card. Try again later.");
			return;
		}
		setLoading(false);
		setUserSubscription();
	}, [setUserSubscription, elements, stripe, subscriptionData]);

	useEffect(() => {
		if (!subscriptionData) {
			return;
		}
		if (subscriptionData.status === "incomplete") {
			finalizeSubscription();
		} else {
			setUserSubscription();
		}
	}, [subscriptionData, setUserSubscription, finalizeSubscription]);

	return (
		<>
			<StripeCardSetup
				loading={loading || subscriptionLoading}
				error={
					error || subscriptionError
						? "Could not create subscription. Try again later"
						: false
				}
				onDone={placeSubscription}
			/>
		</>
	);
});

export default CardSetup;
