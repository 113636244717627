import React, { forwardRef, memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

import useTheme from "../../hooks/useTheme";
import genPackageClassName from "../../utils/genPackageClassName";

import "./code-snippet.css";
import { Button } from "../Button";
import { ChevronDownIcon } from "../../Icons";

export const CodeSnippet = memo(
	forwardRef(
		({ value, className = "", heightParams, alwaysOpen = false }, ref) => {
			const [open, setOpen] = useState(alwaysOpen);
			const [copied, setCopied] = useState();

			const textRef = useRef();
			const timerRef = useRef(null);
			const { theme } = useTheme();

			useEffect(() => {
				if (!copied) return;
				if (timerRef !== null) clearTimeout(timerRef.current);
				timerRef.current = setTimeout(() => {
					timerRef.current = null;
					setCopied(false);
				}, 2000);
			}, [copied]);

			const handleCopy = () => {
				if (!textRef.current) return;
				textRef.current.select();
				document.execCommand("copy");
				setCopied(true);
			};

			return (
				<section
					className={genPackageClassName({
						theme,
						base: "code-snippet",
						additional: className,
						conditionals: {
							"code-snippet-open": open,
						},
					})}
					ref={ref}
				>
					<motion.textarea
						readOnly
						animate={{
							height: open
								? heightParams?.open || "160px"
								: heightParams?.close || "80px",
						}}
						value={value}
						ref={textRef}
					/>
					<motion.div
						className={genPackageClassName({
							theme,
							base: "code-snippet-shadow",
						})}
						animate={{ opacity: open ? 0 : 1 }}
					/>
					<Button
						className={genPackageClassName({
							base: "code-snippet-copy",
						})}
						small
						onClick={handleCopy}
					>
						{copied ? "✅ Copied" : "Copy"}
					</Button>
					{!alwaysOpen && (
						<motion.section
							className={genPackageClassName({
								theme,
								base: "code-snippet-toggle",
							})}
							onClick={() =>
								!alwaysOpen && setOpen((open) => !open)
							}
							animate={{ rotate: open ? 180 : 0 }}
						>
							<span>
								<ChevronDownIcon />
							</span>
						</motion.section>
					)}
				</section>
			);
		}
	)
);

CodeSnippet.displayName = "CodeSnippet";

CodeSnippet.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	heightParams: PropTypes.object,
	alwaysOpen: PropTypes.bool,
};
