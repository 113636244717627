import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
	Loader,
	BadgeButton,
	FormStatus,
	StatusToggle,
	Button,
} from "../../components/CoreUI";
import { MdLibraryAdd } from "react-icons/md";
import useResource from "../../hooks/useResource";
import parseError from "../../utils/parseError";
import ListViewEntry from "../ListViewEntry";

import "./list-view.css";
import Reorder from "../Reorder";
import { motion } from "framer-motion";
import useTrack from "../../hooks/useTrack";
import { PLATFORM_SURVEY_CLICK_ON_EDIT } from "../../events";

const ListView = React.memo(
	({
		user,
		setUser,
		onUpdate,
		onListUpdate,
		fetchUrl,
		toggleUrl,
		renderHead,
		renderContent,
		remoteKey,
		objectRemoteKey,
		Modal,
		refresh,
		setRefresh,
		onOpen,
		onClose,
		disableDelete,
		toggleServiceUrl = false,
		serviceRemoteKey = false,
		serviceName = false,
		partial = false,
		dynamicList = false,
		singleOpen = false,
		checkNotPartialKey = "",
		objName = "",
		className = "",

		children,
		minListRenderValue = -1,

		// FOR REORDERING
		reorder = false,
		reorderUniqueKey = "",
		reorderOptionClassName = "",
		reorderRequestUrl = "",
		reorderSaveOptionMapper,
		event,
		...props
	}) => {
		const [add, setAdd] = useState(false);
		const [edit, setEdit] = useState(null);
		const [list, setList] = useState(null);
		const [{ data, loading, error }, reload, reset] = useResource({
			url: fetchUrl,
			method: "GET",
		});

		const [reorderSaveOptions, setReorderSaveOptions] = useState({});
		const [showTriggerReorderBtn, setShowTriggerReorderBtn] =
			useState(false);
		const [
			{ data: reorderData, loading: reorderLoading, error: reorderError },
			triggerReorder,
		] = useResource(
			{
				url: reorderRequestUrl,
				method: "PUT",
				data: reorderSaveOptions,
			},
			false
		);

		const [activeIdx, setActiveIdx] = useState(dynamicList ? 0 : -1);
		const track = useTrack();

		const handleSetActiveIdx = useCallback((idx) => setActiveIdx(idx), []);

		const handleOpen = useCallback(
			(idx) => {
				if (onOpen && typeof onOpen === "function") {
					onOpen(list[idx]);
				}
			},
			[onOpen, list]
		);

		const handleClose = useCallback(
			(idx) => {
				if (onClose && typeof onClose === "function") {
					if (dynamicList && activeIdx !== -1) {
						return;
					}
					onClose(list[idx]);
				}
			},
			[onClose, list, dynamicList, activeIdx]
		);

		useEffect(() => {
			if (add && !!event) {
				track(event, {});
			}
		}, [add, event, track]);

		useEffect(() => {
			if (!refresh || loading || !setRefresh) {
				return;
			}
			reload();
			setRefresh(false);
		}, [refresh, setRefresh, reload, loading]);

		// Copy list to local state, to edit (if needed)
		useEffect(() => {
			if (data) {
				setList(data[remoteKey]);
				reset();
			}
		}, [data, reset, remoteKey]);

		const onDone = useCallback(
			(...args) => {
				reload();
				onUpdate(...args);
			},
			[onUpdate, reload]
		);

		const handleEdit = useCallback(
			(obj) => {
				if (props.survey) {
					track(PLATFORM_SURVEY_CLICK_ON_EDIT, { ...obj });
				}
				setEdit(obj);
			},
			[props.survey, track]
		);

		const handleDelete = useCallback(
			(id) => {
				if (onClose && typeof onClose === "function") {
					onClose(list.filter((obj) => obj.remote_id === id));
				}
				setList((list) => {
					return list.filter((obj) => obj.remote_id !== id);
				});
			},
			[onClose, list]
		);

		const handleToggle = useCallback(
			(remoteId, active) => {
				const id = list.findIndex((obj) => obj.remote_id === remoteId);
				if (id === -1) {
					return;
				}
				setList((list) => {
					if (active) {
						return [
							{ ...list[id], active },
							...list.slice(0, id).map((obj) => ({
								...obj,
								active: active ? false : obj.active,
							})),
							...list.slice(id + 1).map((obj) => ({
								...obj,
								active: active ? false : obj.active,
							})),
						];
					}
					return [
						...list.slice(0, id).map((obj) => ({
							...obj,
							active: active ? false : obj.active,
						})),
						{ ...list[id], active },
						...list.slice(id + 1).map((obj) => ({
							...obj,
							active: active ? false : obj.active,
						})),
					];
				});
			},
			[list]
		);

		useEffect(() => {
			if (Array.isArray(list) && typeof onListUpdate === "function") {
				onListUpdate(list);
			}
		}, [list, onListUpdate]);

		const handleServiceToggle = useCallback(() => {
			reload();
			onUpdate();
		}, [reload, onUpdate]);

		const renderOption = useCallback(
			(idx) => {
				if (!Array.isArray(list) || list.length === 0) {
					return null;
				}

				const obj = list[idx];

				return (
					<ListViewEntry
						obj={obj}
						idx={idx}
						activeIdx={activeIdx}
						setActiveIdx={handleSetActiveIdx}
						toggleUrl={toggleUrl}
						onToggle={handleToggle}
						onUpdate={onUpdate}
						disableDelete={disableDelete}
						onDelete={handleDelete}
						// key={obj.remote_id}
						onEdit={handleEdit}
						renderContent={renderContent}
						renderHead={renderHead}
						remoteKey={objectRemoteKey}
						partial={partial}
						checkNotPartialKey={checkNotPartialKey}
						dynamicList={dynamicList}
						onOpen={handleOpen}
						onClose={handleClose}
						singleOpen={singleOpen}
						setRefresh={setRefresh}
						reorderable={reorder}
						{...props}
					/>
				);
			},
			[
				list,
				activeIdx,
				handleSetActiveIdx,
				toggleUrl,
				handleToggle,
				onUpdate,
				handleDelete,
				handleEdit,
				checkNotPartialKey,
				dynamicList,
				handleOpen,
				handleClose,
				singleOpen,
				setRefresh,
				objectRemoteKey,
				disableDelete,
				partial,
				props,
				renderContent,
				renderHead,
				reorder,
			]
		);

		const handleDragStart = useCallback(() => {
			setActiveIdx(-1);
			setShowTriggerReorderBtn(true);
		}, []);

		const handleDragEnd = useCallback(() => {
			setActiveIdx(-1);
		}, []);

		const handleSaveNewOrder = useCallback(() => {
			if (reorderLoading) {
				return;
			}
			if (!reorderSaveOptionMapper) {
				return setShowTriggerReorderBtn(false);
			}
			setReorderSaveOptions(reorderSaveOptionMapper(list));
			triggerReorder();
		}, [reorderLoading, reorderSaveOptionMapper, list, triggerReorder]);

		useEffect(() => {
			if (!reorderError && !reorderData) {
				return;
			}
			setShowTriggerReorderBtn(false);
		}, [reorderError, reorderData]);

		return (
			<section className={`list-view ${className}`}>
				{serviceRemoteKey && (
					<StatusToggle
						remoteKey={serviceRemoteKey}
						url={toggleServiceUrl}
						user={user}
						setUser={setUser}
						onUpdate={handleServiceToggle}
					>
						{serviceName}
					</StatusToggle>
				)}

				{loading && <Loader />}
				{error && <FormStatus> {parseError(error)}</FormStatus>}

				{Array.isArray(list) && (
					<>
						{list.length !== 0 && (
							<Button
								className="list-view-add-btn"
								onClick={() => setAdd(true)}
								small
							>
								Add {objName}
							</Button>
						)}
						{(minListRenderValue === -1 ||
							list.length >= minListRenderValue) &&
							children}
						{reorder ? (
							<>
								{showTriggerReorderBtn && (
									<>
										{reorderLoading ? (
											<Loader className="list-view-reorder-loader" />
										) : (
											<Button
												dark
												type="button"
												className="list-view-reorder-btn"
												onClick={handleSaveNewOrder}
											>
												Save my new order
											</Button>
										)}
									</>
								)}
								<Reorder
									list={list}
									setList={setList}
									itemMotionElement="div"
									uniqueKey={reorderUniqueKey || "remote_id"}
									disabled={reorderLoading}
									renderOption={renderOption}
									optionClassName={`list-view-reorder-option ${reorderOptionClassName}`}
									dragStart={handleDragStart}
									dragEnd={handleDragEnd}
									className="list-view-reorder-container"
								></Reorder>
							</>
						) : (
							list.map((obj, idx) => renderOption(idx))
						)}
						{list.length === 0 && (
							<section className="list-view-empty">
								<span>
									<MdLibraryAdd />
								</span>
								<p>
									Looks like you've not created any {objName}
									s.
									<br />
								</p>

								<BadgeButton onClick={() => setAdd(true)} small>
									Add {objName}
								</BadgeButton>
							</section>
						)}
					</>
				)}

				{add && (
					<Modal
						open={add}
						user={user}
						setUser={setUser}
						onClose={() => setAdd(false)}
						onDone={onDone}
					/>
				)}

				{edit && (
					<Modal
						open={edit}
						onClose={() => setEdit(null)}
						user={user}
						setUser={setUser}
						initial={edit}
						onDone={onDone}
					/>
				)}
			</section>
		);
	}
);

export default ListView;
