import React from "react";
import { AuthPage } from "../../components/CoreUI";

import Products from "../../components/Products";

import "./products-page.css";

import { PLATFORM_VIEW_PRODUCTS } from "../../events";

const ProductsPage = React.memo(({ user, setUser }) => {
	return (
		<AuthPage
			active="products"
			className="products-page"
			user={user}
			setUser={setUser}
			viewEvent={PLATFORM_VIEW_PRODUCTS}
			// mobileThreshold={1400}
		>
			<Products />
		</AuthPage>
	);
});

export default ProductsPage;
