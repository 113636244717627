import React, { memo, useEffect, useState } from "react";
import { Collapsable } from "../CoreUI";
// import { BadgeButton, Label, Loader, FormStatus, Button } from "../CoreUI";
import { motion } from "framer-motion";

import "./survey-question.css";
import SurveyConfiguration from "../SurveyConfiguration";
import useTrack from "../../hooks/useTrack";
import { PLATFORM_SURVEY_CLICK_ON_VIEW_CONFIGURATION } from "../../events";

export const SurveyQuestionDisplay = memo(({ question }) => {
	if (!question?.options || !Array.isArray(question.options)) {
		return null;
	}
	return (
		<>
			{question.options.map((option) => (
				<motion.section
					className="survey-question-option"
					key={option.remote_id}
					layout
				>
					<img src={option.icon_url} alt="Option" />
					{option.text}
					{option.text_input && (
						<div className="survey-question-option-follow-up">
							<span>Follow up question</span>
							{option.follow_text || "Not set"}
						</div>
					)}
				</motion.section>
			))}
		</>
	);
});

const SurveyQuestion = memo(({ user, question }) => {
	const [configOpen, setConfigOpen] = useState(false);
	const track = useTrack();

	useEffect(() => {
		if (configOpen) {
			track(PLATFORM_SURVEY_CLICK_ON_VIEW_CONFIGURATION, { question });
		}
	}, [configOpen, track, question]);

	return (
		<>
			<SurveyQuestionDisplay question={question} />

			{/* <section className="survey-section">
				<Label className="survey-section-label">Status</Label>
				This question is {question.active ? "" : "NOT"} active
			</section> */}
			<Collapsable
				open={configOpen}
				setOpen={setConfigOpen}
				heading="View Configurations"
				className="survey-question-collapsable"
			>
				<SurveyConfiguration user={user} question={question} viewOnly />
			</Collapsable>
		</>
	);
});

export default SurveyQuestion;
