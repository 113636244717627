import { THEME, THEME_CLASS_NAME_MAP } from "../conf";

import genClassName, { genClassNameArgType } from "./genClassName";

type genPackageClassNameArgType = {
	theme?: keyof typeof THEME;
} & Omit<genClassNameArgType, "prefix">;

type genPackageClassNameType = (arg: genPackageClassNameArgType) => string;

const genPackageClassName: genPackageClassNameType = ({
	theme,
	base = "",
	conditionals = {},
	additional = "",
} = {}) =>
	genClassName({
		base,
		additional,
		conditionals: {
			...conditionals,
			...(theme && theme in THEME
				? { [THEME_CLASS_NAME_MAP[theme] as string]: true }
				: {}),
		},
		prefix: "disco",
	});

export default genPackageClassName;
