const persistentStateFactory = (localStorageKey) => {
	const state = {
		get status() {
			const status = localStorage.getItem(localStorageKey);
			if (!status || isNaN(status)) {
				return 0;
			}
			return Number(status);
		},

		set status(status) {
			if (isNaN(status)) {
				return;
			}
			localStorage.setItem(localStorageKey, status);
		},
	};
	return state;
};

export default persistentStateFactory;
