import { useCallback, useContext, useEffect } from "react";
import { UniversalPreviewContext } from "../components/CoreUI";
import { DEFAULT_UNIVERSAL_PREVIEW_PROPS } from "../conf";
import useMessage from "./useMessage";

const usePreview = (inModal = false, open = false) => {
	// const universalPreviewContext = useContext(UniversalPreviewContext);

	const {
		universalPreviewProps = {},
		setUniversalPreviewProps = () => {},
		cachedUniversalPreviewMessages,
	} = useContext(UniversalPreviewContext) || {};
	// universalPreviewContext
	// 	? universalPreviewContext
	// 	: {
	// 			universalPreviewProps: {},
	// 			setUniversalPreviewProps: () => {},
	// 	  };

	const resetPreviewProps = useCallback(
		() => setUniversalPreviewProps(DEFAULT_UNIVERSAL_PREVIEW_PROPS),
		[setUniversalPreviewProps]
	);

	useEffect(() => {
		resetPreviewProps();
	}, [resetPreviewProps, open]);

	const updateUniversalPreviewProps = useCallback(
		(fn) => {
			if (inModal && !open) {
				return;
			}
			setUniversalPreviewProps((universalPreviewProps) =>
				fn(universalPreviewProps)
			);
		},
		[inModal, open, setUniversalPreviewProps]
	);

	const triggerRefresh = useCallback(
		() =>
			updateUniversalPreviewProps((universalPreviewProps) => ({
				...universalPreviewProps,
				refresh: true,
			})),
		[updateUniversalPreviewProps]
	);

	const onRefresh = useCallback(
		() =>
			updateUniversalPreviewProps((universalPreviewProps) => ({
				...universalPreviewProps,
				refresh: false,
			})),
		[updateUniversalPreviewProps]
	);

	const openFullScreen = useCallback(
		() =>
			updateUniversalPreviewProps((universalPreviewProps) => ({
				...universalPreviewProps,
				inFullScreen: true,
			})),
		[updateUniversalPreviewProps]
	);
	const closeFullScreen = useCallback(
		() =>
			updateUniversalPreviewProps((universalPreviewProps) => ({
				...universalPreviewProps,
				inFullScreen: false,
			})),
		[updateUniversalPreviewProps]
	);

	const toggleFullScreen = useCallback(
		() =>
			updateUniversalPreviewProps((universalPreviewProps) => ({
				...universalPreviewProps,
				inFullScreen: !universalPreviewProps.inFullScreen,
			})),
		[updateUniversalPreviewProps]
	);

	const setPreviewType = useCallback(
		(value) =>
			updateUniversalPreviewProps((universalPreviewProps) => ({
				...universalPreviewProps,
				type: value,
			})),
		[updateUniversalPreviewProps]
	);

	const setCustomPreviewType = useCallback(
		(value) =>
			updateUniversalPreviewProps((universalPreviewProps) => ({
				...universalPreviewProps,
				customPreviewType: value,
			})),
		[updateUniversalPreviewProps]
	);

	const setCustomPreviewID = useCallback(
		(value) =>
			updateUniversalPreviewProps((universalPreviewProps) => ({
				...universalPreviewProps,
				customPreviewID: value,
			})),
		[updateUniversalPreviewProps]
	);
	const setCustomPreviewData = useCallback(
		(value) =>
			updateUniversalPreviewProps((universalPreviewProps) => ({
				...universalPreviewProps,
				customPreviewData: value,
			})),
		[updateUniversalPreviewProps]
	);

	const disableNavigation = useCallback(
		() =>
			updateUniversalPreviewProps((universalPreviewProps) => ({
				...universalPreviewProps,
				disableNav: true,
			})),
		[updateUniversalPreviewProps]
	);
	const enableNavigation = useCallback(
		() =>
			updateUniversalPreviewProps((universalPreviewProps) => ({
				...universalPreviewProps,
				disableNav: false,
			})),
		[updateUniversalPreviewProps]
	);

	const enableShowFullPreview = useCallback(
		() =>
			updateUniversalPreviewProps((universalPreviewProps) => ({
				...universalPreviewProps,
				showFullPreview: true,
			})),
		[updateUniversalPreviewProps]
	);

	const disableShowFullPreview = useCallback(
		() =>
			updateUniversalPreviewProps((universalPreviewProps) => ({
				...universalPreviewProps,
				showFullPreview: false,
			})),
		[updateUniversalPreviewProps]
	);

	const setShowFullPreview = useCallback(
		(value) =>
			updateUniversalPreviewProps((universalPreviewProps) => ({
				...universalPreviewProps,
				showFullPreview: value,
			})),
		[updateUniversalPreviewProps]
	);

	const postMessage = useCallback(
		({ type = "PLATFORM_DATA_TRANSFER", payload = {} } = {}) => {
			const previewWindow = document.querySelector(
				".universal-preview-widget"
			);
			if (!previewWindow || !previewWindow?.contentWindow) return;
			cachedUniversalPreviewMessages.current = {
				...cachedUniversalPreviewMessages.current,
				...payload,
			};
			previewWindow.contentWindow.postMessage({ type, payload }, "*");
		},
		[cachedUniversalPreviewMessages]
	);
	const clearCachedMessages = useCallback(() => {
		cachedUniversalPreviewMessages.current = {};
	}, [cachedUniversalPreviewMessages]);

	useMessage("PLATFORM_FETCH_CACHED_MESSAGES", () => {
		postMessage({ payload: cachedUniversalPreviewMessages.current });
	});

	return {
		universalPreviewProps,
		setUniversalPreviewProps: updateUniversalPreviewProps,
		triggerRefresh,
		onRefresh,
		openFullScreen,
		closeFullScreen,
		toggleFullScreen,
		setPreviewType,
		setCustomPreviewType,
		setCustomPreviewID,
		setCustomPreviewData,
		enableNavigation,
		disableNavigation,
		enableShowFullPreview,
		disableShowFullPreview,
		postMessage,
		clearCachedMessages,
		setShowFullPreview,
	};
};

export default usePreview;
