import React, { memo } from "react";
import checkIcon from "../../img/check-3d.png";
import { Loader } from "../CoreUI";

import "./profile-integration.css";

const ProfileIntegration = memo(({ onClick, name, icon, loading, done }) => {
	return (
		<section onClick={onClick} className="profile-integration">
			<img src={icon} alt="" />
			<main>
				<h3>{name}</h3>
				<h4>Powered by Disco</h4>
			</main>
			{done ? (
				<img src={checkIcon} alt="" />
			) : loading ? (
				<Loader />
			) : (
				<span>Get Started</span>
			)}
		</section>
	);
});

export default ProfileIntegration;
