import React, { useMemo, useState, useCallback, useEffect } from "react";

import {
	Checkbox,
	FilledButton,
	FormStatus,
	Loader,
	SubscriptionFeatures,
} from "../CoreUI";
import Modal from "./Modal";
import CardSetup from "../CardSetup";
import "./card-modal.css";
import isShopifyShop from "../../utils/isShopifyShop";
import ShopPaySetView from "../ShopPaySetView";
import getSubscriptionText from "../../utils/getSubscriptionText";
import isProUser from "../../utils/isProUser";
import useResource from "../../hooks/useResource";
import { BRAND_INFO_URL } from "../../conf";
import parseError from "../../utils/parseError";

const CardModal = React.memo(
	({ onClose, onSave, user, setUser, features = false, ...rest }) => {
		const promptProAuth = useMemo(
			() =>
				isShopifyShop(user) &&
				getSubscriptionText(user).premium &&
				!isProUser(user),
			[user]
		);

		const [proAuthReqOptions, setProAuthReqOptions] = useState({
			url: BRAND_INFO_URL,
			method: "PUT",
			data: {
				// payment_authorized: user.publisher.payment_authorized,
				payment_authorized: true,
			},
		});

		const [{ data, error, loading }, toggleProAuth] = useResource(
			proAuthReqOptions,
			false
		);

		// const handleProAuthCheckMark = useCallback(
		// 	({ target: { checked } }) => {
		// 		setProAuthReqOptions((proAuthReqOptions) => ({
		// 			...proAuthReqOptions,
		// 			data: {
		// 				payment_authorized: checked,
		// 			},
		// 		}));
		// 	},
		// 	[]
		// );

		const handleButtonClick = useCallback(() => {
			if (loading) {
				// if (loading || !proAuthReqOptions.data.payment_authorized) {
				return;
			}
			toggleProAuth();
		}, [toggleProAuth, loading]);

		useEffect(() => {
			if (!data) {
				return;
			}
			setUser((user) => ({
				...user,
				publisher: {
					...user.publisher,
					payment_authorized: data?.brand?.payment_authorized,
				},
			}));

			if (typeof onClose === "function") {
				onClose();
			}
		}, [data, setUser, onClose]);

		return (
			<Modal
				{...rest}
				onClose={onClose}
				heading={
					promptProAuth
						? "Unlock Pro Features"
						: isShopifyShop(user)
						? "Upgrade to pro"
						: "Edit your Credit Card"
					// : "Set up Credit Card"
				}
				className="card-modal"
			>
				{features && <SubscriptionFeatures user={user} />}
				{promptProAuth ? (
					<section className="card-modal-pro-auth">
						<div>
							This feature is a part of the Disco Pro bundle of
							upsell and insight tools including Upsells and
							Surveys. There will be a .05 dollar per order charge
							associated with any order utilizing any of these
							features.
						</div>
						{/* <section className="card-modal-pro-auth-tick">
							<Checkbox
								disabled={loading}
								checked={
									proAuthReqOptions.data.payment_authorized
								}
								onChange={handleProAuthCheckMark}
							/>
							<span>I understand!</span>
						</section> */}
						{error && (
							<FormStatus>{parseError(error, true)}</FormStatus>
						)}
						<section className="card-modal-pro-auth-action">
							{loading ? (
								<Loader />
							) : (
								<FilledButton
									// light={
									// 	!proAuthReqOptions.data
									// 		.payment_authorized
									// }
									onClick={handleButtonClick}
								>
									Unlock Pro Features
								</FilledButton>
							)}
						</section>
					</section>
				) : (
					<>
						{isShopifyShop(user) && (
							<ShopPaySetView
								user={user}
								setUser={setUser}
								inModal
							/>
						)}
						{!isShopifyShop(user) && (
							<CardSetup onDone={onClose} setUser={setUser} />
						)}
					</>
				)}
			</Modal>
		);
	}
);

export default CardModal;
