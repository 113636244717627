import React, { memo, useCallback, useMemo } from "react";
import EmailsSetupStep from "./EmailsSetupStep";
import "./emails.css";
import { PageHeader, Tabs } from "../CoreUI";
import { useState } from "react";
import LookerDashPerformance from "../PerformanceModules/LookerDashPerformance";
import EmailsAdvanced from "./EmailsAdvanced";
import { EmailsSettingToggles } from "./EmailsCoreUI";

const EMAILS_ADVANCED_SETTINGS = "Advanced Settings";
const EmailsHeader = memo(({ tabs, setTabs }) => {
	const handleTabChange = useCallback(
		(name, index) => {
			setTabs((tabs) =>
				tabs.map((tab, idx) => {
					return { ...tab, active: tab.name === name };
				})
			);
		},
		[setTabs]
	);

	const mainTabs = useMemo(
		() => tabs.filter((tab) => tab.name !== EMAILS_ADVANCED_SETTINGS),
		[tabs]
	);

	const actionChildren = useMemo(
		() => (
			<Tabs
				options={tabs.filter(
					(tab) => tab.name === EMAILS_ADVANCED_SETTINGS
				)}
				onChange={handleTabChange}
			/>
		),
		[tabs, handleTabChange]
	);

	return (
		<PageHeader
			heading={"Emails"}
			className="emails-module-header"
			tabOptions={mainTabs}
			handleTabChange={handleTabChange}
			actionChildren={actionChildren}
		/>
	);
});
const Emails = memo(({ user, setUser }) => {
	const [tabs, setTabs] = useState([
		{ name: "Manage Emails", active: true },
		{ name: "View Performance", active: false },
		{
			name: EMAILS_ADVANCED_SETTINGS,
			active: false,
			className: "emails-advanced-settings-tab",
		},
	]);

	return (
		<>
			<EmailsHeader tabs={tabs} setTabs={setTabs} />
			{tabs[0].active && (
				<EmailsSetupStep
					subHeading={`Welcome back, ${user.first_name}`}
					heading="Add Disco to your emails"
					user={user}
					setUser={setUser}
				>
					<EmailsSettingToggles user={user} setUser={setUser} />
				</EmailsSetupStep>
			)}
			{tabs[1].active && (
				<LookerDashPerformance
					type="email"
					idx={"email"}
					user={user}
					setUser={setUser}
				/>
			)}
			{tabs[2].active && <EmailsAdvanced user={user} setUser={setUser} />}
		</>
	);
});

export default Emails;
