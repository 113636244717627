import React, { useState, useEffect, useRef, useCallback } from "react";
import { Reorder as FramerReorder } from "framer-motion";

const ReorderItem = React.memo(
	({
		motionElement = "div",
		item,
		onClick,
		className = "",
		disabled,
		children,

		id,
		dragEnd,
		dragStart,
		productRow = false,
		onReorder,
	}) => {
		const postRef = useRef();
		const [inDrag, setInDrag] = useState(false);

		const handleClick = useCallback(() => {
			if (typeof onClick !== "function") return;
			onClick(id);
		}, [onClick, id]);

		useEffect(() => {
			if (!inDrag || typeof dragStart !== "function") {
				return;
			}
			dragStart();
		}, [inDrag, dragStart]);

		return (
			<FramerReorder.Item
				ref={postRef}
				className={`${className} ${
					inDrag ? `${className}-in-drag` : ""
				}`}
				onClick={handleClick}
				value={item}
				layout
				as={motionElement}
				animate={{
					scale: inDrag ? (!productRow ? 1.08 : 0.995) : 1,
				}}
				onDragStart={() => (!disabled ? setInDrag(true) : null)}
				dragListener={!disabled}
				onDragEnd={() => {
					if (disabled) {
						return;
					}
					setInDrag(false);
					if (typeof dragEnd === "function") dragEnd(id);
					if (typeof onReorder === "function") onReorder();
				}}
				dragElastic={0.1}
			>
				{children(id)}
			</FramerReorder.Item>
		);
	}
);
export default ReorderItem;
