import React, { useRef, useState, useEffect, useCallback } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { FETCH_CARD_SECRET } from "../../conf";
import useResource from "../../hooks/useResource";
import { FormStatus } from "../CoreUI";

import "./stripe-card-setup.css";
import { Button, Loader } from "@disco/disco_core";
const StripeCardSetup = React.memo(
	({
		loading,
		error,
		onDone,
		showButton = true,
		buttonText = "Connect Card",
		className = "",
	}) => {
		const stripe = useStripe();
		const elements = useElements();
		const [stripeResource, setStripeResource] = useState({
			loading: false,
			error: false,
		});
		const cardSecretRef = useRef(null);
		const [
			{
				data: cardSecret,
				loading: cardSecretLoading,
				error: cardSecretError,
			},
			getCardSecret,
			resetCardSecret,
		] = useResource({ url: FETCH_CARD_SECRET, method: "GET" }, false);

		const initiatePay = useCallback(() => getCardSecret(), [getCardSecret]);

		const stripePay = useCallback(async () => {
			if (!stripe || !elements) {
				return;
			}
			setStripeResource({ loading: true, error: false });

			const cardElement = elements.getElement(CardElement);
			try {
				const resp = await stripe.confirmCardSetup(
					cardSecretRef.current.secret,
					{
						payment_method: {
							type: "card",
							card: cardElement,
						},
					}
				);
				if (resp.error) {
					setStripeResource({
						loading: false,
						error: resp.error.message,
					});
				} else {
					setStripeResource({
						loading: false,
						error: false,
					});
					onDone(resp.setupIntent.payment_method);
				}
			} catch (err) {
				setStripeResource({
					loading: false,
					error: "Could not process your payment! Try again later",
				});
			}
		}, [stripe, onDone, elements]);

		useEffect(() => {
			if (!cardSecret) {
				return;
			}
			cardSecretRef.current = cardSecret;
			resetCardSecret();
			stripePay();
		}, [cardSecret, stripePay, resetCardSecret]);

		useEffect(() => {
			if (!cardSecretError) {
				return;
			}
			resetCardSecret();
			setStripeResource({
				loading: false,
				error: "Could not process your payment! Try again later",
			});
			return;
		}, [cardSecretError, resetCardSecret]);

		return (
			<section className={`stripe-card-setup ${className}`}>
				<CardElement
					className={`stripe-card ${
						cardSecretLoading || cardSecretError
							? "stripe-card-disable"
							: ""
					}`}
				/>
				{!(loading || cardSecretLoading || stripeResource.loading) &&
					showButton && (
						<Button onClick={initiatePay}>{buttonText}</Button>
					)}
				{(loading || cardSecretLoading || stripeResource.loading) && (
					<Loader className="card-loader" />
				)}
				{(stripeResource.error || cardSecretError || error) && (
					<FormStatus error>
						{"Could not complete card setup. Try again later"}
					</FormStatus>
				)}
			</section>
		);
	}
);

export default StripeCardSetup;
