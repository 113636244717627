import React, { memo, forwardRef, ReactElement } from "react";
import {
	AlertTriangleIcon,
	CheckCircleIcon,
	InfoCircleIcon,
	CrossCircleIcon,
} from "../../Icons";
import genPackageClassName from "../../utils/genPackageClassName";
import { Card, CardProps } from "../Card";
import { PaddedContainer, SPACING } from "../PaddedContainer";
import { FONT_SIZE, Text, TextProps } from "../Typography";
import {
	Extend,
	GenericRef,
	ObjectValuesAsTypes,
	StandardComponentWithChildren,
} from "../../shared.types";
import "./status-label.css";

export const STATUS = {
	ERROR: "error",
	WARNING: "warning",
	SUCCESS: "success",
	INFO: "info",
} as const;

export const STATUS_ICON = {
	[STATUS.ERROR]: <CrossCircleIcon />,
	[STATUS.WARNING]: <AlertTriangleIcon />,
	[STATUS.SUCCESS]: <CheckCircleIcon />,
	[STATUS.INFO]: <InfoCircleIcon />,
} as const;

export type StatusLabelStatus = ObjectValuesAsTypes<typeof STATUS>;
export type StatusLabelIcon = ObjectValuesAsTypes<typeof STATUS_ICON>;

export type NativeStatusLabelProps = {
	className?: string;
	title?: string;
	showIcon?: boolean;
	icon?: ReactElement;
	type?: StatusLabelStatus;
	childrenProps?: TextProps;
};

export type StatusLabelProps = Extend<CardProps, NativeStatusLabelProps>;

type StatusLabelComponent = StandardComponentWithChildren<StatusLabelProps>;

export const StatusLabel: StatusLabelComponent = memo(
	forwardRef(
		(
			{
				className = "",
				title = "",
				children,
				showIcon = true,
				icon,
				type = STATUS.ERROR,
				childrenProps,
				...rest
			},
			ref?: GenericRef
		) => {
			return (
				<Card
					className={genPackageClassName({
						base: "status-label",
						conditionals: {
							[`status-label-${type}`]: type,
						},
						additional: className,
					})}
					vPadding={SPACING.TINY}
					hPadding={SPACING.REGULAR}
					ref={ref}
					{...rest}
				>
					{showIcon && (
						<Text
							hPadding={SPACING.REGULAR}
							tightLeft
							className={genPackageClassName({
								base: "status-label-icon",
							})}
							centerContent
							fitContent
						>
							{icon || STATUS_ICON[type]}
						</Text>
					)}
					<PaddedContainer
						className={genPackageClassName({
							base: "status-label-detail",
						})}
					>
						{title && (
							<Text
								thick
								size={FONT_SIZE.LG_BODY}
								className={genPackageClassName({
									base: "status-label-title",
								})}
							>
								{title}
							</Text>
						)}
						{children && (
							<Text
								size={FONT_SIZE.BODY}
								{...childrenProps}
								className={genPackageClassName({
									base: "status-label-children",
									additional: childrenProps?.className,
								})}
							>
								{children}
							</Text>
						)}
					</PaddedContainer>
				</Card>
			);
		}
	)
);
