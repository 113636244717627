import React from "react";
import { memo } from "react";
import useTheme from "../../hooks/useTheme";
import genPackageClassName from "../../utils/genPackageClassName";
import { GenericRef, Extend } from "../../shared.types";

import "./loader.css";
import { forwardRef } from "react";
import { PaddedContainer, PaddedContainerProps } from "../PaddedContainer";

type NativeLoaderProps = {
	small?: boolean;
	center?: boolean;
	middle?: boolean;
	light?: boolean;
	dark?: boolean;
};

export type LoaderProps = Extend<PaddedContainerProps, NativeLoaderProps>;

type LoaderComponent = (props: LoaderProps) => React.ReactNode | null;

export const Loader: LoaderComponent = memo(
	forwardRef(
		(
			{
				small = false,
				center = false,
				middle = false,
				light = false,
				dark = false,
				className = "",
				...props
			}: LoaderProps,
			ref?: GenericRef
		) => {
			const { theme } = useTheme();
			return (
				<PaddedContainer
					motionElement
					Element="div"
					className={genPackageClassName({
						theme,
						base: "loader",
						additional: className,
						conditionals: {
							"loader-center": center,
							"loader-light": light,
							"loader-small": small,
							"loader-dark": dark,
						},
					})}
					hMarginAuto={middle}
					ref={ref}
					{...props}
				>
					<div
						className={genPackageClassName({
							theme,
							base: "loader-circle",
						})}
					/>
				</PaddedContainer>
			);
		}
	)
);
