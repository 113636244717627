import React, { memo, forwardRef, useCallback } from "react";
import PropTypes from "prop-types";

import { PaddedContainer } from "../PaddedContainer";
import { childrenPropTypes, isFunction } from "../../utils";
import genPackageClassName from "../../utils/genPackageClassName";

import "./base-form.css";

export const BaseForm = memo(
	forwardRef(({ className = "", onSubmit, children, ...props }, ref) => {
		const handleSubmit = useCallback(
			(e) => {
				e.preventDefault();
				if (!isFunction(onsubmit)) return;
				onSubmit(e);
			},
			[onSubmit]
		);

		return (
			<PaddedContainer
				className={genPackageClassName({
					base: "base-form",
					additional: className,
				})}
				Element="form"
				ref={ref}
				onSubmit={handleSubmit}
				{...props}
			>
				{children}
			</PaddedContainer>
		);
	})
);
BaseForm.displayName = "BaseForm";
BaseForm.propTypes = {
	className: PropTypes.string,
	onSubmit: PropTypes.func,
	children: childrenPropTypes,
};
