import {
	Button,
	genClassName,
	Loader,
	PaddedContainer,
	parseError,
	SPACING,
	useResource,
} from "@disco/disco_core";
import React, { useEffect, useState } from "react";

import LeadOffersEditorFields, {
	LEAD_OFFER_FIELD_VALIDATOR,
} from "./LeadOffersEditorFields";
import LeadOffersEditorTypeSelector from "./LeadOffersEditorTypeSelector";

import "./lead-offers-editor.css";
import { LEAD_OFFER_TYPE_KEY } from "../modals/LeadOffersTypeSelectorModal";
import { SAVE_LEAD_OFFERS_URL } from "../../conf";
import usePreview from "../../hooks/usePreview";

const EMPTY_OFFER = {
	code: "",
	heading: "",
	subheading: "",
	[LEAD_OFFER_TYPE_KEY]: "",
	landing_page_url: "",
	disclosure: "",
};
const LeadOffersEditor = ({ baseOffer, onOfferTypeEdit, onClose, onSave }) => {
	const [offer, setOffer] = useState(baseOffer || EMPTY_OFFER);
	const [errors, setErrors] = useState({});
	const [
		{ loading: saveLoading, data: saveData, error: saveError },
		saveOffer,
	] = useResource(
		{
			url: SAVE_LEAD_OFFERS_URL(offer),
			method: offer?.remote_id ? "PUT" : "POST",
			data: offer,
		},
		false
	);

	const validateErrors = () => {
		let hasErrors = false;
		setErrors({});
		Object.entries(offer).forEach(([remoteKey, value]) => {
			const validator = LEAD_OFFER_FIELD_VALIDATOR[remoteKey];
			if (typeof validator !== "function") return;
			const [isValid, errorValue] = validator({
				inp: value,
				offerType: offer[LEAD_OFFER_TYPE_KEY],
			});
			if (isValid) return;
			hasErrors = true;
			setErrors((errors) => ({ ...errors, [remoteKey]: errorValue }));
		});
		return hasErrors;
	};

	const handleSave = () => {
		const hasErrors = validateErrors();
		if (hasErrors) return;
		saveOffer();
	};

	// TODO: fix errors, actually have an unknown error show up here?
	useEffect(() => {
		if (!saveError) return;
		setErrors(parseError(saveError, { verbose: true }));
	}, [saveError]);

	useEffect(() => {
		if (!saveData) return;
		const savedOffer = saveData.offer;
		if (typeof onSave !== "function") return;
		onSave({ offer: savedOffer });
	}, [saveData, onSave]);

	// TODO: Think if we need to clear the cache else where as well
	const { clearCachedMessages, triggerRefresh } = usePreview();
	useEffect(() => {
		return () => {
			console.warn("RUNNING UNMOUNT");
			triggerRefresh();
			clearCachedMessages();
		};
	}, [clearCachedMessages, triggerRefresh]);

	return (
		<PaddedContainer className="lead-offers-editor">
			<PaddedContainer vPadding={SPACING.MEDIUM}>
				<LeadOffersEditorTypeSelector
					onOfferTypeEdit={onOfferTypeEdit}
					offer={offer}
					setOffer={setOffer}
					disabled={saveLoading}
				/>
				<LeadOffersEditorFields
					offer={offer}
					setOffer={setOffer}
					errors={errors}
					setErrors={setErrors}
					disabled={saveLoading}
				/>
			</PaddedContainer>
			<PaddedContainer flexContent>
				<Button
					marginRight={SPACING.SMALL}
					onClick={handleSave}
					disabled={saveLoading}
				>
					{saveLoading ? <Loader small light /> : "Save offer"}
				</Button>
				<Button secondary onClick={onClose} disabled={saveLoading}>
					Cancel
				</Button>
			</PaddedContainer>
		</PaddedContainer>
	);
};

export default LeadOffersEditor;
