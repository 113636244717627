import React, { useEffect, useRef } from "react";
// import useResource from "../../hooks/useResource";
import { DEFAULT_OPTION_IMG } from "../../conf";
import { Checkbox, Input, Label } from "../CoreUI";

import { useCallback } from "react";
import { CgMenu } from "react-icons/cg";
import { FiTrash2 } from "react-icons/fi";
import useModal from "../../hooks/useModal";
import IconPicker from "../IconPicker";
import "./survey-option.css";

const SurveyOption = React.memo(
	({ option, id, handleChange, user, removeOption, isNew, focussed }) => {
		const inputRef = useRef();
		const ref = useRef(null);
		const { open: imgOpen, handleOpen, handleClose } = useModal(false);
		// const [requestOptions, setRequestOptions] = useState({
		// 	url: EDIT_SURVEY_OPTION_URL(option),
		// 	method: "PUT",
		// 	data: {},
		// });

		// const [{ loading, data }, editOption] = useResource(
		// 	requestOptions,
		// 	false
		// );

		const handleOptionDelete = useCallback(() => {
			// if (loading) {
			// 	return;
			// }
			// if (!isNew) {
			// 	setRequestOptions((requestOptions) => ({
			// 		...requestOptions,
			// 		data: {
			// 			...requestOptions.data,
			// 			deleted: true,
			// 		},
			// 	}));
			// 	return editOption();
			// }
			removeOption(id);
		}, [id, removeOption]);

		const onDone = useCallback(
			(img) => {
				handleChange({ ...option, icon_url: img }, id);
			},
			[handleChange, option, id]
		);

		useEffect(() => {
			if (!inputRef.current) {
				return;
			}
			if (focussed) {
				inputRef.current.focus();
			} else {
				inputRef.current.blur();
			}
		}, [focussed, id]);

		return (
			<>
				<main layout className="survey-option-main">
					<img
						ref={ref}
						src={option.icon_url || DEFAULT_OPTION_IMG}
						onClick={handleOpen}
						alt=""
						className="survey-option-img"
					/>
					<Input
						ref={inputRef}
						value={option.text}
						placeholder="Add Option Text"
						onChange={(e) =>
							handleChange(
								{
									...option,
									text: e.target.value,
								},
								id
							)
						}
					/>
					<Label>
						Follow up
						<Checkbox
							checked={option.text_input}
							onChange={({ target: { checked } }) => {
								handleChange(
									{ ...option, text_input: checked },
									id
								);
							}}
						/>
					</Label>

					{!isNew && (
						<Label>
							Active
							<Checkbox
								checked={option.active}
								onChange={({ target: { checked } }) => {
									handleChange(
										{ ...option, active: checked },
										id
									);
								}}
							/>
						</Label>
					)}
					{/* {loading ? (
						<Loader />
					) : ( */}
					<span
						className="survey-option-act"
						onClick={handleOptionDelete}
					>
						<FiTrash2 />
					</span>
					{/* )} */}
					<span className="survey-option-act survey-option-reorder">
						<CgMenu />
					</span>
				</main>
				{option.text_input && (
					<Input
						className="survey-option-follow-up"
						placeholder="Follow up question text"
						value={option.follow_text || ""}
						onChange={(e) =>
							handleChange(
								{
									...option,
									follow_text: e.target.value,
								},
								id
							)
						}
					/>
				)}

				<IconPicker
					remoteKey="image_url"
					heading="Choose An Icon"
					open={imgOpen}
					user={user}
					onClose={handleClose}
					defaultValue={DEFAULT_OPTION_IMG}
					value={option.icon_url}
					onDone={onDone}
					snapRef={ref}
				/>
			</>
		);
	}
);
export default SurveyOption;
