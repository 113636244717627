export const days = [
	"Sunday",
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
];
export const fullMonths = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];
export const months = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"June",
	"July",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

const parseDate = ({
	date,
	noTime = false,
	noDate = false,
	noYear = false,
	useFullMonth = false,
}) => {
	const dateObj = new Date(date);
	if (isNaN(dateObj)) {
		return false;
	}
	const usableMonths = useFullMonth ? fullMonths : months;
	const ret = `${usableMonths[dateObj.getMonth()]} ${dateObj.getDate()}${
		noYear ? "" : `, ${dateObj.getFullYear()}`
	}`;

	let hour =
		dateObj.getHours() > 12 ? dateObj.getHours() - 12 : dateObj.getHours();
	if (hour === 0) hour = 12;
	const ampm = dateObj.getHours() >= 12 ? "PM" : "AM";
	const mins =
		dateObj.getMinutes() < 10
			? "0" + dateObj.getMinutes()
			: dateObj.getMinutes();

	if (noTime) {
		return ret;
	}
	const time = `${hour}:${mins} ${ampm}`;
	if (noDate) {
		return time;
	}

	return `${time} on ` + ret;
};

export default parseDate;
