import React, { ComponentProps, forwardRef, memo } from "react";
import useTheme from "../../hooks/useTheme";
import { motion } from "framer-motion";
import genPackageClassName from "../../utils/genPackageClassName";

import "./padded-container.css";
import {
	GenericRef,
	ObjectValuesAsTypes,
	PolymorphicComponentPropsWithRef,
	PolymorphicRef,
} from "../../shared.types";

export const SPACING = {
	MICRO: "mc",
	TINY: "tn",
	SMALL: "sm",
	REGULAR: "rg",
	MEDIUM: "md",
	LARGE: "lg",
	JUMBO: "jb",
	MEGA: "mg",
} as const;

const DEFINED_SPACING = Object.values(SPACING);

export type PaddedContainerSpacing =
	| ObjectValuesAsTypes<typeof SPACING>
	| number // TODO: QA
	| "auto";

type NativeBasePaddedContainerProps<T extends React.ElementType> = {
	className?: string;
	hPadding?: PaddedContainerSpacing;
	vPadding?: PaddedContainerSpacing;
	tightBottom?: boolean;
	tightRight?: boolean;
	tightTop?: boolean;
	tightLeft?: boolean;
	fitContent?: boolean;
	centerContent?: boolean;
	flexContent?: boolean;
	alignContentCenter?: boolean;
	vMarginAuto?: boolean;
	hMarginAuto?: boolean;
	dummy?: boolean;
	clickable?: boolean;
	marginTop?: PaddedContainerSpacing;
	marginBottom?: PaddedContainerSpacing;
	marginLeft?: PaddedContainerSpacing;
	marginRight?: PaddedContainerSpacing;
	style?: React.CSSProperties;
	Element?: T;
};

export type BasePaddedContainerProps<T extends React.ElementType> =
	PolymorphicComponentPropsWithRef<T, NativeBasePaddedContainerProps<T>>;

type BasePaddedContainerComponent = <T extends React.ElementType = "section">(
	props: BasePaddedContainerProps<T>
) => React.ReactNode | null;

export const BasePaddedContainer: BasePaddedContainerComponent = memo(
	forwardRef(
		<T extends React.ElementType = "section">(
			{
				children,
				className = "",
				hPadding,
				vPadding,
				tightBottom = false,
				tightRight = false,
				tightTop = false,
				tightLeft = false,
				fitContent = false,
				centerContent = false,
				flexContent = false,
				alignContentCenter = false,
				vMarginAuto = false,
				hMarginAuto = false,
				dummy = false,
				clickable = false,
				marginTop,
				marginBottom,
				marginLeft,
				marginRight,
				style,
				Element,
				...rest
			}: BasePaddedContainerProps<T>,
			ref?: PolymorphicRef<T>
		) => {
			const { theme } = useTheme();

			const spaceStyleFactory = (
				prop: PaddedContainerSpacing | undefined,
				cssVariable: string
			) =>
				!prop
					? {}
					: {
							[cssVariable]:
								typeof prop === "string" &&
								DEFINED_SPACING.find(
									(spacing) => spacing === prop
								)
									? `var(--disco-spacing-${prop})`
									: typeof prop === "number"
									? `${prop}px`
									: prop,
					  };

			const genStyles = {
				...spaceStyleFactory(hPadding, "--h-padding"),
				...spaceStyleFactory(vPadding, "--v-padding"),
				...(hMarginAuto
					? { "--margin-left": "auto", "--margin-right": "auto" }
					: {}),
				...(vMarginAuto
					? { "--margin-top": "auto", "--margin-bottom": "auto" }
					: {}),
				...spaceStyleFactory(marginTop, "--margin-top"),
				...spaceStyleFactory(marginBottom, "--margin-bottom"),
				...spaceStyleFactory(marginLeft, "--margin-left"),
				...spaceStyleFactory(marginRight, "--margin-right"),
				...(style || {}),
			};

			const props = {
				className: genPackageClassName({
					theme,
					base: "padded-container",
					conditionals: {
						"padded-container-tight-bottom": tightBottom,
						"padded-container-tight-right": tightRight,
						"padded-container-tight-left": tightLeft,
						"padded-container-tight-top": tightTop,
						"padded-container-fit-content": fitContent,
						"padded-container-flex-content": flexContent,
						"padded-container-dummy": dummy,
						"padded-container-clickable": clickable,
						"padded-container-align-content-center":
							alignContentCenter,
						"flex-center": centerContent,
					},
					additional: className,
				}),
				style: genStyles,
				ref,
				...rest,
			};

			const Component = Element || "section";

			return <Component {...props}>{children}</Component>;
		}
	)
);

const MotionPaddedContainer: BasePaddedContainerComponent = motion(
	BasePaddedContainer,
	{
		forwardMotionProps: true,
	}
);

// TODO: Consider to changing to union of with & without as we migrate more components in case of error [see file end]
export type PaddedContainerProps = ComponentProps<
	typeof BasePaddedContainer
> & { motionElement?: boolean; ref?: GenericRef };

type PaddedContainerComponent = (
	props: PaddedContainerProps
) => React.ReactNode | null;

export const PaddedContainer: PaddedContainerComponent = memo(
	forwardRef(
		(
			{ motionElement = false, ...rest }: PaddedContainerProps,
			ref?: GenericRef
		) => {
			if (motionElement)
				return <MotionPaddedContainer {...rest} ref={ref} />;
			return <BasePaddedContainer {...rest} ref={ref} />;
		}
	)
);

// export type GenericRef<T extends HTMLElement> =
// 	| ((instance: T | null) => void)
// 	| React.RefObject<T>
// 	| null;

// export type PaddedContainerProps = {
// 	motionElement?: boolean;
// } & ComponentPropsWithoutRef<typeof BasePaddedContainer> &
// 	ComponentPropsWithRef<typeof BasePaddedContainer>;
