import axios from "axios";
import { BASE_URL, REFRESH_TOKEN_URL } from "../conf";
import tokenHasExpited from "./tokenHasExpired";
import tokenStorage from "./tokenStorage";

const getNewAccessToken = async (refreshToken) => {
	if (!refreshToken) {
		tokenStorage.clear();
		return Promise.reject({ logout: true });
	}

	const data = {
		refresh: refreshToken,
	};

	let resp;
	try {
		resp = await axios.post(REFRESH_TOKEN_URL, data);
	} catch (error) {
		if (error.response) {
			tokenStorage.clear();
			return Promise.reject({ logout: true });
		} else {
			return Promise.reject({ logout: false });
		}
	}

	if (resp.data.access) {
		tokenStorage.set({
			accessToken: resp.data.access
		});
		return resp.data.access;
	} else {
		tokenStorage.clear();
		return Promise.reject({ status: "logout" });
	}
};

export const getValidTokens = async () => {
	const { accessToken, refreshToken, publisher } = tokenStorage.get();
	if ((!accessToken && !refreshToken) || !publisher) {
		tokenStorage.clear();
		return Promise.reject({ logout: true });
	}

	let token = accessToken;

	if (tokenHasExpited(accessToken)) {
		try {
			token = await getNewAccessToken(refreshToken);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	return { token, publisher };
};

export const axiosRequestInterceptor = async (config) => {
	const { token, publisher } = await getValidTokens();
	config.headers.Authorization = `Bearer ${token}`;
	config.headers["X-Publisher"] = publisher;
	const {isSuper, superDjId} = tokenStorage.get();

	// If we detect that we have these two fields set in localStorage, we can
	// assume that this is currently a spoofed user, ie a superuser pretending
	// to be a normal user in some brand.
	if (isSuper && superDjId){
		// Important to keep in mind that these need to match
		// what we have in django settings! The backend is expecting
		// something specific.
		config.headers["X-Disco-a"] = isSuper;
		config.headers["X-Disco-b"] = superDjId;
	}

	return config;
};

const createApiAxios = () => {
	const apiAxios = axios.create({
		baseURL: BASE_URL,
	});

	apiAxios.interceptors.request.use(async (config) => {
		const { token, publisher } = await getValidTokens();
		config.headers.Authorization = `Bearer ${token}`;
		config.headers["X-Publisher"] = publisher;
		return config;
	});

	apiAxios.interceptors.response.use(
		async (response) => response,
		(error) => {
			// if (error.response && (error.response.status === 401 || error.response.status === 403)) {
			if (error.response && error.response.status === 401) {
				tokenStorage.clear();
			}
			return Promise.reject(error);
		}
	);

	return apiAxios;
};

export default createApiAxios;
