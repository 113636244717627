import React, {
	forwardRef,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { CgClose } from "react-icons/cg";
import { MdCheck } from "react-icons/md";
import useResource from "../../hooks/useResource";
import useTrack from "../../hooks/useTrack";
import parseError from "../../utils/parseError";
import {
	FormStatus,
	LabeledInput,
	Loader,
	QuestionTooltip,
	ReviewTooltip,
	TagButton,
} from "../CoreUI";
import "./edit-view.css";

const EditView = memo(
	forwardRef(
		(
			{
				className = "",
				type = "text",
				onEdit,
				name,
				draftTooltipName = "",
				user,
				setUser,
				userLevel = false,
				empty = false,
				validator,
				remoteKey,
				url,
				editable = true,
				draftable = false,
				tooltipTitle = "",
				tooltipText = "",
				onFocus,
				dummyToggle = false,
				onMouseEnter,
				onMouseLeave,
				onDiscard,
				list = false,
				toggle = false,
				toggleEvent,
				toggleDirect = false,
				brandColor = false,

				explicitToggleValue,
				triggerExplicitToggle,
				setTriggerExplicitToggle,

				children,
				...props
			},
			ref
		) => {
			const [payload, setPayload] = useState({
				url,
				method: "PUT",
				data: {},
			});
			const [{ loading, data, error }, save, reset] = useResource(
				payload,
				false
			);
			const track = useTrack();

			const currentValue = useMemo(() => {
				const currentValue = userLevel
					? user[remoteKey]
					: draftable &&
					  (user.draft[remoteKey] ||
							(empty && user.draft[remoteKey] === ""))
					? user.draft[remoteKey]
					: user.publisher[remoteKey] || "";
				if (!list) {
					return currentValue || currentValue === 0
						? currentValue.toString()
						: currentValue;
				}
				return currentValue
					.map((remoteItem) => remoteItem.name)
					.join(", ");
			}, [user, draftable, remoteKey, list, userLevel, empty]);
			const liveValue = useMemo(() => {
				const _value = userLevel
					? user[remoteKey]
					: user.publisher[remoteKey];
				return !list
					? _value
					: _value.map((remoteItem) => remoteItem.name).join(", ");
			}, [user, userLevel, remoteKey, list]);

			const [edited, setEdited] = useState(false);
			const [formError, setFormError] = useState(false);
			const [value, setValue] = useState(currentValue);

			const selected = useMemo(() => {
				if (!list || userLevel) {
					return null;
				}
				return (
					(draftable && user.draft && user.draft[remoteKey]) ||
					user.publisher[remoteKey]
				);
			}, [
				list,
				remoteKey,
				userLevel,
				draftable,
				user.draft,
				user.publisher,
			]);

			const toggleState = useMemo(() => {
				if (!toggle || userLevel) {
					return null;
				}
				return user.publisher[remoteKey];
			}, [toggle, userLevel, remoteKey, user.publisher]);

			const handleChange = useCallback(({ target: { value } }) => {
				setFormError("");
				setValue(value);
			}, []);

			const handleToggle = useCallback(
				(toggleVal) => {
					if (!!toggleEvent) {
						track(toggleEvent, {
							enabled: toggleVal,
						});
					}
					setPayload((payload) => ({
						...payload,
						data: {
							[remoteKey]: toggleVal,
						},
					}));
					save();
				},
				[remoteKey, save, toggleEvent, track]
			);

			useEffect(() => {
				if (
					explicitToggleValue === undefined ||
					!triggerExplicitToggle ||
					typeof setTriggerExplicitToggle !== "function"
				) {
					return;
				}
				if (triggerExplicitToggle) {
					handleToggle(explicitToggleValue);
					setTriggerExplicitToggle(false);
				}
			}, [
				triggerExplicitToggle,
				setTriggerExplicitToggle,
				handleToggle,
				explicitToggleValue,
			]);

			const handleUpdate = useCallback(
				(data) => {
					// TODO: TOGGLE => NOT DIRECT => USE TERNARY INSTEAD OF OR OPERATOR => LEADS TO UNDEFINED
					const newValue = toggle
						? toggleDirect
							? data[remoteKey]
							: remoteKey in data
							? data[remoteKey]
							: remoteKey in data?.brand
							? data?.brand?.[remoteKey]
							: data?.user?.[remoteKey]
						: data?.[remoteKey] ||
						  (empty && data?.[remoteKey] === "")
						? data?.[remoteKey]
						: data?.brand?.[remoteKey]
						? data?.brand?.[remoteKey]
						: data?.user?.[remoteKey];

					setUser((user) => {
						const newUser = { ...user };
						if (userLevel) {
							return {
								...newUser,
								[remoteKey]: newValue,
							};
						}
						if (draftable) {
							newUser.draft = {
								...newUser.draft,
								[remoteKey]: newValue,
							};
						} else {
							newUser.publisher = {
								...newUser.publisher,
								[remoteKey]: newValue,
							};
						}
						return newUser;
					});
					if (typeof onEdit === "function") {
						onEdit(newValue, data);
					}
					reset();
				},
				[
					draftable,
					userLevel,
					onEdit,
					remoteKey,
					setUser,
					reset,
					empty,
					toggle,
					toggleDirect,
				]
			);

			const handleDiscard = useCallback(() => {
				setValue(currentValue || "");
				setFormError("");
				reset();
				if (typeof onDiscard === "function") {
					onDiscard();
				}
			}, [reset, currentValue, onDiscard]);

			const handleClick = useCallback(() => {
				if (!value.trim().length && !empty) {
					return setFormError("Please enter the " + name);
				}
				const valid =
					typeof validator !== "function" ? null : validator(value);

				if (valid && !valid[0]) {
					return setFormError(valid[1]);
				}

				setPayload((payload) => ({
					...payload,
					data: { [remoteKey]: valid ? valid[1] : value },
				}));
				save();
				if (valid) {
					setValue(valid[1]);
				}
			}, [save, value, name, remoteKey, empty, validator]);

			useEffect(() => {
				if (list || toggle) {
					return;
				}
				setEdited(
					value && currentValue
						? value.trim() !== currentValue.trim()
						: currentValue || value
				);
			}, [value, user.publisher, remoteKey, currentValue, list, toggle]);

			useEffect(() => {
				if (!data) {
					return;
				}
				handleUpdate(data);
			}, [
				data,
				setUser,
				onEdit,
				remoteKey,
				reset,
				draftable,
				handleUpdate,
			]);

			const handleFocus = useCallback(() => {
				if (typeof onFocus === "function") {
					onFocus(remoteKey);
				}
			}, [onFocus, remoteKey]);

			const handleMouseEnter = useCallback(() => {
				if (typeof onMouseEnter === "function") {
					onMouseEnter(remoteKey);
				}
			}, [onMouseEnter, remoteKey]);

			const labelTooltip = useMemo(
				() =>
					tooltipTitle !== "" ? (
						<QuestionTooltip
							className="edit-view-label-tooltip-icon"
							tooltipClassName="edit-view-label-tooltip"
							heading={tooltipTitle}
						>
							{tooltipText}
						</QuestionTooltip>
					) : (
						<></>
					),
				[tooltipText, tooltipTitle]
			);

			const inDraft = useMemo(() => {
				return (
					draftable &&
					(((liveValue || (list && liveValue === "")) &&
						(currentValue || (empty && currentValue === ""))) ||
						(!liveValue && currentValue)) &&
					liveValue !== currentValue
				);
			}, [draftable, liveValue, list, currentValue, empty]);

			const finalLabel = useMemo(
				() =>
					inDraft ? (
						<>
							{name}
							{labelTooltip}
							<ReviewTooltip
								withSecondaryTooltip={tooltipTitle !== ""}
								draftTooltipName={draftTooltipName}
								name={name}
								list={list}
							/>
						</>
					) : (
						<>
							{name}
							{labelTooltip}
						</>
					),
				[
					name,
					labelTooltip,
					tooltipTitle,
					list,
					inDraft,
					draftTooltipName,
				]
			);

			return (
				<div
					className={`edit-view ${
						error || formError ? "edit-view-error" : ""
					}  ${className}`}
					onClick={handleFocus}
					tabIndex={1}
					onFocus={handleFocus}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={onMouseLeave}
					ref={ref}
				>
					<LabeledInput
						type={type}
						value={value || ""}
						onChange={handleChange}
						disabled={loading || !editable}
						label={finalLabel}
						list={list}
						selected={selected}
						toggle={toggle}
						dummyToggle={dummyToggle}
						toggleState={toggleState}
						handleToggle={handleToggle}
						onSave={handleUpdate}
						name={name}
						remoteKey={remoteKey}
						empty={empty}
						inDraft={inDraft}
						loading={loading}
						{...props}
					>
						{!list && edited && (
							<section className="edit-view-action">
								{loading && <Loader small />}
								<TagButton
									disabled={
										loading ||
										(value.trim().length === 0 && !empty)
									}
									onClick={handleClick}
									icon={<MdCheck />}
									green
								/>
								<TagButton
									disabled={loading}
									onClick={handleDiscard}
									icon={<CgClose />}
									className="edit-view-discard"
									red
								/>
							</section>
						)}
						{children && children(value, setValue)}
					</LabeledInput>
					<section className="edit-view-status">
						{!list && (formError || error) && (
							<FormStatus>
								{formError || parseError(error)}
							</FormStatus>
						)}
					</section>
				</div>
			);
		}
	)
);

export default EditView;
