const transition = {
	type: "spring",
	stiffness: 2000,
	mass: 3,
	damping: 100,
};

const tooltipTransition = {
	type: "spring",
	stiffness: 1200,
	mass: 1,
	damping: 40,
};

const subtleTransition = {
	type: "spring",
	stiffness: 300,
	mass: 2.6,
	damping: 40,
};

const boomTransition = {
	type: "spring",
	stiffness: 800,
	mass: 7,
	damping: 75,
};

const pageTransition = {
	type: "spring",
	stiffness: 2000,
	mass: 5,
	damping: 140,
};

const slideTransition = {
	type: "spring",
	stiffness: 2000,
	mass: 4,
	damping: 140,
};

const easeInTransitionFactory = (time = 0.3) => ({
	duration: time,
	ease: [0, 0.88, 0.48, 1],
});

const anim = {
	surveyOption: {
		initial: {
			scale: 0.86,
			y: -40,
			transition: {
				type: "spring",
				stiffness: 2000,
				mass: 4,
				damping: 100,
			},
		},
		animate: {
			scale: 1,
			y: 0,
			transition: {
				type: "spring",
				stiffness: 2000,
				mass: 4,
				damping: 100,
			},
		},
		exit: {
			scale: 0.85,
			y: 40,
			transition: {
				type: "spring",
				stiffness: 1800,
				mass: 4,
				damping: 100,
			},
		},
	},
	select: {
		initial: {
			y: -40,
			scale: 0.9,
			height: "0px",
			opacity: 0,
		},
		animate: {
			y: 0,
			scale: 1,
			height: "auto",
			opacity: 1,
			transition: {
				...easeInTransitionFactory(0.3),
				staggerChildren: 0.07,
			},
		},

		exit: {
			y: -40,
			scale: 0.9,
			height: "0px",
			opacity: 0,
			transition: {
				...easeInTransitionFactory(0.3),
				staggerChildren: 0.07,
				delay: 0.21,
			},
		},
	},
	rowItem: {
		initial: {
			y: -10,
			opacity: 0,
			scale: 0.9,
			transition: { staggerChildren: 0.06, ...boomTransition },
		},
		animate: {
			y: 0,
			scale: 1,
			opacity: 1,
			transition: { staggerChildren: 0.06, ...boomTransition },
		},
		exit: {
			y: 10,
			opacity: 0,
			scale: 0.9,
			transition: { staggerChildren: 0.06, ...boomTransition },
		},
	},
	riseFromBottom: {
		initial: {
			y: -10,
			opacity: 0,
			scale: 0.9,
		},
		animate: {
			y: 0,
			scale: 1,
			opacity: 1,
			transition: {
				staggerChildren: 0.06,
				staggerDirection: -1,
				...boomTransition,
			},
		},
		exit: {
			y: 10,
			opacity: 0,
			scale: 0.9,
		},
	},

	staggeredFade: {
		initial: {
			y: -20,
			opacity: 0,
			transition: subtleTransition,
		},
		animate: {
			y: 0,
			scale: 1,
			opacity: 1,
			transition: subtleTransition,
		},
		exit: {
			y: 20,
			opacity: 0,
			transition: subtleTransition,
		},
	},

	table: {
		initial: {
			y: 50,
			scale: 0.9,
			opacity: 0,
			transition: { staggerChildren: 0.125, ...subtleTransition },
		},
		animate: {
			y: 0,
			scale: 1,
			opacity: 1,
			transition: { staggerChildren: 0.125, ...subtleTransition },
		},
		exit: {
			y: -50,
			opacity: 0,
			scale: 0.9,
			transition: { staggerChildren: 0.125, ...subtleTransition },
		},
	},

	boom: {
		initial: {
			y: -60,
			scale: 0.4,
			opacity: 0,
			transition: boomTransition,
		},
		animate: {
			y: 0,
			scale: 1,
			opacity: 1,
			transition: boomTransition,
		},
	},

	selectOption: {
		initial: {
			y: -10,
			scale: 0,
			opacity: 0,
		},
		animate: {
			y: 0,
			scale: 1,
			opacity: 1,
		},
		exit: {
			y: -10,
			scale: 0,
			opacity: 0,
		},
	},
	page: {
		initial: {
			y: -40,
			scale: 0.85,
			transition: easeInTransitionFactory(0.4),
		},
		animate: {
			y: 0,
			scale: 1,
			transition: easeInTransitionFactory(0.4),
		},
		exit: {
			y: 40,
			scale: 0.85,
			transition: easeInTransitionFactory(0.4),
		},
	},

	pageContent: {
		initial: {
			scale: 0.9,
			opacity: 0.6,
			y: 90,
			transition: easeInTransitionFactory(0.5),
		},
		animate: {
			scale: 1,
			opacity: 1,
			y: 0,
			transition: easeInTransitionFactory(0.5),
		},
		exit: {
			scale: 0.9,
			opacity: 0.6,
			y: -90,
			transition: easeInTransitionFactory(0.5),
		},
	},

	simplePageContent: {
		initial: {
			scale: 0.95,
			opacity: 0.4,
			y: 70,
			transition: { duration: 0.3, ease: [0, 0.88, 0.48, 1] },
		},
		animate: {
			scale: 1,
			opacity: 1,
			y: 0,
			transition: { duration: 0.3, ease: [0, 0.88, 0.48, 1] },
		},
		exit: {
			scale: 0.95,
			opacity: 0.4,
			y: 70,
			transition: { duration: 0.3, ease: [0, 0.88, 0.48, 1] },
		},
	},

	centralBackground: {
		initial: {
			opacity: 0.4,
			scale: 0.85,
			transition: { duration: 0.3, ease: "easeIn" },
		},
		animate: {
			opacity: 1,
			scale: 1,
			transition: { duration: 0.3, ease: "easeIn" },
		},
		exit: {
			opacity: 0.4,
			scale: 0.85,
			transition: { duration: 0.3, ease: "easeIn" },
		},
	},

	centralHero: {
		initial: {
			scale: 1.15,
			transition: { duration: 0.3, ease: "easeIn" },
		},
		animate: {
			scale: 1,
			transition: { duration: 0.3, ease: "easeIn" },
		},
		exit: {
			scale: 1.15,
			transition: { duration: 0.3, ease: "easeIn" },
		},
	},

	widgetModule: {
		initial: {
			opacity: 0.2,
			x: 100,
			transition: pageTransition,
		},
		animate: {
			scale: 1,
			opacity: 1,
			x: 0,
			transition: pageTransition,
		},
		exit: {
			opacity: 0.2,
			x: -100,
			transition: pageTransition,
		},
	},

	widgetModuleItem: {
		initial: {
			opacity: 0,
			y: 100,
			transition: pageTransition,
		},
		animate: {
			scale: 1,
			opacity: 1,
			y: 0,
			transition: pageTransition,
		},
		exit: {
			opacity: 0,
			y: -100,
			transition: pageTransition,
		},
	},

	builder: {
		initial: {
			scale: 0.78,
			y: -80,
			transition,
		},
		animate: {
			scale: 1,
			y: 0,
			transition,
		},
		exit: {
			scale: 0.78,
			y: 80,
			transition,
		},
	},

	productOverlay: {
		initial: {
			scale: 0.6,
			opacity: 0,
			tooltipTransition,
		},
		animate: {
			scale: 1,
			opacity: 1,
			tooltipTransition,
		},
		exit: {
			scale: 0.6,
			opacity: 0,
			tooltipTransition,
		},
	},
	tooltip: {
		initial: {
			scale: 0.6,
			opacity: 0,
			y: -30,
			transition: tooltipTransition,
		},
		animate: {
			scale: 1,
			y: 0,
			opacity: 1,
			transition: tooltipTransition,
		},
		exit: {
			scale: 0.6,
			opacity: 0,
			y: -30,
			transition: tooltipTransition,
		},
	},
	preview: {
		initial: {
			opacity: 0,
			y: 30,
			transition: tooltipTransition,
		},
		animate: {
			y: 0,
			opacity: 1,
			transition: tooltipTransition,
		},
		exit: {
			opacity: 0,
			y: 30,
			transition: tooltipTransition,
		},
	},
	dialogOverlay: {
		initial: {
			opacity: 0,
		},
		animate: {
			opacity: 1,
		},
		exit: {
			opacity: 0,
		},
	},
	dialog: {
		initial: {
			scale: 0.7,
			y: -70,
			opacity: 0,
			transition,
		},
		animate: {
			scale: 1,
			y: 0,
			opacity: 1,
			transition,
		},
		exit: {
			scale: 0.7,
			y: 70,
			opacity: 0,
			transition,
		},
	},
	sheet: {
		initial: {
			scale: 1,
			y: "100%",
			opacity: 0,
			transition,
		},
		animate: {
			scale: 1,
			y: 0,
			opacity: 1,
			transition,
		},
		exit: {
			scale: 1,
			y: "100%",
			opacity: 0,
			transition,
		},
	},
	simpleFade: {
		initial: {
			y: 10,
			opacity: 0,
			transition: { ease: "easeOut", duration: 0.16 },
		},
		animate: {
			y: 0,
			opacity: 1,
			transition: { ease: "easeOut", duration: 0.16 },
		},
		exit: {
			y: 10,
			opacity: 0,
			transition: { ease: "easeIn", duration: 0.16 },
		},
	},

	sidebar: {
		initial: {
			scale: 1,
			x: 220,
			opacity: 0,
			transition,
		},
		animate: {
			scale: 1,
			x: 0,
			opacity: 1,
			transition,
		},
		exit: {
			x: 220,
			opacity: 0,
			transition,
		},
	},

	setupCard: {
		initial: {
			opacity: 0.4,
			x: 200,
			transition: slideTransition,
		},
		animate: {
			x: 0,
			opacity: 1,
			transition: slideTransition,
		},
		exit: {
			opacity: 0.4,
			x: -200,
			transition: slideTransition,
		},
	},
	tooltipPreview: {
		initial: {
			scale: 1,
			opacity: 0,
			y: -30,
			transition: tooltipTransition,
		},
		animate: {
			scale: 1,
			y: 0,
			opacity: 0.98,
			transition: tooltipTransition,
		},
		exit: {
			scale: 1,
			opacity: 0,
			y: -30,
			transition: tooltipTransition,
		},
	},
	circleOption: {
		initial: {
			scale: 0,
			opacity: 0,
		},
		animate: {
			scale: 1,
			opacity: 1,
			transition,
		},
		exit: {
			scale: 0,
			opacity: 0,
		},
	},
	checklist: {
		initial: {
			y: 100,
			scale: 0,
			opacity: 0,
		},
		animate: {
			y: 0,
			scale: [1, 1.25, 1],
			opacity: 1,
			transition: {
				delay: 1.4,
				ease: [1.0, -0.085, 0.6, 1.275],
				type: "linear",
				duration: 0.32,
			},
		},
	},
	horizontalSlide: {
		initial: {
			opacity: 0.4,
			x: 20,
			transition: slideTransition,
		},
		animate: {
			x: 0,
			opacity: 1,
			transition: slideTransition,
		},
		exit: {
			opacity: 0.4,
			x: -20,
			transition: slideTransition,
		},
	},
	modalOverlay: {
		initial: {
			opacity: 0,
			transition: {
				delayChildren: 0.15,
				staggerChildren: 0.04,
			},
		},

		animate: {
			opacity: 1,
			scale: 1,
			transition: {
				duration: 0.1,
				delayChildren: 0.15,
				staggerChildren: 0.04,
			},
		},

		exit: {
			opacity: 0,
			transition: {
				delay: 0.1,
				staggerChildren: 0.06,
			},
		},
	},
	modalOverlayItem: {
		initial: {
			opacity: 0,
			x: 20,
		},
		animate: {
			opacity: 1,
			x: 0,
		},
		exit: {
			opacity: 0,
			x: 10,
		},
	},
	verticalSlide: {
		initial: {
			scale: 1,
			opacity: 0,
			y: -30,
			transition: tooltipTransition,
		},
		animate: {
			scale: 1,
			y: 0,
			opacity: 1,
			transition: tooltipTransition,
		},
		exit: {
			scale: 1,
			opacity: 0,
			y: -30,
			transition: tooltipTransition,
		},
	},

	variantFactory: ({
		x = 0,
		y = 0,
		opacity = 1,
		delay = 0,
		transitionProps = {},
	}) => ({
		initial: {
			x,
			opacity,
			y,
		},
		animate: {
			y: 0,
			x: 0,
			opacity: 1,
			transition: { ...slideTransition, delay, ...transitionProps },
		},
		exit: {
			x,
			y,
		},
	}),
};

export default anim;
