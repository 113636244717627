import { logoutZendesk } from "./zendesk";

const tokenStorage = {
	ACCESS_TOKEN_KEY: "accessToken",
	REFRESH_TOKEN_KEY: "refreshToken",
	PUBLISHER_KEY: "publisher",
	IS_SUPER_KEY: "isSuper",
	SUPER_DJ_ID_KEY: "superDjId",

	get() {
		const res = {};
		res.accessToken = localStorage.getItem(this.ACCESS_TOKEN_KEY);
		res.refreshToken = localStorage.getItem(this.REFRESH_TOKEN_KEY);
		res.publisher = localStorage.getItem(this.PUBLISHER_KEY);
		res.isSuper = localStorage.getItem(this.IS_SUPER_KEY) === "true";
		res.superDjId = localStorage.getItem(this.SUPER_DJ_ID_KEY);

		return res;
	},

	set({
		accessToken = "",
		refreshToken = "",
		publisher = "",
		isSuper = false,
		superDjId = ""
	} = {}) {
		if (accessToken !== "" && typeof accessToken === "string") {
			localStorage.setItem(this.ACCESS_TOKEN_KEY, accessToken);
		}
		if (refreshToken !== "" && typeof refreshToken === "string") {
			localStorage.setItem(this.REFRESH_TOKEN_KEY, refreshToken);
		}
		if (publisher !== "" && typeof publisher === "string") {
			localStorage.setItem(this.PUBLISHER_KEY, publisher);
		}
		if (!localStorage.getItem(this.IS_SUPER_KEY)) {
			localStorage.setItem(this.IS_SUPER_KEY, isSuper ? "true" : "false");
		}
		if (superDjId !== ""){
			localStorage.setItem(this.SUPER_DJ_ID_KEY, superDjId);
		}

	},
	clear() {
		logoutZendesk();
		localStorage.clear();
		localStorage.setItem("isExisting", "true");
	},
};

export default tokenStorage;
