import React, { memo, useCallback, useEffect, useState, useMemo } from "react";

import {
	DEFAULT_PRODUCT_IMG,
	PRODUCTS_ADD_URL,
	SAVE_PRODUCT_URL,
} from "../../conf";
import useModal from "../../hooks/useModal";
import useResource from "../../hooks/useResource";
import isShopifyShop from "../../utils/isShopifyShop";
import parseError from "../../utils/parseError";
import { Collapsable } from "../CoreUI";
import {
	Button,
	Loader,
	PaddedContainer,
	Text,
	ToggleInput,
	LabeledInput,
	StatusLabel,
	SPACING,
	STATUS,
	FONT_SIZE,
	INPUT_TYPE,
	FONT_COLOR,
} from "@disco/disco_core";

import ImagePicker from "../ImagePicker";
import VariantsModal, { VariantsTable } from "../modals/VariantsModal";
import FloatingModal, { SIDEBAR } from "./FloatingModal";
import Confirmation from "../modals/Confirmation";

import "./product-sidebar-modal.css";

// Providing considerations for non-Shopify brand products
const nonShopifySources = ["BigCommerce", "WooCommerce"];

const baseProduct = {
	name: "",
	photo_url: DEFAULT_PRODUCT_IMG,
	price: "",
	discounted_price: "",
	active: false,
	upsell: false,
	landing_page: "",
};

const parsePrice = (price) => {
	if (isNaN(price) || price === "" || price === false) {
		return null;
	}
	const priceNum = Number(price);
	return priceNum.toFixed(2);
};

const getDiscPercent = (product) => {
	const disc = parsePrice(product.discounted_price);
	const price = parsePrice(product.price);

	if (!price || !disc || price < disc) {
		return 0;
	} else if (
		(nonShopifySources.includes(product.source) && disc === null) ||
		disc === "0.00"
	) {
		// Account for non-Shopify products and their methods for discounting
		return 0;
	}

	return (((price - disc) / price) * 100).toFixed(2);
};

const getDiscPrice = (product, percent) => {
	let disc = parsePrice(product.discounted_price);
	let price = parsePrice(product.price);

	// Account for non-Shopify products and their methods for discounting
	if (nonShopifySources.includes(product.source) && percent === 0) {
		return [price, price];
	}

	if (isNaN(percent)) {
		return [disc, price];
	}

	if (!price && !disc) {
		return [0, 0];
	}

	price = Number(price);
	disc = Number(disc);

	if (!price || price < disc) {
		return [disc, ((100 - Number(percent)) * disc) / 100];
	}

	return [price, ((100 - Number(percent)) * price) / 100];
};

const getRealtimePrice = (product, newPrice) => {
	const discPercent = isNaN(product.discountPercent)
		? 0
		: Number(product.discountPercent);

	if (isNaN(newPrice)) {
		return [0, 0];
	}

	return [
		newPrice === "" ? "" : Number(newPrice),
		newPrice === "" ? "" : ((100 - discPercent) * Number(newPrice)) / 100,
	];
};

const productFinder = (products, remoteId) => {
	const product = remoteId
		? products.find((product) => product.remote_id === remoteId)
		: baseProduct;

	const discountPercent = getDiscPercent(product);
	const [price] = getDiscPrice(product, discountPercent);

	return {
		...product,
		photo_url: product.photo_url || DEFAULT_PRODUCT_IMG,
		discountPercent,
		price,
		originalPrice: product.price,
		originalDiscountedPrice: product.discounted_price,
		discounted_price: product.discounted_price,
		supplementary_images: Array.isArray(product.supplementary_images)
			? product.supplementary_images
					.filter((img) => img?.photo_url !== null)
					.sort((left, right) => left.position - right.position)
			: product.supplementary_images,
	};
};

const ProductSidebarModal = memo(
	({
		products,
		setProducts,
		onClose,
		remoteId,
		user,
		setRefresh,
		...rest
	}) => {
		const SHOW_MORE_IMAGES_THRESHOLD = 7;

		const [product, setProduct] = useState(() =>
			productFinder(products, remoteId)
		);

		const [originalProduct, setOriginalProduct] = useState(() =>
			productFinder(products, remoteId)
		);

		const [saveData, setSaveData] = useState({});

		const [{ loading, data, error }, save, reset] = useResource(
			{
				url: !remoteId ? PRODUCTS_ADD_URL : SAVE_PRODUCT_URL(product),
				method: !remoteId ? "POST" : "PUT",
				data: saveData,
			},
			false
		);

		const [formErrors, setFormErrors] = useState({});
		const isShopify = !!product.shopify_product_id && isShopifyShop(user);

		const {
			open: variantsEditOpen,
			handleOpen: handleVariantsEditOpen,
			handleClose: handleVariantsEditClose,
		} = useModal();

		const [variantsOpen, setVariantsOpen] = useState(false);

		const {
			open: closeConfirmationOpen,
			handleClose: handleCloseConfirmationClose,
			handleOpen: handleCloseConfirmationOpen,
		} = useModal();

		useEffect(() => {
			setProduct(productFinder(products, remoteId));
			setOriginalProduct(productFinder(products, remoteId));
			setFormErrors({});
			reset();
		}, [products, remoteId, reset]);

		const handleVariantsDone = useCallback((data) => {
			setProduct((product) => ({ ...product, variants: data.variants }));
		}, []);

		const handleChange = ({ target: { name, value } }) => {
			if (name === "discountPercent") {
				const [, discounted_price] = getDiscPrice(product, value);
				setProduct((product) => ({
					...product,
					[name]: value,
					discounted_price,
				}));
				return;
			}
			if (name === "price") {
				const [price, discounted_price] = getRealtimePrice(
					product,
					value
				);
				setProduct((product) => ({
					...product,
					price,
					discounted_price,
				}));
				return;
			}

			setProduct((product) => ({ ...product, [name]: value }));
		};

		const clearData = useCallback(() => {
			setProduct(() => ({
				...originalProduct,
			}));

			onClose();
		}, [onClose, setProduct, originalProduct]);

		const handleCancel = useCallback(() => {
			clearData();
		}, [clearData]);

		const handleClose = useCallback(() => {
			if (JSON.stringify(product) !== JSON.stringify(originalProduct)) {
				handleCloseConfirmationOpen();
				return;
			}

			clearData();
		}, [handleCloseConfirmationOpen, originalProduct, product, clearData]);

		const handleSave = useCallback(() => {
			let errors = {};

			if (isNaN(product.price)) {
				errors.price = "Please enter a price between 0 and 100";
			}

			if (isNaN(product.discountPercent)) {
				errors.discountPercent =
					"Please enter a discount percent between 0 and 100";
			}

			if (!product.name?.trim()?.length) {
				errors.name = "Please enter a product name";
			}

			if (!product.landing_page?.trim()?.length) {
				errors.landing_page = "Please enter a product landing page";
			}

			if (
				errors?.price ||
				errors?.discountPercent ||
				errors?.name ||
				errors?.landing_page
			) {
				setFormErrors(errors);

				return;
			}

			const saveData = Object.entries(product).reduce(
				(acc, [key, value]) => {
					if (key === "price" || key === "discounted_price") {
						if (!product.shopify_product_id) {
							acc[key] = Number(value).toFixed(2);
						}
					} else if (key === "supplementary_images") {
						acc[key] = Array.isArray(value)
							? value.map((image, index) => ({
									...image,
									position: index,
							  }))
							: value;
					} else acc[key] = value;
					return acc;
				},
				{}
			);

			setFormErrors({});

			setSaveData(saveData);
			save();
		}, [product, save]);

		const handleConfirmation = useCallback(() => {
			handleSave();
			handleCloseConfirmationClose();
		}, [handleSave, handleCloseConfirmationClose]);

		const handleConfirmationCancel = useCallback(() => {
			handleCloseConfirmationClose();
			handleCancel();
		}, [handleCloseConfirmationClose, handleCancel]);

		useEffect(() => {
			if (!data) {
				return;
			}

			if (!remoteId) {
				setProducts((products) => [...products, data]);
			} else {
				setProducts((products) => {
					const idx = products.findIndex(
						(product) => product.remote_id === remoteId
					);
					if (idx === -1) {
						return products;
					}
					return [
						...products.slice(0, idx),
						{
							...data,
							supplementary_images: product.supplementary_images,
						},
						...products.slice(idx + 1),
					];
				});
			}
			if (typeof setRefresh === "function") setRefresh(true);
			reset();
			onClose();
		}, [
			data,
			reset,
			setProducts,
			onClose,
			remoteId,
			setRefresh,
			product.supplementary_images,
		]);

		const handleSupplementarySelect = useCallback((index) => {
			setProduct((product) => ({
				...product,
				photo_url: product.supplementary_images[index].photo_url,
			}));
		}, []);

		const setImages = (images) => {
			if (images.length == 0) {
				product.photo_url = DEFAULT_PRODUCT_IMG;
			}

			setProduct((product) => ({
				...product,
				supplementary_images: images,
			}));
		};

		const labelProps = useMemo(
			() => ({
				size: FONT_SIZE.BODY,
				color: FONT_COLOR.MID,
				marginTop: SPACING.REGULAR,
			}),
			[]
		);

		const imageInputBodyText = [
			{
				label: "For best results, attach a square image:",
				text: "2048x2048",
			},
			{
				label: "Supported formats:",
				text: "PNG, JPEG/JPG & GIF",
			},
			{
				label: "Max file size:",
				text: "15MB",
			},
		];

		const renderImageInputBody = useMemo(
			() => (
				<>
					<Text
						color={FONT_COLOR.MID}
						size={FONT_SIZE.BODY}
						marginTop={SPACING.REGULAR}
						marginBottom={SPACING.MEDIUM}
					>
						<Text thin marginBottom={SPACING.TINY}>
							General Requirements
						</Text>

						{imageInputBodyText.map((props) => {
							{
								return (
									<PaddedContainer flexContent>
										<Text thin marginRight={SPACING.MICRO}>
											{props.label}
										</Text>
										<Text thick> {props.text}</Text>
									</PaddedContainer>
								);
							}
						})}
						<Text
							marginTop={SPACING.REGULAR}
							marginBottom={SPACING.TINY}
							thin
						>
							Imagery Recommendations
						</Text>
						<Text thin>
							Product images that blend lifestyle photography with
							vibrant backgrounds tend to generate the highest
							engagement
						</Text>
					</Text>
				</>
			),
			[]
		);

		const renderActiveImage = useMemo(
			() =>
				product.photo_url && (
					<img
						key={product.photo_url}
						src={product.photo_url}
						data-testid="product-sidebar-modal-image"
						alt=""
					/>
				),
			[product.photo_url]
		);

		const disableAdd = useMemo(
			() =>
				(!remoteId || product.draft) &&
				product.supplementary_images?.length > 0,
			[remoteId, product.draft, product.supplementary_images]
		);

		const Inputs = [
			{
				label: "PRICE",
				type: "number",
				placeholder: "Price",
				value: product.price,
				name: "price",
				unit: "$",
				onChange: handleChange,
				inputType: INPUT_TYPE.TEXT,
				show: !isShopify && !remoteId,
				labelProps: labelProps,
			},
			{
				label: "DISCOUNT PERCENT",
				type: "number",
				placeholder: "Discount Percent",
				value: product.discountPercent,
				unit: "%",
				name: "discountPercent",
				onChange: handleChange,
				inputType: INPUT_TYPE.TEXT,
				show: !isShopify && !remoteId,
				labelProps: labelProps,
			},
			{
				label: "PRODUCT LANDING PAGE URL",
				value: product.landing_page,
				name: "landing_page",
				placeholder: "Product Landing Page",
				onChange: handleChange,
				inputType: INPUT_TYPE.TEXT,
				show: true,
				labelProps: labelProps,
			},
		];

		return (
			<>
				<Confirmation
					open={closeConfirmationOpen}
					onClose={handleCloseConfirmationClose}
					onCancel={handleConfirmationCancel}
					onConfirmation={handleConfirmation}
					title={"Save changes before leaving"}
					cancelButtonText="Leave Without Saving"
					confirmationButtonText="Save Changes"
				>
					<Text
						size={FONT_SIZE.SUB_TITLE}
						color={FONT_COLOR.MID}
						marginTop={SPACING.SMALL}
						marginBottom={SPACING.REGULAR}
					>
						All unsaved changes will be lost. Are you sure you want
						to leave this page?
					</Text>
				</Confirmation>
				<FloatingModal
					{...rest}
					designSystem
					onClose={handleClose}
					position={SIDEBAR}
					className="product-sidebar-modal"
					heading={remoteId ? "Edit Your Product" : "Add a Product"}
					fixedHeader
					data-testid="product-sidebar-modal"
					closer={
						<PaddedContainer
							hPadding={SPACING.REGULAR}
							flexContent
							className="product-sidebar-modal-btn-group"
						>
							{loading ? (
								<Loader
									marginTop={SPACING.TINY}
									marginBottom={SPACING.TINY}
								/>
							) : (
								<PaddedContainer
									flexContent
									vPadding={SPACING.TINY}
								>
									<Button
										onClick={handleCancel}
										hPadding={SPACING.REGULAR}
										vPadding={SPACING.TINY}
										marginRight={SPACING.TINY}
										secondary
									>
										<Text
											size={FONT_SIZE.LABEL}
											color={FONT_COLOR.MID}
										>
											Cancel
										</Text>
									</Button>
									<Button
										onClick={handleSave}
										hPadding={SPACING.REGULAR}
										vPadding={SPACING.TINY}
									>
										<Text size={FONT_SIZE.LABEL}>Save</Text>
									</Button>
								</PaddedContainer>
							)}
						</PaddedContainer>
					}
				>
					<PaddedContainer
						className="product-sidebar-editable"
						marginLeft={SPACING.REGULAR}
						marginRight={SPACING.REGULAR}
						marginTop={SPACING.MEDIUM}
						marginBottom={SPACING.MEGA}
					>
						<Text
							size={FONT_SIZE.LG_BODY}
							color={FONT_COLOR.MID}
							marginBottom={SPACING.SMALL}
						>
							Changes to product details will only affect how
							products are displayed on Disco.
						</Text>
						<PaddedContainer
							className="product-sidebar-hero"
							flexContent
							alignContentCenter
						>
							<PaddedContainer className="product-sidebar-details">
								<LabeledInput
									placeholder="Product Name"
									name="name"
									label="PRODUCT TITLE"
									onChange={handleChange}
									value={product.name}
									inputType={INPUT_TYPE.TEXT}
									maxLength={40}
									softMaxLength={true}
									data-testid="product-sidebar-modal-name"
									labelProps={labelProps}
								/>
								{product.name.length > 40 && (
									<StatusLabel
										type={STATUS.WARNING}
										marginTop={SPACING.TINY}
										noBorder
										noShadow
									>
										Product names over 40 characters will be
										shortened
									</StatusLabel>
								)}
								{!!formErrors?.name && (
									<StatusLabel
										type={STATUS.ERROR}
										marginTop={SPACING.TINY}
										noBorder
										noShadow
									>
										{formErrors?.name}
									</StatusLabel>
								)}
							</PaddedContainer>
						</PaddedContainer>

						<Text marginBottom={SPACING.SMALL} {...labelProps}>
							PRODUCT IMAGE
						</Text>
						<PaddedContainer
							flexContent
							marginBottom={SPACING.MEDIUM}
						>
							<PaddedContainer className="product-sidebar-image-picker">
								<ImagePicker
									activeImage={renderActiveImage}
									endingAddButton
									secondaryAddButton
									large
									showMoreImagesThreshold={
										SHOW_MORE_IMAGES_THRESHOLD
									}
									images={product.supplementary_images}
									setImages={setImages}
									onSelect={handleSupplementarySelect}
									active={product.photo_url}
									disableAdd={disableAdd}
									user={user}
									modalProps={{
										children: renderImageInputBody,
									}}
									autoSelect
								/>
							</PaddedContainer>
						</PaddedContainer>

						{disableAdd && ( //!remoteId  && product.supplementary_images?.length > 0
							<StatusLabel
								type={STATUS.INFO}
								marginTop={SPACING.REGULAR}
								marginBottom={SPACING.MEDIUM}
								noBorder
								noShadow
								childrenProps={{
									thin: true,
								}}
							>
								This product's changes need approval before
								adding more images
							</StatusLabel>
						)}

						<Text {...labelProps}>CROSS-SELL THIS PRODUCT</Text>
						<ToggleInput
							className="product-sidebar-toggle"
							toggle={product.active}
							handleToggle={(value) =>
								handleChange({
									target: { name: "active", value },
								})
							}
						/>
						<Text {...labelProps}>UPSELL THIS PRODUCT</Text>
						<ToggleInput
							className="product-sidebar-toggle"
							toggle={product.upsell}
							handleToggle={(value) =>
								handleChange({
									target: { name: "upsell", value },
								})
							}
						/>

						{Inputs.map((props) => {
							return (
								props.show && (
									<LabeledInput key={props.name} {...props}>
										{formErrors[props.name] && (
											<StatusLabel
												type={STATUS.ERROR}
												marginTop={SPACING.TINY}
												noBorder
												noShadow
											>
												{formErrors[props.name]}
											</StatusLabel>
										)}
									</LabeledInput>
								)
							);
						})}

						{!formErrors?.landing_page && !error && (
							<StatusLabel
								type={STATUS.WARNING}
								marginTop={SPACING.REGULAR}
								marginBottom={SPACING.MEDIUM}
								noBorder
								noShadow
								childrenProps={{
									thin: true,
								}}
							>
								Ensure the updated product landing page URL is
								valid
							</StatusLabel>
						)}

						{error && (
							<StatusLabel
								type={STATUS.ERROR}
								marginTop={SPACING.REGULAR}
								vPadding={SPACING.MICRO}
								hPadding={SPACING.MICRO}
								noBorder
								noShadow
								childrenProps={{
									thin: true,
								}}
							>
								{parseError(error)}
							</StatusLabel>
						)}

						{isShopify && product.variants.length > 1 && (
							<Collapsable
								open={variantsOpen}
								setOpen={setVariantsOpen}
								heading="View Variants"
								leftOpen
							>
								<Button
									className="product-sidebar-edit-variants-btn"
									onClick={handleVariantsEditOpen}
									rounded={false}
									centerContent
									marginTop={SPACING.REGULAR}
									secondary
								>
									<Text
										size={FONT_SIZE.LABEL}
										color={FONT_COLOR.DEFAULT}
									>
										Edit Variants
									</Text>
								</Button>

								<VariantsTable user={user} product={product} />
							</Collapsable>
						)}
					</PaddedContainer>
					<div className="product-sidebar-fade" />
				</FloatingModal>
				<VariantsModal
					product={product}
					onDone={handleVariantsDone}
					open={variantsEditOpen}
					onClose={handleVariantsEditClose}
					setProducts={setProducts}
					setRefresh={setRefresh}
					user={user}
				/>
			</>
		);
	}
);

export default ProductSidebarModal;
