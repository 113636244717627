import React, { memo, useCallback } from "react";
import MobileOnboardingView from "../centralViews/MobileOnboardingView";
import OnboardingStep from "./OnboardingStep";

const MobileOnboardingStep = memo(() => {
	const customRenderer = useCallback((props) => {
		return <MobileOnboardingView {...props} />;
	}, []);

	return (
		<>
			<OnboardingStep
				showBack
				customRenderer={customRenderer}
			></OnboardingStep>
		</>
	);
});

export default MobileOnboardingStep;
