import React, { useState } from "react";
import { BadgeButton } from "../CoreUI";
import { Link } from "react-router-dom";
import "./page-boundary.css";
import Modal from "../modals/Modal";
import logoWhite from "../../img/logo-new-white.png";

const PageBoundary = React.memo(
	({ login = false, superLogin = false, children }) => {
		const [openModal, setModal] = useState(false);
		const onClose = () => {
			setModal(false);
		};
		return (
			<div className="page-boundary">
				<h1>
					Welcome to <img src={logoWhite} alt="Disco Logo" />
				</h1>

				{children}

				<section className="page-boundary-extra">
					{!login && !superLogin && (
						<>
							{login && <h3>New Here? Sign up today</h3>}
							{!login && (
								<h3>Already have an account? Login now</h3>
							)}
						</>
					)}
					{superLogin && <h3>Super login ( Shhh! )</h3>}
					{!login && (
						<Link to={login ? "./register" : "./login"}>
							<BadgeButton light className="page-boundary-action">
								{login
									? "Register"
									: superLogin
									? "Normal Login"
									: "Login"}
							</BadgeButton>
						</Link>
					)}
					{/* {!login && !superLogin && (
            <BadgeButton
              light
              className="page-boundary-action page-boundary-action-secondary"
              onClick={() => setModal(true)}
            >
              See how it works?
            </BadgeButton>
          )} */}
				</section>
				<Modal
					open={openModal}
					onClose={onClose}
					heading="Disco"
					extended
					className="how-it-works-modal"
				/>
			</div>
		);
	}
);

export default PageBoundary;
