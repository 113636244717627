import React, { memo, useEffect, useRef } from "react";
import useTrait, { PUBLISHER_TRAIT } from "../hooks/useTrait";
import useIdentity from "../hooks/useIdentity";
import isShopifyShop from "../utils/isShopifyShop";

const TraitManager = memo(({ children }) => {
	const { user } = useIdentity();
	const { fetchTrait, initTraits } = useTrait();
	const traitRef = useRef({});

	useEffect(() => {
		if (!user || traitRef.current?.initialized) return;
		traitRef.current.initialized = true;
		initTraits();
	}, [user, initTraits]);

	useEffect(() => {
		if (!user || traitRef.current?.[PUBLISHER_TRAIT.IS_OSP_CHECKOUT_ACTIVE])
			return;
		traitRef.current[PUBLISHER_TRAIT.IS_OSP_CHECKOUT_ACTIVE] = true;
		if (!isShopifyShop(user)) return; // Don't fetch for shopify shops
		fetchTrait({ trait: PUBLISHER_TRAIT.IS_OSP_CHECKOUT_ACTIVE });
	}, [fetchTrait, user]);

	return <section>{children}</section>;
});

export default TraitManager;
