import React, { memo, useCallback, useEffect, useState } from "react";
import VariantsMatchModal from "../modals/VariantMatchModal";
import ProductsMatch from "../ProductsMatch";

const ProductsMerge = memo(
	({
		user,
		setUser,
		onDone,
		save,
		setSave,
		setFormError,
		data,
		reset,
		match,
		setPayload,
		onboarding = true,
	}) => {
		const [matches, setMatches] = useState({});

		const [variantsOpen, setVariantsOpen] = useState(false);

		const handleSave = useCallback(() => {
			setPayload(
				Object.entries(matches)
					.filter((match) => match[1] !== "none")
					.map(([from, to]) => ({
						product_id: from,
						merge_product_id: to,
					}))
			);
			match();
		}, [matches, match, setPayload]);

		useEffect(() => {
			if (!data) {
				return;
			}
			if (data?.product_merge_conflict) {
				setVariantsOpen(true);
			} else if (typeof onDone === "function") {
				onDone();
			}
			reset();
		}, [data, reset, onDone]);

		const handleVariantsDone = useCallback(() => {
			setVariantsOpen(false);

			if (typeof onDone === "function") {
				onDone();
			}
		}, [onDone]);

		const handleVariantsClose = useCallback(() => {
			setVariantsOpen(false);
		}, []);

		return (
			<>
				<ProductsMatch
					matches={matches}
					setMatches={setMatches}
					setError={setFormError}
					user={user}
					save={save}
					setSave={setSave}
					onSave={handleSave}
					onboarding={onboarding}
				/>
				<VariantsMatchModal
					user={user}
					setUser={setUser}
					open={variantsOpen}
					onDone={handleVariantsDone}
					onClose={handleVariantsClose}
				/>
			</>
		);
	}
);
export default ProductsMerge;
