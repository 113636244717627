import { GET } from "./GET";

export const USER_TYPES = {
	NEW: 1,
	EXISTING: 2,
};

const getJoinData = () => {
	const token = GET("token"),
		email = GET("email"),
		type = GET("type"),
		brand = GET("brand");
	if (!token || !email || !type || !brand) return null;
	return {
		email,
		token,
		type: Number(type),
		brand,
	};
};

export default getJoinData;
