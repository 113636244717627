import React, { useCallback } from "react";
import ReorderItem from "./ReorderItem";
import { Reorder as FramerReorder } from "framer-motion";
import { genClassName } from "@disco/disco_core";

const Reorder = React.memo(
	({
		list,
		setList,
		renderOption,
		className = "",
		optionClassName = "",
		disabled = false,
		onChildClick,

		groupMotionElement = "section",
		itemMotionElement = "div",
		axis = "y",

		dragEnd,
		dragStart,
		uniqueKey = "remote_id",
		onReorder,

		productRow = false,
	}) => {
		return (
			<FramerReorder.Group
				values={list}
				onReorder={setList}
				as={groupMotionElement}
				axis={axis}
				className={genClassName({
					base: "reorder-container",
					additional: className,
				})}
			>
				{list.map((item, id) => (
					<ReorderItem
						motionElement={itemMotionElement}
						item={item}
						onClick={onChildClick}
						disabled={disabled}
						id={id}
						dragStart={dragStart}
						dragEnd={dragEnd}
						onReorder={onReorder}
						productRow={productRow}
						className={optionClassName}
						key={item[uniqueKey]}
					>
						{renderOption}
					</ReorderItem>
				))}
			</FramerReorder.Group>
		);
	}
);

export default Reorder;
