import getSidebarOptions from "./getSidebarOptions";
import makeRedirectURL from "./makeRedirectURL";

const getPlatformPath = (name, user, params) => {
	const options = getSidebarOptions(user);

	let path = "/performance";

	if (name !== "home") {
		for (const option of options) {
			const props = option.props(false);

			if (props.name === name) {
				path = props.to;
			}

			if (!option?.children) continue;
			for (const child of option.children) {
				if (child.name === name) {
					path = child.to;
				}
			}
		}
	}

	if (params) path = makeRedirectURL(path, params);

	return path;
};

export default getPlatformPath;
