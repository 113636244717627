import React, { memo, useCallback, useMemo } from "react";
import { PREVIEW_TYPES_IDX, TOGGLE_SURVEY_URL } from "../../conf";
import { PLATFORM_SURVEY_TOGGLE, PLATFORM_VIEW_SURVEY } from "../../events";
import SurveyPerformanceModule from "../PerformanceModules/SurveyPerformanceModule";
import SurveyBuilder from "../SurveyBuilder";
import WidgetModule, { WidgetModuleToggler } from "./WidgetModule";

import useIdentity from "../../hooks/useIdentity";
import "./survey-module.css";
import useViewTrack from "../../hooks/useViewTrack";

const SurveyModuleSettings = memo(
	({
		user,
		setUser,
		onUpdate,
		triggerRefresh,
		setCustomPreviewType,
		setCustomPreviewID,
	}) => {
		const handleUpdate = useCallback(
			(...args) => {
				triggerRefresh();
				if (typeof onUpdate === "function") {
					onUpdate(...args);
				}
			},
			[onUpdate, triggerRefresh]
		);

		const handleSurveyOpen = useCallback(
			(survey = false) => {
				setCustomPreviewID((survey && survey.remote_id) || false);
				setCustomPreviewType(PREVIEW_TYPES_IDX.SURVEY);
			},
			[setCustomPreviewID, setCustomPreviewType]
		);

		const handleSurveyClose = useCallback(() => {
			setCustomPreviewID(false);
			setCustomPreviewType(false);
		}, [setCustomPreviewID, setCustomPreviewType]);

		return (
			<>
				<WidgetModuleToggler
					user={user}
					setUser={setUser}
					moduleName="Surveys"
					remoteKey="survey"
					toggleUrl={TOGGLE_SURVEY_URL}
					onToggle={triggerRefresh}
					event={PLATFORM_SURVEY_TOGGLE}
				/>
				<SurveyBuilder
					user={user}
					setUser={setUser}
					onUpdate={handleUpdate}
					handleSurveyOpen={handleSurveyOpen}
					handleSurveyClose={handleSurveyClose}
					minimal={false}
				></SurveyBuilder>
			</>
		);
	}
);

const SurveyModuleResults = memo(({ user, setUser }) => {
	return (
		<>
			<SurveyPerformanceModule user={user} setUser={setUser} hideNav />
		</>
	);
});

const SurveyModule = memo(({ onUpdate }) => {
	const { user, setUser } = useIdentity();

	useViewTrack(PLATFORM_VIEW_SURVEY);

	const renderSettings = useCallback(
		(props) => (
			<SurveyModuleSettings
				user={user}
				setUser={setUser}
				onUpdate={onUpdate}
				{...props}
			/>
		),
		[user, setUser, onUpdate]
	);
	const renderResults = useCallback(
		(props) => (
			<SurveyModuleResults user={user} setUser={setUser} {...props} />
		),
		[user, setUser]
	);
	const tabOptions = useMemo(
		() => [
			{
				name: "Manage Surveys",
				component: renderSettings,
			},
			{
				name: "View Results",
				component: renderResults,
				disablePreview: true,
				minimalFloat: true,
			},
		],
		[renderSettings, renderResults]
	);
	return (
		<WidgetModule
			user={user}
			setUser={setUser}
			tabOptions={tabOptions}
			heading={
				user?.isNewIa ? "Customer Surveys" : "Survey your customers"
			}
			className="new-survey-module"
			previewType={PREVIEW_TYPES_IDX.ALL_SURVEY}
		/>
	);
});

export default SurveyModule;
