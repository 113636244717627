const getFirstError = (error) => {
	if (!error || !error.response) {
		return false;
	}
	let err = Object.entries(error.response.data);
	if (err.length < 1) {
		return false;
	}
	err = err[0];
	return err;
};

export default getFirstError;
