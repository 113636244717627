import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { Page } from "../../components/CoreUI";
import ResetStep from "../../components/onboardingSteps/ResetStep";
import isAuth from "../../utils/isAuth";

const PasswordResetPage = React.memo(function () {
	const [auth, setAuth] = useState(isAuth()[0]);

	if (auth) {
		return <Navigate to="/" />;
	}

	return (
		<Page className="form-page">
			<ResetStep setAuth={setAuth} />
		</Page>
	);
});

export default PasswordResetPage;
