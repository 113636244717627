import {
	FONT_SIZE,
	InformationTooltip,
	SPACING,
	Text,
} from "@disco/disco_core";
import React, { createRef, memo, useState } from "react";
import {
	FETCH_CATEGORY_WHITELIST_URL,
	SAVE_CATEGORY_WHITELIST_URL,
} from "../../conf";
import useModal from "../../hooks/useModal";
import anim from "../../utils/anim";
import { ModalSelectListInput, Tooltip } from "../CoreUI";

const VARIANT = {
	...anim.tooltip,
	animate: { ...anim.tooltip.animate, y: -30 },
};
const CategoryOptInModalLabel = memo(
	({ onboarding = false, designSystem = false }) => {
		const { open, handleOpen, handleClose } = useModal(false);

		if (designSystem) {
			return (
				<>
					We prefer to{" "}
					<InformationTooltip
						activator={
							<Text Element="u" hPadding={SPACING.MICRO}>
								partner
							</Text>
						}
					>
						<Text size={FONT_SIZE.BODY}>
							The more partner categories you add, the more you'll
							learn about what resonates with your customers
						</Text>
					</InformationTooltip>{" "}
					with shops in these{" "}
					<Text
						color={"var(--disco-theme-default)"}
						tightRight
						hPadding={SPACING.MICRO}
					>
						categories
					</Text>
				</>
			);
		}

		return (
			<>
				We prefer to{" "}
				<span
					onMouseEnter={onboarding ? null : handleOpen}
					onMouseLeave={onboarding ? null : handleClose}
					style={{
						textDecoration: onboarding ? "none" : "underline",
					}}
				>
					partner
				</span>{" "}
				with Shops in these{" "}
				<span style={{ "--highlight": "var(--theme-2)" }}>
					Categories
				</span>
				<Tooltip
					open={open}
					variants={VARIANT}
					style={{ width: "300px" }}
				>
					The more partner categories you add, the more you'll learn
					about what resonates with your customers
				</Tooltip>
			</>
		);
	}
);
const CategoryOptInModal = memo(
	({
		user,
		setUser,
		focusFunction,
		parentRef,
		onboarding = false,
		onClose,
		customStepProps,
		designSystem,
		...props
	}) => {
		const [stepProps, setStepProps] = useState([
			{
				inlineName: "partner categories",
				label: (
					<CategoryOptInModalLabel
						designSystem={designSystem}
						onboarding={onboarding}
					/>
				),
				inputProps: {
					ref: parentRef || createRef(),
					onMouseEnter: focusFunction,
					"data-name": "category_whitelist",
					"data-customid": 0,
				},
				remoteKey: "category_whitelist",
				tooltipName: "categories",

				title: "Partner Categories",

				asideTitle: "Select categories",
				// mainTitle: "Categories your partners are in",
				mainTitle:
					"You prefer to partner with shops in the following categories",
				buttonText: "Save",

				mainFooter: () => (
					<>
						The categories you choose here will help Disco learn
						more about what resonates well with your customers.
					</>
				),

				split: true,
				stepped: false,
				required: true,
				searchable: true,

				fetchUrl: FETCH_CATEGORY_WHITELIST_URL,
				saveUrl: SAVE_CATEGORY_WHITELIST_URL,
				directSave: true,
				requestValueMapper: ({ value }) =>
					value.map((category) => category.remote_id),

				draftable: !onboarding,
				onSave: onClose,
				...(customStepProps?.[0] || {}),
				// inputWrapperProps: {
				// 	card: true,
				// 	actionText: onboarding
				// 		? "Not quite right? That’s okay. Lets make some changes now based on your preferences."
				// 		: "Not quite right? That’s okay. Lets make some changes now based on your preferences. ",
				// 	...(customStepProps?.[0]?.inputWrapperProps || {}),
				// },
			},
		]);

		return (
			<>
				<ModalSelectListInput
					stepProps={stepProps}
					user={user}
					setUser={setUser}
					setStepProps={setStepProps}
					onboarding={onboarding}
					designSystem={designSystem}
					{...props}
				/>
			</>
		);
	}
);

export default CategoryOptInModal;
