import { createContext, useEffect, useState } from "react";
import React, { memo } from "react";
import { getEntries } from "../utils/contentfulUtils";

import { Loader } from "./CoreUI";

export const ContentContext = createContext();

const parseResponse = (response) => {
	const content = {};
	response.items.forEach((item) => {
		const contentType = item?.sys?.contentType?.sys?.id;
		if (!contentType) return;
		if (!content[contentType]) content[contentType] = {};
		// New values WILL overwrite older ones
		Object.entries(item.fields).forEach(([key, value]) => {
			content[contentType][key] = value;
		});
	});

	return content;
};

const ContentWrapper = memo(({ children }) => {
	const [data, setData] = useState({ error: null, content: null });
	useEffect(() => {
		getEntries().then((response) => {
			setData({ error: null, content: parseResponse(response) });
		});
	}, []);

	return (
		<ContentContext.Provider value={data.content}>
			{data.content ? (
				children
			) : data.error ? (
				"Could not load content"
			) : (
				<Loader light center />
			)}
		</ContentContext.Provider>
	);
});

export default ContentWrapper;
