import React, { forwardRef, memo, useCallback, useMemo, useState } from "react";

import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import logoBlue from "../../img/logo-blue.svg";

import logoImg from "../../img/logo-light.svg";
import "./side-bar.css";

import getSidebarOptions from "../../utils/getSidebarOptions";

import { FiX } from "react-icons/fi";
import useDimensions from "../../hooks/useDimensions";
import useTrack from "../../hooks/useTrack";
import getPlatformPath from "../../utils/getPlatformPath";
import SidebarBrand from "../SidebarBrand";

const iconData = { className: "side-bar-nav-icon" };

const LinkWrapper = memo(
	({ children, to, direct, internalDirect, closeSidebar }) => {
		return direct || internalDirect ? (
			<a
				href={to}
				target={direct ? "_blank" : ""}
				rel="noopener noreferrer"
				onClick={closeSidebar}
			>
				{children}
			</a>
		) : (
			<Link to={to} onClick={closeSidebar}>
				{children}
			</Link>
		);
	}
);

const NavItem = memo(
	forwardRef(
		(
			{
				children,
				title,
				name,
				icon,
				active,
				to,
				event,
				viewChildren = false,
				direct,
				user,
				closeSidebar,
			},
			ref
		) => {
			const track = useTrack();

			const handleTrack = useCallback(
				(event) => {
					if (event) {
						track(event, { from_sidebar: true });
					}
				},
				[track]
			);

			const open =
				Array.isArray(children) &&
				children.findIndex((child) => child.name === active) !== -1;

			return (
				<LinkWrapper
					to={to}
					direct={direct}
					user={user}
					closeSidebar={closeSidebar}
				>
					<section
						className={`side-bar-nav-item ${
							name === active || open ? "side-bar-nav-active" : ""
						}`}
						onClick={() => handleTrack(event)}
					>
						<IconContext.Provider value={iconData}>
							{icon} {title}
						</IconContext.Provider>
						{Array.isArray(children) &&
							(active === name || open) && (
								<>
									{children
										.filter(
											({ visible }) =>
												typeof visible !== "function" ||
												visible(user)
										)
										.map(
											(
												{
													name,
													to,
													icon,
													title,
													flowRef,
													event,
												},
												idx
											) => {
												const props = { key: name };
												if (!viewChildren) {
													props.to = to;
												}
												return (
													<Link
														{...props}
														ref={
															flowRef
																? ref
																: undefined
														}
														onClick={() =>
															handleTrack(event)
														}
													>
														<section
															className={`side-bar-nav-sub-item 
												${viewChildren ? "side-bar-nav-sub-item-no-hover" : ""} ${
																name === active
																	? "side-bar-nav-sub-active"
																	: ""
															} ${
																idx === 0
																	? "side-bar-nav-sub-item-first"
																	: ""
															}`}
														>
															<IconContext.Provider
																value={iconData}
															>
																{icon} {title}
															</IconContext.Provider>
														</section>
													</Link>
												);
											}
										)}
								</>
							)}
					</section>
				</LinkWrapper>
			);
		}
	)
);

const SideBar = memo(function ({
	active,
	user,
	blue = false,
	nav = true,
	open,
	onClose,
}) {
	const { isMobile } = useDimensions();

	const sidebarOptions = useMemo(
		() => getSidebarOptions(user).filter((option) => !option.hidden),
		[user]
	);

	return (
		<aside
			className={`side-bar${blue ? " side-bar-blue" : ""}${
				isMobile && !open ? " mobile-side-bar-closed" : ""
			}`}
		>
			{nav && (
				<nav>
					<section className="side-bar-hero">
						{isMobile && (
							<FiX className="side-bar-close" onClick={onClose} />
						)}
						<Link
							to={getPlatformPath("home", user)}
							className="side-bar-logo"
							rel="noopener noreferrer"
						>
							<img
								src={blue ? logoBlue : logoImg}
								alt="Disco Logo"
							/>
						</Link>
					</section>
					{sidebarOptions
						.filter(
							({ visible }) =>
								typeof visible !== "function" || visible(user)
						)
						.map((navItem) => (
							<NavItem
								{...navItem.props(active)}
								key={navItem.props(active).to}
								user={user}
								closeSidebar={onClose}
							>
								{navItem.children}
							</NavItem>
						))}
				</nav>
			)}
			<SidebarBrand />
		</aside>
	);
});

export default SideBar;
