import axios from "axios";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import {
	BaseForm,
	Button,
	FormStatus,
	Input,
	Loader,
	Page,
} from "../../components/CoreUI";
import PageBoundary from "../../components/PageBoundary";
import { SUPER_LOGIN_URL } from "../../conf";
import isAuth from "../../utils/isAuth";
import makeRedirectURL from "../../utils/makeRedirectURL";
import parseError from "../../utils/parseError";
import tokenStorage from "../../utils/tokenStorage";
import "./form-page.css";

const SuperLoginPage = React.memo(function () {
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [brandName, setBrandName] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const [auth, setAuth] = useState(isAuth()[0]);

	const login = async () => {
		setLoading(true);
		let resp;
		try {
			resp = await axios.post(SUPER_LOGIN_URL, {
				email,
				password,
				brand_name: brandName,
			});
		} catch (error) {
			setLoading(false);

			if (error && error.response) {
				setError(parseError(error));
			} else {
				setError("Could not connect to the internet. Try again later.");
			}
			return;
		}
		// On superuser login, set applicable fields into our browser memory
		tokenStorage.set({
			accessToken: resp.data.access,
			refreshToken: resp.data.refresh,
			publisher: resp.data.publisher_id,
			isSuper: true,
			superDjId: resp.data.dj_id,
		});
		setAuth(true);
	};

	const handleLogin = (e) => {
		e.preventDefault();
		if (
			email.trim().length < 1 ||
			password.trim().length < 1 ||
			brandName.trim().length < 1
		) {
			return setError("Email, password and shop name are required");
		}
		setError("");
		login();
	};

	if (auth) {
		return <Navigate to={makeRedirectURL("/")} />;
	}

	return (
		<Page className="form-page">
			<PageBoundary superLogin>
				<BaseForm heading="Super Login" className="form-page-form">
					<Input
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						placeholder="Email"
						disabled={loading}
					/>
					<Input
						type="password"
						value={password}
						placeholder="Password"
						disabled={loading}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<Input
						type="text"
						value={brandName}
						placeholder="Shop Name"
						disabled={loading}
						onChange={(e) => setBrandName(e.target.value)}
					/>

					{error && (
						<FormStatus className="form-page-error">
							{" "}
							Whoops! {error}{" "}
						</FormStatus>
					)}
					{loading && <Loader />}
					{!loading && (
						<Button disabled={loading} onClick={handleLogin}>
							Super Login
						</Button>
					)}
				</BaseForm>
			</PageBoundary>
		</Page>
	);
});

export default SuperLoginPage;
