// Factory for generic key mappers
export const keyMapperFactory = (key = "remote_id", nameKey = "name") => (
	data
) =>
	data.reduce(
		(map, item) => ({
			...map,
			[item[key]]: item[nameKey],
		}),
		{}
	);

// Specific mapper for subcategories
export const subcategoryMapperFactory = () => (data) =>
	data.reduce(
		(map, cat) => ({
			...map,
			[cat.name]: cat.subcategories.reduce(
				(subMap, item) => ({
					...subMap,
					[item.remote_id]: item.name,
				}),
				{}
			),
		}),
		{}
	);
