import { useCallback, useContext, useEffect } from "react";
import { PingContext } from "../components/PingManager";

const usePing = ({ key, callback = null }) => {
	const { pings, setPings } = useContext(PingContext);

	const ping = useCallback(
		(data) => {
			setPings((pings) => {
				if (pings[key]) return pings;
				return { ...pings, [key]: data || true };
			});
		},
		[setPings, key]
	);

	const recvPing = useCallback(() => {
		setPings((pings) => {
			if (!pings[key]) return pings;
			return { ...pings, [key]: false };
		});
	}, [setPings, key]);

	useEffect(() => {
		if (!callback || !pings[key]) return;
		callback(pings[key]);
		recvPing();
	}, [pings, key, callback, recvPing]);

	return { ping, recvPing, pingActive: !!pings[key] };
};

export const PING_TYPES = {
	MODAL_SELECT_NEXT: "MODAL_SELECT_NEXT",
	MODAL_SELECT_BACK: "MODAL_SELECT_BACK",
	ONBOARDING_GOALS_PULSE: "ONBOARDING_GOALS_PULSE",
	REFRESH_AUDIENCES_HISTORY: "REFRESH_AUDIENCES_HISTORY",
	UPDATE_LEAD_OFFER_TYPE: "UPDATE_LEAD_OFFER_TYPE",
};

export default usePing;
