/* eslint-disable no-useless-escape */
import React, {
	memo,
	useEffect,
	useState,
	useMemo,
	useRef,
	useCallback,
} from "react";
import {
	Button,
	CheckboxElement,
	Loader,
	SPACING,
	ExternalLinkIcon,
	Text,
	FONT_SIZE,
	PaddedContainer,
	RadioElement,
} from "@disco/disco_core";
import { Link } from "react-router-dom";
import CentralView, { REGISTER_TAB } from "./CentralView";
import reqdStringValidator from "../../utils/reqdStringValidator";
import "./register-view.css";
import { HForm } from "../CoreUI";
import useTrack from "../../hooks/useTrack";
import {
	PLATFORM_ONBOARDING_CLICK_ANY_ACCOUNT_FIELD,
	PLATFORM_ONBOARDING_CLICK_ON_REGISTER_INITIATE,
	PLATFORM_ONBOARDING_PAGE_VIEW_CREATE_ACCOUNT,
} from "../../events";
import validateEmail from "../../utils/validEmail";
import isFunction from "../../utils/isFunction";
import useResource from "../../hooks/useResource";
import tokenStorage from "../../utils/tokenStorage";
import {
	SAVE_LEAD_URL,
	PRIVACY_POLICY_URL,
	TOS_URL,
	REGISTER_URL,
	GA_CODE,
	PLATFORM_IDS,
	SHOPIFY_APP_STORE_URL,
	BIGCOMMERCE_APP_STORE_URL,
	IS_TEST_ENVIRONMENT,
} from "../../conf";
import parseError from "../../utils/parseError";
import { GET, GETAll } from "../../utils/GET";
import useDimensions from "../../hooks/useDimensions";
import useContent from "../../hooks/useContent";
import fromShopify from "../../utils/fromShopify";
import ReactGA from "react-ga";
import windowRedirect from "../../utils/windowRedirect";

import background from "../../img/register-background.png";
import getPlatformList from "../../utils/getPlatformList";

// Google Analytics

ReactGA.initialize(GA_CODE, { testMode: process.env.NODE_ENV === "test" });
ReactGA.pageview(window.location.pathname + window.location.search);

const movOptions = {
	0: "Less than 500",
	1: "500 - 1,000",
	2: "1,000 - 5,000",
	3: "5,000 - 10,000",
	4: "10,000 - 50,000",
	5: "50,000+",
};

const utmParams = new Set([
	"utm_medium",
	"utm_source",
	"utm_campaign",
	"utm_content",
	"utm_term",
]);

const utmPayload = Object.entries(GETAll()).reduce((acc, [key, val]) => {
	if (utmParams.has(key)) return { ...acc, [key]: val };
	return acc;
}, {});

let demo = false; // No state as if => true, stay true

const passwordRules = [
	{
		text: "One uppercase letter",
		errorMessage: "Your password must contain one uppercase letter",
		validator: (password) => password?.toLowerCase() !== password,
	},
	{
		text: "One lowercase letter",
		errorMessage: "Your password must contain one lowercase letter",
		validator: (password) => password?.toUpperCase() !== password,
	},
	{
		text: "One special character",
		errorMessage: "Your password must contain one special character",
		validator: (password) =>
			/[~`!#$@%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(password),
	},
	{
		text: "Minimum length of 8 characters",
		errorMessage:
			"Your password must have a minimum length of 8 characters",
		validator: (password) => password.length >= 8,
	},
];

const RegisterView = memo(({ setAuth }) => {
	const platformList = getPlatformList();
	const shopifyPayload = GETAll();
	const store_hash = GET("store_hash");

	const [step, setStep] = useState(!store_hash && !fromShopify() ? 0 : 1);

	const getEmail = GET("email");
	const getBrandName = GET("brand");
	const getVia = GET("via");
	const getSignupEmail = GET("signup_email");
	const getShopUrl = GET("shop");
	const isExistingAccount = GET("existing") === "true";

	// Used for form fields
	const [regData, setRegData] = useState({
		email: getEmail ?? getSignupEmail ?? "",
		brand_name: getBrandName ?? "",
		hdyhau: getVia,
		hero_url: getShopUrl,
	});

	// Used as payload in the register API call
	// can be different from regData after validators are run
	const [regPayload, setRegPayload] = useState({
		existing: isExistingAccount,
	});

	const track = useTrack(PLATFORM_ONBOARDING_PAGE_VIEW_CREATE_ACCOUNT);
	const { isMobile } = useDimensions();

	const {
		platformOnboarding: { hubspotDemoUrl },
	} = useContent();

	const fields = useMemo(
		() => [
			{
				label: "Your full name",
				validator(val) {
					const { error, value } = reqdStringValidator(
						val,
						"your full name"
					);
					if (error) return { error };
					if (value.indexOf(" ") === -1)
						return {
							error: "Please enter both first and last names ",
						};
					return { value };
				},
				props: {
					type: "text",
					placeholder: "Your full name",
					name: "full_name",
					"data-testid": "register-name",
				},
			},
			{
				label: "Your email",
				validator(val) {
					const { error, value } = reqdStringValidator(
						val,
						"your email"
					);
					if (error) return { error };
					if (!validateEmail(value))
						return {
							error: "Please enter a valid email address",
						};
					return { value };
				},

				props: {
					type: "email",
					placeholder: "Your email",
					name: "email",
					"data-testid": "register-email",
				},
			},
			{
				label: "Password",
				rules: passwordRules,

				props: {
					type: "password",
					placeholder: "Choose a password",
					name: "password",
					"data-testid": "register-password",
				},
			},
			{
				label: "Brand name",
				validator(val) {
					return reqdStringValidator(val, "your brand name");
				},

				props: {
					type: "text",
					placeholder: "Brand name",
					name: "brand_name",
					"data-testid": "register-brand",
				},
			},
			{
				label: "Website",
				validator(val) {
					return reqdStringValidator(val, "your shop's URL");
				},

				props: {
					type: "text",
					placeholder: "Shop URL",
					name: "hero_url",
					"data-testid": "register-url",
				},
			},
			{
				label: "Monthly Order Volume",
				remoteKey: "monthly_order_volume",
				validator(val) {
					return reqdStringValidator(val, "monthly order volume");
				},
				props: {
					type: "select",
					placeholder: "Select",
					name: "monthly_order_volume",
					options: movOptions,
					"data-testid": "register-via",
				},
			},
		],
		[]
	);

	const [formError, setFormError] = useState(false);
	const [leadPayload, setLeadPayload] = useState({});

	const [{ loading, error, data }, register, reset] = useResource(
		{ url: REGISTER_URL, method: "POST", data: regPayload },
		false,
		false
	);

	const [
		{ loading: leadLoading, error: leadError, data: leadData },
		saveLead,
		resetLead,
	] = useResource(
		{ url: SAVE_LEAD_URL, method: "POST", data: leadPayload },
		false,
		false
	);

	const nextRef = useRef(false);

	const handleSubmit = (e, demoClicked = false) => {
		e.preventDefault();

		nextRef.current = !demoClicked;
		track(PLATFORM_ONBOARDING_CLICK_ON_REGISTER_INITIATE, {});
		const acc = {};

		reset();
		resetLead();

		for (const field of fields) {
			const name = field?.props?.name;
			if (isFunction(field.validator)) {
				const valid = field.validator(regData[name]);
				if (valid.error) return setFormError(valid.error);
			} else if (Array.isArray(field.rules)) {
				for (const rule of field.rules) {
					if (!rule.validator(regData[name])) {
						return setFormError(rule.errorMessage);
					}
				}
			}
			acc[name] = regData[name];
		}

		if (!tos)
			return setFormError(
				"Please accept the terms of service and the privacy policy to register"
			);

		setFormError(false);
		setRegPayload((regPayload) => {
			const payload = {
				...regPayload,
				...acc,
				is_mobile: isMobile,
				demo: demo,
				tos,
				privacy: tos,
			};
			if (shopifyPayload && shopifyPayload.shop && shopifyPayload.code)
				payload.shopify_data = shopifyPayload;

			if (getVia) payload.via = getVia;
			if (store_hash) payload.bigcommerce_store_hash = store_hash;
			payload.password2 = payload.password;

			if (window.Rewardful && window.Rewardful.referral) {
				payload.referral = window.Rewardful.referral;
			}
			return payload;
		});
		setLeadPayload({
			...acc,
			...utmPayload,
			is_mobile: isMobile,
			demo: demo,
			tos,
			privacy: tos,
		});

		// GA Tracking for a user submitting the Lead Gen form
		// ReactGA.event({
		// 	category: "Form Submission",
		// 	action: "Lead Gen Form",
		// });
		if (IS_TEST_ENVIRONMENT) {
			// We do not want to create hubspot leads for a test environment (STAGING | DEVELOPMENT)
			demoClicked ? window.open(hubspotDemoUrl) : register();
			return;
		}
		saveLead();
	};

	useEffect(() => {
		// This is bypassed if a hubspot lead is not created for test environment
		if (!leadData) return;
		resetLead();
		if (!nextRef.current) window.open(hubspotDemoUrl);
		else {
			register();
		}
	}, [leadData, resetLead, hubspotDemoUrl, register]);

	useEffect(() => {
		if (!data) return;
		reset();
		tokenStorage.set({
			accessToken: data.access,
			refreshToken: data.refresh,
			publisher: data.publisher_id,
		});
		setAuth(2);
	}, [data, setAuth, reset]);

	const [tos, setTos] = useState(false);

	const [showDemo, setShowDemo] = useState(false);

	useEffect(() => {
		if (showDemo) return;
		setShowDemo(
			!!regData.brand_name &&
				!!regData.email &&
				!!regData.full_name &&
				!!regData.hero_url &&
				regData.monthly_order_volume >= 2
		);
	}, [regData, showDemo]);

	const [platform, setPlatform] = useState(-1);

	const handleSelect = useCallback((platform) => {
		setPlatform(platform);
	}, []);

	const handlePlatformSubmit = (platform) => {
		if (PLATFORM_IDS.shopify === platform) {
			windowRedirect(SHOPIFY_APP_STORE_URL);
		} else if (PLATFORM_IDS.bigcommerce === platform) {
			windowRedirect(BIGCOMMERCE_APP_STORE_URL);
		} else {
			setStep(1);
		}
	};

	const renderPlatformViews = useCallback(
		({ platform, onSelect, handleSubmit }) => {
			return (
				<>
					<Text
						size={FONT_SIZE.SUB_TITLE}
						marginTop={SPACING.REGULAR}
						marginBottom={SPACING.REGULAR}
						className="platforms-view-subheading"
					>
						{"Select your platform"}
					</Text>
					<PaddedContainer className="platforms-view-platforms">
						{platformList.map((platformData) => (
							<RadioElement
								key={platformData.id}
								className={`platforms-view-platform ${
									platform === platformData.id
										? "platforms-view-platform-selected"
										: ""
								}`}
								selected={platform === platformData.id}
								onClick={() => onSelect(platformData.id)}
								border={true}
								data-testid={`select-platform-radio-element-${platformData.id}`}
							>
								<img src={platformData.img} alt="" />
								<div className="platforms-view-btn-panel">
									{platform === platformData.id && (
										<Button
											className="platforms-view-external-btn"
											data-testid={`select-platform-btn-${platformData.id}`}
											onClick={() =>
												handleSubmit(platformData.id)
											}
										>
											{platformData.id ===
												PLATFORM_IDS.shopify ||
											platformData.id ===
												PLATFORM_IDS.bigcommerce ? (
												<>
													<span>
														Install Disco on the App
														Store
													</span>
													<ExternalLinkIcon />
												</>
											) : (
												"Continue"
											)}
										</Button>
									)}
								</div>
							</RadioElement>
						))}
					</PaddedContainer>
				</>
			);
		},
		[]
	);

	return (
		<CentralView
			tabActive={REGISTER_TAB}
			background={background}
			className="register-view"
			showLogo
			showMobileLogo
			heading={"Let's Get Started"}
		>
			{step == 0 ? (
				renderPlatformViews({
					platform,
					onSelect: handleSelect,
					handleSubmit: handlePlatformSubmit,
				})
			) : (
				<HForm
					fields={fields}
					data={regData}
					setData={setRegData}
					heading={isMobile ? "Let's Get Started" : ""}
					onSubmit={handleSubmit}
					focusEvent={PLATFORM_ONBOARDING_CLICK_ANY_ACCOUNT_FIELD}
					error={
						formError
							? formError
							: error
							? parseError(error)
							: leadError
							? parseError(leadError)
							: null
					}
					errorTestId="register-error"
				>
					<section
						className={`register-view-demo ${
							!showDemo ? "register-view-demo-hidden" : ""
						}  ${leadLoading ? "register-view-demo-disabled" : ""}`}
					>
						Want to schedule a demo instead?
						<span onClick={(e) => handleSubmit(e, true)}>
							{" "}
							Click here.
						</span>
					</section>
					<label className="register-view-tos">
						<CheckboxElement
							checked={tos}
							onChange={(e) => setTos(e.target.checked)}
						>
							I agree to the
							<a
								href={TOS_URL}
								target="_blank"
								rel="noopener noreferrer"
							>
								Terms of Service
							</a>{" "}
							and{" "}
							<a
								href={PRIVACY_POLICY_URL}
								target="_blank"
								rel="noopener noreferrer"
							>
								Privacy Policy
							</a>
						</CheckboxElement>
					</label>

					<section className="register-view-footer">
						{loading || leadLoading ? (
							<Loader marginTop={SPACING.REGULAR} />
						) : (
							<Button
								xLarge={!isMobile}
								large={isMobile}
								shadow={isMobile}
								rounded={!isMobile}
								data-testid="register-submit"
								className="register-submit"
								marginTop={SPACING.REGULAR}
								type="submit"
							>
								Create an account
							</Button>
						)}
					</section>
				</HForm>
			)}
			<section className="register-view-login">
				Already have an account? <Link to={"/login"}>Sign in</Link>
			</section>
		</CentralView>
	);
});

export default RegisterView;
