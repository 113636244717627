import {
	Loader,
	StatusLabel,
	genClassName,
	isFunction,
} from "@disco/disco_core";
import React, { memo, useCallback } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { AuthPage, PageHeader } from "../../components/CoreUI";
import useIdentity from "../../hooks/useIdentity";
import getPlatformPath from "../../utils/getPlatformPath";
import "./top-level-page.css";
import useTrack from "../../hooks/useTrack";

export const getTopLevelPageHeaderProps = ({
	subPages,
	urlParam,
	pageProps,
	filter,
}) => {
	// Could potentially remove requirement for pageProps being an object if we have a valid use-case
	if (
		typeof subPages !== "object" ||
		(typeof pageProps !== "object" && typeof pageProps !== "function")
	)
		// pageProps can be function or object
		return null;

	const getParsedPageProps = (subPageKey) =>
		typeof pageProps === "function"
			? pageProps(subPageKey) || {}
			: pageProps?.[subPageKey] || {};

	const tabOptions = Object.keys(subPages)
		.filter(typeof filter === "function" ? filter : () => true)
		.map((subPageKey) => ({
			...getParsedPageProps(subPageKey)?.headerProps?.tabOption,
			active: urlParam === subPageKey,
			urlKey: subPageKey,
		}));

	return {
		...(getParsedPageProps(urlParam)?.headerProps || {}),
		tabOptions,
	};
};

export const TopLevelPageHeader = memo(
	({ heading = "", tabOptions, ...rest }) => {
		const navigate = useNavigate();

		const { user } = useIdentity();
		const track = useTrack();

		const handleTabChange = useCallback(
			(_, idx) => {
				track(tabOptions[idx]?.event, { from_sidebar: false });
				navigate({
					pathname: getPlatformPath(tabOptions?.[idx]?.urlKey, user),
				});
			},
			[user, track, navigate, tabOptions]
		);

		return (
			<PageHeader
				className="top-level-page-header"
				heading={heading}
				tabOptions={tabOptions}
				handleTabChange={handleTabChange}
				{...rest}
			></PageHeader>
		);
	}
);

const TopLevelPage = memo(
	({
		subPages,
		pageProps,
		loading = false,
		error = null,
		className = "",
		pageHeader = null,
		...rest
	}) => {
		const { subPage } = useParams();
		const { user } = useIdentity();

		const SubPage = subPage ? subPages[subPage] : null;

		if (!SubPage) {
			return (
				<Navigate
					to={getPlatformPath(Object.keys(subPages)[0], user)}
				/>
			);
		}

		return (
			<AuthPage
				className={genClassName({
					base: "top-level-page",
					additional: className,
				})}
				active={subPage}
				{...rest}
			>
				{error ? (
					<StatusLabel className="top-level-page-error">
						{error}
					</StatusLabel>
				) : loading ? (
					<Loader center />
				) : (
					<>
						{pageHeader}
						<SubPage
							{...(isFunction(pageProps)
								? pageProps(subPage)
								: pageProps)}
						/>
					</>
				)}
			</AuthPage>
		);
	}
);

export default TopLevelPage;
