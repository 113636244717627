import React, { memo, forwardRef, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { PaddedContainer } from "../PaddedContainer";
import genPackageClassName from "../../utils/genPackageClassName";
import { isFunction } from "../../utils";

import "./dot-switcher.css";

const Dot = memo(({ className = "", idx = -1, onClick, ...rest }) => {
	const handleClick = useCallback(
		() => (isFunction(onClick) ? onClick(idx) : null),
		[onClick, idx]
	);

	return (
		<PaddedContainer
			Element="div"
			className={genPackageClassName({
				base: "dot",
				additional: className,
			})}
			onClick={handleClick}
			{...rest}
		/>
	);
});

Dot.displayName = "Dot";
Dot.propTypes = {
	className: PropTypes.string,
	idx: PropTypes.number,
	onClick: PropTypes.func,
};

export const DOT_SWITCHER_TYPE = {
	THEME: "theme",
	FADED: "faded",
};

export const DotSwitcher = memo(
	forwardRef(
		(
			{
				className = "",
				vertical = false,
				dots = 1,
				active = 0,
				setActive,
				type = DOT_SWITCHER_TYPE.THEME,
				disabled = false,
				viewOnly = false,
				...props
			},
			ref
		) => {
			const handleClick = useCallback(
				(idx) =>
					!isFunction(setActive) || disabled || viewOnly
						? null
						: setActive(idx),
				[setActive, disabled, viewOnly]
			);

			const dummyDotArray = useMemo(() => [...Array(dots)], [dots]);

			return (
				<PaddedContainer
					className={genPackageClassName({
						base: "dot-switcher",
						conditionals: {
							"dot-switcher-horizontal": !vertical,
							"dot-switcher-vertical": vertical,
							"dot-switcher-disabled": disabled,
							"dot-switcher-view-only": viewOnly,
							[`dot-switcher-${type}`]: type,
						},
						additional: className,
					})}
					ref={ref}
					{...props}
				>
					{dummyDotArray.map((_, idx) => (
						<Dot
							className={genPackageClassName({
								base: "dot-switcher-dot",
								conditionals: {
									"dot-switcher-dot-active": active === idx,
								},
							})}
							onClick={handleClick}
							idx={idx}
							key={`dot_${idx}`}
						/>
					))}
				</PaddedContainer>
			);
		}
	)
);

DotSwitcher.displayName = "DotSwitcher";
DotSwitcher.propTypes = {
	className: PropTypes.string,
	vertical: PropTypes.bool,
	disabled: PropTypes.bool,
	viewOnly: PropTypes.bool,
	dots: PropTypes.number,
	active: PropTypes.number,
	setActive: PropTypes.func,
	type: PropTypes.oneOf(Object.values(DOT_SWITCHER_TYPE)),
};
