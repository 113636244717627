import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { FETCH_SURVEY_CSV, FETCH_SURVEY_RESULT } from "../../conf";
import {
	CSVGenerator,
	FormStatus,
	InlineHelperTooltip,
	ProgressView,
} from "../CoreUI";
import useResource from "../../hooks/useResource";
import ModulePreviewContentChild from "../ModulePreviewContentChild";

import "./survey-result.css";
import isShopifyShop from "../../utils/isShopifyShop";

const formatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	minimumFractionDigits: 2,
});

const SurveyResult = memo(({ user, inline = false, surveyId = "" }) => {
	const [results, setResults] = useState({});

	const [payload, setPayload] = useState({
		question_id: surveyId,
	});

	const [
		{ loading: resultsLoading, data: resultsData, error: resultsError },
		fetchResults,
		resetResults,
	] = useResource(
		{
			url: FETCH_SURVEY_RESULT,
			method: "POST",
			data: payload,
		},
		false
	);

	const genResults = useCallback((responses, options, displays) => {
		let total = 0;
		let totalAov = 0;
		let totalTov = 0;
		let totalPercent = 100;
		if (responses.length === 0) {
			return setResults({
				responses: [],
			});
		}
		const temp = responses.map((res) => {
			const opt = options.find((option) => {
				return option.remote_id === res.answer_id;
			});
			total += res.answer_count;
			totalAov += res.aov;
			totalTov += res.tov;
			if (!opt) {
				return { name: "Other", count: res.answer_count };
			}
			return {
				name: opt.text,
				count: res.answer_count,
				aov: res.aov,
				tov: res.tov,
				percentage: res.percentage,
			};
		});

		for (const opt of options) {
			if (
				responses.findIndex(
					(resOpt) => resOpt.answer_id === opt.remote_id
				) === -1
			) {
				temp.push({ name: opt.text, count: 0 });
			}
		}
		for (const opt of temp) {
			if (opt.count > 0) {
				setResults({
					responses: temp,
					totalResponses: total,
					totalAov: totalAov,
					totalTov: totalTov,
					totalPercent: totalPercent,
					displays,
				});
				return;
			}
		}
		setResults({});
	}, []);

	useEffect(() => {
		if (resultsData && resultsData.responses && resultsData.options) {
			genResults(
				resultsData.responses,
				resultsData.options,
				resultsData.num_displays
			);
			resetResults();
		}
	}, [resultsData, genResults, resetResults]);

	const handleFilterChange = useCallback(
		(filters) => {
			setPayload(() => ({
				question_id: surveyId,
				...filters,
			}));
		},
		[surveyId]
	);

	const showInlineHelperTooltip = useMemo(() => {
		const migrationDate = user?.publisher?.migration_date || false;
		const {
			start_date: filterStartDate,
			customer_type: filterCustomerType,
		} = payload;

		if (
			!isShopifyShop(user) ||
			!migrationDate ||
			!filterStartDate ||
			!filterCustomerType
		) {
			return false;
		}

		return (
			new Date(filterStartDate).getTime() -
				new Date(migrationDate).getTime() <
			0
		);
	}, [user, payload]);

	useEffect(() => {
		setPayload((payload) => ({
			...payload,
			question_id: surveyId,
		}));
	}, [surveyId]);

	useEffect(() => {
		fetchResults();
	}, [payload, fetchResults]);

	// Formatting USD Currency

	// Section for building table sort
	const [sortConfig, setSortConfig] = useState(null);

	useEffect(() => {
		setResults((results) => {
			let sortableItems = results.responses?.map?.((el) => el);
			if (!Array.isArray(sortableItems)) return results;
			if (sortConfig !== null) {
				sortableItems.sort((a, b) => {
					if (a[sortConfig.key] < b[sortConfig.key]) {
						return sortConfig.direction === "ascending" ? -1 : 1;
					}
					if (a[sortConfig.key] > b[sortConfig.key]) {
						return sortConfig.direction === "ascending" ? 1 : -1;
					}
					return 0;
				});
			}
			return { ...results, responses: sortableItems };
		});
	}, [sortConfig]);

	const requestSort = useCallback(key => {
		let direction = "ascending";
		if (
			sortConfig &&
			sortConfig.key === key &&
			sortConfig.direction === "ascending"
		) {
			direction = "descending";
		}
		setSortConfig({ key, direction });
	}, [sortConfig]);

	const getClassNamesFor = useCallback(name => {
		if (!sortConfig) {
			return;
		}
		return sortConfig.key === name ? sortConfig.direction : undefined;
	}, [sortConfig]);
	// END table sort section

	return (
		<ModulePreviewContentChild
			user={user}
			heading="Responses for this survey"
			className="survey-result"
			loading={resultsLoading}
			error={resultsError}
			onFilterChange={handleFilterChange}
			inline={inline}
			hideRedemptionModeFilter
		>
			<section className="survey-results">
				{Array.isArray(results.responses) &&
					results.responses.length !== 0 && (
						<CSVGenerator
							method="POST"
							heading="Export your survey responses"
							requestUrl={FETCH_SURVEY_CSV}
							requestPayload={{
								question: payload?.question_id,
								...payload,
							}}
							downloadedFileName="SurveyResponses"
						/>
					)}
				{Array.isArray(results.responses) &&
					(results.responses.length === 0 ? (
						<FormStatus type="theme">
							No responses recorded so far
						</FormStatus>
					) : (
						<>
							<section className="survey-result-completion">
								<h2>
									Survey completion rate
									{results.displays > 0 && (
										<span>
											{(
												(results.totalResponses /
													results.displays) *
												100
											).toFixed(1)}
											%
										</span>
									)}
								</h2>
								{results.displays ? (
									<ProgressView
										progress={
											results.totalResponses /
											results.displays
										}
									/>
								) : (
									<FormStatus type="theme">
										Not enough data to show completion rates
									</FormStatus>
								)}
							</section>
							<table className="survey-results-table lib-table">
								<thead>
									<tr>
										<th>
											<button
												type="button"
												onClick={() =>
													requestSort("name")
												}
												className={getClassNamesFor(
													"name"
												)}
											>
												Response
											</button>
										</th>
										<th>
											<button
												type="button"
												onClick={() =>
													requestSort("count")
												}
												className={getClassNamesFor(
													"count"
												)}
											>
												Answer Count
											</button>
										</th>
										<th>
											<button
												type="button"
												onClick={() =>
													requestSort("aov")
												}
												className={getClassNamesFor(
													"aov"
												)}
											>
												Average Order Value (AOV)
											</button>
										</th>
										<th>
											<button
												type="button"
												onClick={() =>
													requestSort("tov")
												}
												className={getClassNamesFor(
													"tov"
												)}
											>
												Total Order Value
											</button>
										</th>
										<th>
											<button
												type="button"
												onClick={() =>
													requestSort("percentage")
												}
												className={getClassNamesFor(
													"percentage"
												)}
											>
												% of Responses
											</button>
										</th>
									</tr>
								</thead>
								<tbody>
									{/* Data for each column */}
									{results.responses.map((data) => (
										<tr key={data.name}>
											{/* Response Text */}
											<td>{data.name}</td>
											{/* Number of Responses */}
											<td>{data.count}</td>
											{/* Average Order Value */}
											<td>
												{isNaN(data.aov)
													? "$0"
													: formatter.format(
															data.aov
													  )}
											</td>
											{/* Total Order Value */}
											<td>
												{isNaN(data.tov)
													? "$0"
													: formatter.format(
															data.tov
													  )}
											</td>
											{/* Percentage of Responses */}
											<td>
												{isNaN(data.percentage)
													? "0%"
													: data.percentage + "%"}
											</td>
										</tr>
									))}
									{/* Total Row */}
									<tr>
										<td>
											<strong>Totals</strong>
										</td>
										<td>{results.totalResponses}</td>
										<td>
											{formatter.format(results.totalAov)}
										</td>
										<td>
											{formatter.format(results.totalTov)}
										</td>
										<td>{results.totalPercent + "%"}</td>
									</tr>
								</tbody>
							</table>
						</>
					))}
				<InlineHelperTooltip
					open={showInlineHelperTooltip}
					heading="Hey there!"
					className="survey-results-inline-tooltip"
				>
					We’re still processing your data, for now you’ll only see
					post-migration results when selecting this customer segment.
				</InlineHelperTooltip>
			</section>
		</ModulePreviewContentChild>
	);
});

export default SurveyResult;
