import React, { forwardRef, memo, useCallback, useMemo, useState } from "react";

import "./script-setup.css";

import rechargeHelp1 from "../../img/script/rechargeHelp1.png";
import rechargeHelp2 from "../../img/script/rechargeHelp2.png";
import squarespaceHelp1 from "../../img/script/squarespaceHelp1.png";
import squarespaceHelp2 from "../../img/script/squarespaceHelp2.png";
import squarespaceHelp3 from "../../img/script/squarespaceHelp3.png";
import squarespaceHelp4 from "../../img/script/squarespaceHelp4.png";
import squarespaceHelp5 from "../../img/script/squarespaceHelp5.png";

import gtmHelp1 from "../../img/script/gtmHelp1.png";
import gtmHelp2 from "../../img/script/gtmHelp2.png";
import gtmHelp3 from "../../img/script/gtmHelp3.png";
import wooHelp1 from "../../img/script/wooHelp1.png";
import wooHelp2 from "../../img/script/wooHelp2.png";
import wooHelp3 from "../../img/script/wooHelp3.png";
import wooHelp4 from "../../img/script/wooHelp4.png";
import wooHelp5 from "../../img/script/wooHelp5.png";
import wooHelp6 from "../../img/script/wooHelp7.png";
import wooHelp7 from "../../img/script/wooHelp6.png";
import useIdentity from "../../hooks/useIdentity";
import {
	CodeSnippet,
	FONT_SIZE,
	PaddedContainer,
	SPACING,
	Text,
	Select,
} from "@disco/disco_core";

export const ScriptText = memo(
	forwardRef(({ type, user, className = "", ...rest }, ref) => {
		return (
			<PaddedContainer vPadding={SPACING.REGULAR} tightBottom>
				<CodeSnippet
					alwaysOpen
					ref={ref}
					className={`script-text ${className}`}
					value={
						type !== "GTM"
							? type === "WooCommerce"
								? `<script>
								if (window.location.href.includes("order-received")) {
									const head = document.getElementsByTagName("head")[0];
									let discoScript = document.createElement("script");
									discoScript.type = "text/javascript";
									discoScript.src = "${
										process.env.REACT_APP_ENV ===
											"staging" ||
										process.env.REACT_APP_ENV ===
											"development"
											? "https://widget.disconetwork-staging.com/static/disco.js"
											: "https://widget.disconetwork.com/static/disco.js"
									}"
									discoScript.setAttribute("publisher_id", "${user.publisher.remote_id}");
									discoScript.setAttribute("environment", "${process.env.REACT_APP_ENV}")
									head.appendChild(discoScript);
								}
							</script>`
								: `<script src="${
										process.env.REACT_APP_ENV ===
											"staging" ||
										process.env.REACT_APP_ENV ===
											"development"
											? "https://widget.disconetwork-staging.com/static/disco.js"
											: "https://widget.disconetwork.com/static/disco.js"
								  }" publisher_id="${
										user.publisher.remote_id
								  }" environment="${
										process.env.REACT_APP_ENV
								  }" type="text/javascript"></script>`
							: `<script src="${
									process.env.REACT_APP_ENV === "staging" ||
									process.env.REACT_APP_ENV === "development"
										? "https://widget.disconetwork-staging.com/static/disco.js"
										: "https://widget.disconetwork.com/static/disco.js"
							  }?publisher_id=${
									user.publisher.remote_id
							  }" type="text/javascript"></script>`
					}
					{...rest}
				/>
			</PaddedContainer>
		);
	})
);

const ScriptSetupTab = memo(({ user, setupSteps, script, scriptType }) => (
	<>
		{script && (
			<li className="script-setup-tabs-item">
				Copy the script
				<ScriptText user={user} type={scriptType} />
			</li>
		)}
		{Array.isArray(setupSteps) &&
			setupSteps.map((setupStep, id) => (
				<li className="script-setup-tabs-item" key={id}>
					{setupStep.description}
					{setupStep.img && (
						<img src={setupStep.img} alt="Script Setup" />
					)}
				</li>
			))}
	</>
));

export const RechargeSetup = memo(({ ...props }) => {
	const steps = [
		{
			description: `Click on "settings" -> "checkout"`,
			img: rechargeHelp1,
		},
		{
			description: `Paste Script in “Additional Content & Scripts” Section`,
			img: rechargeHelp2,
		},
	];

	return <ScriptSetupTab {...props} setupSteps={steps} />;
});

export const SquarespaceSetup = memo(({ ...props }) => {
	const steps = [
		{
			description: `Navigate to the “Settings” section of your site`,
			img: squarespaceHelp1,
		},
		{
			description: `Click to the “Advanced” section in “Settings”`,
			img: squarespaceHelp2,
		},
		{
			description: `Click to the “Code Injection” section in “Advanced”`,
			img: squarespaceHelp3,
		},
		{
			description: `At the bottom of “Code Injection”, there is a section to place a script on the “Order Confirmation Page”. Paste the script here.`,
			img: squarespaceHelp4,
		},
		{
			description: `Save and you’re all set!`,
			img: squarespaceHelp5,
		},
	];

	return <ScriptSetupTab {...props} setupSteps={steps} />;
});

export const GTMSetup = memo(({ ...props }) => {
	const steps = [
		{
			description: `Add a new custom HTML tag`,
			img: gtmHelp1,
		},
		{
			description: `Paste Script in tag configuration as shown`,
			img: gtmHelp2,
		},
		{
			description: `Choose a suitable trigger for the tag click 'Save'`,
			img: gtmHelp3,
		},
		{
			description: `Enter a name for the tag and you're all set`,
		},
	];

	return <ScriptSetupTab {...props} setupSteps={steps} />;
});

export const WooCommerceSetup = memo(({ ...props }) => {
	const steps = [
		{
			description: `Navigate to the “Pages” tab in WordPress and select the “Checkout page”`,
			img: wooHelp1,
		},
		{
			description: `Click on the plus (+) sign and select “Custom HTML”`,
			img: wooHelp2,
		},
		{
			description: `Paste your full script in the HTML container and click “Update”`,
			img: wooHelp3,
		},
		{
			description: `Navigate back to WordPress and select “WooCommerce”`,
			img: wooHelp4,
		},
		{
			description: `Click into the “Settings” section and select the “Advanced” tab`,
			img: wooHelp5,
		},
		{
			description: `Scroll down to the “Checkout endpoints” section and copy the value in “Order received”`,
			img: wooHelp6,
		},
		{
			description: `Paste this value in the “if” statement of the script and click “Update”`,
			img: wooHelp7,
		},
	];
	return <ScriptSetupTab {...props} setupSteps={steps} />;
});

const ScriptSetup = memo(({ initial = "", script = true }) => {
	const { user } = useIdentity();
	const [options, optionsData] = useMemo(
		() => [
			{
				recharge: "Recharge",
				squarespace: "Squarespace",
				gtm: "GTM",
				woocommerce: "WooCommerce",
			},
			{
				recharge: { name: "Recharge", component: RechargeSetup },
				squarespace: {
					name: "Squarespace",
					component: SquarespaceSetup,
				},
				gtm: {
					name: "GTM",
					component: GTMSetup,
					alternate: "Google Tag Manger",
				},
				woocommerce: {
					name: "WooCommerce",
					component: WooCommerceSetup,
				},
			},
		],
		[]
	);

	const [active, setActive] = useState(
		typeof user.publisher.platforms?.[0]?.name === "string" &&
			user.publisher.platforms[0].name.toLowerCase() in options
			? user.publisher.platforms[0].name.toLowerCase()
			: "recharge"
	);

	const HelpComponent = useMemo(() => {
		return optionsData[active]?.component || (() => null);
	}, [optionsData, active]);

	const handleChange = useCallback(({ target: { value } }) => {
		setActive(value);
	}, []);

	return (
		<section className="script-setup">
			<Text size={FONT_SIZE.LG_BODY}>Showing steps for </Text>
			<Select options={options} value={active} onChange={handleChange} />

			<ol>
				{
					<HelpComponent
						user={user}
						script={script}
						scriptType={optionsData[active]?.name}
					/>
				}
			</ol>
		</section>
	);
});

export default ScriptSetup;
