/* eslint-disable no-useless-escape */
export const INTEGRATIONS_MODULE_PRIVACY_WEBHOOK_URL =
	"https://merchant.disconetwork.com/identify/optout_users/";
const JS_CODE = ({ privateKey, publicKey, publisherId }) => `
const payload = {
	"users": [
		{
			"email": <USER_EMAIL>,
			"phone_number": <USER_PHONE_NUMBER>
		}
	]
}
const options = {
	method: 'POST',
	headers: {
		"Content-Type": "application/json",
		"X-Public-Key": "${publicKey}",
		"X-Private-Key": "${privateKey}",
		"X-publisher": "${publisherId}"
	},
	body: JSON.stringify(payload)
  };
  
fetch('${INTEGRATIONS_MODULE_PRIVACY_WEBHOOK_URL}', options)
	.then(response => response.json())
	.then(response => console.log(response))
	.catch(err => console.error(err));
`;

const PYTHON_CODE = ({ privateKey, publicKey, publisherId }) => `
import requests

url = "${INTEGRATIONS_MODULE_PRIVACY_WEBHOOK_URL}"

payload = {
	"users": [
		{
			"email": <USER_EMAIL>,
			"phone_number": <USER_PHONE_NUMBER>
		}
	]
}
headers = {
	"Content-Type": "application/json",
	"X-Public-Key": "${publicKey}",
	"X-Private-Key": "${privateKey}",
	"X-publisher": "${publisherId}"
}

response = requests.request("POST", url, json=payload, headers=headers)

print(response.text)
`;
const NODE_CODE = ({ privateKey, publicKey, publisherId }) => `
const { URLSearchParams } = require('url');
const fetch = require('node-fetch');
const payload = {
	"users": [
		{
			"email": <USER_EMAIL>,
			"phone_number": <USER_PHONE_NUMBER>
		}
	]
}
const url = '${INTEGRATIONS_MODULE_PRIVACY_WEBHOOK_URL}';
const options = {
	method: 'POST',
	headers: {
		"Content-Type": "application/json",
		"X-Public-Key": "${publicKey}",
		"X-Private-Key": "${privateKey}",
		"X-publisher": "${publisherId}"
	},
	body: JSON.stringify(payload)
};

fetch(url, options)
	.then(res => res.json())
	.then(json => console.log(json))
	.catch(err => console.error('error:' + err));
`;
const PHP_CODE = ({ privateKey, publicKey, publisherId }) => `
<?php
require_once('vendor/autoload.php');

$client = new \GuzzleHttp\Client();

$response = $client->request('POST', '${INTEGRATIONS_MODULE_PRIVACY_WEBHOOK_URL}', [
	'form_params' => [
		'data' => '{"token": "PUBLIC_KEY", "event": "Ordered Product", "customer_properties": {"$email": "abraham.lincoln@klaviyo.com"}, "properties": {"item_name": "Boots","$value": 100}}'
	],
	'headers' => [
		'Content-Type' => 'application/json',
		'X-Public-Key': '${publicKey}',
		'X-Private-Key': '${privateKey}',
		'X-publisher': '${publisherId}'
	],
]);

echo $response->getBody();
`;

export const INTEGRATIONS_MODULE_WEBHOOK_CODE_LANGUAGES = {
	JAVASCRIPT: 0,
	PYTHON: 1,
	NODE: 2,
	PHP: 3,
};
export const INTEGRATIONS_MODULE_WEBHOOK_CODE = ({ user, language }) => {
	console.log({ user, language });
	const {
		publisher: {
			api_key: { private_key: privateKey, public_key: publicKey },
			remote_id: publisherId,
		},
	} = user;

	return {
		[INTEGRATIONS_MODULE_WEBHOOK_CODE_LANGUAGES.JAVASCRIPT]: {
			setup: null,
			code: JS_CODE({ privateKey, publicKey, publisherId }),
		},
		[INTEGRATIONS_MODULE_WEBHOOK_CODE_LANGUAGES.PYTHON]: {
			setup: `$ python -m pip install requests`,
			code: PYTHON_CODE({ privateKey, publicKey, publisherId }),
		},
		[INTEGRATIONS_MODULE_WEBHOOK_CODE_LANGUAGES.NODE]: {
			setup: `$ npm install node-fetch --save`,
			code: NODE_CODE({ privateKey, publicKey, publisherId }),
		},
		[INTEGRATIONS_MODULE_WEBHOOK_CODE_LANGUAGES.PHP]: {
			setup: `$ composer require guzzlehttp/guzzle`,
			code: PHP_CODE({ privateKey, publicKey, publisherId }),
		},
	};
};
