import React, { memo, forwardRef, useMemo, Fragment } from "react";
import PropTypes from "prop-types";
import { PaddedContainer, SPACING } from "../PaddedContainer";
import { FONT_COLOR, FONT_SIZE, Text } from "../Typography";
import genPackageClassName from "../../utils/genPackageClassName";
import { CheckIcon } from "../../Icons";
import { childrenPropTypes } from "../../utils";

import "./stepped-progress-bar.css";

const SteppedProgressBarElement = memo(
	forwardRef(
		(
			{
				done = false,
				hideChildrenOnDone = false,
				doneProgress = false,
				active = false,
				className = "",
				children,
				...props
			},
			ref
		) => {
			const parsedDoneProgress = useMemo(
				() =>
					typeof doneProgress === "string"
						? doneProgress
						: `${
								doneProgress <= 1
									? doneProgress * 100
									: doneProgress
						  }% Done`,
				[doneProgress]
			);
			return (
				<PaddedContainer
					className={genPackageClassName({
						base: "stepped-progress-bar-element-container",
						additional: className,
					})}
					ref={ref}
				>
					<PaddedContainer
						className={genPackageClassName({
							base: "stepped-progress-bar-element flex-center",
							conditionals: {
								"stepped-progress-bar-element-done": done,
								"stepped-progress-bar-element-active": active,
							},
						})}
						{...props}
					>
						{done ? (
							<>
								<CheckIcon />
								{!hideChildrenOnDone && children}
							</>
						) : (
							children
						)}
					</PaddedContainer>
					{active && doneProgress !== false && (
						<PaddedContainer
							className={genPackageClassName({
								base: "stepped-progress-bar-element-done-progress",
							})}
						>
							{parsedDoneProgress}
						</PaddedContainer>
					)}
				</PaddedContainer>
			);
		}
	)
);
SteppedProgressBarElement.displayName = "SteppedProgressBarElement";
SteppedProgressBarElement.propTypes = {
	done: PropTypes.bool,
	hideChildrenOnDone: PropTypes.bool,
	doneProgress: PropTypes.bool,
	active: PropTypes.bool,
	className: PropTypes.string,
	children: childrenPropTypes,
};

export const SteppedProgressBar = memo(
	({
		className = "",
		title = "",
		stepData,
		activeStep,
		activeStepProgress = false,
		hideDoneState = false,
		addFinalizeStep = false,
		contractSteps = false,
		hideChildrenOnDone = false,
		...props
	}) => {
		const renderableStepData = useMemo(() => {
			const renderableData = [];

			if (stepData.length > 3 && contractSteps) {
				stepData.forEach((stepDataItem, id) => {
					if (
						id >= Math.min(activeStep, stepData.length - 2) - 1 &&
						id <= Math.max(activeStep, 1)
					) {
						renderableData.push({
							...stepDataItem,
							active: id === activeStep,
						});
					}
				});
				const rem = stepData.length - 1 - Math.max(activeStep, 1);
				if (rem > 0) {
					if (rem === 1) {
						renderableData.push({
							...stepData[activeStep + 1],
							active: false,
						});
					} else {
						renderableData.push({
							name: ` + ${rem} more`,
							active: false,
						});
					}
				}
			} else {
				stepData.forEach((stepDataItem, id) => {
					renderableData.push({
						...stepDataItem,
						active: id === activeStep,
					});
				});
			}
			if (addFinalizeStep) {
				renderableData.push({
					name: "Finalize",
					active: activeStep === stepData.length,
				});
			}
			return renderableData;
		}, [activeStep, addFinalizeStep, contractSteps, stepData]);

		if (!renderableStepData) {
			return;
		}

		return (
			<PaddedContainer
				className={genPackageClassName({
					base: "stepped-progress-bar",
					conditionals: {
						"stepped-progress-bar-with-title": title,
					},
					additional: className,
				})}
				{...props}
			>
				{title && (
					<Text
						className={genPackageClassName({
							base: "stepped-progress-bar-title flex-center",
						})}
						size={FONT_SIZE.LG_BODY}
						color={FONT_COLOR.WHITE}
						hPadding={SPACING.REGULAR}
					>
						{title}
					</Text>
				)}
				{renderableStepData.map((renderableStepDataItem, idx) => {
					const { name, active } = renderableStepDataItem;
					const isStepDone =
						renderableStepData.findIndex(({ active }) => active) >
						idx;
					const done = hideDoneState ? false : isStepDone;
					const doneProgress =
						active && activeStepProgress !== false
							? activeStepProgress
							: false;

					return (
						<Fragment key={`stepped-progress-bar-element-${name}`}>
							<SteppedProgressBarElement
								active={active}
								done={done}
								doneProgress={doneProgress}
								hideChildrenOnDone={hideChildrenOnDone}
							>
								{name}
							</SteppedProgressBarElement>
							{/* TODO: ADD SUPPORT FOR SHOP DISCO SEPARATOR */}
						</Fragment>
					);
				})}
			</PaddedContainer>
		);
	}
);
SteppedProgressBar.displayName = "SteppedProgressBar";
SteppedProgressBar.propTypes = {
	className: PropTypes.string,
	title: childrenPropTypes,
	stepData: PropTypes.array,
	activeStep: PropTypes.number,
	activeStepProgress: childrenPropTypes,
	hideDoneState: PropTypes.bool,
	addFinalizeStep: PropTypes.bool,
	contractSteps: PropTypes.bool,
	hideChildrenOnDone: PropTypes.bool,
};
