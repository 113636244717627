import React, { memo } from "react";
import anim from "../../utils/anim";
import "./floating-modal.css";

import OldModal from "./Modal";
import { Modal, SPACING, FONT_SIZE } from "@disco/disco_core";
export const BELOW_RIGHT = 0,
	SIDEBAR = 1,
	BOTTOM_LEFT = 2,
	TOP_LEFT = 3,
	RIGHT_SIDE = 4,
	LEFT_SIDE = 5;

const buffer = 15;
const positions = [
	({ x, y, height, width }) => [
		`${y + height + buffer}px`,
		`${window.innerWidth - x - width}px`,
		"initial",
		"initial",
	],
	() => ["0px", "0px", "0px", "initial"],
	({ x, y, height, width }) => [
		`${y + height + buffer}px`,
		"initial",
		"initial",
		`${x}px`,
	],
	({ x, y, height, width }) => [
		`initial`,
		"initial",
		`${window.innerHeight - y + 20}px`,
		`${x}px`,
	],
	({ x, y, height, width }) => [
		`${y}px`,
		`initial`,
		`initial`,
		`${x + width + 10}px`,
	],
	({ x, y, height, width }) => [
		`${y}px`,
		`${window.innerWidth - x + 10}px`,
		`initial`,
		`initial`,
	],
];

export { positions as FLOATING_MODAL_POSITIONS };

const FloatingModal = memo(
	({
		target,
		className,
		position,
		overlayClassName = "",
		heading,
		style,
		designSystem = false,
		...rest
	}) => {
		let positioningStyles = {};

		if (
			(target?.current || position === SIDEBAR) &&
			(typeof position === "function" ||
				typeof positions?.[position] === "function")
		) {
			const [top, right, bottom, left] =
				typeof position === "function"
					? position(target.current.getBoundingClientRect())
					: positions?.[position](
							position === SIDEBAR
								? {}
								: target.current.getBoundingClientRect()
					  );
			positioningStyles = {
				top,
				right,
				bottom,
				left,
				position: "absolute",
			};
		}
		return (
			<>
				{designSystem ? (
					<Modal
						overlayClassName={`floating-modal-overlay ${overlayClassName}`}
						className={`floating-modal  ${
							position === SIDEBAR ? "sidebar-modal" : ""
						} ${className}`}
						title={heading}
						customVariant={
							position === SIDEBAR ? anim.sidebar : null
						}
						style={{ ...positioningStyles, ...style }}
						hPadding={SPACING.REGULAR}
						vPadding={SPACING.REGULAR}
						titleProps={{
							size: FONT_SIZE.BODY,
							marginLeft: SPACING.REGULAR,
						}}
						{...rest}
					/>
				) : (
					<OldModal
						overlayClassName={`floating-modal-overlay ${overlayClassName}`}
						className={`floating-modal  ${
							position === SIDEBAR ? "sidebar-modal" : ""
						} ${className}`}
						heading={heading}
						customVariant={
							position === SIDEBAR ? anim.sidebar : null
						}
						style={{ ...positioningStyles, ...style }}
						{...rest}
					/>
				)}
			</>
		);
	}
);

export default FloatingModal;
