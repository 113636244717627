import React, { memo, useCallback, useMemo, useState } from "react";
import ProductsContainer from "../ProductsContainer";
import WidgetModule, { WidgetModuleToggler } from "./WidgetModule";
import useViewTrack from "../../hooks/useViewTrack";

import {
	PREVIEW_TYPES_IDX,
	SAVE_BRAND_URL,
	TOGGLE_UPSELL_DISCOUNT_URL,
	TOGGLE_UPSELL_URL,
} from "../../conf";
import isShopifyShop from "../../utils/isShopifyShop";

import {
	PLATFORM_UPSELL_TOGGLE,
	PLATFORM_UPSELL_TOGGLE_SHOW_OFFER_CODE,
	PLATFORM_UPSELL_TOGGLE_SHOW_PRICES,
	PLATFORM_VIEW_UPSELL_THANKYOU,
} from "../../events";
import useIdentity from "../../hooks/useIdentity";
import "./upsell-module.css";

const UpsellModuleSettings = memo(({ user, setUser, triggerRefresh }) => {
	return (
		<>
			<WidgetModuleToggler
				user={user}
				setUser={setUser}
				moduleName="upsells"
				toggleUrl={TOGGLE_UPSELL_URL}
				remoteKey="upsells"
				onToggle={triggerRefresh}
				event={PLATFORM_UPSELL_TOGGLE}
				designSystem
			/>

			{isShopifyShop(user) && user.in_smart_upsells_beta && (
				<WidgetModuleToggler
					toggleUrl={SAVE_BRAND_URL}
					remoteKey="smart_upsells"
					moduleName="Disco Smart Upsells"
					user={user}
					setUser={setUser}
					onToggle={triggerRefresh}
					className="upsell-edit-view"
					designSystem
				/>
			)}
			<WidgetModuleToggler
				toggleUrl={SAVE_BRAND_URL}
				remoteKey="show_upsell_price"
				moduleName="to show prices for upsell products"
				user={user}
				setUser={setUser}
				onToggle={triggerRefresh}
				event={PLATFORM_UPSELL_TOGGLE_SHOW_PRICES}
				className="upsell-edit-view"
				designSystem
			/>
			<WidgetModuleToggler
				toggleUrl={TOGGLE_UPSELL_DISCOUNT_URL}
				remoteKey="upsell_discount"
				moduleName="to show offer code on upsells"
				user={user}
				setUser={setUser}
				onToggle={triggerRefresh}
				event={PLATFORM_UPSELL_TOGGLE_SHOW_OFFER_CODE}
				className="upsell-edit-view"
				designSystem
			/>
		</>
	);
});

const UpsellModuleOrdering = memo(({ user, setUser, triggerRefresh }) => {
	const [editMode, setEditMode] = useState(false);
	return (
		<ProductsContainer
			upsellOnly={true}
			user={user}
			setUser={setUser}
			setRefresh={triggerRefresh}
			editMode={editMode}
			setEditMode={setEditMode}
		/>
	);
});

const UpsellModule = memo(() => {
	const { user, setUser } = useIdentity();

	useViewTrack(PLATFORM_VIEW_UPSELL_THANKYOU);

	const renderSettings = useCallback(
		(props) => (
			<UpsellModuleSettings user={user} setUser={setUser} {...props} />
		),
		[user, setUser]
	);
	const renderOrdering = useCallback(
		(props) => (
			<UpsellModuleOrdering user={user} setUser={setUser} {...props} />
		),
		[user, setUser]
	);

	const tabOptions = useMemo(() => {
		let options = [
			{
				name: "Manage Upsell",
				component: renderSettings,
			},
			{
				name: "Manage Product Ordering",
				component: renderOrdering,
			},
		];
		if (!user.publisher.smart_upsells) {
			return options;
		}
		return options.slice(0, 1);
	}, [user, renderSettings, renderOrdering]);

	return (
		<WidgetModule
			user={user}
			setUser={setUser}
			tabOptions={tabOptions}
			heading={user?.isNewIa ? "Upsell on DiscoFeed" : "Upsell"}
			className="new-upsell-module"
			previewType={PREVIEW_TYPES_IDX.UPSELL}
		/>
	);
});

export default UpsellModule;
