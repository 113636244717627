import React, {
	forwardRef,
	memo,
	useCallback,
	MouseEvent,
	MouseEventHandler,
	ReactNode,
} from "react";

import genPackageClassName from "../../utils/genPackageClassName";

import "./tag.css";
import {
	PaddedContainer,
	PaddedContainerProps,
	SPACING,
} from "../PaddedContainer";
import { FONT_SIZE, Text, TextProps } from "../Typography";
import {
	Extend,
	GenericRef,
	ObjectValuesAsTypes,
	StandardComponentWithChildren,
} from "../../shared.types";

export const TAG_TYPE = {
	DEFAULT: "default",
	THEME: "theme",
	INFO: "info",
	SUCCESS: "success",
	ERROR: "error",
} as const;

export type TagType = ObjectValuesAsTypes<typeof TAG_TYPE>;

export type NativeTagProps = {
	disabled?: boolean;
	type?: TagType;
	editable?: boolean;
	iconLeft?: ReactNode;
	iconRight?: ReactNode;
	onClick?: MouseEventHandler<HTMLElement>;
	small?: boolean;
	contentProps?: TextProps;
};

export type TagProps = Extend<PaddedContainerProps, NativeTagProps>;

type TagComponent = StandardComponentWithChildren<TagProps>;

export const Tag: TagComponent = memo(
	forwardRef(
		(
			{
				className = "",
				disabled = false,
				type = TAG_TYPE.DEFAULT,
				editable = false,
				children,
				iconLeft = null,
				iconRight = null,
				onClick,
				small = false,
				contentProps,
				...rest
			},
			ref?: GenericRef
		) => {
			const handleClick = useCallback(
				(e: MouseEvent<HTMLElement>) => {
					if (disabled || typeof onClick !== "function") return;
					onClick(e);
				},
				[disabled, onClick]
			);

			return (
				<PaddedContainer
					ref={ref}
					vPadding={SPACING.MICRO}
					hPadding={SPACING.REGULAR}
					className={genPackageClassName({
						base: "tag",
						conditionals: {
							"tag-disabled": disabled,
							[`tag-${type}`]: type,
							"tag-has-left-icon": !!iconLeft,
							"tag-has-right-icon": !!iconRight,
							"tag-editable": editable,
							"tag-small": small,
						},
						additional: className,
					})}
					onClick={handleClick}
					{...rest}
				>
					{iconLeft}
					<Text
						size={FONT_SIZE[small ? "LABEL" : "BODY"]}
						thin
						className={genPackageClassName({
							base: "tag-content",
						})}
						{...contentProps}
					>
						{children}
					</Text>
					{iconRight}
				</PaddedContainer>
			);
		}
	)
);
