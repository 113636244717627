import React, { useState, useCallback, memo, forwardRef, useMemo } from "react";
import { Tooltip } from "../CoreUI";
import { ChromePicker } from "react-color";
import "./brand-color-edit-view.css";
import EditView from "../EditView";
import NewEditView from "../NewEditView";
import OverlayPreview from "../previews/OverlayPreview";
import { SAVE_COLOR_URL } from "../../conf";

const colorValidator = (color) => {
	if (color.indexOf("#") !== 0 || color.length < 4 || color.length > 7) {
		return [
			false,
			"Please enter a valid hex color value or use the color picker",
		];
	}
	return [true, color];
};

export default memo(
	forwardRef(
		(
			{
				user,
				setUser,
				onUpdate,
				onMouseEnter,
				onMouseLeave,
				editMode = false,
				designSystem = false,
			},
			ref
		) => {
			const [showPicker, setShowPicker] = useState(false);

			const handleFocus = useCallback(() => {
				setShowPicker(true);
			}, []);
			const handleLoseFocus = useCallback(() => {
				setShowPicker(false);
			}, []);
			const handleSave = useCallback(() => {
				handleLoseFocus();
				if (typeof onUpdate === "function") onUpdate();
			}, [onUpdate, handleLoseFocus]);

			const brandEditViewChildren = useCallback(
				(value, setValue) => {
					const handleColorChange = (color) => {
						setValue(color.hex);
					};
					return (
						<section className="brand-color-edit-view-selector-container">
							<Tooltip
								open={showPicker}
								className="brand-color-edit-view-selector"
							>
								<ChromePicker
									color={value}
									onChange={handleColorChange}
									className="brand-color-edit-view-chrome"
									width="100%"
									disableAlpha
								/>
							</Tooltip>
							<div
								className="brand-color-edit-view-icon"
								style={{
									backgroundColor: value || "#fff",
								}}
							/>
						</section>
					);
				},
				[showPicker]
			);

			const Element = useMemo(
				() => (designSystem ? NewEditView : EditView),
				[designSystem]
			);

			return (
				<Element
					user={user}
					setUser={setUser}
					remoteKey="modal_color"
					placeholder="Enter your primary shop color"
					url={SAVE_COLOR_URL}
					name={"Shop Color"}
					onClick={handleFocus}
					onBlur={handleLoseFocus}
					// onMouseEnter={handleFocus}
					validator={colorValidator}
					onEdit={handleSave}
					onDiscard={handleLoseFocus}
					className="brand-color-edit-view"
					ref={ref}
					tooltipTitle="Preview your Accent Color"
					tooltipText={
						<>
							<div className="brand-color-tooltip-body">
								Your accent color will be used throughout the
								Disco experience. In this example, it's found
								behind the white card.
							</div>
							<OverlayPreview user={user} editMode={editMode} />
						</>
					}
					brandColor
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
				>
					{brandEditViewChildren}
				</Element>
			);
		}
	)
);
