import React, { memo, useMemo, useCallback } from "react";
import { BorderedCard, ModalSelectHLine } from "../CoreUI";
import SHIELD_CHECK_IMG from "../../img/shield-check.svg";
import Modal from "./Modal";

import "./tos-update-modal.css";
import { PolicyMigrationFlowFooter } from "../PolicyMigrationFlow";
import { PRIVACY_POLICY_URL, TOS_URL } from "../../conf";
import useTrackView from "../../hooks/useTrackView";
import {
	PLATFORM_PRIVACY_UPDATE_PRIVACY_LINK_CLICK,
	PLATFORM_PRIVACY_UPDATE_TOS_LINK_CLICK,
	PLATFORM_PRIVACY_UPDATE_TOS_MODAL_VIEW,
} from "../../events";
import windowRedirect from "../../utils/windowRedirect";
import useTrack from "../../hooks/useTrack";

const TOSUpdateModal = memo(({ open, onClose }) => {
	useTrackView({
		event: PLATFORM_PRIVACY_UPDATE_TOS_MODAL_VIEW,
		canTrack: open,
	});
	const track = useTrack();

	const cardHeader = useMemo(
		() => (
			<>
				<header>
					<img src={SHIELD_CHECK_IMG} alt="" />
					<h2>Updated Terms of Service</h2>
				</header>
				<ModalSelectHLine />
			</>
		),
		[]
	);
	const cardFooter = useMemo(() => {
		return <PolicyMigrationFlowFooter isIaNav={false} onClose={onClose} />;
	}, [onClose]);

	const handleTOS = useCallback(() => {
		track(PLATFORM_PRIVACY_UPDATE_TOS_LINK_CLICK, {
			isIaNav: false,
		});
		windowRedirect(TOS_URL);
	}, [track]);
	const handlePrivacy = useCallback(() => {
		track(PLATFORM_PRIVACY_UPDATE_PRIVACY_LINK_CLICK, {
			isIaNav: false,
		});
		windowRedirect(PRIVACY_POLICY_URL);
	}, [track]);

	const renderContent = useCallback(() => {
		return (
			<BorderedCard
				header={cardHeader}
				className="tos-update-modal-card"
				footer={cardFooter}
			>
				<section className="tos-update-modal-card-text">
					As part of our ongoing efforts to provide quality services
					to brands and their customers, we have updated our{" "}
					<span onClick={handleTOS}>Terms of Service</span> and{" "}
					<span onClick={handlePrivacy}>Privacy Policy</span>. These
					changes reflect our current terms and practices and provide
					additional transparency around our data practices and the
					choices available to brands and their customers. We're
					excited to empower brands in the Disco Network with more
					opportunities to reach and acquire new shoppers!
				</section>
			</BorderedCard>
		);
	}, [cardHeader, cardFooter, handlePrivacy, handleTOS]);
	return <Modal customRenderer={renderContent} open={open} />;
});

export default TOSUpdateModal;
