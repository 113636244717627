import {
	PLATFORM_CLICK_ON_PERFORMANCE_EMAILS,
	PLATFORM_CLICK_ON_PERFORMANCE_PAID_CAMPAIGN,
	PLATFORM_CLICK_ON_PERFORMANCE_PARTNERSHIPS,
	PLATFORM_CLICK_ON_PERFORMANCE_SUMMARY,
	PLATFORM_CLICK_ON_PERFORMANCE_SURVEYS,
} from "../events";
import isShopifyShop from "./isShopifyShop";

const events = {
	Summary: PLATFORM_CLICK_ON_PERFORMANCE_SUMMARY,
	Network: PLATFORM_CLICK_ON_PERFORMANCE_PARTNERSHIPS,
	"Paid Campaign": PLATFORM_CLICK_ON_PERFORMANCE_PAID_CAMPAIGN,
	Emails: PLATFORM_CLICK_ON_PERFORMANCE_EMAILS,
	Surveys: PLATFORM_CLICK_ON_PERFORMANCE_SURVEYS,
};

// TODO: EXPORT TO BE MORE GENERIC AND USE IN PLACES LIKE THE SETTINGS TAB
const genTabbedOptions = (options, activeIdx) => {
	return Object.entries(options).reduce((map, [key, value], idx) => {
		if (!value) {
			return map;
		}
		return [
			...map,
			{
				active: value === activeIdx,
				name: key,
				urlKey: value,
				event: events[key],
			},
		];
	}, []);
};

const genSidebarOptions = (options, prefix) => {
	return Object.entries(options).reduce((map, [key, value], idx) => {
		if (!value) {
			return map;
		}
		return [
			...map,
			{
				title: key,
				name: value,
				to: `${prefix}/${value}`,
				event: events[key],
			},
		];
	}, []);
};

export const isPerformanceInMaintenance = ({ user } = {}) =>
	!!user?.performance_maintenance_mode;

const getPerformanceSubheadings = ({
	user,
	activeIdx = null,
	sidebar = false,
} = {}) => {
	let defaultOptions = {
		Summary: "summary",
		Network: "network",
		"Cross-Sell": false,
		Emails: false,
		Surveys: false
	};

	const IN_MAINTENANCE_MODE = isPerformanceInMaintenance({ user });
	if (!user || IN_MAINTENANCE_MODE) {
		if (IN_MAINTENANCE_MODE) {
			defaultOptions = {};
		}
		if (!sidebar) {
			return genTabbedOptions(defaultOptions, activeIdx);
		}
		return genSidebarOptions(defaultOptions, "/performance");
	}

	// TODO this re-definition should get optimized
	if (user.publisher.has_bod_dashboard) {
		defaultOptions = {
			"Cross-Sell": "crossSell",
			Network: "network",
			Summary: "summary",
			Emails: false,
			Surveys: false
		}
	}

	if (user.publisher.has_survey) {
		defaultOptions.Surveys = "surveyPerformance";
	}
	if (user.publisher.shopify_emails_enabled) {
		defaultOptions.Emails = "emailPerformance";
	}
	if (!sidebar) {
		return genTabbedOptions(defaultOptions, activeIdx);
	}
	return genSidebarOptions(defaultOptions, "/performance");
};

export default getPerformanceSubheadings;
