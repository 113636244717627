export const makeValidParam = (param) => {
	const res = { ...param, param_name: Number(param.param_name) };
	delete res.id;
	return res;
};
export const validateParam = (param) => {
	if (isNaN(param.param_name) || Number(param.param_name) < 0) {
		return "Please select the 'type' of all UTM Parameters";
	}
	if (!param.param_value?.trim?.()?.length) {
		return "Please enter a value for all the parameters";
	}
	return false;
};

export const initialParam = {
	param_name: -1,
	param_value_earned: "",
	param_value_premium: "",
};

export const PARAM_TYPES = {
	PREMIUM: 1,
	UPSELL: 2,
};
