import React, { forwardRef, memo, useMemo, useRef } from "react";
import genPackageClassName from "../../utils/genPackageClassName";
import {
	GuidedTooltip,
	OffsetGuidedTooltip,
	OffsetGuidedTooltipProps,
} from "../GuidedTooltip";
import {} from "../BaseTooltip";

import useModal from "../../hooks/useModal";
import { PaddedContainer, PaddedContainerProps } from "../PaddedContainer";
import { mergeRefs } from "../../utils";
import { Text } from "../Typography";
import { GenericRef, Extend } from "../../shared.types";

import "./tooltip.css";
import { InfoCircleIcon } from "../../Icons";

type NativeTooltipProps = {
	activator?: React.ReactNode;
	activatorClassName?: string;
	activatorSize?: number;
	activatorProps?: PaddedContainerProps;
};

export type TooltipProps = Extend<OffsetGuidedTooltipProps, NativeTooltipProps>;

type TooltipComponent = (props: TooltipProps) => React.ReactNode | null;

export const Tooltip: TooltipComponent = memo(
	forwardRef(
		(
			{
				activator,
				className = "",
				activatorClassName = "",
				activatorSize,
				activatorProps,
				initialOffset,
				animateOffset,
				...rest
			}: TooltipProps,
			ref?: GenericRef
		) => {
			const { open, handleOpen, handleClose } = useModal(false);
			const activatorRef = useRef(null);

			const ActivatorContainer =
				typeof activator === "string" ? Text : PaddedContainer;

			const hasOffset = !!initialOffset || !!animateOffset;
			const TooltipElement = hasOffset
				? OffsetGuidedTooltip
				: GuidedTooltip;

			const tooltipProps = useMemo(() => {
				let props = rest;
				if (!hasOffset) return props;
				return { ...props, initialOffset, animateOffset };
			}, [initialOffset, animateOffset, rest, hasOffset]);

			const activatorStyles = useMemo(() => {
				const { style = {} } = activatorProps || {};
				if (!activatorSize) return style;
				return {
					"--activator-size": activatorSize,
					...style,
				};
			}, [activatorProps, activatorSize]);

			return (
				<>
					<ActivatorContainer
						onMouseEnter={handleOpen}
						onMouseLeave={handleClose}
						ref={mergeRefs(ref, activatorRef)}
						fitContent
						centerContent
						{...activatorProps}
						style={activatorStyles}
						className={genPackageClassName({
							base: "tooltip-activator",
							additional:
								activatorClassName ||
								activatorProps?.className ||
								"",
						})}
					>
						{activator}
					</ActivatorContainer>
					<TooltipElement
						className={genPackageClassName({
							base: "tooltip",
							additional: className,
						})}
						open={open}
						target={activatorRef}
						{...tooltipProps}
					/>
				</>
			);
		}
	)
);

type InformationTooltipComponent = (
	props: TooltipProps
) => React.ReactNode | null;

export const InformationTooltip: InformationTooltipComponent = memo(
	forwardRef(({ ...rest }: TooltipProps, ref?: GenericRef) => (
		<Tooltip
			activator={<InfoCircleIcon />}
			initialOffset={{ left: 5, top: 10 }}
			animateOffset={{ left: 5, top: -20 }}
			ref={ref}
			{...rest}
		/>
	))
);
