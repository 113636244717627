const parseError = (
	error,
	{ verbose = false, justReturnErrorData = false } = {}
) => {
	if (typeof error === "string") {
		return error;
	}
	if (error && error.response) {
		if (error.response.status / 100 >= 5) {
			return `An unknown error occurred (${error.response.status})`;
		}
		if (typeof error.response.data === "string") {
			return error.response.data.length < 100
				? error.response.data
				: `An unknown error occurred (${error.response.status})`;
		} else {
			const errorData = error.response.data;
			if (justReturnErrorData) return errorData;

			if (verbose) {
				if (typeof errorData === "object") {
					let parsedErrorData = {};
					Object.entries(errorData).forEach(
						([remoteKey, remoteKeyErrors]) => {
							parsedErrorData[remoteKey] = Array.isArray(
								remoteKeyErrors
							)
								? remoteKeyErrors.join(",")
								: remoteKeyErrors;
						}
					);

					return parsedErrorData;
				}
				return;
			}

			if (Array.isArray(errorData)) {
				return errorData.join(",");
			} else {
				return Object.entries(errorData)
					.map((err) => {
						if (
							Array.isArray(err[1]) ||
							typeof err[1] !== "object"
						) {
							return err[1];
						}
						if (typeof err[1] === "object") {
							return (
								err[1].error ||
								"An error occurred, please try again later"
							);
						}
						return "";
					})
					.join(", ");
			}
		}
	} else {
		return "Could not connect to the internet. Try again later.";
	}
};

export default parseError;
