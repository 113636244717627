import {
	Asset,
	Button,
	FONT_COLOR,
	FONT_SIZE,
	InfoCircleIcon,
	PaddedContainer,
	SPACING,
	Text,
} from "@disco/disco_core";
import React, { memo, useCallback } from "react";
import windowRedirect from "../../utils/windowRedirect";

import "./bod-banner.css";

const BoDBanner = memo(() => {
	const handleHelpRedirect = useCallback(
		() =>
			windowRedirect(
				"https://discotechnology.zendesk.com/hc/en-us/articles/8135424192923-Guaranteed-CPA"
			),
		[]
	);

	const handleActionRedirect = useCallback(
		() =>
			windowRedirect(
				"https://www.disconetwork.com/new-site/discos-guaranteed-cpa-model"
			),
		[]
	);

	return (
		<PaddedContainer className="bod-banner-container">
			<Asset size={20} color={FONT_COLOR.WHITE}>
				<InfoCircleIcon />
			</Asset>
			<Text
				inline
				color={FONT_COLOR.WHITE}
				size={FONT_SIZE.BODY}
				marginLeft={SPACING.TINY}
				marginRight={SPACING.TINY}
			>
				Heads up! You need to submit your{" "}
				<Text
					inline
					thick
					className="bod-banner-text-link"
					onClick={handleHelpRedirect}
				>
					Guaranteed CPA metrics
				</Text>{" "}
				to display on the Disco Network.
			</Text>
			<Button
				small
				secondary
				rounded={false}
				className="bod-banner-submit"
				onClick={handleActionRedirect}
			>
				Submit Metrics
			</Button>
		</PaddedContainer>
	);
});

export default BoDBanner;
