import React from "react";
import { BiGridAlt } from "react-icons/bi";
import { FiActivity, FiSettings, FiShoppingCart } from "react-icons/fi";
import {
	PLATFORM_CLICK_ON_DISCO_FEED,
	PLATFORM_CLICK_ON_PERFORMANCE,
} from "../events";
import getDiscofeedSidebarOptions from "./getDiscofeedSidebarOptions";
import getPerformanceSubheadings from "./getPerformanceSubheadings";

// @DEV, when making updates to urls, update the corresponding urls, in ./conf.js at CTA_LINK_MAPPING;
export const SIDEBAR_OPTIONS = [
	{
		props: (active) => ({
			active,
			name: "performance",
			title: "Performance",
			to: "/performance",
			icon: <FiActivity />,
			event: PLATFORM_CLICK_ON_PERFORMANCE,
		}),
		children: null,
	},
	{
		props: (active) => ({
			active,
			name: "manage",
			title: "DiscoFeed",
			to: "/manage",
			icon: <FiShoppingCart />,
			event: PLATFORM_CLICK_ON_DISCO_FEED,
		}),

		children: null,
	},
	{
		props: (active) => ({
			active,
			name: "moreTools",
			title: "More tools",
			to: "/moreTools",
			icon: <BiGridAlt />,
			event: undefined,
		}),

		children: [
			{
				title: "Customer Surveys",
				name: "surveys",
				to: "/moreTools/surveys",
			},
			{
				title: "Email",
				name: "emails",
				to: "/moreTools/emails",
			},
		],
	},

	{
		props: (active) => ({
			active,
			name: "settings",
			title: "Settings",
			to: "/settings",
			icon: <FiSettings />,
		}),
		children: [
			{
				title: "Brand",
				name: "brand",
				to: "/settings/brand",
			},
			{
				title: "Integrations",
				name: "integrations",
				to: "/settings/integrations",
			},
			{
				title: "Payment",
				name: "payment",
				to: "/settings/payment",
			},
			{
				title: "Team",
				name: "team",
				to: "/settings/team",
			},
			{
				title: "Page Setup",
				name: "pageSetup",
				to: "/settings/pageSetup",
			},
			{
				title: "Your Account",
				name: "account",
				to: "/settings/account",
			},
		],
	},
];

const getSidebarOptions = (user) => {
	return SIDEBAR_OPTIONS.filter(
		(options) => !options?.superOnly || user.isSuper
	).map((navDataItem) => {
		if (navDataItem.props().name === "performance") {
			return {
				...navDataItem,
				children: getPerformanceSubheadings({ user, sidebar: true }),
			};
		} else if (navDataItem.props().name === "manage") {
			return {
				...navDataItem,
				children: getDiscofeedSidebarOptions(user),
			};
		}

		return navDataItem;
	});
};

export default getSidebarOptions;
