import React, { useEffect, useRef } from "react";
import { FaShopify } from "react-icons/fa";
import { SHOPIFY_SYNC_URL } from "../../conf";
import useResource from "../../hooks/useResource";
import { Button, Card, FormStatus, Loader } from "../CoreUI";
import "./shopify.css";
const ShopifyCard = React.memo(({ user, setUser }) => {
	const [{ loading, error, data }, load, reset] = useResource(
		{
			url: SHOPIFY_SYNC_URL,
			method: "GET",
		},
		false
	);

	const refreshRef = useRef(null);

	useEffect(() => {
		if (!data) {
			return;
		}
		setUser((user) => ({ ...user, product_sync_complete: true }));
		reset();
	}, [data, reset, setUser]);

	useEffect(() => {
		if (user.product_sync_complete && refreshRef.current === null) {
			refreshRef.current = setTimeout(() => {
				window.location.reload();
			}, 10000);
			return () => clearTimeout(refreshRef.current);
		}
	}, [user.product_sync_complete]);

	return (
		<Card className="shopify-card">
			<div className="shopify-logo">
				<FaShopify />
			</div>

			<h2>Connected</h2>
			<p>
				Your Disco account is linked with
				<span> {user.shopify_shop}.myshopify.com </span> store!
			</p>
			{!loading && !user.product_sync_complete && (
				<Button onClick={load}>Sync Products Now</Button>
			)}
			{loading && <Loader />}
			{error && (
				<FormStatus>Whoop! Could not sync. Try again later </FormStatus>
			)}
			{user.product_sync_complete && (
				<FormStatus type="theme">
					Your products are syncing. This may take a couple of
					minutes,
					<br />
					depending on the size of your product catalogue.
					<Loader light />
				</FormStatus>
			)}
		</Card>
	);
});

export default ShopifyCard;
