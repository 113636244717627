import { useEffect } from "react";

const useMessage = (type, handler) => {
	useEffect(() => {
		const handleMessage = ({ data: { type: messageType, payload } }) => {
			if (messageType !== type) {
				return;
			}
			return handler(payload);
		};

		window.addEventListener("message", handleMessage);
		return () => {
			window.removeEventListener("message", handleMessage);
		};
	}, [handler, type]);
};

export default useMessage;
