import React, { memo, useCallback, useMemo } from "react";
import { TOGGLE_POST_PURCHASE_URL } from "../../conf";

import BrandColorEditView from "../BrandColorEditView";
import WidgetModule, { WidgetModuleToggler } from "./WidgetModule";

import "./post-purchase-module.css";

export const PostPurchaseModuleHome = memo(
	({ user, setUser, triggerRefresh, designSystem = false }) => {
		return (
			<>
				<WidgetModuleToggler
					user={user}
					setUser={setUser}
					moduleName="Post Purchase Experience"
					remoteKey="post_purchase"
					toggleUrl={TOGGLE_POST_PURCHASE_URL}
					onToggle={triggerRefresh}
					designSystem={designSystem}
				/>
				<BrandColorEditView
					user={user}
					setUser={setUser}
					onUpdate={triggerRefresh}
					designSystem={designSystem}
				/>
			</>
		);
	}
);

const PostPurchaseModule = memo(({ user, setUser }) => {
	const renderHome = useCallback(
		(props) => (
			<PostPurchaseModuleHome user={user} setUser={setUser} {...props} />
		),
		[user, setUser]
	);
	const tabOptions = useMemo(
		() => [
			{
				name: "Home",
				component: renderHome,
			},
		],
		[renderHome]
	);

	return (
		<WidgetModule
			user={user}
			setUser={setUser}
			tabOptions={tabOptions}
			heading="Post Purchase Experience"
			className="post-purchase-module"
		/>
	);
});

export default PostPurchaseModule;
