import React, { memo, useMemo, useState, useCallback, useEffect } from "react";
import parseError from "../../utils/parseError";
import { FormStatus, Loader, RadioInput } from "../CoreUI";
import { motion } from "framer-motion";
import anim from "../../utils/anim";
import { HiOutlineChevronDown as DownIcon } from "react-icons/hi";
import useModal from "../../hooks/useModal";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import "./module-preview-content-child.css";
import isShopifyShop from "../../utils/isShopifyShop";
import useIdentity from "../../hooks/useIdentity";

const ModulePreviewContentChild = memo(
	({
		className = "",
		heading = "",
		showFilter = true,
		loading = false,
		error = false,
		onFilterChange,
		children,
		inline = false,
		hideRedemptionModeFilter = false,
		...props
	}) => {
		const { open, handleToggle } = useModal();
		const { user } = useIdentity();

		const [filtersSelected, setFiltersSelected] = useState({
			customer: 0,
			date: 3,
			module_type: 0,
		});

		const [dateRange, setDateRange] = useState([new Date(), new Date()]);

		const handleChange = useCallback((value, key) => {
			setFiltersSelected((filtersSelected) => ({
				...filtersSelected,
				[key]: value,
			}));
		}, []);

		const handleDateChange = useCallback((newDateRange) => {
			setDateRange(newDateRange || [new Date(), new Date()]);
		}, []);

		const filters = useMemo(() => {
			const customerSegmentOptions = [
				{ name: "All Customers", value: null },
				{ name: "New Customers", value: "New" },
				{ name: "Existing Customers", value: "Returning" },
			];
			return {
				customer: {
					heading: "Customer Segments",
					options: isShopifyShop(user)
						? customerSegmentOptions
						: customerSegmentOptions.slice(0, 1),
				},
				date: {
					heading: "Dates shown",
					options: [
						{ name: "Last 7 days", value: 7 },
						{ name: "Last 14 days", value: 14 },
						{ name: "Last 30 days", value: 30 },
						{ name: "All time", value: 0 },
						{
							name: "Custom date range",
							value: "custom",
							className:
								"module-preview-content-child-fiter-custom-date",
							renderChildren: () => (
								<DateRangePicker
									onChange={handleDateChange}
									value={dateRange}
									maxDate={new Date()}
								/>
							),
						},
					],
				},
				module_type: {
					heading: "Redemption mode",
					options: [
						{ name: "Both", value: null },
						{ name: "Checkout", value: "checkout" },
						{ name: "One-click upsell", value: "ocu" },
					],
				},
			};
		}, [user, dateRange, handleDateChange]);

		useEffect(() => {
			if (typeof onFilterChange !== "function") {
				return;
			}

			const days = filters["date"].options[filtersSelected["date"]].value;
			let newPayload = {
				customer_type:
					filters["customer"].options[filtersSelected["customer"]]
						.value,
				module_type:
					filters["module_type"].options[
						filtersSelected["module_type"]
					]?.value,
			};

			if (days) {
				let [start_date, end_date] = dateRange;

				if (days !== "custom") {
					start_date = new Date(
						new Date().getTime() - days * 3600 * 24 * 1000
					).toISOString();
					end_date = null;
				}

				newPayload = { ...newPayload, start_date, end_date };
			}
			onFilterChange(newPayload);
		}, [filtersSelected, filters, onFilterChange, dateRange]);

		return (
			<motion.section
				variants={anim.verticalSlide}
				initial="initial"
				animate="animate"
				exit="exit"
				className={`module-preview-content-child ${
					inline ? "module-preview-content-child-inline" : ""
				} ${className}`}
				{...props}
			>
				<header>
					<h3>{heading}</h3>
					{showFilter && (
						<section
							className="module-preview-content-child-header-open"
							onClick={handleToggle}
						>
							Showing data from{" "}
							{filters?.customer?.options?.[
								filtersSelected?.customer
							]?.name.toLowerCase() || "all customers"}
							<motion.span
								initial={{ rotate: 0 }}
								animate={{ rotate: open ? 0 : 180 }}
							>
								<DownIcon />
							</motion.span>
						</section>
					)}
				</header>
				{open && (
					<section
						className={`module-preview-content-child-filters ${
							filters?.date?.options?.[filtersSelected?.date]
								?.value === "custom"
								? "module-preview-content-child-filters-extended"
								: ""
						}`}
						layout
					>
						{Object.entries(filters)
							.filter(([key]) =>
								!hideRedemptionModeFilter
									? true
									: key !== "module_type"
							)
							.map(([name, filter]) => (
								<RadioInput
									data={filter.options}
									name={name}
									onChange={handleChange}
									selected={filtersSelected[name]}
									disabled={loading}
								>
									<h3>{filter.heading}</h3>
								</RadioInput>
							))}
					</section>
				)}
				<section className="module-preview-content-child-render">
					{loading && <Loader />}
					{error && <FormStatus>{parseError(error)}</FormStatus>}
					{!loading && !error && children}
				</section>
			</motion.section>
		);
	}
);

export default ModulePreviewContentChild;
