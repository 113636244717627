import React, { memo } from "react";

import { SAVE_LEADGEN_URL } from "../../conf";

import { PLATFORM_LEAD_OFFERS_TOGGLE } from "../../events";
import LeadOffersKlaviyoConfig from "../LeadOffersKlaviyoConfig";

import { WidgetModuleToggler } from "../feedModules/WidgetModule";
import { SPACING } from "@disco/disco_core";
import LeadOffersConfigurator from "../leadOffers/LeadOffersConfigurator";

import "./lead-offers-container.css";

const LeadOffersContainer = memo(() => {
	return (
		<>
			<WidgetModuleToggler
				moduleName="offers"
				remoteKey="is_active_lead_gen"
				toggleUrl={SAVE_LEADGEN_URL}
				event={PLATFORM_LEAD_OFFERS_TOGGLE}
				designSystem
			/>
			<LeadOffersConfigurator />
			<LeadOffersKlaviyoConfig marginTop={SPACING.MEDIUM} />
		</>
	);
});

export default LeadOffersContainer;
