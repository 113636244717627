import React, { memo, useMemo } from "react";
import Confetti from "react-confetti";

const style = {
	zIndex: 0,
};

const SuccessConfetti = memo(
	({ target, height = 80, width = 80, running, ...props }) => {
		const source = useMemo(() => {
			const box = target?.getBoundingClientRect?.();

			if (!box)
				return {
					y: window.innerHeight / 2 - width / 2,
					x: window.innerWidth / 2 - width / 2,
					w: width,
					h: height,
				};

			return {
				x: box.x + box.width / 2 - width / 2,
				y: box.y + box.height / 2 - height / 2,
				w: width,
				h: height,
			};
		}, [target, width, height]);

		return (
			<Confetti
				width={window.innerWidth}
				style={style}
				height={window.innerHeight}
				numberOfPieces={2000}
				confettiSource={source}
				gravity={0.4}
				initialVelocityY={27}
				initialVelocityX={22}
				recycle={false}
				run={running}
				{...props}
			/>
		);
	}
);

export default SuccessConfetti;
