export const userUpdateZendesk = (user, isLeft = false) => {
	if (window.zE) {
		window.zE("webWidget", "updateSettings", {
			webWidget: {
				position: { horizontal: isLeft ? "left" : "right" },
			},
		});
	}
	if (window.$zopim) {
		window.$zopim(function () {
			window.$zopim.livechat.setName(user.publisher.name);
			window.$zopim.livechat.setEmail(user.email);
		});
	}
};

export const logoutZendesk = () => {
	if (window.zE) window.zE("webWidget", "logout");
};
