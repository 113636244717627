import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { Page } from "../../components/CoreUI";
import LoginTeamStep from "../../components/onboardingSteps/LoginTeamStep";

const LoginTeamPage = React.memo(function () {
	const [auth, setAuth] = useState(false);

	if (auth) {
		return <Navigate to="/" />;
	}

	return (
		<Page className="login-team-page">
			<LoginTeamStep setAuth={setAuth} />
		</Page>
	);
});

export default LoginTeamPage;
