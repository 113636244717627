import { Card, INPUT_TYPE, SPACING } from "@disco/disco_core";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { PREVIEW_TYPES_IDX } from "../../conf";
import {
	PLATFORM_CROSS_SELL_CLICK_ON_MANAGE_CROSS_CELL,
	PLATFORM_CROSS_SELL_CLICK_ON_MANAGE_PRODUCT_ORDERING,
	PLATFORM_SURVEY_CLICK_ON_VIEW_RESULTS,
	PLATFORM_UPSELL_CLICK_ON_MANAGE_PRODUCT_ORDERING,
	PLATFORM_UPSELL_CLICK_ON_MANAGE_UPSELL,
} from "../../events";
import usePreview from "../../hooks/usePreview";
import useTrack from "../../hooks/useTrack";
import { EmbeddedPreviewPage, MiniBadge, PageHeader } from "../CoreUI";
import EditView from "../EditView";
import NewEditView from "../NewEditView";

import "./widget-module.css";
import useIdentity from "../../hooks/useIdentity";
import { GET } from "../../utils/GET";
import { useSearchParams } from "react-router-dom";

export const WidgetModuleToggler = memo(
	({
		moduleName = "",
		remoteKey = "",
		toggleUrl,
		onToggle,
		hideDefaultFeedbackText,
		event,
		designSystem = false,
		...props
	}) => {
		const { user, setUser } = useIdentity();
		const track = useTrack();

		const handleEdit = useCallback(
			(value, data) => {
				if (onToggle && typeof onToggle === "function") {
					if (!!event) {
						track(event, { enabled: value || false });
					}
					onToggle(value || false, data);
				}
			},
			[event, onToggle, track]
		);

		if (designSystem) {
			return (
				<NewEditView
					inputType={INPUT_TYPE.TOGGLE}
					user={user}
					setUser={setUser}
					url={toggleUrl}
					remoteKey={remoteKey}
					feedback={`Enable ${moduleName}`}
					onEdit={handleEdit}
					inline
					{...props}
				/>
			);
		}

		return (
			<EditView
				user={user}
				setUser={setUser}
				url={toggleUrl}
				hideDefaultFeedbackText={hideDefaultFeedbackText}
				remoteKey={remoteKey}
				label={`Enable ${moduleName}`}
				toggle
				onEdit={handleEdit}
				className="new-widget-module-toggler"
				{...props}
			/>
		);
	}
);

const WidgetModuleHeaderPreviewButton = memo(
	({ hidePreview, handlePreviewOpen }) => {
		if (!hidePreview || !handlePreviewOpen) {
			return null;
		}

		return <MiniBadge onClick={handlePreviewOpen}>Show Preview</MiniBadge>;
	}
);

const WidgetModuleHeader = memo(
	({
		moduleClassName = "",
		heading = "",
		hidePreview,
		handlePreviewOpen,
		tabOptions,
		handleTabChange,
		actionChildren,
	}) => {
		const renderActionChildren = useMemo(
			() => (
				<>
					{actionChildren}
					<WidgetModuleHeaderPreviewButton
						hidePreview={hidePreview}
						handlePreviewOpen={handlePreviewOpen}
					/>
				</>
			),
			[actionChildren, hidePreview, handlePreviewOpen]
		);
		return (
			<PageHeader
				className={`new-widget-module-header ${moduleClassName}-header`}
				heading={heading}
				tabOptions={tabOptions}
				actionChildren={renderActionChildren}
				handleTabChange={handleTabChange}
			></PageHeader>
		);
	}
);

const WidgetModule = memo(
	({
		user,
		setUser,
		className = "",
		heading = "",
		tabOptions,
		previewType = "",
		...props
	}) => {
		const [tabActive, setTabActive] = useState(0);
		const [searchParams, setSearchParams] = useSearchParams();
		const track = useTrack();

		useEffect(() => {
			const derivedBaseTabIdx = GET("tab");
			if (
				typeof derivedBaseTabIdx !== "string" ||
				isNaN(derivedBaseTabIdx)
			)
				return;
			setTabActive(Number(derivedBaseTabIdx));
			setSearchParams("", { replace: true });
		}, [searchParams, setSearchParams]);

		const {
			triggerRefresh,
			openFullScreen,
			setPreviewType,
			setCustomPreviewType,
			setCustomPreviewID,
		} = usePreview();

		const parsedTabOptions = useMemo(
			() =>
				tabOptions.map((tabOption, idx) => ({
					...tabOption,
					name: tabOption.name,
					active: idx === tabActive,
				})),
			[tabActive, tabOptions]
		);

		const actionChildren = useMemo(
			() => tabOptions[tabActive]?.actionChildren || null,
			[tabActive, tabOptions]
		);

		const handleTabChange = useCallback(
			(_, idx) => {
				const index = Number(idx);
				if (
					previewType === PREVIEW_TYPES_IDX.ALL_SURVEY &&
					index === 1
				) {
					track(PLATFORM_SURVEY_CLICK_ON_VIEW_RESULTS, {});
				}
				if (
					previewType === PREVIEW_TYPES_IDX.CROSS_SELL &&
					index === 0
				) {
					track(PLATFORM_CROSS_SELL_CLICK_ON_MANAGE_CROSS_CELL, {});
				}
				if (
					previewType === PREVIEW_TYPES_IDX.CROSS_SELL &&
					index === 1
				) {
					track(
						PLATFORM_CROSS_SELL_CLICK_ON_MANAGE_PRODUCT_ORDERING,
						{}
					);
				}
				if (previewType === PREVIEW_TYPES_IDX.UPSELL && index === 0) {
					track(PLATFORM_UPSELL_CLICK_ON_MANAGE_UPSELL, {});
				}
				if (previewType === PREVIEW_TYPES_IDX.UPSELL && index === 1) {
					track(PLATFORM_UPSELL_CLICK_ON_MANAGE_PRODUCT_ORDERING, {});
				}
				setTabActive(index);
			},
			[previewType, track]
		);

		const renderHeader = useCallback(
			(props) => (
				<WidgetModuleHeader
					{...props}
					actionChildren={actionChildren}
					heading={heading}
					moduleClassName={className}
					tabOptions={parsedTabOptions}
					handleTabChange={handleTabChange}
				/>
			),
			[
				parsedTabOptions,
				heading,
				handleTabChange,
				className,
				actionChildren,
			]
		);

		useEffect(() => {
			setPreviewType(previewType);
		}, [previewType, setPreviewType, tabActive]);

		return (
			<EmbeddedPreviewPage
				user={user}
				setUser={setUser}
				renderHeader={renderHeader}
				className={`new-widget-module-container ${className}`}
				disablePreview={tabOptions[tabActive].disablePreview || false}
			>
				<Card
					className="new-widget-module-card"
					marginRight={SPACING.SMALL}
					vPadding={
						!tabOptions[tabActive].minimalFloat && SPACING.MEDIUM
					}
					hPadding={
						!tabOptions[tabActive].minimalFloat && SPACING.MEDIUM
					}
				>
					{tabOptions[tabActive].component({
						triggerRefresh,
						setCustomPreviewID,
						setCustomPreviewType,
						openFullScreen,
					})}
				</Card>
				{tabOptions[tabActive].children && (
					<Card
						className="new-widget-module-card"
						marginRight={SPACING.SMALL}
						vPadding={
							!tabOptions[tabActive].minimalFloat &&
							SPACING.MEDIUM
						}
						hPadding={
							!tabOptions[tabActive].minimalFloat &&
							SPACING.MEDIUM
						}
					>
						{tabOptions[tabActive].children({
							triggerRefresh,
							setCustomPreviewID,
							setCustomPreviewType,
						})}
					</Card>
				)}
			</EmbeddedPreviewPage>
		);
	}
);

export default WidgetModule;
