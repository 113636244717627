import { StatusLabel, Text, useModal } from "@disco/disco_core";
import React from "react";
import UTMModal from "../modals/UTMModal";

const LeadOffersEditorUTMBanner = ({ ...rest }) => {
	const { open, handleOpen, handleClose } = useModal(false);
	return (
		<>
			<StatusLabel type="warning" noShadow noBorder {...rest}>
				Please confirm your{" "}
				<Text
					inline
					color="--disco-theme-mid"
					clickable
					onClick={handleOpen}
				>
					UTM settings
				</Text>{" "}
				are properly configured.
			</StatusLabel>
			<UTMModal open={open} onClose={handleClose} />
		</>
	);
};
export default LeadOffersEditorUTMBanner;
