import React, { useEffect, useState } from "react";
import {
	AuthPage,
	Button,
	FilledButton,
	FloatingCard,
	FormStatus,
	Loader,
} from "../../components/CoreUI";

import "./join-team-page.css";

import { Navigate, useNavigate } from "react-router-dom";
import { ACCEPT_INVITE_URL } from "../../conf";
import useResource from "../../hooks/useResource";
import getJoinData, { USER_TYPES } from "../../utils/getJoinData";
import makeRedirectURL from "../../utils/makeRedirectURL";
import parseError from "../../utils/parseError";
import tokenStorage from "../../utils/tokenStorage";
import { userUpdateZendesk } from "../../utils/zendesk";

const joinData = getJoinData();

const JoinTeamPage = React.memo(({ user, setUser }) => {
	const [auth, setAuth] = useState(true);

	useEffect(() => {
		userUpdateZendesk(user);
	}, [user]);

	useEffect(() => {
		if (!joinData || !auth) return;
		if (joinData.email.toLowerCase() !== user.email.toLowerCase()) {
			tokenStorage.clear();
			setAuth(false);
		}
	}, [user, auth]);

	const [{ loading, error, data }, handleAccept] = useResource(
		{
			url: ACCEPT_INVITE_URL,
			method: "POST",
			data: {
				token: joinData?.token,
			},
		},
		false
	);

	const navigate = useNavigate();

	useEffect(() => {
		if (!data) return;
		tokenStorage.set({
			accessToken: data.access,
			refreshToken: data.refresh,
			publisher: data.publisher,
		});
		window.location.href = "/";
	}, [data]);

	if (!joinData) return <Navigate to="/" />;

	if (!auth) {
		return (
			<Navigate
				to={makeRedirectURL(
					`${
						joinData === USER_TYPES.EXISTING
							? "/loginTeam?"
							: "/registerTeam?"
					}`
				)}
			/>
		);
	}

	const handleDecline = () => {
		navigate(makeRedirectURL("/"));
	};

	return (
		<AuthPage
			active=""
			className="join-team-page"
			user={user}
			setUser={setUser}
		>
			<FloatingCard className="join-team-card">
				<h2>You have been invited to join {joinData.brand}</h2>
				<p>
					Accept this invitation to join {joinData?.brand}'s team and
					control their Disco settings.
				</p>
				<p className="join-team-confirm">
					Do you want to join {joinData.brand}'s team?
				</p>
				<section className="join-team-action">
					{loading ? (
						<Loader />
					) : (
						<>
							<FilledButton onClick={handleAccept}>
								Yes
							</FilledButton>
							<Button light onClick={handleDecline}>
								No
							</Button>
						</>
					)}
				</section>
				{error && <FormStatus>{parseError(error)}</FormStatus>}
			</FloatingCard>
		</AuthPage>
	);
});

export default JoinTeamPage;
