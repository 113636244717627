import {
	PaddedContainer,
	Text,
	Asset,
	Button,
	SPACING,
	FONT_COLOR,
	CrossIcon,
} from "@disco/disco_core";
import React, { memo, useCallback } from "react";

import "./utm-param.css";

const UTMParam = memo(({ id, name, value, onDelete }) => {
	const handleDelete = useCallback(() => {
		onDelete(id);
	}, [onDelete, id]);

	return (
		<PaddedContainer
			className="utm-param"
			marginLeft={SPACING.TINY}
			marginTop={SPACING.TINY}
			marginBottom={SPACING.TINY}
			hPadding={SPACING.SMALL}
			vPadding={SPACING.MICRO}
			flexContent
		>
			<Text color={FONT_COLOR.MID} thin>
				{name}:{value}
			</Text>
			<Button
				className="utm-param-exit-btn"
				onClick={handleDelete}
				marginLeft={SPACING.TINY}
				secondary
				small
			>
				<Asset size={16} color={FONT_COLOR.MID}>
					<CrossIcon />
				</Asset>
			</Button>
		</PaddedContainer>
	);
});

export default UTMParam;
