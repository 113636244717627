import React from "react";
import iPhoneFrame from "../../img/iphone.png";
import "./overlay-preview.css";

const OverlayPreview = React.memo(({ user, editMode = false, direct }) => (
	<section
		className={`overlay-preview-container ${
			direct ? "overlay-preview-container-internal" : ""
		}`}
	>
		<section className="overlay-preview">
			<main
				className="overlay-preview-body"
				style={{
					backgroundColor: direct || user.publisher.modal_color,
				}}
			>
				<div className="overlay-preview-content">
					<img
						src={
							editMode
								? user.draft.logo_url
								: user.publisher.logo_url
						}
						alt="Logo"
					/>
				</div>
			</main>
			<img src={iPhoneFrame} alt="Mobile phone frame" />
		</section>
	</section>
));

export default OverlayPreview;
