import axios from "axios";
import React, {
	createRef,
	memo,
	useCallback,
	useEffect,
	useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
	Button,
	FormStatus,
	GuidedForm,
	Loader,
} from "../../components/CoreUI";
import { PASSWORD_RESET_CONFIRM_URL } from "../../conf";
import hand from "../../img/hand.png";
import { GET } from "../../utils/GET";
import parseError from "../../utils/parseError";
import OnboardingStep from "./OnboardingStep";
import "./reset-confirmation-step.css";

const uidb64 = GET("uidb64");
const token = GET("token");
const ResetConfirmationStep = memo(() => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(false);
	const [data, setData] = useState({
		password: {
			tooltip: {
				heading: "Your password must include:",
				text: (
					<>
						<ul>
							<li>One uppercase letter</li>
							<li>One lowercase letter</li>
							<li>One special character</li>
							<li>Minimum length of 8 characters</li>
						</ul>
					</>
				),
			},
			props: {
				value: "",
				placeholder: "Password",

				type: "password",
				label: "Your Password",
				ref: createRef(),
			},
		},
		password2: {
			tooltip: {
				heading: "Confirm your password",
				text: "Type your password again to make sure it's correct.",
			},
			props: {
				value: "",
				placeholder: "Confirm password",
				type: "password",

				label: "Confirm your password",
				ref: createRef(),
			},
		},
	});
	const navigate = useNavigate();

	const confirmResetPassword = useCallback(async () => {
		const password = data.password.props.value;
		const password2 = data.password2.props.value;
		if (loading) {
			return;
		}

		setLoading(true);
		let resp;
		try {
			resp = await axios.post(PASSWORD_RESET_CONFIRM_URL, {
				password,
				password2,
				token: token || "",
				uidb64: uidb64 || "",
			});
			setLoading(false);
			if (resp && resp.data) {
				setSuccess(true);
			}
		} catch (error) {
			setLoading(false);
			setSuccess(false);
			return setError(parseError(error, true));
		}
	}, [data.password.props.value, data.password2.props.value, loading]);

	const handleConfirm = useCallback(
		(e) => {
			if (e) {
				e.preventDefault();
			}
			const password = data.password.props.value;
			const password2 = data.password2.props.value;
			if (!token || !uidb64) {
				return setError(
					"Whoops! An unexpected error occurred. Please try again later"
				);
			} else if (
				password.trim().length < 1 ||
				password2.trim().length < 1
			) {
				return setError(
					"Your password and confirmation password are required"
				);
			} else if (password !== password2) {
				return setError("Whoops! Your passwords do not match");
			} else if (password.length < 8) {
				return setError(
					"The password must be at least 8 characters long"
				);
			}
			setError(false);
			confirmResetPassword();
		},
		[
			data.password.props.value,
			data.password2.props.value,
			confirmResetPassword,
		]
	);

	useEffect(() => {
		if (!success) {
			return;
		}
		return setTimeout(() => {
			navigate({
				pathname: "/login",
			});
		}, 4000);
	}, [success, navigate]);

	// useEffect(() => {
	// triggerIntercom();
	// }, []);

	return (
		<>
			<OnboardingStep
				heading="You are almost done!"
				subText={"Just enter your new password"}
				aside={{
					heading: "Howdy.",
					image: hand,
					logo: true,
					imageWidth: "95%",
					imageUp: true,
				}}
				showLogout={false}
				showNext={false}
				className="reset-confirmation-step"
				showBack={false}
				steps={null}
				noPad
			>
				<GuidedForm
					data={data}
					setData={setData}
					initialHighlight="password"
				>
					{!loading && !success && (
						<Button large light onClick={handleConfirm} theme>
							Reset My Password
						</Button>
					)}
					{error && <FormStatus type="error">{error}</FormStatus>}
					{success && (
						<FormStatus type="success">
							Success! Your password has been reset.
							<br />
							You'll now be redirected to the login page
						</FormStatus>
					)}
					{loading && <Loader />}
				</GuidedForm>
			</OnboardingStep>
		</>
	);
});

export default ResetConfirmationStep;
