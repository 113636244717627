import React, { memo } from "react";
import TopLevelPage from "../TopLevelPage";
import { CrossSellModule, UpsellModule } from "../../components/feedModules";
import Products from "../../components/Products";

const subPages = {
	discoFeed: CrossSellModule,
	products: Products,
	upsells: UpsellModule,
};

const DiscoFeedPage = memo(() => {
	return <TopLevelPage subPages={subPages}></TopLevelPage>;
});

export default DiscoFeedPage;
