import { useEffect, useRef } from "react";
import useTrack from "./useTrack";

const useViewTrack = (event, properties) => {
	const track = useTrack();
	const tracked = useRef(false);

	useEffect(() => {
		if (tracked.current || !event) return;
		track(event, properties);
		tracked.current = true;
	}, [track, event, properties]);
};

export default useViewTrack;
