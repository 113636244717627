import React, { useCallback, useEffect, useState } from "react";
import { CgMenu } from "react-icons/cg";
import {
	PRODUCT_UPDATE_ACTIVE_URL,
	PRODUCT_UPDATE_UPSELL_URL,
} from "../../conf";
import useModal from "../../hooks/useModal";
import useResource from "../../hooks/useResource";
import { BadgeButton, Checkbox, Loader } from "../CoreUI";
import "./product-row.css";

const ProductRow = React.memo(
	({
		product,
		onActiveUpdate,
		onEdit,
		onDelete,
		onUpsellUpdate,
		onUpsellEmailUpdate,
		editMode,
		onVariantsOpen,
		productID = 0,
		onboarding = false,
		selectOnly = false,
		upsellOnly = false,
		crossSellOnly = false,
		upsellEmailOnly = false,
	}) => {
		const [updateOptions, setUpdateOptions] = useState({
			url: PRODUCT_UPDATE_ACTIVE_URL(product),
			method: "PUT",
			data: {},
		});

		const {
			open: deleteOpen,
			handleOpen: handleDeleteOpen,
			handleClose: handelDeleteClose,
		} = useModal();

		const [upsellOptions, setUpsellOptions] = useState({
			url: PRODUCT_UPDATE_UPSELL_URL(product),
			method: "PUT",
			data: {},
		});

		const [{ data, loading }, update, reset] = useResource(
			updateOptions,
			false
		);
		const [
			{ data: upsellData, loading: upsellLoading },
			upsellUpdate,
			upsellReset,
		] = useResource(upsellOptions, false);

		const handleVariantsOpen = useCallback(() => {
			if (typeof onVariantsOpen === "function") {
				onVariantsOpen(product.remote_id);
			}
		}, [onVariantsOpen, product.remote_id]);

		useEffect(() => {
			if (data) {
				if (data.deleted) {
					onDelete(data.remote_id);
				} else if (data.active !== product.active) {
					onActiveUpdate(
						data.remote_id,
						data.active,
						data.all_active
					);
				} else {
					onUpsellEmailUpdate(data.remote_id, data.upsell_email);
				}
				reset();
			}
		}, [
			data,
			onActiveUpdate,
			reset,
			onDelete,
			onUpsellEmailUpdate,
			product,
		]);

		useEffect(() => {
			if (upsellData) {
				onUpsellUpdate(
					product.remote_id,
					upsellData.upsell,
					upsellData.all_upsell
				);
				upsellReset();
			}
		}, [upsellData, product.remote_id, onUpsellUpdate, upsellReset]);

		const show = () => {
			setUpdateOptions((options) => ({
				...options,
				data: {
					active: true,
				},
			}));
			update();
		};

		const hide = () => {
			setUpdateOptions((options) => ({
				...options,
				data: { active: false },
			}));
			update();
		};

		const enableUpsell = () => {
			setUpsellOptions((options) => ({
				...options,
				data: { upsell: true },
			}));
			upsellUpdate();
		};

		const disableUpsell = () => {
			setUpsellOptions((options) => ({
				...options,
				data: { upsell: false },
			}));
			upsellUpdate();
		};

		const deleteProduct = useCallback(() => {
			setUpdateOptions((options) => ({
				...options,
				data: {
					deleted: true,
				},
			}));
			update();
		}, [update]);

		const enableUpsellEmail = useCallback(() => {
			setUpdateOptions((options) => ({
				...options,
				data: {
					upsell_email: true,
				},
			}));
			update();
		}, [update]);

		const disableUpsellEmail = useCallback(() => {
			setUpdateOptions((options) => ({
				...options,
				data: {
					upsell_email: false,
				},
			}));
			update();
		}, [update]);

		const handleDelete = useCallback(() => {
			handelDeleteClose();
			deleteProduct();
		}, [deleteProduct, handelDeleteClose]);

		return (
			<>
				<span className="product-row-reorder">
					{!selectOnly && (
						<>
							<CgMenu />
							<span>{productID + 1}</span>
						</>
					)}
				</span>

				<td>
					<img
						src={product.photo_url}
						className="product-row-img"
						alt="Product"
					/>
				</td>
				<td className="product-row-name">
					{product.name}
					{product.name.length > 40 && (
						<div className="product-row-warning">
							Shorten descriptor to 40 characters
						</div>
					)}
				</td>
				<td className="product-row-price">
					{`$ ${
						product.price ? product.price : product.discounted_price
					}` || "None"}
				</td>
				{!onboarding && (
					<td className="product-row-discount">
						{!isNaN(product.price) &&
						!isNaN(product.discounted_price) &&
						product.price > 0 &&
						Number(product.price) >=
							Number(product.discounted_price)
							? `${(
									(1 -
										Number(product.discounted_price) /
											Number(product.price)) *
									100
							  ).toFixed(0)}%`
							: "None"}
					</td>
				)}

				{!(selectOnly || upsellEmailOnly) ? (
					upsellOnly ? (
						<>
							{/* {!loading && !product.draft && !upsellLoading && ( */}
							<td>
								{!upsellLoading ? (
									<Checkbox
										checked={product.upsell}
										onClick={(e) =>
											product.upsell
												? disableUpsell()
												: enableUpsell()
										}
									/>
								) : (
									<Loader className="product-row-loader" />
								)}
							</td>
						</>
					) : crossSellOnly ? (
						<td>
							{!loading ? (
								<Checkbox
									checked={product.active}
									onClick={(e) =>
										product.active ? hide() : show()
									}
								/>
							) : (
								<Loader className="product-row-loader" />
							)}
						</td>
					) : (
						<>
							{/* <td colSpan={product.draft ? 3 : 1}> */}
							<td colSpan={1}>
								{/* {product.draft && (
									<section className="products-container-review">
										<b>Under review</b>
										<br />
										You can make changes once this product
										is approved.
									</section>
								)} */}
								{/* {!loading && !product.draft && !upsellLoading && ( */}
								{!loading ? (
									<Checkbox
										checked={product.active}
										onClick={() => {
											product.active ? hide() : show();
										}}
									/>
								) : (
									<Loader className="product-row-loader" />
								)}
							</td>
							{/* {!loading && !product.draft && !upsellLoading && ( */}
							<td>
								{!upsellLoading ? (
									<Checkbox
										checked={product.upsell}
										onClick={() =>
											product.upsell
												? disableUpsell()
												: enableUpsell()
										}
									/>
								) : (
									<Loader className="product-row-loader" />
								)}
							</td>
							{/* {!product.draft && ( */}
							{!onboarding && (
								<td className="product-row-actions">
									{!loading && !upsellLoading && (
										<>
											<BadgeButton
												small
												className="product-row-btn product-row-edit-btn"
												onClick={() =>
													typeof onEdit === "function"
														? onEdit(
																product.remote_id
														  )
														: null
												}
											>
												Edit
											</BadgeButton>
											{Array.isArray(product.variants) &&
												product.variants.length > 0 && (
													<BadgeButton
														small
														className="product-row-btn"
														onClick={
															handleVariantsOpen
														}
													>
														See Variants
													</BadgeButton>
												)}
											<BadgeButton
												small
												className="product-row-btn product-row-delete-btn"
												onClick={handleDeleteOpen}
											>
												Delete
											</BadgeButton>
										</>
									)}
								</td>
							)}

							{/* )} */}
						</>
					)
				) : upsellEmailOnly ? (
					<td>
						{!loading ? (
							<>
								<Checkbox
									checked={product.upsell_email}
									onChange={(e) => {
										e.target.checked
											? enableUpsellEmail()
											: disableUpsellEmail();
									}}
								/>
							</>
						) : (
							<Loader className="product-row-loader" />
						)}
					</td>
				) : null}

				{/* {(error || deleteError || upsellError) && (
					<FormStatus className="product-row-update-active-error">
						Whoops! Could not{" "}
						{error
							? "update"
							: upsellError
							? "change upsell for "
							: "delete "}
						the product. Try again later
					</FormStatus>
				)} */}
				{/* <Modal
					heading="Delete Product"
					open={deleteOpen}
					onClose={handelDeleteClose}
					className="product-row-delete-modal"
				>
					<p>
						Are you sure you want to delete <b>{product.name}</b>?
					</p>
					<Button onClick={handleDelete}>Delete</Button>
					<Button onClick={handelDeleteClose} light>
						Cancel
					</Button>
				</Modal> */}
			</>
		);
	}
);

export default ProductRow;
