import React, { memo, forwardRef, useCallback, useState, useMemo } from "react";
import {
	ClockIcon,
	FONT_COLOR,
	FONT_SIZE,
	genClassName,
	Asset,
	isFunction,
	PaddedContainer,
	SPACING,
	Text,
	UploadIcon,
} from "@disco/disco_core";
import ImageModal from "../modals/ImageModal";
import "./new-edit-image.css";
import useModal from "../../hooks/useModal";

const NewEditImageAction = memo(({ children, ...rest }) => {
	return (
		<Text
			Element="section"
			className="new-edit-image-action"
			color={FONT_COLOR.MID}
			size={FONT_SIZE.BODY}
			thick
			vPadding={SPACING.TINY}
			hPadding={SPACING.SMALL}
			centerContent
			{...rest}
		>
			{children}
		</Text>
	);
});

const NewEditImage = memo(
	forwardRef(
		(
			{
				user,
				selector,
				draftSelector,
				underReview = false,
				name,
				className = "",
				editText = false,
				defaultValue,
				remoteKey,
				onMouseEnter,
				customRenderer,
				onDone,
				circular = false,
				...rest
			},
			ref
		) => {
			const {
				open: modalOpen,
				handleOpen: openModal,
				handleClose: closeModal,
			} = useModal(false);
			const [showDraft, setShowDraft] = useState(false);
			const [fileName, setFileName] = useState("");

			const handleDone = useCallback(
				(image, name) => {
					setFileName(name);
					if (!isFunction(onDone)) return;
					onDone(image, name);
				},
				[onDone]
			);

			const handleMouseEnter = useCallback(() => {
				if (typeof onMouseEnter === "function") {
					onMouseEnter(remoteKey);
				}
			}, [onMouseEnter, remoteKey]);

			const value = underReview
				? (draftSelector && draftSelector(user)) ||
				  selector(user) ||
				  defaultValue
				: selector(user) || defaultValue;

			const imgProps = useMemo(
				() => ({
					src: showDraft ? selector(user) || defaultValue : value,
					alt: showDraft ? "Current shop logo" : name,
				}),
				[showDraft, user, defaultValue, value, selector, name]
			);

			return (
				<>
					{isFunction(customRenderer) ? (
						customRenderer({
							value: value,
							onOpen: openModal,
							ref,
							underReview: underReview,
							fileName,
							label: editText,
						})
					) : (
						<PaddedContainer
							className={genClassName({
								base: "new-edit-image",
								conditionals: {
									"new-edit-image-circular": circular,
								},
								additional: className,
							})}
							ref={ref}
							onMouseEnter={handleMouseEnter}
							centerContent
						>
							<img alt="" {...imgProps} />
							<PaddedContainer
								className={"new-edit-image-actions"}
								flexContent
							>
								<NewEditImageAction onClick={openModal}>
									<Asset size={16} marginRight={SPACING.TINY}>
										<UploadIcon />
									</Asset>
									{editText || "Change photo"}
								</NewEditImageAction>
								{underReview && (
									<NewEditImageAction
										onMouseEnter={() => setShowDraft(true)}
										onMouseLeave={() => {
											setShowDraft(false);
										}}
									>
										<Asset
											size={16}
											marginRight={SPACING.TINY}
										>
											<ClockIcon />
										</Asset>
										View Live Image
									</NewEditImageAction>
								)}
							</PaddedContainer>
						</PaddedContainer>
					)}
					<ImageModal
						className={genClassName({
							base: "new-edit-image-modal",
							conditionals: {
								[`${className}-modal`]: className,
							},
						})}
						open={modalOpen}
						user={user}
						selector={selector}
						defaultValue={defaultValue}
						onClose={closeModal}
						remoteKey={remoteKey}
						onDone={handleDone}
						designSystem
						{...rest}
					/>
				</>
			);
		}
	)
);
export default NewEditImage;
