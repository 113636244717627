import React, { memo } from "react";
import isShopifyShop from "../../utils/isShopifyShop";
import CardSetup from "../CardSetup";
import BasicPaymentPlanCard from "../PaymentPlanCards/BasicPaymentPlanCard";
// import FreePaymentPlanCard from "../PaymentPlanCards/FreePaymentPlanCard";
import ProPaymentPlanCard from "../PaymentPlanCards/ProPaymentPlanCard";
import ShopPaySetView from "../ShopPaySetView";
import Modal from "./Modal";
import "./payment-modal.css";

const PaymentModal = memo(({ user, setUser, onClose, ...props }) => {
	return (
		<Modal className="payment-modal" onClose={onClose} {...props}>
			<h1>Upgrade your Disco membership</h1>
			{/* <div className="payment-modal-subtext">
				<span>Here</span> are some of the reasons you should upgrade
			</div>
			<section className="payment-modal-details">
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero
				at architecto assumenda vitae deserunt aliquam atque corrupti
				iste soluta. Numquam ad nobis voluptates eaque consequatur
				praesentium sed error, nam delectus?
			</section> */}
			<section className="payment-modal-plans">
				{/* <FreePaymentPlanCard user={user} /> */}
				<BasicPaymentPlanCard user={user} />
				<span>or</span>
				<ProPaymentPlanCard user={user} />
			</section>
			<section className="payment-modal-section payment-modal-action">
				{isShopifyShop(user) && (
					<ShopPaySetView user={user} setUser={setUser} inModal />
				)}
				{!isShopifyShop(user) && (
					<CardSetup onDone={onClose} setUser={setUser} />
				)}
			</section>
		</Modal>
	);
});

export default PaymentModal;
