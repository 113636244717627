import React, {
	createRef,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";

import { FormStatus, Loader, Select, Table } from "../CoreUI";

import filterFactory from "react-bootstrap-table2-filter";
import { FETCH_SIMILAR_PRODUCTS_URL } from "../../conf";

import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import { BsFillCaretDownFill } from "react-icons/bs";
import useResource from "../../hooks/useResource";
import isShopifyShop from "../../utils/isShopifyShop";
import parseError from "../../utils/parseError";
import { ProductDisplay } from "../ProductComponents";
import { BOTTOM_LEFT } from "../modals/FloatingModal";
import VariantsModal from "../modals/VariantsModal";
import "./products-match.css";

const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => {
	return sortOrder === "asc"
		? "lib-table-sort"
		: "lib-table-sort lib-table-sort-desc";
};

const ProductMatchSelect = memo(
	({ products, self, disabled, matchedTo, onMatch }) => {
		const handleSelect = ({ target: { value } }) => {
			onMatch(value, self);
		};

		const options = useMemo(() => {
			if (!Array.isArray(products) || products.length < 1) {
				return [];
			}

			const options = {
				none: (
					<section className="products-match-option">
						<h4>Do not match</h4>
					</section>
				),
			};
			products.forEach((product, idx) => {
				options[product.remote_id] = (
					<section className="products-match-option">
						<img src={product.photo_url} alt={product.name} />
						<h4>{product.name}</h4>
					</section>
				);
			});

			return options;
		}, [products]);

		return (
			<Select
				options={options}
				value={matchedTo}
				disabled={disabled}
				onChange={handleSelect}
				className="products-match-select"
				placeholder="Select Product"
				// searchable
			/>
		);
	}
);

const ProductsMatch = memo(
	({
		user,
		matches,
		setMatches,
		setError,
		save,
		setSave,
		onSave,
		onboarding,
	}) => {
		const [variantsOpen, setVariantsOpen] = useState({
			remoteId: false,
			ref: null,
		});

		const handleMatch = useCallback(
			(shopProductId, selfProductId) => {
				setMatches((oldMatches) => {
					const matches = { ...oldMatches };
					return { ...matches, [selfProductId]: shopProductId };
				});
			},
			[setMatches]
		);

		const [products, setProducts] = useState(null);
		const [shopifyProducts, setShopifyProducts] = useState(null);

		// const [{loading: shopifyPri}]

		const [{ data: remoteProducts, loading, error }, , resetProducts] =
			useResource({
				url: FETCH_SIMILAR_PRODUCTS_URL,
				method: "GET",
			});

		useEffect(() => {
			if (!remoteProducts) {
				return;
			}

			const products = [],
				shopifyProducts = [];
			matches = {};

			if (!Array.isArray(remoteProducts)) {
				return;
			}

			for (const product of remoteProducts) {
				if (product.shopify_product_id) {
					shopifyProducts.push(product);
				} else {
					products.push(product);
					if (onboarding) {
						matches[product.remote_id] = "none";
					}
				}
			}

			setProducts(products);
			setShopifyProducts(shopifyProducts);
			setMatches(matches);
			resetProducts();
		}, [remoteProducts, resetProducts]);

		const handleVariantsOpen = useCallback(
			(e, remoteId, ref) => {
				if (e) {
					e.stopPropagation();
				}
				const product = products.find(
					(product) => product.remote_id === remoteId
				);
				if (!product) {
					return;
				}

				setVariantsOpen({
					remoteId: remoteId,
					product,
					ref,
				});
			},
			[products]
		);

		const handleVariantsClose = useCallback(() => {
			setVariantsOpen({ remoteId: false, ref: null });
		}, []);

		const productsData = useMemo(() => {
			if (!products) {
				return [];
			}
			const doneMap = {};
			Object.entries(matches).forEach(([from, to]) => {
				doneMap[to] = from;
			});

			return products.map((product) => {
				const priorityMap = product.similar_products.reduce(
					(map, remoteId, idx) => ({ ...map, [remoteId]: idx }),
					{}
				);
				return {
					// ...product,
					remote_id: product.remote_id,
					match: {
						products: [...shopifyProducts].sort(
							({ remote_id: id1 }, { remote_id: id2 }) =>
								priorityMap[id1] - priorityMap[id2]
						),
						onMatch: handleMatch,
						disabled: product?.merged,
						matchedTo: matches[product.remote_id] ?? false,
						self: product.remote_id,
					},

					display: {
						product,
						remoteId: product.remote_id,
						onVariantsOpen: handleVariantsOpen,
						editName: false,
						variantRef: createRef(),
						inStock:
							!(
								product.shopify_product_id &&
								isShopifyShop(user)
							) || !Array.isArray(product.variants)
								? true
								: product.variants.reduce(
										(inStock, variant) =>
											inStock ||
											variant.sell_if_zero ||
											variant.quantity > 0,
										false
								  ),
					},
				};
			});
		}, [
			products,
			handleVariantsOpen,
			shopifyProducts,
			matches,
			handleMatch,
			user,
		]);

		const updateProducts = useCallback(() => {
			setProducts((products) => {
				return products.map((product) =>
					matches[product.remote_id]
						? { ...product, merged: true }
						: product
				);
			});
		}, [matches]);

		const handleSave = useCallback(() => {
			if (Object.entries(matches).length < products.length) {
				return setError(
					"Please match all of your Disco products to a Shopify product, or select None"
				);
			}
			setError(false);
			updateProducts();
			onSave();
		}, [matches, products, setError, onSave, updateProducts]);

		useEffect(() => {
			if (!save) {
				return;
			}
			handleSave();
			setSave(false);
		}, [save, setSave, handleSave]);

		const rowClasses = useCallback(
			(product) =>
				`lib-table-row${
					!product.display.inStock ? " lib-table-row-red" : ""
				} ${product.match.disabled ? " lib-table-row-green" : ""}`,
			[]
		);

		const columns = useMemo(
			() => [
				{
					text: <>Shopify Products</>,
					dataField: "match",

					formatter: (match) => {
						return <ProductMatchSelect {...match} />;
					},
					headerSortingClasses,
				},

				{
					sort: true,
					text: (
						<>
							Disco Product{" "}
							<span className="lib-table-sort-icon">
								<BsFillCaretDownFill />
							</span>
						</>
					),
					dataField: "display",
					headerClasses: "lib-table-display-header",
					// filter: nameFilter,
					filterValue: (display) => display.name,
					formatter: (display) => {
						return (
							<ProductDisplay
								// onboarding={true}
								user={user}
								{...display}
								showSelect={false}
							/>
						);
					},
					headerSortingClasses,

					sortValue(display) {
						return display.name;
					},
				},
			],
			[user]
		);

		return (
			<section className={`products-match`}>
				{loading && <Loader />}
				{error && <FormStatus>{parseError(error)}</FormStatus>}
				{!loading &&
					!error &&
					Array.isArray(products) &&
					(products.length > 0 ? (
						<Table
							// className="lib-table"
							// rowClassName="lib-table-row"
							keyField="remote_id"
							className="products-match-table"
							rowClasses={rowClasses}
							columns={columns}
							data={productsData}
							filter={filterFactory()}
							filterPosition="inline"
						/>
					) : (
						<section className="product-match-merged">
							<h3>All your products have been merged!</h3>
							<p>
								All of your existing Disco products have been
								merged with a Shopify product. You have no more
								products left to match up.
							</p>
						</section>
					))}

				<VariantsModal
					product={variantsOpen.product}
					user={user}
					setProducts={setProducts}
					open={variantsOpen.remoteId}
					onClose={handleVariantsClose}
					target={variantsOpen.ref}
					position={BOTTOM_LEFT}
				/>
			</section>
		);
	}
);

export default ProductsMatch;
