import React, { forwardRef, memo } from "react";

import genPackageClassName from "../../utils/genPackageClassName";

import "./link-button.css";
import {
	PaddedContainer,
	PaddedContainerProps,
	SPACING,
} from "../PaddedContainer";
import {
	GenericRef,
	Extend,
	StandardComponentWithChildren,
} from "../../shared.types";

type NativeLinkButtonProps = {
	center?: boolean;
	middle?: boolean;
	small?: boolean;
	disabled?: boolean;
	icon?: boolean;
};

export type LinkButtonProps = Extend<
	PaddedContainerProps,
	NativeLinkButtonProps
>;
type LinkButtonComponent = StandardComponentWithChildren<LinkButtonProps>;

export const LinkButton: LinkButtonComponent = memo(
	forwardRef(
		(
			{
				className = "",
				children,
				center = false,
				middle = false,
				small = false,
				disabled = false,
				icon = false,
				...rest
			},
			ref?: GenericRef
		) => {
			const renderBtn = (
				<PaddedContainer
					ref={ref}
					vPadding={small ? SPACING.MICRO : SPACING.TINY}
					hPadding={SPACING.MICRO}
					className={genPackageClassName({
						base: "link-btn",
						additional: className,
						conditionals: {
							"text-body": !small,
							"text-label": small,

							"link-btn-icon flex-center": !!icon,

							"link-btn-disabled": disabled,
						},
					})}
					hMarginAuto={middle}
					{...rest}
				>
					{children}
				</PaddedContainer>
			);

			if (center) {
				return (
					<section
						className={genPackageClassName({
							base: "absolute-center-container",
						})}
					>
						{renderBtn}
					</section>
				);
			}

			return renderBtn;
		}
	)
);
