import React, { memo, useCallback } from "react";
import {
	Button,
	PaddedContainer,
	Text,
	SPACING,
	Modal,
	FONT_SIZE,
} from "@disco/disco_core";
import "./confirmation-modal.css";

const Confirmation = memo(
	({
		title,
		confirmationButtonText = "Save",
		cancelButtonText = "Cancel",
		open,
		onConfirmation,
		onCancel,
		onClose,
		children,
		...rest
	}) => {
		const handleConfirmation = useCallback(() => {
			onConfirmation();
			onClose();
		}, [onConfirmation, onClose]);

		const handleCancel = useCallback(() => {
			onClose();
			onCancel();
		}, [onCancel, onClose]);

		return (
			<Modal open={open} onClose={onClose} title={title} {...rest}>
				<PaddedContainer>
					<PaddedContainer flexContent>{children}</PaddedContainer>
					<PaddedContainer
						flexContent
						marginTop={SPACING.REGULAR}
						className="confirmation-modal-btn-group"
					>
						<Button onClick={handleCancel} secondary>
							<Text size={FONT_SIZE.LG_BODY}>
								{" "}
								{cancelButtonText}
							</Text>
						</Button>

						<Button onClick={handleConfirmation}>
							<Text size={FONT_SIZE.LG_BODY}>
								{confirmationButtonText}
							</Text>
						</Button>
					</PaddedContainer>
				</PaddedContainer>
			</Modal>
		);
	}
);

export default Confirmation;
