import React, { forwardRef, memo, useMemo, useState } from "react";
import parseDate from "../../utils/parseDate";
import CardModal from "../modals/CardModal";
import SettingsModule, { SettingsModuleSection } from "./SettingsModule";

import getSubscriptionText from "../../utils/getSubscriptionText";

import {
	FONT_COLOR,
	FONT_SIZE,
	InformationTooltip,
	PaddedContainer,
	SPACING,
	Table,
	Tag,
	TAG_TYPE,
	Text,
	TOOLTIP_TYPE,
} from "@disco/disco_core";
import { FETCH_SHOPIFY_BILLING_HISTORY } from "../../conf";
import useModal from "../../hooks/useModal";
import useResource from "../../hooks/useResource";
import isArray from "../../utils/isArray";
import isShopifyShop from "../../utils/isShopifyShop";
import PaymentModal from "../modals/PaymentModal";
import ShopPaySetView from "../ShopPaySetView";
import "./subscription-module.css";

const HEADERS = [
	{
		text: "Date",
		dataField: "billing_period",
	},
	{
		text: "Status",
		dataField: "status",
	},
	{
		text: "Total Upsells",
		dataField: "upsells",
	},
	{
		text: (
			<PaddedContainer flexContent>
				<Text color="--disco-yellow-dark">Total OCUs</Text>
				<InformationTooltip
					activatorProps={{
						marginLeft: SPACING.SMALL,
						className: "payment-module-ocu-tooltip",
					}}
					type={TOOLTIP_TYPE.REVIEW}
				>
					<Text size={FONT_SIZE.BODY}>
						As of <b>March 18, 2024</b>, One Click Upsells (i.e. One
						Click Offers) has been deprecated. These charges
						occurred prior to that date.
					</Text>
				</InformationTooltip>
			</PaddedContainer>
		),
		dataField: "OCUs",
	},
	{
		text: "Subscription fee",
		dataField: "subscription_charge",
	},
	{
		text: "Total charges",
		dataField: "total_payment_amount",
	},
];

const PaymentModule = memo(
	forwardRef(({ user, setUser }, parentRef) => {
		const {
			premium,
			text: iconText,
			inTrail,
		} = useMemo(() => {
			return getSubscriptionText(user);
		}, [user]);

		const {
			open: cardOpen,
			handleClose: handleCardClose,
			handleOpen: handleCardOpen,
		} = useModal();

		const [
			{
				data: billingHistoryData,
				loading: billingHistoryLoading,
				error: billingHistoryError,
			},
		] = useResource({
			url: FETCH_SHOPIFY_BILLING_HISTORY,
			method: "GET",
		});

		// useEffect(() => {
		// 	if (!billingHistoryData) {
		// 		return;
		// 	}
		// 	reset();
		// }, [billingHistoryData, reset]);

		const billingHistory = useMemo(() => {
			if (billingHistoryError) return [];
			if (!billingHistoryData || !isArray(billingHistoryData.history))
				return [];
			return billingHistoryData.history.map((item) => ({
				billing_period: item.billing_period,
				status: (
					<Tag
						type={
							item.status === "Trial"
								? TAG_TYPE.INFO
								: item.status === "Upcoming"
								? TAG_TYPE.DEFAULT
								: item.status === "Paid"
								? TAG_TYPE.SUCCESS
								: TAG_TYPE.DEFAULT
						}
					>
						{item.status}
					</Tag>
				),
				upsells: item.upsells,
				OCUs: item.OCUs,
				subscription_charge: item.subscription_charge,
				total_payment_amount: item.total_payment_amount,
			}));
		}, [billingHistoryData, billingHistoryError]);

		const [hover, setHover] = useState({});

		const handleMouseEnter = (data, type) => {
			if (type === HEADERS.UPSELL) {
				if (data.upsell_count === 0) return;
				setHover({
					type: HEADERS.UPSELL,
					sortKey: data.sort_key,
					open: true,
				});
			} else if (type === HEADERS.OCU) {
				if (data.ocu_count === 0) return;
				setHover({
					type: HEADERS.OCU,
					sortKey: data.sort_key,
					open: true,
				});
			}
		};

		return (
			<section ref={parentRef}>
				{/* Plan Details section */}
				<SettingsModule>
					<SettingsModuleSection
						tightTop
						title={
							premium
								? "Your plan details"
								: "Your payments are not setup"
						}
					>
						<Text
							size={FONT_SIZE.BODY}
							color={FONT_COLOR.MID}
							vPadding={SPACING.REGULAR}
							tightBottom
						>
							{premium
								? "Congrats, you're a Disco Member"
								: "Please setup your payment details to upgrade your Disco membership"}
						</Text>
					</SettingsModuleSection>
					{!isShopifyShop(user) ? (
						<p>
							{!premium && `${iconText}. `}
							{premium && !inTrail && (
								<>
									Your card ending with
									<b> {user.publisher.card} </b>
									will be charged on{" "}
									{parseDate({
										date: user.publisher.subscriptions[0]
											.end_date,
										noTime: true,
									})}
									{". "}
								</>
							)}

							<span
								onClick={handleCardOpen}
								className="subscription-module-action"
							>
								{premium
									? "Edit your card details"
									: "Set up credit card"}
							</span>
						</p>
					) : (
						!premium && (
							<ShopPaySetView user={user} setUser={setUser} />
						)
					)}
					{/* {(!isShopifyShop(user) || premium) && (
							<section className="subscription-module-credits">
								<CreditsView
									heading="Earned Credits"
									className="subscription-module-earned"
									credits={user.publisher.earned_credits}
									showUnit={false}
									onClick={() =>
										isShopifyShop(user)
											? setCreditsOpen(true)
											: null
									}
								/>
								{!isShopifyShop(user) && (
									<CreditsView
										heading="Premium Credits"
										credits={user.publisher.premium_credits}
										showUnit={false}
										action="Get more credits"
										onClick={handleCreditsOpen}
									/>
								)}
							</section>
						)} */}
				</SettingsModule>

				{/* TODO - Add pagination */}
				{/* TODO - Add download button */}
				{/* Billing History section */}
				{isShopifyShop(user) && (
					<SettingsModule tightTop>
						<PaddedContainer vPadding={SPACING.TINY} tightBottom>
							<Table
								title="Billing history"
								headers={HEADERS}
								data={billingHistory}
								loading={billingHistoryLoading}
								sortable={true}
								noDataMessage="No billing history found"
							/>
						</PaddedContainer>
					</SettingsModule>
				)}
				<CardModal
					open={cardOpen && premium && !isShopifyShop(user)}
					onClose={handleCardClose}
					user={user}
					features={!premium}
					setUser={setUser}
				/>
				<PaymentModal
					open={cardOpen && !premium}
					onClose={handleCardClose}
					user={user}
					setUser={setUser}
				/>
			</section>
		);
	})
);

export default PaymentModule;
