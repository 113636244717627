import React, { memo, useContext, useEffect, useRef } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { BsTag } from "react-icons/bs";
import { CgMenuLeftAlt } from "react-icons/cg";
import { FiCalendar, FiSmartphone } from "react-icons/fi";
import { MdAlternateEmail } from "react-icons/md";

import {
	AUDIENCES_PROVIDER_DATA,
	FETCH_AUDIENCES_HISTORY_URL,
	FETCH_REQUESTS_INFO_URL,
} from "../../conf";
import useResource from "../../hooks/useResource";
import parseDate from "../../utils/parseDate";
import parseError from "../../utils/parseError";
import readableInt from "../../utils/readableInt";
import { Rectangle } from "../CoreUI";
import { audiencesHistoryContext } from "../feedModules/AudiencesModule";
import "./audiences-history-table-wrapper.css";
import random, { randInt } from "../../utils/random";
import InlineToast from "../InlineToast";
import { getTimeDelta } from "../../hooks/useUpdatedAt";
import usePing, { PING_TYPES } from "../../hooks/usePing";
import useContent from "../../hooks/useContent";
import {
	anim,
	FONT_SIZE,
	Loader,
	PaddedContainer,
	SPACING,
	StatusLabel,
	Table,
	Tag,
	TAG_TYPE,
	Text,
} from "@disco/disco_core";

const HEADERS = [
	{
		icon: <CgMenuLeftAlt />,
		text: "Platform",
		dataField: "platform",
	},
	{
		icon: <BsTag />,
		text: "Status",
		dataField: "status",
	},
	{
		icon: <MdAlternateEmail />,
		text: "Requestor",
		dataField: "requestor",
	},
	{
		icon: <AiOutlineUser />,
		text: "Audience Name",
		dataField: "name",
	},
	{
		icon: <FiSmartphone />,
		text: "Audience Size",
		dataField: "size",
	},
	{
		icon: <FiCalendar />,
		text: "Date Requested",
		dataField: "date",
	},
];

const AudiencesHistoryProvider = memo(({ children: provider }) => {
	const { icon, name } =
		AUDIENCES_PROVIDER_DATA[provider === null ? 0 : provider] || {};
	return (
		<Text
			hPadding={SPACING.SMALL}
			tightLeft
			size={FONT_SIZE.BODY}
			className="audiences-history-provider"
		>
			<img src={icon} alt="" />{" "}
			{provider === null ? <Rectangle width={30}></Rectangle> : name}
		</Text>
	);
});

const statuses = [
	{
		name: "Pending",
		tagType: TAG_TYPE.INFO,
		rectType: "yellow",
	},
	{
		name: "Sent",
		tagType: TAG_TYPE.SUCCESS,
		rectType: "green",
	},
	{
		name: "Cancelled",
		tagType: TAG_TYPE.ERROR,
		rectType: "red",
	},
];
const AudiencesHistoryStatus = memo(({ children: status }) => {
	const statusData =
		statuses[status === null ? randInt(0, statuses.length - 1) : status];

	return (
		<Tag type={statusData.tagType}>
			{status === null ? (
				<Rectangle type={statusData?.rectType} width={30} />
			) : (
				statusData?.name
			)}
		</Tag>
	);
});

const emptyItems = [
	{
		provider: null,
		status: null,
		requester: null,
		name: null,
		size: null,
		date: null,
	},
	{
		provider: null,
		status: null,
		requester: null,
		name: null,
		size: null,
		date: null,
	},
	{
		provider: null,
		status: null,
		requester: null,
		name: null,
		size: null,
		date: null,
	},
	{
		provider: null,
		status: null,
		requester: null,
		name: null,
		size: null,
		date: null,
	},
	{
		provider: null,
		status: null,
		requester: null,
		name: null,
		size: null,
		date: null,
	},
];

const emptyWidths = {
	requester: [170, 200],
	name: [120, 160],
	size: [60, 90],
	date: [30, 60],
};

const AudiencesHistoryTable = memo(({ items }) => {
	const tableRef = useRef();

	const isEmpty = !items.length;

	const content = useContent().platformAudiences;

	const renderRow = (row, index) => {
		return (
			<tr key={index}>
				<td data-testid={`audiences-history-table-provider-${row.id}`}>
					<AudiencesHistoryProvider>
						{row.provider}
					</AudiencesHistoryProvider>
				</td>

				<td data-testid={`audiences-history-table-status-${row.id}`}>
					<AudiencesHistoryStatus>
						{row.status}
					</AudiencesHistoryStatus>
				</td>

				<td data-testid={`audiences-history-table-requester-${row.id}`}>
					{isEmpty ? (
						<Rectangle width={random(...emptyWidths.requester)} />
					) : (
						row.requester
					)}
				</td>
				<td data-testid={`audiences-history-table-name-${row.id}`}>
					{isEmpty ? (
						<Rectangle width={random(...emptyWidths.name)} />
					) : (
						row.name
					)}
				</td>
				<td data-testid={`audiences-history-table-size-${row.id}`}>
					{isEmpty ? (
						<Rectangle width={random(...emptyWidths.size)} />
					) : row.size ? (
						readableInt(row.size)
					) : (
						"Calculating"
					)}
				</td>
				<td data-testid={`audiences-history-table-date-${row.id}`}>
					{isEmpty ? (
						<Rectangle width={random(...emptyWidths.date)} />
					) : (
						getTimeDelta(row.date_created, 7, " ago")
					)}
				</td>
			</tr>
		);
	};

	return (
		<>
			{isEmpty && (
				<InlineToast type="warn">{content.historyEmpty}</InlineToast>
			)}
			<PaddedContainer vPadding={SPACING.TINY} tightBottom>
				<Table
					ref={tableRef}
					headers={HEADERS}
					data={isEmpty ? emptyItems : items}
					renderRow={renderRow}
				/>
			</PaddedContainer>
		</>
	);
});

const AudiencesHistoryTableWrapper = memo(({ children }) => {
	const { history, setHistory } = useContext(audiencesHistoryContext);

	const [{ data, loading, error }, fetchHistory, reset] = useResource(
		{
			url: FETCH_REQUESTS_INFO_URL,
		},
		false
	);

	usePing({
		key: PING_TYPES.REFRESH_AUDIENCES_HISTORY,
		callback: fetchHistory,
	});

	useEffect(() => {
		if (Array.isArray(history?.items) || error || loading) return;
		fetchHistory();
	}, [history, error, loading, fetchHistory]);

	useEffect(() => {
		if (!data) return;
		setHistory({ items: data.lookalike_requests });
		reset();
	}, [data, setHistory, reset]);

	return (
		<PaddedContainer
			motionElement
			marginTop={SPACING.REGULAR}
			marginBottom={SPACING.REGULAR}
			vPadding={SPACING.SMALL}
			hPadding={SPACING.MEDIUM}
			className="audiences-history-table-wrapper"
			variants={anim.simplePageContent}
			initial="initial"
			animate="animate"
		>
			{children && (
				<Text
					size={FONT_SIZE.SUB_TITLE}
					marginTop={SPACING.REGULAR}
					marginBottom={SPACING.REGULAR}
					className="audiences-history-table-wrapper-heading"
				>
					{children}
				</Text>
			)}
			{Array.isArray(history?.items) ? (
				<AudiencesHistoryTable
					items={history.items}
				></AudiencesHistoryTable>
			) : error ? (
				<StatusLabel>{parseError(error)}</StatusLabel>
			) : (
				<Loader />
			)}
		</PaddedContainer>
	);
});

export default AudiencesHistoryTableWrapper;
