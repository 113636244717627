import React, { memo, useMemo, useState, useCallback } from "react";

import {
	EmailsBrandOptOutModalSelector,
	EmailsProductsPreview,
	EmailsSubCategoryOptOutModalSelector,
} from "./EmailsCoreUI";
import EmailsStep from "./EmailsStep";
import "./emails-advanced.css";

const EmailsAdvanced = memo(({ user, setUser }) => {
	const [refreshPreview, setRefreshPreview] = useState(false);
	const asideContent = useMemo(
		() => (
			<EmailsProductsPreview
				user={user}
				refresh={refreshPreview}
				setRefresh={setRefreshPreview}
			/>
		),
		[user, refreshPreview, setRefreshPreview]
	);

	const handleClose = useCallback(() => setRefreshPreview(true), []);

	return (
		<EmailsStep
			subHeading="Tune it the way you like for emails"
			heading="Advanced Settings"
			className="email-step-advanced"
			aside={asideContent}
		>
			<EmailsSubCategoryOptOutModalSelector
				user={user}
				setUser={setUser}
				onClose={handleClose}
			/>
			<EmailsBrandOptOutModalSelector
				user={user}
				setUser={setUser}
				onClose={handleClose}
			/>
		</EmailsStep>
	);
});

export default EmailsAdvanced;
