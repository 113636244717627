import React, { memo, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { PLATFORM_SURVEY_CLICK_ON_PERFORMANCE } from "../../events";
import useResource from "../../hooks/useResource";
import useTrack from "../../hooks/useTrack";
import parseError from "../../utils/parseError";
import { FormStatus, Loader } from "../CoreUI";

import "./performance-module.css";

export const PerformanceModuleRedirectButton = memo(
	({ className = "", children, ...props }) => (
		<Link {...props}>
			<div className={`performance-module-redirect-btn ${className}`}>
				{children}
			</div>
		</Link>
	)
);

const PerformanceModule = memo(
	({
		type = "",
		renderSidebarOption,
		renderPerformance,
		fetchModulesUrl,
		moduleNameDisplay = "",
		fetchModulesRemoteKey = "",
		listFilter,
		activeIdentifierKey = "active",
		hideNav = false,
		redirectButtonText = "",
		redirectButtonTo = "",
		children,
		className = "",
		moduleArrayModifier,
	}) => {
		const [activeItem, setActiveItem] = useState(null);
		const track = useTrack();
		// const [
		// 	{
		// 		loading: performanceLoading,
		// 		data: performanceData,
		// 		error: performanceError,
		// 	},
		// 	fetchPerformance,
		// ] = useResource({
		// 	url: FETCH_MODULE_ANALYTICS,
		// 	method: "POST",
		// 	data: {
		// 		module_type: type,
		// 	},
		// });

		const [
			{ loading: modulesLoading, data: modulesData, error: modulesError },
		] = useResource({
			url: fetchModulesUrl,
			method: "GET",
		});

		const searchFilter = useCallback(
			(value) => {
				if (typeof listFilter === "function") {
					return listFilter(value);
				}
				return true;
			},
			[listFilter]
		);

		const renderableModuleArray = useMemo(() => {
			let renderableModuleArray =
				modulesData && Array.isArray(modulesData[fetchModulesRemoteKey])
					? [
							...modulesData[fetchModulesRemoteKey].filter(
								(moduleData) => moduleData[activeIdentifierKey]
							),
							...modulesData[fetchModulesRemoteKey].filter(
								(moduleData) => !moduleData[activeIdentifierKey]
							),
					  ]
							.filter((mod) => !mod?.deleted)
							.filter(searchFilter)
					: [];

			if (typeof moduleArrayModifier === "function") {
				renderableModuleArray = moduleArrayModifier(
					renderableModuleArray
				);
			}
			return renderableModuleArray;
		}, [
			modulesData,
			fetchModulesRemoteKey,
			activeIdentifierKey,
			searchFilter,
			moduleArrayModifier,
		]);

		if (!type) {
			return null;
		}

		return (
			<>
				{modulesLoading && <Loader center />}
				{modulesError && (
					<FormStatus>{parseError(modulesError, true)}</FormStatus>
				)}
				{modulesData && (
					<section
						className={`performance-module-container ${className}`}
					>
						<section className="performance-module-sidebar">
							{children}
							{renderableModuleArray.length > 0 ? (
								renderableModuleArray.map((moduleData, idx) => (
									<div
										className={`performance-module-sidebar-item ${
											activeItem?.remoteId ===
											moduleData.remote_id
												? "performance-module-sidebar-item-active"
												: ""
										} ${moduleData?.className || ""}`}
										key={`${moduleData?.remote_id}_${idx}`}
										onClick={() => {
											if (type === "survey") {
												track(
													PLATFORM_SURVEY_CLICK_ON_PERFORMANCE,
													{ data: moduleData }
												);
											}
											setActiveItem({
												idx,
												remoteId: moduleData.remote_id,
											});
										}}
									>
										{renderSidebarOption(moduleData)}
									</div>
								))
							) : (
								<FormStatus
									type="theme"
									className="performance-module-sidebar-empty"
								>
									Please create {moduleNameDisplay} to see its
									performance
								</FormStatus>
							)}
						</section>
						{!hideNav && redirectButtonText && (
							<PerformanceModuleRedirectButton
								to={redirectButtonTo}
								className="performance-module-redirect-btn-sideline"
							>
								{redirectButtonText}
							</PerformanceModuleRedirectButton>
						)}
						<section className="performance-module-content">
							{!activeItem ||
							renderableModuleArray.find(
								(moduleData) =>
									moduleData.remote_id ===
									activeItem?.remoteId
							) === undefined ? (
								<section className="performance-analysis-module-empty">
									<h4>
										Select {moduleNameDisplay} to see its
										performance
									</h4>
								</section>
							) : typeof renderPerformance === "function" ? (
								renderPerformance(
									renderableModuleArray[activeItem.idx]
								)
							) : null}
						</section>
					</section>
				)}
			</>
		);
	}
);

export default PerformanceModule;
