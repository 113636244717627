import React, {
	createContext,
	memo,
	useCallback,
	useEffect,
	useState,
} from "react";
import { MOBILE_VIEWPORT_WIDTH } from "../conf";
import debounce from "../utils/debounce";

export const DimensionsContext = createContext();

const DimensionsManager = memo(({ children }) => {
	const [size, setSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const changeWindowSize = useCallback(() => {
		const { innerWidth: width, innerHeight: height } = window;
		setSize({
			width,
			height,
		});
	}, []);

	useEffect(() => {
		const debouncedChangeWindowSize = debounce(changeWindowSize, 200);
		window.addEventListener("resize", debouncedChangeWindowSize);

		return () => {
			window.removeEventListener("resize", debouncedChangeWindowSize);
		};
	}, [changeWindowSize]);

	return (
		<DimensionsContext.Provider
			value={{
				...size,
				isDesktop: size.width > MOBILE_VIEWPORT_WIDTH,
				isMobile: size.width <= MOBILE_VIEWPORT_WIDTH,
			}}
		>
			{children}
		</DimensionsContext.Provider>
	);
});

export default DimensionsManager;
