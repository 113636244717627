import React, { useMemo, memo, useState, useCallback, useEffect } from "react";
import { motion } from "framer-motion";
import anim from "../../utils/anim";

import "./survey-configuration.css";
import { FormStatus, Label, LabeledInput } from "../CoreUI";
// import { FormStatus, Label, LabeledInput, ToggleInput } from "../CoreUI";
import ModuleRules from "../ModuleRules";

const SurveyConfiguration = memo(
	({
		user,
		question,
		toggleRandomizeOptions,
		setRules,
		setApplyAnd,
		toggleRules,
		viewOnly = false,
	}) => {
		const [showRuleState, setShowRuleState] = useState(
			question.apply_rules ? 1 : 0
		);

		const handleShowRuleRadio = useCallback(
			(value) => setShowRuleState(value),
			[]
		);

		const showRuleData = [
			{ name: "Everyone who completes a purchase" },
			{
				name: "Customers who meet specific rules",
				renderChildren: () => (
					<ModuleRules
						user={user}
						rules={question.rules}
						setRules={setRules}
						applyAnd={question.apply_and}
						setApplyAnd={setApplyAnd}
						selectedProduct={[]}
						moduleName="survey"
						moduleType="survey"
					/>
				),
			},
		];

		useEffect(() => {
			if (viewOnly) {
				return;
			}
			if (showRuleState === 0) {
				toggleRules(false);
			} else if (showRuleState === 1) {
				toggleRules(true);
			}
		}, [showRuleState, toggleRules, viewOnly]);

		if (viewOnly) {
			return (
				<section className="survey-config-view-only">
					<section className="survey-config-group">
						<FormStatus
							className="survey-config-form-status"
							type={
								question?.randomize_options
									? "success"
									: "yellow"
							}
						>
							Randomize option order is{" "}
							{question?.randomize_options ? "" : "NOT"} active
						</FormStatus>
					</section>
					<section className="survey-config-group">
						{question?.rules.length > 0 && question?.apply_rules ? (
							<>
								<Label>
									This survey will show up when{" "}
									{question.apply_and === null
										? "none of the following are met"
										: ""}
								</Label>
								<ModuleRules
									edit={false}
									rules={question.rules}
									applyAnd={question.apply_and}
									setRules={() => {}}
								/>
							</>
						) : (
							<FormStatus
								className="survey-config-form-status"
								type="theme"
							>
								This survey will be visible to all customers
							</FormStatus>
						)}
					</section>
				</section>
			);
		}

		return (
			<motion.section
				variants={anim.pageContent}
				initial="initial"
				animate="animate"
				exit="exit"
				className="survey-config-container"
			>
				<div className="survey-config-group">
					<LabeledInput
						toggle
						label="Randomize survey option order"
						toggleState={question?.randomize_options || false}
						handleToggle={toggleRandomizeOptions}
						// customFeedbackText=""
						// hideDefaultFeedbackText
					/>
				</div>
				<div className="survey-config-group">
					<LabeledInput
						radio
						label="Show this survey to"
						data={showRuleData}
						selected={showRuleState}
						onChange={handleShowRuleRadio}
						className="survey-config-rules"
					/>
				</div>
			</motion.section>
		);
	}
);

export default SurveyConfiguration;
