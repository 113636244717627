import { useMemo } from "react";

const useInReview = (user) => {
	const inReview = useMemo(
		() => user?.publisher?.under_review || false,
		[user.publisher.under_review]
	);

	return { inReview };
};

export default useInReview;
