import React, { useState, useEffect, useCallback } from "react";
import {
	BadgeButton,
	Loader,
	FormStatus,
	Collapsable,
	Button,
} from "../CoreUI";
import { motion } from "framer-motion";
import "./list-view-entry.css";

import useResource from "../../hooks/useResource";
import parseError from "../../utils/parseError";
import useTrack from "../../hooks/useTrack";
import { PLATFORM_SURVEY_CLICK_ON_DISABLE } from "../../events";

const ListViewEntry = React.memo(
	({
		obj,
		idx,
		activeIdx = idx,
		setActiveIdx,
		renderHead,
		renderContent,
		toggleUrl,
		onToggle,
		onUpdate,
		onDelete,
		disableDelete = false,
		onEdit,
		remoteKey,
		partial = false,
		checkNotPartialKey = "",
		dynamicList = false,
		onOpen,
		onClose,
		singleOpen = false,
		survey = false,
		setRefresh,
		reorderable = false,
	}) => {
		const [open, setOpen] = useState(false);

		const [flagPayload, setFlagPayload] = useState({
			url: toggleUrl(obj),
			method: "PUT",
			data: {},
		});

		const [{ loading, data, error }, changeFlag, resetFlags] = useResource(
			flagPayload,
			false
		);

		const track = useTrack();

		const toggleActive = useCallback(() => {
			if (survey && obj.active) {
				track(PLATFORM_SURVEY_CLICK_ON_DISABLE, {});
			}

			setFlagPayload((data) => ({
				...data,
				data: {
					active: !obj.active,
				},
			}));
			if (typeof onUpdate === "function") {
				onUpdate(obj);
			}
			changeFlag();
		}, [survey, obj, onUpdate, changeFlag, track]);

		const deleteObj = () => {
			setFlagPayload((data) => ({
				...data,
				data: {
					deleted: true,
				},
			}));
			changeFlag();
		};

		useEffect(() => {
			if (data) {
				if (data[remoteKey].deleted) {
					onDelete(obj.remote_id);
				} else {
					if (!survey) {
						onToggle(obj.remote_id, data[remoteKey].active);
					} else {
						if (setRefresh && typeof setRefresh === "function") {
							setRefresh(true);
						}
					}
				}
				resetFlags();
			}
		}, [
			data,
			onToggle,
			resetFlags,
			obj,
			remoteKey,
			onDelete,
			survey,
			setRefresh,
		]);

		useEffect(() => {
			if (open) {
				if (onOpen && typeof onOpen === "function") {
					onOpen(idx);
					return;
				}
			} else if (!open) {
				if (onClose && typeof onClose === "function") {
					onClose(idx);
					return;
				}
			}
		}, [open, onOpen, onClose, idx]);

		useEffect(() => {
			if (!singleOpen) {
				return;
			}
			if (idx !== activeIdx) {
				setOpen(false);
				return;
			} else {
				setOpen(true);
				return;
			}
		}, [idx, activeIdx, singleOpen]);

		const setCollapsableOpen = useCallback(
			(val) => {
				if (
					!singleOpen ||
					!setActiveIdx ||
					typeof setActiveIdx !== "function"
				) {
					return setOpen(val);
				}
				if (open) {
					setActiveIdx(-1);
				} else {
					setActiveIdx(idx);
				}
			},
			[singleOpen, idx, setActiveIdx, open]
		);

		return (
			<Collapsable
				className="list-view-entry"
				open={open}
				setOpen={setCollapsableOpen}
				heading={renderHead(obj, idx)}
				dynamicList={dynamicList}
				reorderable={reorderable}
			>
				<motion.section layout>
					{renderContent(obj, idx, setCollapsableOpen)}

					{!dynamicList ? (
						<>
							<section className="list-view-entry-action">
								{!loading && (
									<>
										{(!partial ||
											obj[checkNotPartialKey]) && (
											<BadgeButton
												small
												onClick={toggleActive}
											>
												{obj.active
													? "Disable"
													: "Make active"}
											</BadgeButton>
										)}
										<BadgeButton
											small
											onClick={() => {
												onEdit(obj);
											}}
										>
											{partial && !obj[checkNotPartialKey]
												? "Continue Editing"
												: "Edit"}
										</BadgeButton>
										{!(obj.is_default || disableDelete) && (
											<BadgeButton
												className="list-view-entry-delete"
												small
												onClick={deleteObj}
											>
												Delete
											</BadgeButton>
										)}
									</>
								)}
								{loading && <Loader />}
							</section>
						</>
					) : survey ? (
						<>
							<section className="list-view-entry-action-custom">
								<Button type="button" onClick={toggleActive}>
									{obj.active ? "Disable" : "Make active"}
								</Button>
								<Button
									type="button"
									dark
									onClick={() => onEdit(obj)}
								>
									Edit
								</Button>
							</section>
						</>
					) : null}
					{dynamicList && (
						<section className="list-view-entry-action list-view-entry-action-secondary">
							{!obj.is_default && !loading && (
								<BadgeButton
									className="list-view-entry-delete-secondary"
									onClick={deleteObj}
									type="button"
								>
									Delete
								</BadgeButton>
							)}
							{loading && <Loader />}
						</section>
					)}
					{error && <FormStatus>{parseError(error)}</FormStatus>}
				</motion.section>
			</Collapsable>
		);
	}
);

export default ListViewEntry;
