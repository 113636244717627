import React, { forwardRef, memo } from "react";
import {
	PaddedContainer,
	SPACING,
	PaddedContainerProps,
} from "../PaddedContainer";
import genPackageClassName from "../../utils/genPackageClassName";
import { GenericRef, Extend, ObjectValuesAsTypes } from "../../shared.types";

import "./base-tooltip.css";

export const TOOLTIP_TYPE = {
	DEFAULT: "default",
	DARK: "dark",
	REVIEW: "review",
} as const;

export type TooltipType = ObjectValuesAsTypes<typeof TOOLTIP_TYPE>;

type NativeBaseTooltipProps = {
	type?: TooltipType;
	blur?: boolean;
};

export type BaseTooltipProps = Extend<
	PaddedContainerProps,
	NativeBaseTooltipProps
>;

type BaseTooltipComponent = (props: BaseTooltipProps) => React.ReactNode | null;

export const BaseTooltip: BaseTooltipComponent = memo(
	forwardRef(
		(
			{
				children,
				type = TOOLTIP_TYPE.DEFAULT,
				className = "",
				blur = false,
				noBorder = false,
				...rest
			}: BaseTooltipProps,
			ref?: GenericRef
		) => {
			return (
				<PaddedContainer
					ref={ref}
					className={genPackageClassName({
						base: "base-tooltip",
						additional: className,
						conditionals: {
							[`base-tooltip-${type}`]: type,
							"base-tooltip-blur": blur,
							"base-tooltip-no-border": noBorder,
						},
					})}
					vPadding={SPACING.REGULAR}
					hPadding={SPACING.REGULAR}
					{...rest}
				>
					{children}
				</PaddedContainer>
			);
		}
	)
);
