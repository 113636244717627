const windowRedirect = (url) => {
	if (!url) {
		return;
	}
	const redirectWindow = window.open();
	if (!redirectWindow) {
		return;
	}
	redirectWindow.opener = null;
	redirectWindow.location = url;
};
export default windowRedirect;
