import { useSortable } from "@dnd-kit/sortable";
import { forwardRef, memo, useMemo } from "react";
import { CSS } from "@dnd-kit/utilities";

const AbstractListItem = memo(
	forwardRef(
		(
			{
				id,
				activeStyle,
				disabled = false,
				horizontal = false,
				children,
				...rest
			},
			ref
		) => {
			const {
				attributes,
				listeners,
				setNodeRef,
				transform,
				transition,
				active,
			} = useSortable({
				id,
				disabled,
			});

			const isActive = useMemo(
				() => active && active.id === id,
				[id, active]
			);

			const totalActiveStyle = {
				zIndex: 10,
				boxShadow: "0px 32px 40px 0 rgba(0, 0, 0, 0.15)",
				...activeStyle,
			};

			const style = {
				transform: CSS.Transform.toString({
					...transform,
					y: horizontal ? undefined : transform?.y,
					scaleX: 1,
					scaleY: 1,
				}),
				transition,
				...(isActive ? totalActiveStyle : {}),
			};

			const setRef = (passed) => {
				setNodeRef(passed);
				if (ref) {
					if (typeof ref === "function") {
						ref(passed);
					} else {
						ref.current = passed;
					}
				}
			};

			return children({
				ref: setRef,
				style,
				...attributes,
				...listeners,
				...rest,
				isActive,
				transform,
			});
		}
	)
);

export default AbstractListItem;
