import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import CentralView from "./CentralView";
import background from "../../img/login-background.png";
import { Button, BaseForm, FormStatus, LabeledInput, Loader } from "../CoreUI";
import "./login-view.css";
import ShopSelector from "../ShopSelector";
import useResource from "../../hooks/useResource";
import { FETCH_STORE_URL, LOGIN_URL } from "../../conf";
import parseError from "../../utils/parseError";
import tokenStorage from "../../utils/tokenStorage";
import useTrack from "../../hooks/useTrack";
import useViewTrack from "../../hooks/useViewTrack";
import {
	PLATFORM_CLICK_ON_LOGIN,
	PLATFORM_LOGIN_SUCCESSFUL,
	PLATFORM_VIEW_LOGIN,
} from "../../events";
import { Link } from "react-router-dom";
import useDimensions from "../../hooks/useDimensions";

const LoginView = memo(({ setAuth }) => {
	const [data, setData] = useState({ email: "", password: "" });
	const [shops, setShops] = useState(null);
	const track = useTrack();
	const publisherRef = useRef();
	const { isMobile } = useDimensions();

	useViewTrack(PLATFORM_VIEW_LOGIN);

	const handleChange = ({ target: { name, value } }) => {
		setData((data) => ({ ...data, [name]: value }));
	};

	const [
		{ loading: shopsLoading, error: shopsError, data: shopsData },
		fetchShops,
		resetShops,
	] = useResource(
		{
			url: FETCH_STORE_URL,
			method: "POST",
			data,
		},
		false,
		false
	);

	const [formError, setFormError] = useState("");

	const [
		{ loading: loginLoading, error: loginError, data: loginData },
		login,
		resetLogin,
	] = useResource(
		{
			url: LOGIN_URL,
			method: "POST",
			data,
		},
		false,
		false
	);

	useEffect(() => {
		if (!loginData) return;
		tokenStorage.set({
			accessToken: loginData.access,
			refreshToken: loginData.refresh,
			publisher: publisherRef.current,
		});

		track(PLATFORM_LOGIN_SUCCESSFUL, {});
		setAuth(true);
		resetLogin();
	}, [loginData, resetLogin, setAuth, track]);

	const loginPublisher = useCallback(
		(publisher) => {
			track(PLATFORM_CLICK_ON_LOGIN, {});
			login();

			publisherRef.current = publisher;
		},
		[login, track]
	);

	useEffect(() => {
		if (!shopsData) return;
		resetShops();
		if (shopsData.length <= 1) {
			loginPublisher(shopsData[0].publisher_id);
		} else setShops(shopsData);
	}, [shopsData, loginPublisher, resetShops]);

	const handleLogin = (e) => {
		if (e) {
			e.preventDefault();
		}
		track(PLATFORM_CLICK_ON_LOGIN, {});
		if (!data.email.trim() || !data.password) {
			return setFormError("Both email and password are required");
		}
		setFormError("");
		fetchShops();
	};

	return (
		<CentralView
			background={background}
			className="login-view"
			showMobileLogo
			mobileHeading="Welcome back!"
		>
			<BaseForm
				heading={
					!isMobile
						? Array.isArray(shops)
							? "Select a shop"
							: "Welcome back!"
						: null
				}
				onSubmit={handleLogin}
			>
				{Array.isArray(shops) ? (
					<ShopSelector
						shops={shops}
						loading={loginLoading || shopsLoading}
						error={loginError}
						onLogin={loginPublisher}
					/>
				) : (
					<>
						<LabeledInput
							label={"Email Address"}
							name="email"
							value={data.email}
							onChange={handleChange}
							placeholder="Email Address"
						/>
						<LabeledInput
							label={"Password"}
							name="password"
							type="password"
							value={data.password}
							onChange={handleChange}
							placeholder="Password"
						/>
						{shopsLoading || loginLoading ? (
							<Loader small2 />
						) : (
							<Button
								className="login-view-login-button"
								gradient
								large
								shadow={isMobile}
							>
								Log In
							</Button>
						)}
						{(shopsError || loginError || formError) && (
							<FormStatus>
								{formError
									? formError
									: shopsError
									? parseError(shopsError)
									: parseError(loginError)}
							</FormStatus>
						)}
						{!isMobile && (
							<>
								<Link to="/reset" className="login-view-link">
									Forgot Password?
								</Link>
							</>
						)}
						<span className="login-view-link login-view-link-register">
							Don't have an account?{" "}
							<Link to="/register">Sign up</Link>
						</span>
					</>
				)}
			</BaseForm>
		</CentralView>
	);
});

export default LoginView;
