import React, { useEffect, useState } from "react";
import { FaShopify } from "react-icons/fa";
import { SHOPIFY_CONNECT_URL } from "../../conf";
import useResource from "../../hooks/useResource";
import { Button, Card, FormStatus, Input, Loader } from "../CoreUI";
import "./shopify.css";

const ShopifyConnectCard = React.memo(() => {
	const [shop, setShop] = useState("");
	const [connectOptions, setConnectOptions] = useState({
		url: SHOPIFY_CONNECT_URL,
		method: "GET",
		data: { shop },
	});

	const [formError, setFormError] = useState("");

	const [{ loading, data, error }, startConnect, reset] = useResource(
		connectOptions,
		false
	);

	const connect = () => {
		if (shop.trim().length < 1) {
			return setFormError("Please enter a shop");
		}
		setFormError("");
		setConnectOptions((options) => ({
			...options,
			params: { shop: shop.trim() },
		}));
		startConnect();
	};

	useEffect(() => {
		if (data && data.redirect_url) {
			window.location.href = data.redirect_url;
			reset();
		}
	}, [data, reset]);

	return (
		<Card className="shopify-card">
			<div className="shopify-logo">
				<FaShopify />
			</div>
			<h2>Not Connected to shopify</h2>
			<p>
				Disco can connect to your Shopify account to pull in your
				inventory. To allow us to do so, connect Disco with your shopify
				store. Disco can connect to your Shopify account to pull in your
				inventory. To allow us to do so, connect Disco with your shopify
				store.
			</p>
			<Input
				placeholder="Shop name"
				value={shop}
				onChange={(e) => setShop(e.target.value)}
			/>
			{!loading && <Button onClick={connect}>Connect</Button>}
			{loading && <Loader />}
			{error || formError ? (
				<FormStatus>
					{formError ||
						(error.response && error.response.data.error) ||
						"Could not connect to shopify. Try again later"}
				</FormStatus>
			) : null}
		</Card>
	);
});

export default ShopifyConnectCard;
