// Declare all events here. Follow naming convention of:
// <source>_<verb>

// For generic redirects upon click, use this event
export const PLATFORM_REDIRECT_ON_CLICK = "PLATFORM_REDIRECT_ON_CLICK";
export const REDIRECT_INITIATOR = {};

export const PLATFORM_VIEW_PRODUCTS = "PLATFORM_VIEW_PRODUCTS";
export const PLATFORM_VIEW_PROFILE = "PLATFORM_VIEW_PROFILE";
export const PLATFORM_VIEW_SETTINGS = "PLATFORM_VIEW_SETTINGS";
export const PLATFORM_VIEW_PERSONALIZE = "PLATFORM_VIEW_PERSONALIZE";
export const PLATFORM_VIEW_SURFACES = "PLATFORM_VIEW_SURFACES";
export const PLATFORM_VIEW_PERFORMANCE = "PLATFORM_VIEW_PERFORMANCE";
export const PLATFORM_VIEW_EMAILS = "PLATFORM_VIEW_EMAILS";
export const PLATFORM_VIEW_AUDIENCES = "PLATFORM_VIEW_AUDIENCES";
export const PLATFORM_VIEW_INTEGRATION = "PLATFORM_VIEW_INTEGRATION";
export const PLATFORM_VIEW_PLATFORM_HELP = "PLATFORM_VIEW_PLATFORM_HELP";
export const PLATFORM_VIEW_LEARN = "PLATFORM_VIEW_LEARN";
export const PLATFORM_VIEW_GROW = "PLATFORM_VIEW_GROW";
export const PLATFORM_VIEW_SELL = "PLATFORM_VIEW_SELL";
export const PLATFORM_VIEW_SURVEY = "PLATFORM_VIEW_SURVEY";
export const PLATFORM_VIEW_CROSS_SELL_THANKYOU =
	"PLATFORM_VIEW_CROSS_SELL_THANKYOU";
export const PLATFORM_VIEW_DISCO_CASH = "PLATFORM_VIEW_DISCO_CASH";
export const PLATFORM_VIEW_UPSELL_THANKYOU = "PLATFORM_VIEW_UPSELL_THANKYOU";
export const PLATFORM_VIEW_LOGIN = "PLATFORM_VIEW_LOGIN";
export const PLATFORM_VIEW_HOMEPAGE = "PLATFORM_VIEW_HOMEPAGE";
export const PLATFORM_VIEW_BUILD_YOUR_PROFILE =
	"PLATFORM_VIEW_BUILD_YOUR_PROFILE";
// export const PLATFORM_VIEW_COMPLETION_MODAL = "PLATFORM_VIEW_COMPLETION_MODAL";
export const PLATFORM_VIEW_ONBOARDING_COMPLETION_MODAL =
	"PLATFORM_VIEW_ONBOARDING_COMPLETION_MODAL";

export const PLATFORM_SETUP_BACK = "PLATFORM_SETUP_BACK";
export const PLATFORM_SETUP_NEXT = "PLATFORM_SETUP_NEXT";
export const PLATFORM_SETUP_LOGOUT = "PLATFORM_SETUP_LOGOUT";
export const PLATFORM_SETUP_VIEW = "PLATFORM_SETUP_VIEW";
export const PLATFORM_ADD_PRODUCTS_CSV = "PLATFORM_ADD_PRODUCTS_CSV";
export const PLATFORM_ADD_PRODUCTS_CSV_ERROR =
	"PLATFORM_ADD_PRODUCTS_CSV_ERROR";
export const PLATFORM_CHANGE_BRAND_IMG = "PLATFORM_CHANGE_BRAND_IMG";
export const PLATFORM_ADD_PRODUCT_ERROR = "PLATFORM_ADD_PRODUCT_ERROR";
export const PLATFORM_ADD_PRODUCT_IMAGE_ERROR =
	"PLATFORM_ADD_PRODUCT_IMAGE_ERROR";
export const PLATFORM_ADD_PRODUCT = "PLATFORM_ADD_PRODUCT";
export const PLATFORM_EDIT_PRODUCT = "PLATFORM_EDIT_PRODUCT";
export const PLATFORM_CHANGE_PUBLISHER_VISIBILITY =
	"PLATFORM_CHANGE_PUBLISHER_VISIBILITY";
export const PLATFORM_CHANGE_NETWORK_VISIBILITY =
	"PLATFORM_CHANGE_NETWORK_VISIBILITY";

export const PLATFORM_CHANGE_CATEGORIES = "PLATFORM_CHANGE_CATEGORIES";
export const PLATFORM_CHANGE_SUB_CATEGORIES = "PLATFORM_CHANGE_SUB_CATEGORIES";
export const PLATFORM_CHANGE_CATEGORY_WHITELIST =
	"PLATFORM_CHANGE_CATEGORY_WHITELIST";
export const PLATFORM_CHANGE_BADGES = "PLATFORM_CHANGE_BADGES";

export const PLATFORM_CHANGE_OPTOUT = "PLATFORM_CHANGE_OPTOUT";

export const PLATFORM_CHANGE_PREFERRED_BADGES =
	"PLATFORM_CHANGE_PREFERRED_BADGES";

export const PLATFORM_CHANGE_SUB_CATEGORY_BLACKLIST =
	"PLATFORM_CHANGE_SUB_CATEGORY_BLACKLIST";

export const PLATFORM_CHANGE_BRAND_URL = "PLATFORM_CHANGE_BRAND_URL";
export const PLATFORM_CHANGE_BRAND_NAME = "PLATFORM_CHANGE_BRAND_NAME";
export const PLATFORM_CHANGE_BRAND_DESC = "PLATFORM_CHANGE_BRAND_DESC";
export const PLATFORM_CHANGE_BRAND_ORDER_VOLUME =
	"PLATFORM_CHANGE_BRAND_ORDER_VOLUME";
export const PLATFORM_CHANGE_BRAND_POC = "PLATFORM_CHANGE_BRAND_POC";
export const PLATFORM_CHANGE_BRAND_PHONE_NUMBER =
	"PLATFORM_CHANGE_BRAND_PHONE_NUMBER";
export const PLATFORM_CHANGE_BRAND_TWITTER = "PLATFORM_CHANGE_BRAND_TWITTER";
export const PLATFORM_CHANGE_BRAND_INSTAGRAM =
	"PLATFORM_CHANGE_BRAND_INSTAGRAM";
export const PLATFORM_CHANGE_BRAND_FACEBOOK_PIXEL =
	"PLATFORM_CHANGE_BRAND_FACEBOOK_PIXEL";
export const PLATFORM_CHANGE_BRAND_MEDIA_KIT =
	"PLATFORM_CHANGE_BRAND_MEDIA_KIT";
export const PLATFORM_CHANGE_HEADER = "PLATFORM_CHANGE_HEADER";
export const PLATFORM_CHANGE_LONG_BRAND_DESC =
	"PLATFORM_CHANGE_LONG_BRAND_DESC";
export const PLATFORM_CHANGE_PASSWORD = "PLATFORM_CHANGE_PASSWORD";
export const PLATFORM_REQUEST_MOBILE_CLICK = "PLATFORM_REQUEST_MOBILE_CLICK";

// IA Nav
export const PLATFORM_CLICK_ON_LEARN = "PLATFORM_CLICK_ON_LEARN";
export const PLATFORM_CLICK_ON_DISCO_FEED = "PLATFORM_CLICK_ON_DISCO_FEED";
export const PLATFORM_CLICK_ON_DISCO_FEED_FEED =
	"PLATFORM_CLICK_ON_DISCO_FEED_FEED";
export const PLATFORM_CLICK_ON_DISCO_FEED_PRODUCTS =
	"PLATFORM_CLICK_ON_DISCO_FEED_PRODUCTS";

export const PLATFORM_CLICK_ON_GROW = "PLATFORM_CLICK_ON_GROW";
export const PLATFORM_CLICK_ON_SELL = "PLATFORM_CLICK_ON_SELL";
export const PLATFORM_CLICK_ON_SETTINGS = "PLATFORM_CLICK_ON_SETTINGS";
export const PLATFORM_CLICK_ON_PERFORMANCE = "PLATFORM_CLICK_ON_PERFORMANCE";

export const PLATFORM_CLICK_ON_LEARN_CUSTOMER_SURVEY =
	"PLATFORM_CLICK_ON_LEARN_CUSTOMER_SURVEY";
export const PLATFORM_CLICK_ON_LEARN_GROWTH_INSIGHTS =
	"PLATFORM_CLICK_ON_LEARN_GROWTH_INSIGHTS";
export const PLATFORM_CLICK_ON_GROW_CROSS_SELL =
	"PLATFORM_CLICK_ON_GROW_CROSS_SELL";
export const PLATFORM_CLICK_ON_GROW_EMAILS = "PLATFORM_CLICK_ON_GROW_EMAILS";
export const PLATFORM_CLICK_ON_GROW_AUDIENCES =
	"PLATFORM_CLICK_ON_GROW_AUDIENCES";
export const PLATFORM_CLICK_ON_GROW_CASH = "PLATFORM_CLICK_ON_GROW_CASH";
export const PLATFORM_CLICK_ON_SELL_UPSELL = "PLATFORM_CLICK_ON_SELL_UPSELL";
export const PLATFORM_CLICK_ON_SELL_EMAILS = "PLATFORM_CLICK_ON_SELL_EMAILS";
export const PLATFORM_CLICK_ON_SELL_OFFERS = "PLATFORM_CLICK_ON_SELL_OFFERS";
export const PLATFORM_CLICK_ON_SETTINGS_BRAND =
	"PLATFORM_CLICK_ON_SETTINGS_BRAND";
export const PLATFORM_CLICK_ON_SETTINGS_INTEGRATIONS =
	"PLATFORM_CLICK_ON_SETTINGS_INTEGRATIONS";
export const PLATFORM_CLICK_ON_SETTINGS_PAYMENT =
	"PLATFORM_CLICK_ON_SETTINGS_PAYMENT";
export const PLATFORM_CLICK_ON_SETTINGS_TEAM =
	"PLATFORM_CLICK_ON_SETTINGS_TEAM";
export const PLATFORM_CLICK_ON_SETTINGS_PAGE_SETUP =
	"PLATFORM_CLICK_ON_SETTINGS_PAGE_SETUP";
export const PLATFORM_CLICK_ON_SETTINGS_ACCOUNT =
	"PLATFORM_CLICK_ON_SETTINGS_ACCOUNT";
export const PLATFORM_CLICK_ON_PERFORMANCE_SUMMARY =
	"PLATFORM_CLICK_ON_PERFORMANCE_SUMMARY";
export const PLATFORM_CLICK_ON_PERFORMANCE_PARTNERSHIPS =
	"PLATFORM_CLICK_ON_PERFORMANCE_PARTNERSHIPS";
export const PLATFORM_CLICK_ON_PERFORMANCE_PAID_CAMPAIGN =
	"PLATFORM_CLICK_ON_PERFORMANCE_PAID_CAMPAIGN";
export const PLATFORM_CLICK_ON_PERFORMANCE_EMAILS =
	"PLATFORM_CLICK_ON_PERFORMANCE_EMAILS";
export const PLATFORM_CLICK_ON_PERFORMANCE_SURVEYS =
	"PLATFORM_CLICK_ON_PERFORMANCE_SURVEYS";

export const PLATFORM_SURVEY_TOGGLE = "PLATFORM_SURVEY_TOGGLE";
export const PLATFORM_SURVEY_CLICK_ON_ADD_QUESTION =
	"PLATFORM_SURVEY_CLICK_ON_ADD_QUESTION";
export const PLATFORM_SURVEY_CLICK_ON_VIEW_CONFIGURATION =
	"PLATFORM_SURVEY_CLICK_ON_VIEW_CONFIGURATION";
export const PLATFORM_SURVEY_CLICK_ON_DISABLE =
	"PLATFORM_SURVEY_CLICK_ON_DISABLE";
export const PLATFORM_SURVEY_CLICK_ON_EDIT = "PLATFORM_SURVEY_CLICK_ON_EDIT";
export const PLATFORM_SURVEY_CLICK_ON_VIEW_RESULTS =
	"PLATFORM_SURVEY_CLICK_ON_VIEW_RESULTS";
export const PLATFORM_SURVEY_CLICK_ON_PERFORMANCE =
	"PLATFORM_SURVEY_CLICK_ON_PERFORMANCE";

export const PLATFORM_CROSS_SELL_TOGGLE = "PLATFORM_CROSS_SELL_TOGGLE";
export const PLATFORM_CROSS_SELL_TOGGLE_ORDER_OPTIMIZATION =
	"PLATFORM_CROSS_SELL_TOGGLE_ORDER_OPTIMIZATION";
export const PLATFORM_CROSS_SELL_CLICK_ON_MANAGE_CROSS_CELL =
	"PLATFORM_CROSS_SELL_CLICK_ON_MANAGE_CROSS_CELL";
export const PLATFORM_CROSS_SELL_CLICK_ON_MANAGE_PRODUCT_ORDERING =
	"PLATFORM_CROSS_SELL_CLICK_ON_MANAGE_PRODUCT_ORDERING";

export const PLATFORM_AUDIENCES_VIEW_INTRO = "PLATFORM_AUDIENCES_VIEW_INTRO";
export const PLATFORM_AUDIENCES_VIEW_HOW_IT_WORKS =
	"PLATFORM_AUDIENCES_VIEW_HOW_IT_WORKS";
export const PLATFORM_AUDIENCES_VIEW_AMEND_PRIVACY_POLICY =
	"PLATFORM_AUDIENCES_VIEW_AMEND_PRIVACY_POLICY";
export const PLATFORM_AUDIENCES_VIEW_CONNECT_TO_FACEBOOK =
	"PLATFORM_AUDIENCES_VIEW_CONNECT_TO_FACEBOOK";
export const PLATFORM_AUDIENCES_VIEW_AUDIENCE_MATCH =
	"PLATFORM_AUDIENCES_VIEW_AUDIENCE_MATCH";
export const PLATFORM_AUDIENCES_CLICK_REQUEST_AUDIENCE =
	"PLATFORM_AUDIENCES_CLICK_REQUEST_AUDIENCE";
export const PLATFORM_AUDIENCES_CLICK_DISCONNECT_FACEBOOK =
	"PLATFORM_AUDIENCES_CLICK_DISCONNECT_FACEBOOK";
export const PLATFORM_AUDIENCES_CLICK_CHANGE_AD_ACCOUNT =
	"PLATFORM_AUDIENCES_CLICK_CHANGE_AD_ACCOUNT";
export const PLATFORM_AUDIENCES_FACEBOOK_CONNECTED_SUCCESSFULLY =
	"PLATFORM_AUDIENCES_FACEBOOK_CONNECTED_SUCCESSFULLY";
export const PLATFORM_AUDIENCES_ERROR_CONNECTING_TO_FACEBOOK =
	"PLATFORM_AUDIENCES_ERROR_CONNECTING_TO_FACEBOOK";
export const PLATFORM_AUDIENCES_TOGGLE_CATEGORIES =
	"PLATFORM_AUDIENCES_TOGGLE_CATEGORIES";
export const PLATFORM_AUDIENCES_CLICK_CATEGORY =
	"PLATFORM_AUDIENCES_CLICK_CATEGORY";

export const PLATFORM_DISCO_CASH_TOGGLE = "PLATFORM_DISCO_CASH_TOGGLE";
export const PLATFORM_DISCO_CASH_CLICK_OVERLAY =
	"PLATFORM_DISCO_CASH_CLICK_OVERLAY";
export const PLATFORM_DISCO_CASH_CLICK_EMAIL =
	"PLATFORM_DISCO_CASH_CLICK_EMAIL";
export const PLATFORM_DISCO_CASH_CLICK_SEND_SAMPLE_EMAIL =
	"PLATFORM_DISCO_CASH_CLICK_SEND_SAMPLE_EMAIL";

export const PLATFORM_UPSELL_TOGGLE = "PLATFORM_UPSELL_TOGGLE";
export const PLATFORM_UPSELL_TOGGLE_SHOW_PRICES =
	"PLATFORM_UPSELL_TOGGLE_SHOW_PRICES";
export const PLATFORM_UPSELL_TOGGLE_SHOW_OFFER_CODE =
	"PLATFORM_UPSELL_TOGGLE_SHOW_OFFER_CODE";
export const PLATFORM_UPSELL_CLICK_ON_MANAGE_UPSELL =
	"PLATFORM_UPSELL_CLICK_ON_MANAGE_UPSELL";
export const PLATFORM_UPSELL_CLICK_ON_MANAGE_PRODUCT_ORDERING =
	"PLATFORM_UPSELL_CLICK_ON_MANAGE_PRODUCT_ORDERING";

export const PLATFORM_LOGIN_SUCCESSFUL = "PLATFORM_LOGIN_SUCCESSFUL";
export const PLATFORM_CLICK_ON_LOGIN = "PLATFORM_CLICK_ON_LOGIN";

export const PLATFORM_ONBOARDING_VIEW_REGISTER =
	"PLATFORM_ONBOARDING_VIEW_REGISTER";

export const PLATFORM_ONBOARDING_VIEW_REGISTER_COMPLETION =
	"PLATFORM_ONBOARDING_VIEW_REGISTER_COMPLETION";
export const PLATFORM_ONBOARDING_VIEW_REGISTER_FEATURES =
	"PLATFORM_ONBOARDING_VIEW_REGISTER_FEATURES";
export const PLATFORM_ONBOARDING_TOGGLE = "PLATFORM_ONBOARDING_TOGGLE";

export const PLATFORM_ONBOARDING_CLICK_ON_REGISTER_INITIATE =
	"PLATFORM_ONBOARDING_CLICK_ON_REGISTER_INITIATE";

export const PLATFORM_ONBOARDING_CLICK_ON_INITIATE =
	"PLATFORM_ONBOARDING_CLICK_ON_INITIATE";

export const PLATFORM_BUILD_YOUR_PROFILE_CLICK_ON_CLEAR =
	"PLATFORM_BUILD_YOUR_PROFILE_CLICK_ON_CLEAR";
export const PLATFORM_BUILD_YOUR_PROFILE_CLICK_ON_REIMPORT =
	"PLATFORM_BUILD_YOUR_PROFILE_CLICK_ON_REIMPORT";
export const PLATFORM_BUILD_YOUR_PROFILE_CLICK_ON_SAVE_PROFILE =
	"PLATFORM_BUILD_YOUR_PROFILE_CLICK_ON_SAVE_PROFILE";
export const PLATFORM_BUILD_YOUR_PROFILE_CLICK_ON_CHANGE_LOGO =
	"PLATFORM_BUILD_YOUR_PROFILE_CLICK_ON_CHANGE_LOGO";

export const PLATFORM_PRIVACY_CLICK_ON_COPY = "PLATFORM_PRIVACY_CLICK_ON_COPY";
export const PLATFORM_ONBOARDING_COMPLETION_MODAL_CLICK_ON_NEXT =
	"PLATFORM_ONBOARDING_COMPLETION_MODAL_CLICK_ON_NEXT";

export const PLATFORM_PRIVACY_UPDATE_TOS_MODAL_VIEW =
	"PLATFORM_PRIVACY_UPDATE_TOS_MODAL_VIEW";
export const PLATFORM_PRIVACY_UPDATE_ACCEPT_CLICK =
	"PLATFORM_PRIVACY_UPDATE_ACCEPT_CLICK";
export const PLATFORM_PRIVACY_UPDATE_TOS_LINK_CLICK =
	"PLATFORM_PRIVACY_UPDATE_TOS_LINK_CLICK";
export const PLATFORM_PRIVACY_UPDATE_PRIVACY_LINK_CLICK =
	"PLATFORM_PRIVACY_UPDATE_PRIVACY_LINK_CLICK";

export const PLATFORM_AUDIENCES_PAYWALL_VIEW =
	"PLATFORM_AUDIENCES_PAYWALL_VIEW";
export const PLATFORM_AUDIENCES_PAYWALL_SELECT_SPEND =
	"PLATFORM_AUDIENCES_PAYWALL_SELECT_SPEND";
export const PLATFORM_AUDIENCES_PAYWALL_NEXT =
	"PLATFORM_AUDIENCES_PAYWALL_NEXT";
export const PLATFORM_AUDIENCES_PAYWALL_BACK =
	"PLATFORM_AUDIENCES_PAYWALL_BACK";
export const PLATFORM_AUDIENCES_PAYWALL_COMPLETE =
	"PLATFORM_AUDIENCES_PAYWALL_COMPLETE";
export const PLATFORM_AUDIENCES_PAYWALL_SCHEDULE_CALL_CLICK =
	"PLATFORM_AUDIENCES_PAYWALL_SCHEDULE_CALL_CLICK";

export const PLATFORM_AUDIENCES_VIEW_OVERVIEW =
	"PLATFORM_AUDIENCES_VIEW_OVERVIEW";
export const PLATFORM_AUDIENCES_VIEW_HISTORY =
	"PLATFORM_AUDIENCES_VIEW_HISTORY";
export const PLATFORM_AUDIENCES_VIEW_WORKS = "PLATFORM_AUDIENCES_VIEW_WORKS";

export const PLATFORM_AUDIENCES_PREVIEW_SCHEDULE_CALL =
	"PLATFORM_AUDIENCES_PREVIEW_SCHEDULE_CALL";

export const PLATFORM_AUDIENCES_UNLOCK_BETA_INTEGRATION =
	"PLATFORM_AUDIENCES_UNLOCK_BETA_INTEGRATION";

export const PLATFORM_AUDIENCES_OPEN_INTEGRATION =
	"PLATFORM_AUDIENCES_OPEN_INTEGRATION";

export const PLATFORM_AUDIENCES_CLOSE_INTEGRATION =
	"PLATFORM_AUDIENCES_CLOSE_INTEGRATION";

export const PLATFORM_AUDIENCES_CONNECT_INTEGRATION_ACCOUNT =
	"PLATFORM_AUDIENCES_CONNECT_INTEGRATION_ACCOUNT";
export const PLATFORM_AUDIENCES_CONNECT_INTEGRATION_ACCOUNT_SUCCESS =
	"PLATFORM_AUDIENCES_CONNECT_INTEGRATION_ACCOUNT_SUCCESS";
export const PLATFORM_AUDIENCES_SELECT_AD_ACCOUNT =
	"PLATFORM_AUDIENCES_SELECT_AD_ACCOUNT";
export const PLATFORM_AUDIENCES_CONFIGURE_AD_ACCOUNT =
	"PLATFORM_AUDIENCES_CONFIGURE_AD_ACCOUNT";
export const PLATFORM_AUDIENCES_CONNECT_INTEGRATION =
	"PLATFORM_AUDIENCES_CONNECT_INTEGRATION";
export const PLATFORM_AUDIENCES_CONNECT_INTEGRATION_SUCCESS =
	"PLATFORM_AUDIENCES_CONNECT_INTEGRATION_SUCCESS";

export const PLATFORM_AUDIENCES_SELECT_REQUEST_SIZE =
	"PLATFORM_AUDIENCES_SELECT_REQUEST_SIZE";
export const PLATFORM_AUDIENCES_REQUEST = "PLATFORM_AUDIENCES_REQUEST";

export const PLATFORM_ONBOARDING_PAGE_VIEW_CREATE_ACCOUNT =
	"PLATFORM_ONBOARDING_PAGE_VIEW_CREATE_ACCOUNT";
export const PLATFORM_ONBOARDING_PAGE_VIEW_PLATFORM =
	"PLATFORM_ONBOARDING_PAGE_VIEW_PLATFORM";
export const PLATFORM_ONBOARDING_PAGE_VIEW_INSTALL_SUCCESS =
	"PLATFORM_ONBOARDING_PAGE_VIEW_INSTALL_SUCCESS";
export const PLATFORM_ONBOARDING_PAGE_VIEW_OTHER_PLATFORM_INSTALL =
	"PLATFORM_ONBOARDING_PAGE_VIEW_OTHER_PLATFORM_INSTALL";
export const PLATFORM_ONBOARDING_PAGE_VIEW_PROFILE =
	"PLATFORM_ONBOARDING_PAGE_VIEW_PROFILE";
export const PLATFORM_ONBOARDING_PAGE_VIEW_CATEGORIES =
	"PLATFORM_ONBOARDING_PAGE_VIEW_CATEGORIES";
export const PLATFORM_ONBOARDING_PAGE_VIEW_SUCCESS =
	"PLATFORM_ONBOARDING_PAGE_VIEW_SUCCESS";

export const PLATFORM_ONBOARDING_CLICK_ANY_ACCOUNT_FIELD =
	"PLATFORM_ONBOARDING_CLICK_ANY_ACCOUNT_FIELD";
export const PLATFORM_ONBOARDING_CLICK_CREATE_ACCOUNT =
	"PLATFORM_ONBOARDING_CLICK_CREATE_ACCOUNT";
export const PLATFORM_ONBOARDING_CLICK_ANY_PLATFORM =
	"PLATFORM_ONBOARDING_CLICK_ANY_PLATFORM";

export const PLATFORM_ONBOARDING_CLICK_PLATFORM_SAVE_LOG_OUT =
	"PLATFORM_ONBOARDING_CLICK_PLATFORM_SAVE_LOG_OUT";
export const PLATFORM_ONBOARDING_CLICK_PLATFORM_BACK =
	"PLATFORM_ONBOARDING_CLICK_PLATFORM_BACK";
export const PLATFORM_ONBOARDING_CLICK_PLATFORM_CONTINUE =
	"PLATFORM_ONBOARDING_CLICK_PLATFORM_CONTINUE";

export const PLATFORM_ONBOARDING_CLICK_INSTALL_SAVE_LOG_OUT =
	"PLATFORM_ONBOARDING_CLICK_INSTALL_SAVE_LOG_OUT";
export const PLATFORM_ONBOARDING_CLICK_INSTALL_BACK =
	"PLATFORM_ONBOARDING_CLICK_INSTALL_BACK";
export const PLATFORM_ONBOARDING_CLICK_INSTALL_CONTINUE =
	"PLATFORM_ONBOARDING_CLICK_INSTALL_CONTINUE";

export const PLATFORM_ONBOARDING_CLICK_SAVE_LOG_OUT_PROFILE =
	"PLATFORM_ONBOARDING_CLICK_SAVE_LOG_OUT_PROFILE";
export const PLATFORM_ONBOARDING_CLICK_BACK_PROFILE =
	"PLATFORM_ONBOARDING_CLICK_BACK_PROFILE";
export const PLATFORM_ONBOARDING_CLICK_CONTINUE_PROFILE =
	"PLATFORM_ONBOARDING_CLICK_CONTINUE_PROFILE";

export const PLATFORM_ONBOARDING_CLICK_SAVE_LOG_OUT_CATEGORIES =
	"PLATFORM_ONBOARDING_CLICK_SAVE_LOG_OUT_CATEGORIES";
export const PLATFORM_ONBOARDING_CLICK_BACK_CATEGORIES =
	"PLATFORM_ONBOARDING_CLICK_BACK_CATEGORIES";
export const PLATFORM_ONBOARDING_CLICK_CONTINUE_CATEGORIES =
	"PLATFORM_ONBOARDING_CLICK_CONTINUE_CATEGORIES";

export const PLATFORM_ONBOARDING_CLICK_CTA_SUCCESS_SETUP =
	"PLATFORM_ONBOARDING_CLICK_CTA_SUCCESS_SETUP";

export const PLATFORM_ONBOARDING_CLICK_CLEAR_IMPORTED_PROFILE =
	"PLATFORM_ONBOARDING_CLICK_CLEAR_IMPORTED_PROFILE";
export const PLATFORM_ONBOARDING_CLICK_REIMPORT_IMPORTED_PROFILE =
	"PLATFORM_ONBOARDING_CLICK_REIMPORT_IMPORTED_PROFILE";

export const PLATFORM_LEAD_OFFERS_TOGGLE = "PLATFORM_LEAD_OFFERS_TOGGLE";
