import React, { useCallback, useState } from "react";
import {
	Button,
	Card,
	FONT_SIZE,
	SPACING,
	Text,
	useModal,
} from "@disco/disco_core";

import "./lead-offers-inline-view.css";
import LeadOffersEditor from "./LeadOffersEditor";
import LeadOfferTypeSelectorModal, {
	getLeadOfferType,
} from "../modals/LeadOffersTypeSelectorModal";
import usePing, { PING_TYPES } from "../../hooks/usePing";
import LeadOffersInlineViewOffer from "./LeadOffersInlineViewOffer";
import usePreview from "../../hooks/usePreview";

const LeadOffersInlineViewEmpty = ({ onCreateClick, ...rest }) => {
	const handleBtnClick = () => {
		if (typeof onCreateClick !== "function") return;
		onCreateClick();
	};

	return (
		<Card
			vPadding={SPACING.MEDIUM}
			hPadding={SPACING.MEDIUM}
			className="lead-offers-inline-view-empty"
			{...rest}
		>
			<Text size={FONT_SIZE.BODY} color="mid">
				Boost your performance and elevate brand awareness, sales, and
				engagement by setting up offers.
			</Text>
			<Button onClick={handleBtnClick}>Create new offer</Button>
		</Card>
	);
};

const SHOULD_HANDLE_MULTIPLE_OFFERS = false;
const LeadOffersInlineView = ({ offers, setOffers }) => {
	const areOffersEmpty = !Array.isArray(offers) || offers.length === 0;
	const [focusedOffer, setFocusedOffer] = useState(null);
	const [baseOfferType, setBaseOfferType] = useState(null);

	const {
		open: inEditMode,
		handleOpen: openEditMode,
		handleClose: closeEditMode,
	} = useModal(false);

	const {
		open: isTypeSelectorModalOpen,
		handleOpen: openTypeSelectorModal,
		handleClose: closeTypeSelectorModal,
	} = useModal(false);

	const { ping } = usePing({
		key: PING_TYPES.UPDATE_LEAD_OFFER_TYPE,
	});

	const handleOfferEditorClose = () => {
		setFocusedOffer(null);
		setBaseOfferType(null);
		closeEditMode();
	};

	const handleOfferEditorOpen = () => {
		setBaseOfferType(focusedOffer ? getLeadOfferType(focusedOffer) : null);
		openEditMode();
	};

	const handleOfferEditorSave = ({ offer }) => {
		handleOfferEditorClose();
		setOffers((offers) => {
			// First offer
			if (!Array.isArray(offers) || offers.length === 0) return [offer];

			// New Offer
			const isNewOffer =
				offers.findIndex(
					(currOffer) => currOffer.remote_id === offer?.remote_id
				) === -1;
			if (isNewOffer) return [{ ...offer }, ...offers];

			// Updating Offer
			return [...offers].map((prevOffer) =>
				prevOffer?.remote_id === offer?.remote_id
					? { ...prevOffer, ...offer }
					: prevOffer
			);
		});
	};

	const handleTypeSelectorModalDone = (selectedChoice) => {
		closeTypeSelectorModal();
		handleOfferEditorOpen();
		ping(selectedChoice);
	};

	const handleOffersEditorTypeSelector = (offerType) => {
		setBaseOfferType(offerType);
		openTypeSelectorModal();
	};

	const handleInlineOfferEdit = ({ offer }) => {
		setFocusedOffer(offer);
		handleOfferEditorOpen();
	};
	const { triggerRefresh } = usePreview();
	const handleInlineOfferDelete = useCallback(
		({ offer }) => {
			triggerRefresh();
			setOffers((offers) =>
				[...offers].filter(
					(targetOffer) => targetOffer.remote_id !== offer.remote_id
				)
			);
		},
		[setOffers, triggerRefresh]
	);

	return (
		<>
			{inEditMode ? (
				<LeadOffersEditor
					baseOffer={focusedOffer}
					onOfferTypeEdit={handleOffersEditorTypeSelector}
					onClose={handleOfferEditorClose}
					onSave={handleOfferEditorSave}
				/>
			) : areOffersEmpty ? (
				<LeadOffersInlineViewEmpty
					onCreateClick={openTypeSelectorModal}
				/>
			) : (
				<>
					{offers.map((offer, idx) => (
						<LeadOffersInlineViewOffer
							offer={offer}
							onEdit={handleInlineOfferEdit}
							onDelete={handleInlineOfferDelete}
							key={offer.remote_id}
							marginTop={idx > 0 ? SPACING.REGULAR : 0}
						/>
					))}
					{/* TODO: Add support for preventing creation when delete for existing offer is loading (only if SHOULD_HANDLE_MULTIPLE_OFFERS = true) */}
					{SHOULD_HANDLE_MULTIPLE_OFFERS && (
						<LeadOffersInlineViewEmpty
							onCreateClick={openTypeSelectorModal}
							marginTop={SPACING.REGULAR}
						/>
					)}
				</>
			)}

			<LeadOfferTypeSelectorModal
				open={isTypeSelectorModalOpen}
				onClose={closeTypeSelectorModal}
				baseType={baseOfferType}
				onDone={handleTypeSelectorModalDone}
			/>
		</>
	);
};
export default LeadOffersInlineView;
