import React, {
	createRef,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import useModal from "../../hooks/useModal";
import AudiencesPlatformConnect from "../AudiencesPlatformConnect";
import { AccordionBanner, SteppedAccordion } from "../CoreUI";

import {
	BaseTooltip,
	Button,
	FONT_COLOR,
	FONT_SIZE,
	GuidedTooltip,
	Loader,
	SPACING,
	Tag,
	Text,
} from "@disco/disco_core";
import { AnimatePresence } from "framer-motion";
import { FiLock } from "react-icons/fi";
import { IoIosInformationCircleOutline } from "react-icons/io";
import {
	AUDIENCES_PROVIDERS,
	AUDIENCES_PROVIDER_DATA,
	AUDIENCES_SIZES,
	BRAND_INFO_URL,
	META_PRIVACY_URL,
	REQUEST_LOOKALIKE_AUDIENCE_URL,
	SETUP_AUDIENCES_ACCOUNT_URL,
	TIKTOK_AUDIENCES_SIZES,
} from "../../conf";
import {
	PLATFORM_AUDIENCES_CLOSE_INTEGRATION,
	PLATFORM_AUDIENCES_CONNECT_INTEGRATION,
	PLATFORM_AUDIENCES_OPEN_INTEGRATION,
	PLATFORM_AUDIENCES_REQUEST,
	PLATFORM_AUDIENCES_UNLOCK_BETA_INTEGRATION,
} from "../../events";
import useIdentity from "../../hooks/useIdentity";
import usePing, { PING_TYPES } from "../../hooks/usePing";
import useResource from "../../hooks/useResource";
import useTrack from "../../hooks/useTrack";
import { GET } from "../../utils/GET";
import anim from "../../utils/anim";
import AudiencesAdAccount from "../AudiencesAdAccount";
import AudiencesCategory, {
	AudiencesCategoryHeading,
} from "../AudiencesCategory";
import AudiencesName from "../AudiencesName";
import AudiencesSizes from "../AudiencesSizes";
import InlineToast from "../InlineToast";
import "./audiences-integration.css";

const SUCCESS_TIMEOUT = 2500;

const tooltipData = {
	account: {
		heading: "We'll send the audience to this ad account.",
		text: "Please select the ad account that you want Disco to send the audience to.",
	},
	name: {
		heading: "Create a custom title for your audience.",
		text: "In order to manage all your audiences. Please create a custom title or use the default Disco provided.",
	},
};

const tooltipRelProps = { x: 0, y: -35 };

const BetaDisabler = memo(({ betaFlag, provider }) => {
	const [{ loading, error, data }, save, reset] = useResource(
		{
			url: BRAND_INFO_URL,
			method: "PUT",
			data: { [betaFlag]: true },
		},
		false
	);

	const track = useTrack();

	const { setUser } = useIdentity();

	useEffect(() => {
		if (!data) return;
		setUser((user) => ({
			...user,
			publisher: { ...user.publisher, ...data.brand },
		}));
		track(PLATFORM_AUDIENCES_UNLOCK_BETA_INTEGRATION, {
			integration: AUDIENCES_PROVIDER_DATA[provider].name,
		});
		reset();
	}, [data, reset, setUser, track, provider]);

	const { handleOpen, handleClose, open } = useModal(false);

	return (
		<>
			<Text
				Element="section"
				size={FONT_SIZE.LG_BODY}
				color={FONT_COLOR.WHITE}
				thick
				className="audiences-integration-beta-disabler"
				onClick={(e) => e.stopPropagation()}
				onMouseEnter={handleOpen}
				onMouseLeave={handleClose}
			>
				Want to unlock this
				<Tag marginLeft={SPACING.TINY} marginRight={SPACING.TINY}>
					Beta
				</Tag>
				platform?
				{loading ? (
					<Loader marginLeft={SPACING.REGULAR} />
				) : (
					<Button
						rounded
						marginLeft={SPACING.REGULAR}
						thin
						size={FONT_SIZE.BODY}
						onClick={save}
					>
						Yes, I want it.
					</Button>
				)}
				<AnimatePresence>
					{open && (
						<BaseTooltip
							type="dark"
							variants={anim.simpleFade}
							initial="initial"
							animate="animate"
							exit="exit"
							className="audiences-integration-beta-disabler-tooltip"
						>
							<h3>Beta Feature</h3>
							<p>
								We're excited to let you know that you have been
								added to the Snapchat beta cohort to test out a
								new platform integration.
							</p>
						</BaseTooltip>
					)}
				</AnimatePresence>
			</Text>
		</>
	);
});

const lockedRequestButton = (
	<>
		{" "}
		<span>
			<FiLock />
		</span>{" "}
		Request Audience
	</>
);

export const AudiencesIntegration = memo(
	({ permissionText, permissions, provider, betaFlag, ...rest }) => {
		const openProvider = GET("provider");
		const { open, handleClose, handleOpen } = useModal(
			openProvider === provider + ""
		);

		const { open: categoryOpen, handleToggle: toggleCategory } =
			useModal(false);

		const track = useTrack();

		const { user, setUser } = useIdentity();
		const { name, connectionKey, accountKey, icon, testIdKey } =
			AUDIENCES_PROVIDER_DATA[provider];

		const [success, setSuccess] = useState(false);

		const inBeta = !!(betaFlag && !user.publisher[betaFlag]);
		console.log("provider, betaFlag :>> ", provider, betaFlag, inBeta);

		const connected = user.publisher[connectionKey];
		const accountConnected = !!user[accountKey];

		const isSetUp = connected && accountConnected;

		const [account, setAccount] = useState(user[accountKey] || "");
		const [size, setSize] = useState(-1);
		const [reqName, setReqName] = useState("");
		const [targetCategory, setTargetCategory] = useState(null);

		const { ping: refreshHistory } = usePing({
			key: PING_TYPES.REFRESH_AUDIENCES_HISTORY,
		});

		const highlighted = useRef(null);
		const refs = useMemo(
			() => ({
				account: createRef(),
				name: createRef(),
			}),
			[]
		);

		const [tooltip, setTooltip] = useState({});

		const handleFocus = useCallback(
			({ target: { name } }) => {
				setTooltip(tooltipData[name]);
				highlighted.current = refs[name].current;
			},
			[refs]
		);

		const handleBlur = useCallback(() => {
			setTooltip({});
			highlighted.current = null;
		}, []);

		const [
			{ loading: setupLoading, error: setupError, data: setupData },
			saveSetup,
			resetSetup,
		] = useResource(
			{
				url: SETUP_AUDIENCES_ACCOUNT_URL,
				method: "PUT",
				data: { provider_id: provider, account_id: account },
			},
			false
		);

		const [
			{ loading: reqLoading, error: reqError, data: reqData },
			saveReq,
			resetReq,
		] = useResource(
			{
				url: REQUEST_LOOKALIKE_AUDIENCE_URL,
				method: "POST",
				data: {
					provider,
					audience_size_group: (provider ===
					AUDIENCES_PROVIDERS.TIKTOK
						? TIKTOK_AUDIENCES_SIZES
						: AUDIENCES_SIZES)[size]?.sizeGroup,
					audience_name: reqName,
					account_id: account,
					target_shopper_category_id: targetCategory,
				},
			},
			false
		);

		useEffect(() => {
			if (!setupData) return;
			resetSetup();

			setSuccess(
				`You have successfully connected ${name} to Disco Audiences.`
			);

			track(PLATFORM_AUDIENCES_CONNECT_INTEGRATION, {
				integration: name,
			});

			const accountCopy = account;
			setTimeout(() => {
				setUser((user) => ({ ...user, [accountKey]: accountCopy }));
				setSuccess(false);
				handleClose();
			}, SUCCESS_TIMEOUT);
		}, [
			setupData,
			resetSetup,
			setUser,
			account,
			accountKey,
			handleClose,
			name,
			track,
		]);

		useEffect(() => {
			if (!reqData) return;
			resetReq();

			setSuccess(
				`Your audience request has been successfully initiated. We will notify you when your audience is ready.`
			);
			track(PLATFORM_AUDIENCES_REQUEST, {
				integration: name,
				audience_name: reqName,
				target_shopper_category_id: targetCategory,
			});

			refreshHistory();
			setTimeout(() => {
				setSuccess(false);
				handleClose();
			}, SUCCESS_TIMEOUT);
		}, [
			reqData,
			resetReq,
			setUser,
			account,
			accountKey,
			handleClose,
			name,
			refreshHistory,
			track,
			reqName,
			targetCategory,
		]);

		const connectSteps = useMemo(
			() => [
				{
					heading: `Connect ${name} to Disco`,
					content: (
						<AudiencesPlatformConnect
							permissionText={permissionText}
							permissions={permissions}
							provider={provider}
						/>
					),
				},
				{
					heading: `Confirm Your ${name} Ad Account`,
					content: (
						<AudiencesAdAccount
							account={account}
							setAccount={setAccount}
							provider={provider}
							disabled={success}
						/>
					),
					loading: setupLoading,
					buttonText: "Connect Account",
					buttonAction: saveSetup,
					buttonProps: {
						disabled: !connected || !account || success,
					},
					error: setupError,
				},
			],
			[
				name,
				permissionText,
				permissions,
				provider,
				account,
				setAccount,
				connected,
				setupLoading,
				saveSetup,
				setupError,
				success,
			]
		);

		const requestSteps = useMemo(
			() => [
				{
					heading: `Confirm Your ${name} Ad Account`,
					content: (
						<AudiencesAdAccount
							account={account}
							setAccount={setAccount}
							provider={provider}
							name="account"
							onFocus={handleFocus}
							onBlur={handleBlur}
							ref={refs["account"]}
						/>
					),
				},
				{
					heading: `Name Your Audience`,
					content: (
						<AudiencesName
							reqName={reqName}
							setReqName={setReqName}
							provider={provider}
							size={size}
							setSize={setSize}
							name="name"
							onFocus={handleFocus}
							onBlur={handleBlur}
							ref={refs["name"]}
						/>
					),
				},
				{
					heading: `Request Your Audience Size`,
					content: (
						<AudiencesSizes
							account={account}
							setAccount={setAccount}
							provider={provider}
							size={size}
							setSize={setSize}
						/>
					),
				},
				{
					hideLine: true,
					heading: (
						<AudiencesCategoryHeading
							open={categoryOpen}
							onToggle={toggleCategory}
						/>
					),
					content: (
						<AudiencesCategory
							open={categoryOpen}
							selected={targetCategory}
							setSelected={setTargetCategory}
						/>
					),
					loading: reqLoading,
					error: reqError,
					buttonText: "Request Audience",
					buttonAction: saveReq,
					buttonProps: {
						disabled:
							size < 0 || !reqName.trim() || !account || success,
					},
				},
			],
			[
				name,
				provider,
				account,
				setAccount,
				size,
				setSize,
				reqName,
				setReqName,
				saveReq,
				success,
				reqLoading,
				reqError,
				handleBlur,
				handleFocus,
				refs,
				categoryOpen,
				toggleCategory,
				targetCategory,
				setTargetCategory,
			]
		);

		const disabler = useMemo(() => {
			return <BetaDisabler provider={provider} betaFlag={betaFlag} />;
		}, [betaFlag, provider]);

		const buttonControlRef = useRef(null);

		const buttonControlProps = useMemo(() => {
			return {
				onMouseEnter: () => {
					if (!isSetUp || user.publisher.audience_list_eligibility)
						return;
					setTooltip({
						heading: "Not available in preview mode",
						text: `Once you connect with your CSM${
							user.publisher.csm?.name
								? `, ${user.publisher.csm?.name}`
								: ""
						}, you will be able to request an audience.`,
					});
					highlighted.current = buttonControlRef.current;
				},
				onMouseLeave: () => {
					if (!isSetUp || user.publisher.audience_list_eligibility)
						return;

					handleBlur();
				},
				"data-testid": `audiences-integration-${testIdKey}-btn`,
			};
		}, [
			handleBlur,
			user.publisher.csm,
			isSetUp,
			user.publisher.audience_list_eligibility,
			testIdKey,
		]);

		const eventProperties = useMemo(() => {
			return { integration: name };
		}, [name]);

		const onOpen = useCallback(() => {
			track(PLATFORM_AUDIENCES_OPEN_INTEGRATION, eventProperties);
			handleOpen();
		}, [eventProperties, handleOpen, track]);

		const onClose = useCallback(() => {
			track(PLATFORM_AUDIENCES_CLOSE_INTEGRATION, eventProperties);
			handleClose();
		}, [eventProperties, handleClose, track]);

		return (
			<>
				<SteppedAccordion
					open={open}
					onOpen={onOpen}
					onClose={onClose}
					heading={isSetUp ? name : `Add ${name}`}
					closeText={
						isSetUp
							? user.publisher.audience_list_eligibility
								? "Request Audience"
								: lockedRequestButton
							: `Connect ${name}`
					}
					openText={"Close"}
					dashedBorder={!isSetUp}
					className="audiences-integration"
					bodyHeading={isSetUp ? "Request a new audience" : null}
					steps={isSetUp ? requestSteps : connectSteps}
					icon={icon}
					headerContent={inBeta ? disabler : null}
					disabled={
						isSetUp && !user.publisher.audience_list_eligibility
					}
					buttonControlProps={buttonControlProps}
					buttonControlRef={buttonControlRef}
					data-testid={`audiences-integration-${testIdKey}`}
					designSystem
					{...rest}
				>
					{success && <InlineToast>{success}</InlineToast>}
				</SteppedAccordion>
				<GuidedTooltip
					type="dark"
					highlighted={highlighted}
					active={!!tooltip.heading}
					relativeProps={tooltipRelProps}
					vPadding={SPACING.MEDIUM}
					hPadding={SPACING.REGULAR}
				>
					<Text thick>{tooltip.heading}</Text>
					<Text thin marginTop={SPACING.MICRO} size={FONT_SIZE.BODY}>
						{tooltip.text}
					</Text>
				</GuidedTooltip>
			</>
		);
	}
);

const metaPermissionText = (
	<>
		By connecting Meta you agree to the terms and conditions{" "}
		<a href={META_PRIVACY_URL} target="_blank" rel="noopener noreferrer">
			here
		</a>
		. By connecting to Meta you will be providing Disco the following
		permissions.
	</>
);
const metaPermissions = [
	"ads_management",
	"pages_show_list",
	"pages_read_engagement",
	"business_management",
];

const MetaIssueBanner = memo(() => {
	return (
		<AccordionBanner className="meta-issue-banner">
			<IoIosInformationCircleOutline />
			<span>
				Due to performance issues with Meta's API, any NEW audience
				requests may be smaller than usual until the API is fixed.
				Please reach out to{" "}
				<u>
					<a href="mailto:natalie@disconetwork.com">
						natalie@disconetwork.com
					</a>
				</u>{" "}
				if you have any questions.
			</span>
		</AccordionBanner>
	);
});
export const MetaIntegration = memo(() => {
	return (
		<AudiencesIntegration
			permissionText={metaPermissionText}
			permissions={metaPermissions}
			provider={AUDIENCES_PROVIDERS.META}

			// banner={<MetaIssueBanner />} // Pass banner for maintenance message
		/>
	);
});

const snapPermissionText = (
	<>
		By connecting Snapchat you agree to the terms and conditions{" "}
		<a href={META_PRIVACY_URL} target="_blank" rel="noopener noreferrer">
			here
		</a>
		.
	</>
);

export const SnapIntegration = memo(() => {
	return (
		<AudiencesIntegration
			permissionText={snapPermissionText}
			permissions={null}
			provider={AUDIENCES_PROVIDERS.SNAP}
			betaFlag="snapchat_unlocked"
		/>
	);
});

const tiktokPermissionText = (
	<>
		By connecting TikTok you agree to the terms and conditions{" "}
		<a href={META_PRIVACY_URL} target="_blank" rel="noopener noreferrer">
			here
		</a>
		.
	</>
);

export const TiktokIntegration = memo(() => {
	return (
		<AudiencesIntegration
			permissionText={tiktokPermissionText}
			permissions={null}
			provider={AUDIENCES_PROVIDERS.TIKTOK}
		/>
	);
});
