import React, { memo, useCallback } from "react";
import { BorderedCard } from "../CoreUI";
import { PaddedContainer } from "@disco/disco_core";
import logo from "../../img/logo-light.svg";
import "./central-view.css";
import { motion } from "framer-motion";
import anim from "../../utils/anim";
import useTrack from "../../hooks/useTrack";
import { PLATFORM_ONBOARDING_TOGGLE } from "../../events";
import useDimensions from "../../hooks/useDimensions";

export const REGISTER_TAB = 0;
export const LOGIN_TAB = 1;

const CentralView = memo(
	({
		className = "",
		background,
		showMobileLogo,
		mobileHeading,
		...rest
	}) => {
		const { isMobile } = useDimensions();

		return (
			<PaddedContainer className={`central-view ${className}`}>
				{isMobile && (
					<motion.main
						className="central-view-mobile-hero"
						variants={anim.centralHero}
						initial="initial"
						animate="animate"
						exit="exit"
					>
						{showMobileLogo && (
							<img
								src={logo}
								alt=""
								className="central-view-logo"
							/>
						)}
						{mobileHeading && (
							<h3 className="central-view-heading">
								{mobileHeading}
							</h3>
						)}
					</motion.main>
				)}

				{!isMobile && (
					<motion.img
						src={background}
						alt=""
						className="central-view-background"
						variants={anim.centralBackground}
						initial="initial"
						animate="animate"
						exit="exit"
					/>
				)}
				<BorderedCard
					centered
					{...rest}
					className={"central-view-hero"}
				/>
			</PaddedContainer>
		);
	}
);

export default CentralView;
