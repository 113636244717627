import React from "react";
import "./modal-sheet-header.css";

const ModalSheetHeader = ({
	label,
	cancelLabel,
	icon,
	handleCancel,
	className,
	cancelClassName,
}) => {
	return (
		<div className={`modal-sheet-header ${className}`}>
			{icon && <span className="modal-sheet-header-icon">{icon}</span>}
			<h3>{label}</h3>
			<div
				className={`modal-sheet-header-cancel ${cancelClassName}`}
				onClick={handleCancel}
			>
				{cancelLabel}
			</div>
		</div>
	);
};

export default React.memo(ModalSheetHeader);
