import { motion } from "framer-motion";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import useResource from "../../hooks/useResource";
import { ProductBadge, Table } from "../CoreUI";

import {
	FETCH_CONFLICTING_VARIANTS_URL,
	SAVE_CONFLICTING_VARIANTS_URL,
} from "../../conf";
import SteppedModal from "../modals/SteppedModal";

import anim from "../../utils/anim";
import { VariantsTable } from "./VariantsModal";
import "./variants-match-modal.css";

const VariantsMatchModal = memo(({ open, onDone, user, ...rest }) => {
	// const [typeSelector, setTypeSelector] = useState(initial === null);

	const [formError, setFormError] = useState("");
	const [activeStep, setActiveStep] = useState(0);
	const [payload, setPayload] = useState([]);
	const [matches, setMatches] = useState({});

	const handleMatch = useCallback((productId, variantId) => {
		setMatches((matches) => ({
			...matches,
			[productId]: variantId,
		}));
		setFormError(false);
	}, []);

	const [{ loading, data: products, error }, fetch, reset] = useResource(
		{ url: FETCH_CONFLICTING_VARIANTS_URL, method: "GET" },
		false
	);

	const [
		{ loading: saveLoading, data: saveData, error: saveError },
		save,
		saveReset,
	] = useResource(
		{
			url: SAVE_CONFLICTING_VARIANTS_URL,
			method: "PUT",
			data: payload,
		},
		false
	);

	useEffect(() => {
		if (!saveData) {
			return;
		}
		onDone();
		saveReset();
	}, [saveData, onDone, saveReset]);

	const handleSave = useCallback(() => {
		setPayload(
			Object.entries(matches).map(([productId, variantId]) => ({
				merge_variant_id: variantId,
				product_id: productId,
			}))
		);
		save();
	}, [save, matches]);

	const finalComponent = useMemo(() => {
		const retFinalComponent = {
			onDone: handleSave,
			error,
			nextBtnText: "Save product matching",
			modalProps: {
				heading: "Review product matches",
			},
			renderChildren: () => {
				if (!Array.isArray(products)) {
					return null;
				}

				return (
					<Table
						columns={[
							{
								text: "Disco Product",
								dataField: "selfProduct",
								formatter: (selfProduct) => {
									return (
										<ProductBadge
											image={selfProduct.photo_url}
											name={selfProduct.name}
										/>
									);
								},
								sortValue(selfProduct) {
									return selfProduct.name;
								},
							},
							{
								text: "Mapped Shopify Product",
								dataField: "variant",
								formatter: (variant) => {
									return (
										<ProductBadge
											image={variant?.photo_url}
											name={variant?.title}
										/>
									);
								},
								sortValue(variant) {
									return variant.title;
								},
							},
						]}
						keyField="remote_id"
						data={products.map((product) => {
							return {
								selfProduct: product,
								variant: product.merge_candidate.variants.find(
									(variant) =>
										variant.remote_id ===
										matches[product.remote_id]
								),
							};
						})}
					/>
				);
			},
		};

		return retFinalComponent;
	}, [error, handleSave, products, matches]);

	useEffect(() => {
		if (!open || loading || Array.isArray(products)) {
			return;
		}
		fetch();
	}, [products, loading, fetch, open]);

	const stepData = useMemo(() => {
		if (!Array.isArray(products)) {
			return [];
		}

		return [
			{
				active: false,
				name: "Start",
				modalProps: {
					heading: `Map Disco products to a shopify variant`,
				},
				validateData: () => true,

				renderChildren: () => (
					<motion.section
						variants={anim.pageContent}
						className="variants-match-variants"
						initial="initial"
						animate="animate"
						exit="exit"
					>
						<h1 className="variants-match-modal-intro-head">
							You've mapped multiple Disco products to one Shopify
							Product
						</h1>
						<p className="variants-match-modal-intro-text">
							Let's match up these products with their Shopify
							Variants
						</p>
					</motion.section>
				),
			},
			...products.map((product, idx) => ({
				active: false,
				name: product.name,
				modalProps: {
					heading: `Map ${product.name} to a shopify variant`,
				},
				validateData() {
					if (!matches[product.remote_id]) {
						setFormError(
							`Please select a shopify variant to map to ${product.name}`
						);
						return false;
					}
					return true;
				},
				formError,
				renderChildren: () => (
					<motion.section
						variants={anim.pageContent}
						className="variants-match-variants"
						initial="initial"
						animate="animate"
						exit="exit"
					>
						<VariantsTable
							product={product.merge_candidate}
							selected={matches[product.remote_id]}
							user={user}
							showOne
							onSelect={(varianId) => {
								handleMatch(product.remote_id, varianId);
							}}
						/>
						{/* {product.merge_candidate.variants.map((variant) => (
						<ProductBadge
							name={`${product.name} (${variant.title})`}
							image={variant.photo_url}
							active={
								matches[product.remote_id] === variant.remote_id
							}
							onClick={() =>
								handleMatch(
									product.remote_id,
									variant.remote_id
								)
							}
						/>
					))} */}
					</motion.section>
				),
			})),
		];
	}, [products, matches, handleMatch, formError, user]);

	const clearError = useCallback(() => {
		setFormError(false);
	}, []);

	return (
		<SteppedModal
			open={open}
			stepData={stepData}
			activeStep={activeStep}
			onNavigation={clearError}
			setActiveStep={setActiveStep}
			heading={"Map Disco products to Shopify Variants"}
			finalComponent={finalComponent}
			className="variants-match-modal"
			loading={saveLoading}
			dataLoading={loading}
			{...rest}
		></SteppedModal>
	);
});

export default VariantsMatchModal;
