import React, { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Button, Loader, Page } from "../components/CoreUI";
import { SHOPIFY_FINALIZE_URL } from "../conf";
import useResource from "../hooks/useResource";
import { GETAll } from "../utils/GET";
import parseError from "../utils/parseError";
import { logoutSegment } from "../utils/segment";
import tokenStorage from "../utils/tokenStorage";
const payload = GETAll();

const ShopifyConnectPage = React.memo(({ user = null, setUser = null }) => {
	const [{ loading, data, error }, load] = useResource(
		{
			url: SHOPIFY_FINALIZE_URL,
			method: "POST",
			data: payload,
		},
		false
	);

	const [done, setDone] = useState(false);
	const [started, setStarted] = useState(false);
	const navigate = useNavigate();

	// code, hmac, shop, host

	const handleLogout = useCallback(() => {
		tokenStorage.clear();
		logoutSegment();
		navigate({
			pathname: "/register",
			search: `?${new URLSearchParams(payload).toString()}`,
		});
	}, [navigate]);

	useEffect(() => {
		if (
			user &&
			!user.shopify_shop &&
			payload.shop &&
			!error &&
			!data &&
			!started
		) {
			setStarted(true);
			load();
		} else if (
			user &&
			user.shopify_shop &&
			payload.shop &&
			payload.shop.indexOf(user.shopify_shop) === -1
		) {
			handleLogout();
		} else if (!user) {
			navigate({
				pathname: "/register",
				search: `?${new URLSearchParams(payload).toString()}`,
			});
			setStarted(true);
		}
	}, [load, error, data, started, user, navigate, handleLogout]);

	useEffect(() => {
		if (data) {
			setUser((user) => ({ ...user, shopify_shop: data.shop }));
			setDone(true);
		}
	}, [data, setUser]);

	if (!user) {
		return <Navigate to="/" />;
	}

	if (user.shopify_shop || !payload.shop) {
		return <Navigate to="/" />;
	}

	if (done) {
		return <Navigate to="/shopify" />;
	}

	return (
		<Page>
			{loading && <Loader center />}
			{error && (
				<div>
					Whoops! {parseError(error)}{" "}
					<Button onClick={load}>Try again</Button>
				</div>
			)}
		</Page>
	);
});

export default ShopifyConnectPage;
