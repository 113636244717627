import React, {
	CSSProperties,
	forwardRef,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { AnimatePresence } from "framer-motion";
import genPackageClassName from "../../utils/genPackageClassName";
import useModal from "../../hooks/useModal";
import "./guided-tooltip.css";
import { BaseTooltip, BaseTooltipProps } from "../BaseTooltip";
import { isFunction, mergeRefs } from "../../utils";
import {
	GenericRef,
	GenericRefObject,
	Extend,
	ObjectValuesAsTypes,
} from "../../shared.types";
export const GUIDED_TOOLTIP_POSITION = {
	LEFT: "LEFT",
	RIGHT: "RIGHT",
} as const;
type animationPositionType = {
	top: number;
	left: number;
};
type AnimationProps = {
	opacity?: number;
	transition?: {
		type: string;
		stiffness: number;
		mass: number;
		damping: number;
	};
	left: number;
	top: number;
};
export type TooltipPosition = ObjectValuesAsTypes<
	typeof GUIDED_TOOLTIP_POSITION
>;
export type restingPositionModifierArgType = {
	position: animationPositionType;
	tooltipRef: GenericRefObject;
	target?: GenericRefObject;
};
export type restingPositionModifierType = (
	arg: restingPositionModifierArgType
) => animationPositionType;
export type animationPositionModifierArgType = {
	position: AnimationProps;
	tooltipRef: GenericRefObject;
	target?: GenericRefObject;
};
export type animationPositionModifierType = (
	arg: animationPositionModifierArgType
) => AnimationProps;
export type getRestingAnimationPositionArgType = {
	restingPositionModifier?: restingPositionModifierType;
	tooltipRef: GenericRefObject;
	target?: GenericRefObject;
	position?: TooltipPosition;
	exit?: boolean;
};
export type getRestingAnimationPositionType = (
	arg: getRestingAnimationPositionArgType
) => CSSProperties;
const getRestingAnimationPosition: getRestingAnimationPositionType = ({
	restingPositionModifier,
	tooltipRef,
	target,
	position,
	exit = false,
}) => {
	if (!target?.current) {
		// tooltipRef.current is undefined at this point
		return {
			top: exit ? "100%" : "50%",
			left: "50%",
			opacity: 0,
		};
	}
	const {
		y: top,
		x: left,
		width: targetWidth,
	} = target.current.getBoundingClientRect();
	let initialPosition = { top, left };
	const { width: tooltipWidth } =
		tooltipRef.current?.getBoundingClientRect?.() || {
			height: 0,
			width: 0,
		};
	if (position === GUIDED_TOOLTIP_POSITION.LEFT) {
		initialPosition = {
			...initialPosition,
			left: initialPosition.left - tooltipWidth,
		};
	}
	if (position === GUIDED_TOOLTIP_POSITION.RIGHT) {
		initialPosition = {
			...initialPosition,
			left: initialPosition.left + targetWidth,
		};
	}
	if (restingPositionModifier && isFunction(restingPositionModifier)) {
		initialPosition = restingPositionModifier({
			position: initialPosition,
			tooltipRef,
			target,
		});
	}
	return { ...initialPosition, opacity: 0 };
};
export type getAnimationPropsArgType = {
	animatePositionModifier?:
		| animationPositionModifierType
		| animationPositionType;
	isOpen?: boolean;
	tooltipRef: GenericRefObject;
	target?: GenericRefObject;
	position?: TooltipPosition;
	preventOverflowTop?: boolean;
	preventOverflowRight?: boolean;
};
export type getAnimationPropsType = (
	arg: getAnimationPropsArgType
) => AnimationProps;
const getAnimationProps: getAnimationPropsType = ({
	animatePositionModifier,
	tooltipRef,
	target,
	position,
	preventOverflowTop = false,
	preventOverflowRight = false,
}) => {
	let animationProps: AnimationProps = { opacity: 1, left: 0, top: 0 };
	if (!target) return animationProps;
	animationProps = {
		opacity: 1,
		transition: {
			type: "spring",
			stiffness: 2000,
			mass: 4,
			damping: 140,
		},
		left: 0,
		top: 0,
	};
	if (!target.current) {
		if (typeof animatePositionModifier === "object") {
			return { ...animationProps, ...animatePositionModifier };
		}
		return animationProps;
	}
	const { height: tooltipHeight, width: tooltipWidth } =
		tooltipRef.current?.getBoundingClientRect?.() || {
			height: 0,
			width: 0,
		};
	const {
		x,
		y,
		width: targetWidth,
	} = target.current?.getBoundingClientRect?.() || {
		x: 0,
		y: 0,
	};
	let left = x;
	if (position === GUIDED_TOOLTIP_POSITION.LEFT) {
		left = left - tooltipWidth;
	}
	if (position === GUIDED_TOOLTIP_POSITION.RIGHT) {
		left = left + targetWidth;
	}
	const top = y;
	animationProps = { ...animationProps, left, top };
	if (
		animatePositionModifier &&
		typeof animatePositionModifier === "function"
	) {
		animationProps = animatePositionModifier({
			position: animationProps,
			tooltipRef,
			target,
		});
	}
	if (preventOverflowTop) {
		const { top } = animationProps;
		const hasOverflow = top + tooltipHeight > window.innerHeight;
		if (hasOverflow) {
			animationProps = {
				...animationProps,
				top: window.innerHeight - tooltipHeight,
			};
		}
	}
	if (preventOverflowRight) {
		const { left } = animationProps;
		const hasOverflow = left + tooltipWidth > window.innerWidth;
		if (hasOverflow) {
			animationProps = {
				...animationProps,
				left: window.innerWidth - tooltipWidth,
			};
		}
	}
	return animationProps;
};
type NativeGuidedTooltipProps = {
	open?: boolean;
	persistent?: boolean;
	target?: GenericRefObject;
	position?: TooltipPosition;
	restingPositionModifier?: restingPositionModifierType;
	animatePositionModifier?: animationPositionModifierType;
	preventOverflowTop?: boolean;
	preventOverflowRight?: boolean;
};
export type GuidedTooltipProps = Extend<
	BaseTooltipProps,
	NativeGuidedTooltipProps
>;
type GuidedTooltipComponent = (
	props: GuidedTooltipProps
) => React.ReactNode | null;
export const GuidedTooltip: GuidedTooltipComponent = memo(
	forwardRef(
		(
			{
				open = false,
				persistent = false,
				children,
				target,
				className = "",
				position = GUIDED_TOOLTIP_POSITION.RIGHT,
				restingPositionModifier,
				animatePositionModifier,
				preventOverflowTop = false,
				preventOverflowRight = false,
				...rest
			}: GuidedTooltipProps,
			ref?: GenericRef
		) => {
			const [tooltipRefState, setTooltipRefState] = useState(null);
			const {
				open: internalOpen,
				handleOpen: handleMouseEnter,
				handleClose: handleMouseLeave,
			} = useModal(false);
			const tooltipRef = useMemo(
				() => ({ current: tooltipRefState }),
				[tooltipRefState]
			);
			const isOpen = useMemo(
				() => open || (persistent && internalOpen),
				[open, persistent, internalOpen]
			);
			const defaultAnimationFuncArgs = useMemo(
				() => ({ tooltipRef, target, position }),
				[tooltipRef, position, target]
			);
			const animationProps = useMemo(
				() => ({
					initial: getRestingAnimationPosition({
						...defaultAnimationFuncArgs,
						restingPositionModifier,
					}),
					animate: getAnimationProps({
						...defaultAnimationFuncArgs,
						isOpen,
						animatePositionModifier,
						preventOverflowTop,
						preventOverflowRight,
					}),
					exit: getRestingAnimationPosition({
						...defaultAnimationFuncArgs,
						restingPositionModifier,
						exit: true,
					}),
				}),
				[
					restingPositionModifier,
					animatePositionModifier,
					defaultAnimationFuncArgs,
					isOpen,
					preventOverflowTop,
					preventOverflowRight,
				]
			);
			return (
				<AnimatePresence>
					{isOpen && (
						<BaseTooltip
							motionElement
							ref={mergeRefs(ref, setTooltipRefState)}
							className={genPackageClassName({
								base: "guided-tooltip",
								additional: className,
							})}
							onMouseEnter={handleMouseEnter}
							onMouseLeave={handleMouseLeave}
							{...animationProps}
							{...rest}
						>
							{children}
						</BaseTooltip>
					)}
				</AnimatePresence>
			);
		}
	)
);
type NativeOffsetGuidedTooltipProps = {
	initialOffset?: animationPositionType;
	animateOffset?: animationPositionType;
};
export type OffsetGuidedTooltipProps = Extend<
	GuidedTooltipProps,
	NativeOffsetGuidedTooltipProps
>;
type OffsetGuidedTooltipComponent = (
	props: OffsetGuidedTooltipProps
) => React.ReactNode | null;
export const OffsetGuidedTooltip: OffsetGuidedTooltipComponent = memo(
	forwardRef(
		(
			{
				initialOffset,
				animateOffset,
				handleRestingPosition,
				handleAnimatePosition,
				...rest
			}: OffsetGuidedTooltipProps,
			ref?: GenericRef
		) => {
			handleRestingPosition = useCallback(
				({ position }: restingPositionModifierArgType) => {
					const { top = 0, left = 0 } = initialOffset || {};
					return {
						...position,
						top: position.top + top,
						left: position.left + left,
					};
				},
				[initialOffset]
			);
			handleAnimatePosition = useCallback(
				({ position }: animationPositionModifierArgType) => {
					const { top = 0, left = 0 } = animateOffset || {};
					return {
						...position,
						top: position.top + top,
						left: position.left + left,
					};
				},
				[animateOffset]
			);
			return (
				<GuidedTooltip
					ref={ref}
					{...rest}
					restingPositionModifier={handleRestingPosition}
					animatePositionModifier={handleAnimatePosition}
				/>
			);
		}
	)
);
