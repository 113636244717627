import React, { memo, useCallback, useEffect, useState } from "react";
import { SHOPIFY_CONNECT_URL } from "../../conf";
import useIdentity from "../../hooks/useIdentity";
import useModal from "../../hooks/useModal";
import useResource from "../../hooks/useResource";
import parseError from "../../utils/parseError";
import { Button, FormStatus, LabeledInput, Loader } from "../CoreUI";
import Modal from "../modals/Modal";
import ProfileIntegration from "./ProfileIntegration";

import "./shopify-integration.css";

const ShopifyIntegration = memo(({ ...rest }) => {
	const [shop, setShop] = useState("");

	const { user } = useIdentity();

	const handleShopChange = useCallback(
		({ target: { value } }) => setShop(value),
		[]
	);

	const [redirect, setRedirect] = useState(false);
	const [
		{ loading: shopLoading, error: shopError, data: shopData },
		connect,
		shopReset,
	] = useResource(
		{
			url: SHOPIFY_CONNECT_URL(shop),
			method: "GET",
		},
		false
	);

	useEffect(() => {
		if (!shopData) {
			return;
		}
		setRedirect(true);
		window.location.href = shopData.redirect_url;
		shopReset();
	}, [shopData, shopReset]);

	const { open: shopifyOpen, handleClose, handleOpen } = useModal(false);

	return (
		<>
			<ProfileIntegration onClick={handleOpen} {...rest} />
			<Modal
				open={shopifyOpen}
				heading=""
				className="shopify-integration-modal"
				onClose={handleClose}
			>
				<h3>You can use our Shopify App</h3>
				<p>
					Looks like you're a Shopify shop and can use our Shopify
					App. Enter your "myshopify" shop URL to proceed.
				</p>
				<LabeledInput
					value={shop}
					onChange={handleShopChange}
					placeholder="your-shop.myshopify.com"
					label="Your 'myshopify' Shop URL"
				/>
				{!(shopLoading || redirect) && (
					<Button large onClick={connect}>
						Install
					</Button>
				)}
				{(shopLoading || redirect) && <Loader />}
				{shopError && <FormStatus>{parseError(shopError)}</FormStatus>}
			</Modal>
		</>
	);
});

export default ShopifyIntegration;
