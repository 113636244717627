import { motion } from "framer-motion";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DEFAULT_OPTION_IMG, DEFAULT_SURVEY } from "../../conf";
import useResource from "../../hooks/useResource";
import customSurveyIcon from "../../img/custom-survey.png";
import defaultSurveyIcon from "../../img/default-survey.png";
import anim from "../../utils/anim";
import { BaseForm, Button, Input } from "../CoreUI";
import SurveyOptions from "../SurveyOptions";
import "./survey-question-modal.css";

import {
	ADD_SURVEY_QUESTION_URL,
	MIN_SURVEY_OPTION,
	SAVE_SURVEY_QUESTION_URL,
} from "../../conf";
import validateModuleRules from "../../utils/validateModuleRules";
import SurveyConfiguration from "../SurveyConfiguration";
import { SurveyQuestionDisplay } from "../SurveyQuestion";
import SteppedModal from "./SteppedModal";

const smallText = DEFAULT_SURVEY.text.trim().toLowerCase();

const initialQuestionObj = {
	text: "",
	options: [
		{
			text: "",
			active: true,
			icon_url: DEFAULT_OPTION_IMG,
		},
	],
	rules: [],
	apply_and: false,
	apply_rules: false,
	randomize_options: false,
};

const SurveyQuestionModal = React.memo(
	({ open, onClose, user, initial = null, onDone }) => {
		// const [typeSelector, setTypeSelector] = useState(initial === null);

		const [usingDefault, setUsingDefault] = useState(false);
		const [isDefault, setIsDefault] = useState(false);

		const [question, setQuestion] = useState(
			initial === null
				? initialQuestionObj
				: {
						text: initial.question_text,
						...initial,
				  }
		);

		const typeSelector = useMemo(() => initial === null, [initial]);

		const setOptions = useCallback((optionsGetter) => {
			setQuestion((question) => ({
				...question,
				options: optionsGetter(question.options),
			}));
		}, []);

		const handleQuestionChange = useCallback(
			({ target: { value } }) => {
				if (usingDefault) {
					setIsDefault(value.trim().toLowerCase() === smallText);
				}
				setQuestion((question) => ({
					...question,
					text: value,
				}));
			},
			[usingDefault]
		);

		const [formError, setFormError] = useState("");

		const [saveOptions, setSaveOptions] = useState({
			url:
				initial === null
					? ADD_SURVEY_QUESTION_URL
					: SAVE_SURVEY_QUESTION_URL(initial),
			method: initial === null ? "POST" : "PUT",
			data: {},
		});

		const [{ loading, data, error }, save, reset] = useResource(
			saveOptions,
			false
		);

		const [activeStep, setActiveStep] = useState(0);

		useEffect(() => {
			if (data && open) {
				onDone(data.question);
				reset();
				onClose();
			}
		}, [data, open, onClose, onDone, question, reset]);

		const handleType = useCallback((type) => {
			setQuestion(type === 1 ? DEFAULT_SURVEY : initialQuestionObj);
			setUsingDefault(type === 1);
			setIsDefault(type === 1);
			return setActiveStep((activeStep) => activeStep + 1);
		}, []);

		const validateQuestionData = useCallback(() => {
			if (question.text.trim().length < 1) {
				return setFormError("Please enter a question!");
			}

			for (const option of question.options) {
				if (option.text.trim().length < 1) {
					return setFormError("Please enter a value for options");
				}
			}

			if (question.options.length < MIN_SURVEY_OPTION) {
				return setFormError(
					`Please add at least ${MIN_SURVEY_OPTION} options`
				);
			}

			setFormError("");
			return true;
		}, [question]);

		const validateConfigurationData = useCallback(() => {
			const res = validateModuleRules(question);
			if (!res[0]) {
				setFormError(res[1]);
				return false;
			}

			return true;
		}, [question]);

		const handleSave = useCallback(
			(makeActive = true) => {
				if (!validateQuestionData()) {
					return;
				}

				const active = makeActive;
				const {
					apply_rules,
					apply_and,
					rules,
					text,
					randomize_options,
				} = question;
				// OLD LOGIC FOR ACTIVED (CLEANUP)
				// active: active
				// 	? active
				// 	: initial
				// 	? initial.active
				// 	: false,

				setSaveOptions((saveOptions) => {
					const newSave = {
						...saveOptions,
						data: {
							question_text: text,
							randomize_options,
							rules,
							apply_rules,
							apply_and,
							active,
							options: [
								...question.options.map((option, id) => ({
									...option,
									order: id,
									follow_text: option.text_input
										? option.follow_text
										: null,
								})),
							],
						},
					};
					if (isDefault) {
						newSave.data.is_default = true;
					}
					return newSave;
				});

				save();
			},
			[isDefault, validateQuestionData, question, save]
		);

		const setRules = useCallback((callback) => {
			setQuestion((question) => ({
				...question,
				rules: callback(question.rules),
			}));
		}, []);

		const setApplyAnd = useCallback(({ target: { value: applyAnd } }) => {
			setQuestion((question) => ({
				...question,
				apply_and: applyAnd === "null" ? null : applyAnd === "true",
			}));
		}, []);

		const handleSaveForLater = useCallback(() => {
			handleSave(false);
		}, [handleSave]);

		const toggleRandomizeOptions = useCallback((value) => {
			setQuestion((question) => ({
				...question,
				randomize_options: value,
			}));
		}, []);

		const toggleRules = useCallback((value) => {
			setQuestion((question) => ({
				...question,
				apply_rules: value,
			}));
		}, []);

		const finalComponent = useMemo(() => {
			const retFinalComponent = {
				renderActionBar: () => {
					return (
						<Button
							dark
							type="button"
							className="survey-question-modal-final-component-btn"
							onClick={handleSaveForLater}
						>
							Save for later
						</Button>
					);
				},
				onDone: () => handleSave(true),
				error,
				nextBtnText: "Save & make active",
				modalProps: {
					heading: "Review your survey",
				},
				renderChildren: () => {
					return (
						<motion.section
							variants={anim.pageContent}
							initial="initial"
							animate="animate"
							exit="exit"
						>
							<section className="survey-question-modal-final-component">
								<h2>{question.text}</h2>
								<SurveyQuestionDisplay question={question} />
							</section>
							<SurveyConfiguration viewOnly question={question} />
						</motion.section>
					);
				},
			};

			return retFinalComponent;
		}, [question, handleSave, handleSaveForLater, error]);

		const onNavigation = useCallback(() => setFormError(""), []);

		const stepData = useMemo(() => {
			const retStepData = [
				{
					active: true,
					name: "Question Type",
					modalProps: {
						heading: "Select your question type",
					},
					hideNavButtons: true,
					renderChildren: () => (
						<motion.section
							variants={anim.pageContent}
							className="survey-question-modal-type-container"
							initial="initial"
							animate="animate"
							exit="exit"
						>
							<div
								className="survey-question-modal-type"
								onClick={() => handleType(1)}
							>
								<img
									src={defaultSurveyIcon}
									alt="Default Survey"
								/>
								<h3>Default Referral Survey Choices</h3>
							</div>
							<div
								className="survey-question-modal-type"
								onClick={() => handleType(2)}
							>
								<img
									src={customSurveyIcon}
									alt="Default Survey"
								/>
								<h3>Custom Survey Question</h3>
							</div>
						</motion.section>
					),
				},
				{
					active: false,
					name: "Customize Question",
					modalProps: {
						heading: "Customize your question",
					},
					validateData: validateQuestionData,
					formError,
					renderChildren: () => (
						<motion.section
							variants={anim.pageContent}
							initial="initial"
							animate="animate"
							exit="exit"
						>
							<BaseForm>
								<Input
									placeholder="Question Text Here"
									value={question.text}
									disabled={
										initial ? initial.is_default : false
									}
									className="survey-question-modal-inp"
									onChange={handleQuestionChange}
								/>

								<SurveyOptions
									options={question.options}
									setOptions={setOptions}
									isNew={!initial}
									user={user}
								/>
							</BaseForm>
						</motion.section>
					),
				},
				{
					active: false,
					name: "Survey Configuration",
					modalProps: {
						heading: "Configure your survey",
					},
					validateData: validateConfigurationData,
					formError,
					renderChildren: () => (
						<SurveyConfiguration
							user={user}
							question={question}
							toggleRandomizeOptions={toggleRandomizeOptions}
							setRules={setRules}
							setApplyAnd={setApplyAnd}
							toggleRules={toggleRules}
						/>
					),
				},
			];
			if (typeSelector) {
				return retStepData;
			}

			return retStepData.slice(1);
		}, [
			handleType,
			validateQuestionData,
			setRules,
			setApplyAnd,
			formError,
			validateConfigurationData,
			handleQuestionChange,
			question,
			initial,
			setOptions,
			user,
			typeSelector,
			toggleRules,
			toggleRandomizeOptions,
		]);

		return (
			<SteppedModal
				open={open}
				stepData={stepData}
				activeStep={activeStep}
				setActiveStep={setActiveStep}
				heading={initial === null ? "Add a Question" : "Edit Question"}
				onClose={onClose}
				finalComponent={finalComponent}
				onNavigation={onNavigation}
				className="survey-question-modal"
				loading={loading}
			></SteppedModal>
		);
	}
);

export default SurveyQuestionModal;
