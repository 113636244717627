import React, {
	memo,
	forwardRef,
	useState,
	useRef,
	useEffect,
	useCallback,
} from "react";
import PropTypes from "prop-types";

import { PaddedContainer, SPACING } from "../PaddedContainer";
import genPackageClassName from "../../utils/genPackageClassName";

import "./remote-input.css";
import { Input } from "../Input";
import useResource from "../../hooks/useResource";
import { Loader } from "../Loader";
import { Asset } from "../Typography";
import { CheckIcon, PencilIcon } from "../../Icons";
import { isFunction } from "../../utils";

export const RemoteInput = memo(
	forwardRef(
		(
			{
				url,
				method,
				value,
				hideTillHover = false,
				dataMapper,
				stopPropagation,
				active,
				initialActive = false,
				onDone,
				className = "",
				...rest
			},
			ref
		) => {
			const [payload, setPayload] = useState({});
			const [focus, setFocus] = useState(initialActive);
			const inpRef = useRef();

			const focusRef = useRef(false);

			const [{ loading, data }, save, reset] = useResource(
				{ url, method, data: payload },
				false
			);

			const handleBlur = useCallback(() => {
				if (url && value.trim().length) {
					setPayload(dataMapper(value));
					save();
				}
				setFocus(false);
			}, [dataMapper, save, url, value]);

			const handleFocus = useCallback(() => {
				setFocus(true);
				focusRef.current = true;
				if (!inpRef.current) return;
				inpRef.current.select();
			}, []);

			useEffect(() => {
				if (!data) {
					return;
				}
				if (isFunction(onDone)) {
					onDone();
				}
				reset();
			}, [data, reset, onDone]);

			const handleClick = useCallback(
				(e) => {
					if (!stopPropagation) return;
					e.stopPropagation();
				},
				[stopPropagation]
			);

			return (
				<PaddedContainer
					ref={ref}
					className={genPackageClassName({
						base: "remote-inp-container",
						conditionals: {
							"remote-inp-container-focus": focus || active,
							"remote-inp-container-hidden": hideTillHover,
						},
						additional: className,
					})}
				>
					<Input
						{...rest}
						className={genPackageClassName({
							base: "remote-inp",
						})}
						disabled={loading}
						value={value}
						onFocus={handleFocus}
						onBlur={handleBlur}
						ref={inpRef}
						onClick={handleClick}
					>
						{loading && <Loader small />}
						<Asset
							marginLeft={SPACING.TINY}
							marginRight={SPACING.MICRO}
							size={15}
						>
							{focus ? <CheckIcon /> : <PencilIcon />}
						</Asset>
					</Input>
				</PaddedContainer>
			);
		}
	)
);

RemoteInput.displayName = "RemoteInput";
RemoteInput.propTypes = {
	url: PropTypes.string,
	method: PropTypes.string,
	value: PropTypes.string,
	hideTillHover: PropTypes.bool,
	dataMapper: PropTypes.func,
	stopPropagation: PropTypes.bool,
	active: PropTypes.bool,
	initialActive: PropTypes.bool,
	onDone: PropTypes.func,
	className: PropTypes.string,
};
