import React, { forwardRef, memo, useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import genPackageClassName from "../../utils/genPackageClassName";

import "./accordion.css";
import { PaddedContainer, SPACING } from "../PaddedContainer";
import { FONT_SIZE, Text } from "../Typography";
import { anim, childrenPropTypes, isFunction } from "../../utils";
import { Loader } from "../Loader";
import { Button } from "../Button";

export const Accordion = memo(
	forwardRef(
		(
			{
				open,
				icon,
				heading,
				closeText,
				openText,
				loading,
				onOpen,
				onClose,
				onSave,
				children,
				className = "",
				buttonControl = false,
				dashedBorder = false,
				headerContent = null,
				disabled = false,
				buttonControlProps,
				buttonControlRef,
				banner = null,
				...rest
			},
			ref
		) => {
			const actuallyOpen = useMemo(
				() => open && !disabled,
				[disabled, open]
			);

			const handleToggle = useCallback(() => {
				if (disabled) return;
				if (open && isFunction(onClose)) {
					return onClose();
				}
				if (!open && isFunction(onOpen)) {
					return onOpen();
				}
			}, [disabled, onClose, onOpen, open]);

			const handleClick = useCallback(
				(e) => {
					if (!actuallyOpen || !isFunction(onSave)) return;
					e.stopPropagation();
					onSave();
				},
				[actuallyOpen, onSave]
			);

			return (
				<PaddedContainer
					ref={ref}
					motionElement
					layout
					variants={anim.verticalSlide}
					initial="initial"
					animate="animate"
					exit="exit"
					vPadding={SPACING.MEDIUM}
					hPadding={SPACING.MEDIUM}
					className={genPackageClassName({
						base: "accordion",
						conditionals: {
							"accordion-dashed": dashedBorder,
							"accordion-open": actuallyOpen,
						},
						additional: className,
					})}
					{...rest}
				>
					{banner}
					<PaddedContainer
						motionElement
						Element="header"
						layout
						onClick={handleToggle}
					>
						<Text Element="main" size={FONT_SIZE.LG_BODY} thick>
							<img src={icon} alt="" />
							{heading}
						</Text>
						{loading ? (
							<Loader small />
						) : buttonControl ? (
							<Button
								className={genPackageClassName({
									base: "accordion-toggle-btn",
								})}
								onClick={handleClick}
								rounded
								disabled={disabled}
								secondary={actuallyOpen}
								{...(buttonControlProps
									? buttonControlProps
									: {})}
								ref={buttonControlRef}
							>
								{actuallyOpen ? openText : closeText}
							</Button>
						) : (
							<Text
								size={FONT_SIZE.LG_BODY}
								className={genPackageClassName({
									base: "accordion-toggle",
								})}
								onClick={handleClick}
							>
								{actuallyOpen ? openText : closeText}
							</Text>
						)}
						{headerContent}
					</PaddedContainer>
					{actuallyOpen && <main>{children}</main>}
				</PaddedContainer>
			);
		}
	)
);

Accordion.displayName = "Accordion";
Accordion.propTypes = {
	open: PropTypes.bool,
	icon: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node,
		PropTypes.element,
		PropTypes.number,
	]),
	heading: PropTypes.string,
	closeText: PropTypes.string,
	openText: PropTypes.string,
	loading: PropTypes.bool,
	onOpen: PropTypes.func,
	onClose: PropTypes.func,
	onSave: PropTypes.func,
	children: childrenPropTypes,
	className: PropTypes.string,
	buttonControl: PropTypes.bool,
	dashedBorder: PropTypes.bool,
	headerContent: childrenPropTypes,
	disabled: PropTypes.bool,
	buttonControlProps: childrenPropTypes,
	buttonControlRef: childrenPropTypes,
	banner: childrenPropTypes,
};
