import React, { createContext, memo, useState } from "react";

export const PingContext = createContext();

const PingManager = memo(({ children }) => {
	const [pings, setPings] = useState({});
	return (
		<PingContext.Provider value={{ pings, setPings }}>
			{children}
		</PingContext.Provider>
	);
});

export default PingManager;
