import React, { memo } from "react";
import "./inline-toast.css";
import { FiCheckCircle } from "react-icons/fi";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { anim, FONT_COLOR, FONT_SIZE, SPACING, Text } from "@disco/disco_core";

const types = {
	success: { icon: <FiCheckCircle /> },
	warn: { icon: <AiOutlineExclamationCircle /> },
	none: { icon: null },
};

const InlineToast = memo(({ type = "success", children, className = "" }) => {
	return (
		<Text
			motionElement
			vPadding={SPACING.REGULAR}
			hPadding={SPACING.LARGE}
			color={FONT_COLOR.WHITE}
			size={FONT_SIZE.BODY}
			className={`inline-toast inline-toast-${type} ${className}`}
			variants={anim.simpleFade}
			initial="initial"
			animate="animate"
			exit="exit"
		>
			{types[type]?.icon && (
				<span className="inline-toast-icon">{types[type]?.icon}</span>
			)}
			{children}
		</Text>
	);
});

export default InlineToast;
