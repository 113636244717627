import React, { memo, useCallback } from "react";
import { BASIC_PLAN_FEATURES, PRO_PLAN_FEATURES } from "../../conf";
import { FilledButton } from "../CoreUI";
import PaymentPlanCard, { PaymentPlanPrice } from "./PaymentPlanCard";

const BasicPaymentPlanCard = memo(({ user }) => {
	const paymentPlanPrice = useCallback(
		() => (
			<>
				<PaymentPlanPrice subscript="/mo">$50</PaymentPlanPrice>
			</>
		),
		[]
	);
	return (
		<PaymentPlanCard
			heading="Basic"
			user={user}
			features={BASIC_PLAN_FEATURES}
			priceDetails={paymentPlanPrice}
			className="basic-payment-plan-card"
		>
			{/* <FilledButton forward>Upgrade Now</FilledButton> */}
		</PaymentPlanCard>
	);
});

export default BasicPaymentPlanCard;
