import React, { memo, useState, useMemo, useCallback, useEffect } from "react";
import { ReviewTooltip, ShopifyDot } from "../CoreUI";
import {
	Loader,
	PaddedContainer,
	Toggle,
	Checkbox,
	GuidedTooltip,
	RemoteInput,
	Text,
	Button,
	SPACING,
	FONT_COLOR,
	FONT_SIZE,
} from "@disco/disco_core";
import { motion } from "framer-motion";
import anim from "../../utils/anim";
import parseError from "../../utils/parseError";

import { SAVE_PRODUCT_URL } from "../../conf";

import "./product-components.css";
import { VariantsTable } from "../modals/VariantsModal";
import isShopifyShop from "../../utils/isShopifyShop";
import formatPrice from "../../utils/formatPrice";

export const dataMapper = (name) => ({ name });

export const compareStringPrice = (stringPrice1, stringPrice2) => {
	if (!stringPrice1 || !stringPrice2) {
		return false;
	}

	return Number(stringPrice1) > Number(stringPrice2);
};

export const parsePrice = (price) => {
	if (isNaN(price)) {
		return "";
	}
	return `${Number(price).toFixed(2)}`;
};

const tooltipPosProps = {
	x: 0,
	y: -20,
	widthScale: 1,
};

export const ProductDisplay = memo(
	({
		product,
		inStock,
		selected,
		onSelect,
		onVariantsOpen,
		onNameChange,
		editName = true,
		remoteId,
		user,
		variantRef,
		onboarding,
		showVariantModal = true,
		showSelect = true,
	}) => {
		const [value, setValue] = useState(product.name);
		const handleChange = useCallback(({ target: { value } }) => {
			setValue(value);
		}, []);

		const url = SAVE_PRODUCT_URL({ remote_id: remoteId });

		const [onName, setOnName] = useState(false);

		const [variantHover, setVariantHover] = useState(false);

		const isShopify = !!product.shopify_product_id && isShopifyShop(user);

		useEffect(() => {
			setValue(product.name);
		}, [product.name]);

		const openVariants = (e) => {
			e.stopPropagation();
			if (
				product.variants.length > 1 &&
				typeof onVariantsOpen === "function"
			) {
				onVariantsOpen(e, remoteId, variantRef);
			}
		};

		const activeVariants = useMemo(
			() =>
				(!product.active && !product.upsell) ||
				!Array.isArray(product.variants)
					? 0
					: product.variants.reduce((count, variant) => {
							return count + (variant.widget_active ? 1 : 0);
					  }, 0),
			[product]
		);

		return (
			<PaddedContainer
				className={`product-display ${
					onboarding ? "product-display-onboarding" : ""
				}`}
				alignContentCenter
			>
				{showSelect && !onboarding && (
					<PaddedContainer
						marginRight={SPACING.REGULAR}
						onClick={(e) => e.stopPropagation()}
					>
						<Checkbox
							checked={selected}
							onChange={(e) => {
								onSelect(
									{ remote_id: remoteId },
									e.target.checked
								);
							}}
						/>
					</PaddedContainer>
				)}
				<motion.img
					variants={anim.rowItem}
					src={product.photo_url}
					alt=""
				/>
				<PaddedContainer>
					<PaddedContainer
						className="product-display-hero"
						marginLeft={SPACING.REGULAR}
						alignContentCenter
					>
						<PaddedContainer
							className="product-display-name"
							onMouseEnter={() => setOnName(true)}
							onMouseLeave={() => setOnName(false)}
							onClick={(e) => editName && e.stopPropagation()}
						>
							<Text
								size={FONT_SIZE.BODY}
								marginRight={SPACING.MICRO}
							>
								{product.name}
							</Text>
							{editName && (
								<RemoteInput
									onChange={handleChange}
									url={url}
									active={false}
									method="PUT"
									dataMapper={dataMapper}
									onDone={(name) =>
										onNameChange(remoteId, value)
									}
									stopPropagation
									tooltip={
										product.shopify_name
											? {
													heading: "Shopify Name",
													text: product.shopify_name,
											  }
											: false
									}
								/>
							)}
						</PaddedContainer>

						{isShopify &&
							(!inStock ? (
								<Text
									color={"var(--red)"}
									marginLeft={SPACING.TINY}
								>
									Out of Stock
								</Text>
							) : (
								<ShopifyDot />
							))}
						{product.draft && !onboarding && (
							<ReviewTooltip inline={false} name="Product" />
						)}
					</PaddedContainer>

					<PaddedContainer className="product-display-extra">
						{Array.isArray(product.variants) &&
							product.variants.length > 0 && (
								<>
									<Text
										className="product-display-variants"
										marginLeft={SPACING.REGULAR}
										color={FONT_COLOR.MID}
										thin
										ref={variantRef}
										onMouseEnter={() =>
											setVariantHover(true)
										}
										onMouseLeave={() =>
											setVariantHover(false)
										}
									>
										{product.variants.length <= 1
											? `No variants`
											: activeVariants == 0
											? `${product.variants.length} variants available`
											: `${activeVariants} of ${product.variants.length} variants active`}
									</Text>
									<GuidedTooltip
										active={
											showVariantModal &&
											product.variants.length > 1 &&
											variantHover
										}
										highlighted={variantRef}
										preventOverflow
										persistent
										className="product-display-variants-tooltip"
										relativeProps={tooltipPosProps}
										onClick={(e) => e.stopPropagation()}
									>
										<header>
											<h3>Variants</h3>
											<Button onClick={openVariants}>
												Modify product variants
											</Button>
										</header>
										<VariantsTable
											product={product}
											user={user}
										/>
									</GuidedTooltip>
								</>
							)}
						{product.name.length > 40 && (
							<PaddedContainer
								className="product-display-warn"
								marginLeft={SPACING.REGULAR}
							>
								<Text
									vPadding={SPACING.MICRO}
									hPadding={SPACING.MICRO}
								>
									Name exceeds 40 characters
								</Text>
							</PaddedContainer>
						)}
					</PaddedContainer>
				</PaddedContainer>
			</PaddedContainer>
		);
	}
);

export const ProductToggle = memo(
	({ value, onChange, checkbox, disabled, loading, error }) => {
		const handleCheckboxChange = ({ target: { checked } }) => {
			if (typeof onChange === "function") onChange(checked);
		};

		return (
			<section
				className="product-toggle"
				onClick={(e) => e.stopPropagation()}
			>
				{loading ? (
					<Loader small />
				) : checkbox ? (
					<Checkbox
						checked={value}
						disabled={disabled}
						onChange={handleCheckboxChange}
					/>
				) : (
					<Toggle
						toggle={value}
						disabled={disabled}
						setToggleState={onChange}
					/>
				)}
				{error && (
					<section className="product-toggle-error">
						{parseError(error)}
					</section>
				)}
			</section>
		);
	}
);

export const ProductPrice = memo(({ price, discountedPrice }) => {
	let priceNum = Number(price),
		discNum = Number(discountedPrice);

	if (isNaN(priceNum) && isNaN(discNum)) {
		priceNum = null;
		discNum = "";
	} else if (isNaN(priceNum) || priceNum < discNum) {
		if (!priceNum || isNaN(priceNum)) {
			priceNum = null;
			discNum = parsePrice(discNum);
		} else {
			[priceNum, discNum] = [parsePrice(discNum), parsePrice(priceNum)];
		}
	} else if (isNaN(discNum)) {
		discNum = parsePrice(priceNum);
		priceNum = null;
	} else {
		[discNum, priceNum] = [parsePrice(discNum), parsePrice(priceNum)];
	}
	return (
		<PaddedContainer className="product-price" flexContent>
			<Text size={FONT_SIZE.BODY}>${discNum}</Text>
			{priceNum !== null && compareStringPrice(priceNum, discNum) && (
				<Text
					className="product-price-discount"
					size={FONT_SIZE.BODY}
					hPadding={SPACING.MICRO}
					color={FONT_COLOR.MID}
					thin
				>
					${formatPrice(priceNum)}
				</Text>
			)}
		</PaddedContainer>
	);
});
