import { Button } from "@disco/disco_core";
import React, {
	createRef,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { FETCH_BRAND_RECS_URL, SAVE_BRAND_URL } from "../../conf";
import useResource from "../../hooks/useResource";
import parseError from "../../utils/parseError";
import { ActionMessage, GridForm, Loader } from "../CoreUI";
import "./profile-step.css";

import getFirstError from "../../utils/getFirstError";
import isShopifyShop from "../../utils/isShopifyShop";

import BrandImages from "../BrandImages";
import OnboardingStep, { ONBOARDING_STEPS } from "./OnboardingStep";

import shopify from "../../img/icons/shopify.png";

import isFunction from "../../utils/isFunction";

import {
	PLATFORM_ONBOARDING_CLICK_BACK_PROFILE,
	PLATFORM_ONBOARDING_CLICK_CLEAR_IMPORTED_PROFILE,
	PLATFORM_ONBOARDING_CLICK_CONTINUE_PROFILE,
	PLATFORM_ONBOARDING_CLICK_REIMPORT_IMPORTED_PROFILE,
	PLATFORM_ONBOARDING_CLICK_SAVE_LOG_OUT_PROFILE,
	PLATFORM_ONBOARDING_PAGE_VIEW_PROFILE,
} from "../../events";
import useIdentity from "../../hooks/useIdentity";
import useTrack from "../../hooks/useTrack";
import ShopifyIntegration from "../profileIntegrations/ShopifyIntegration";

const initialFormError = { error: false, ref: null };

const errKeyMapping = {
	monthly_order_volume: "monthlyOrderVol",
};

const allIntegrations = [
	// {
	// 	component: WooCommerceIntegration,
	// 	key: "woocommerce",
	// 	props: {icon: woocommerce,
	// 	name: "WooCommerce",},
	// },
	// {
	// 	component: WooCommerceIntegration,
	// 	key: "bigcommerce",
	// 	icon: bigcommerce,
	// 	name: "BigCommerce",
	// },
	{
		existsFor: (user) =>
			!isShopifyShop(user) &&
			user.publisher.platforms.findIndex((platform) => {
				return (
					platform.name.toLowerCase() === "shopify" ||
					platform.name.toLowerCase() === "shopify plus"
				);
			}) !== -1,
		props: { icon: shopify, name: "Shopify" },
		component: ShopifyIntegration,
	},
];

const NEXT = 0,
	LOGOUT = 1,
	NONE = 2;

const ProfileForm = memo(
	({
		user,
		data,
		setData,
		setUser,
		formError,
		setFormError,
		showError,
		error,
		recs,
	}) => {
		const track = useTrack();

		const onClear = useCallback(() => {
			track(PLATFORM_ONBOARDING_CLICK_CLEAR_IMPORTED_PROFILE, {});
			setData((data) => {
				return Object.entries(data).reduce((res, [key, field]) => {
					return {
						...res,
						[key]: {
							...field,
							props: {
								...field.props,
								value: Array.isArray(field.props.value)
									? []
									: "",
							},
						},
					};
				}, {});
			});
		}, [setData, track]);

		const updateData = useCallback(() => {
			if (!recs) return;
			setData((data) => {
				if (!data) return data;
				return Object.entries(data).reduce((res, [key, field]) => {
					return {
						...res,
						[key]: {
							...field,
							props: {
								...field.props,
								value:
									!field.props.value && recs[field.formKey]
										? recs[field.formKey]
										: field.props.value,
							},
						},
					};
				}, {});
			});
		}, [recs, setData]);

		useEffect(() => {
			updateData();
		}, [updateData]);

		const handleReImport = useCallback(() => {
			track(PLATFORM_ONBOARDING_CLICK_REIMPORT_IMPORTED_PROFILE, {});
			updateData();
		}, [updateData, track]);

		useEffect(() => {
			updateData();
		}, [recs, updateData]);

		return (
			<>
				<ActionMessage
					text="We imported these details from your social media
									accounts. Make sure it looks right."
					theme
				>
					<>
						<Button
							rounded={false}
							small
							secondary
							onClick={onClear}
						>
							Clear
						</Button>
						<Button
							rounded={false}
							small
							secondary
							onClick={handleReImport}
						>
							Re-import
						</Button>
					</>
				</ActionMessage>
				<GridForm
					data={data}
					setData={setData}
					user={user}
					setUser={setUser}
					formError={formError}
					setFormError={setFormError}
					initialHighlight="brandDesc"
					resetHighlight={showError && error}
					listenBlur
				/>
			</>
		);
	}
);

const ProfileStep = memo(() => {
	const [next, setNext] = useState(false);
	const [logout, setLogout] = useState(false);
	const { user, setUser } = useIdentity();
	// const [subStep, setSubStep] = useState(
	// 	GET("integration") === "oauth" ? 1 : 0
	// );
	const nextType = useRef(NEXT);

	const [formError, setFormError] = useState(initialFormError);
	const [showError, setShowError] = useState(false);

	const integrations = useMemo(() => {
		const platforms = new Set(
			user.publisher.platforms.map((platform) =>
				platform.name.toLowerCase()
			)
		);
		return allIntegrations.filter(
			(integration) =>
				(integration.key && platforms.has(integration.key)) ||
				(isFunction(integration.existsFor) &&
					integration.existsFor(user))
		);
	}, [user]);

	const [payload, setPayload] = useState({
		url: SAVE_BRAND_URL,
		method: "PUT",
		data: {},
	});
	const [{ data: brandData, loading, error }, setupBrand, resetBrand] =
		useResource(payload, false);

	const [formData, setFormData] = useState({
		instagram: {
			tooltip: {
				heading: "Enter your shop's Instagram handle",
				text: "Enter your Instagram handle here. If you're invited to the Disco collective, we'll need this information for our cross promotional campaigns.",
			},
			errorName: "Instagram",
			props: {
				ref: createRef(),
				placeholder: "Your shop's Instagram handle",
				type: "text",
				label: "Instagram handle",
				value: user.publisher.instagram || "",
			},
			formKey: "instagram",
		},
		twitter: {
			tooltip: {
				heading: "Enter your shop's Twitter handle",
				text: "Enter your Twitter handle here. If you're invited to the Disco collective, we'll need this information for our cross promotional campaigns.",
			},
			errorName: "Twitter",
			props: {
				ref: createRef(),
				placeholder: "Your shop's Twitter handle",
				type: "text",
				label: "Twitter handle",
				value: user.publisher.twitter || "",
			},
			formKey: "twitter",
		},
		facebook: {
			tooltip: {
				heading: "Enter your shop's facebook page",
				text: "Enter your Facebook page here. If you're invited to the Disco collective, we'll need this information for our cross promotional campaigns.",
			},
			errorName: "Facebook Page",
			props: {
				ref: createRef(),
				placeholder: "Your shop's Facebook page",
				type: "text",
				label: "Facebook URL",
				value: user.publisher.facebook || "",
			},
			formKey: "facebook",
		},
		modal_color: {
			props: {
				ref: createRef(),
				type: "color",
				required: true,
				value: user.publisher.modal_color || "#ffffff",
				label: "Brand color",
				placeholder: "Brand color",
			},
			validator(color) {
				return {
					valid: /^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(color),
					heading: "Brand color needs to be a HEX color",
					text: "Please enter a valid HEX color to continue",
				};
			},
			errorName: "Brand Color",
			tooltip: {
				heading: "Choose a primary shop color",
				text: "This should be your primary shop color. It will be used to customize the design of your Disco widgets. You can always change this later.",
			},
			formKey: "modal_color",
		},
		images: {
			props: {
				type: "custom",
				customComponent(user, setUser, ref) {
					return (
						<BrandImages user={user} setUser={setUser} ref={ref} />
					);
				},
				label: "How did you hear about us?",
				ref: createRef(),
				placeholder: "How did you hear about us?",
			},
		},

		brandDesc: {
			tooltip: {
				heading: "What kind of description?",
				text: "Describe your shop. Keep it concise and to the point. This is how your shop will be described to potential shoppers.",
			},
			formKey: "single_liner",
			errorName: "Short Description",
			displayOrder: 1,
			props: {
				required: true,
				ref: createRef(),
				placeholder: "Short description",
				type: "text",
				textarea: true,
				className: "profile-step-long-inp",
				label: "A short description of your shop",
				maxLength: 55,
				value: user.publisher.single_liner || "",
			},
		},
	});

	const save = useCallback(() => {
		if (nextType.current !== LOGOUT) {
			if (!formData) return;
			const ret = Object.entries(formData)
				.sort((o1, o2) => o2[1].displayOrder - o1[1].displayOrder)
				.reduce((ret, [key, field]) => {
					let newRet = false;
					if (field.props.required) {
						if (field.props.value.length < 1) {
							setFormError({
								error: {
									heading: field.errorName + " is required",
									text: `Enter a value for ${field.errorName} to proceed`,
								},
								ref: field.props.ref,
							});

							newRet = true;
						}
					}
					if (isFunction(field.validator)) {
						const res = field.validator(field.props.value);
						if (!res.valid) {
							setFormError({
								error: {
									heading:
										res.heading ||
										field.errorName + " is required",
									text:
										res.text ||
										`Enter a value for ${field.errorName} to proceed`,
								},
								ref: field.props.ref,
							});
							newRet = true;
						}
					}
					return ret || newRet;
				}, false);

			if (ret) {
				return;
			}
		}

		setFormError(initialFormError);
		setPayload(() => ({
			...payload,
			data:
				formData &&
				Object.entries(formData)
					.filter(([, field]) => !!field.formKey)
					.reduce(
						(payload, [, field]) => ({
							...payload,
							[field.formKey]: field.props.value,
						}),
						{}
					),
		}));

		setupBrand();
	}, [formData, setupBrand, payload]);

	const [recs, setRecs] = useState(null);
	const [{ data: recsData, error: recsError }, , resetRecs] = useResource({
		url: FETCH_BRAND_RECS_URL,
	});

	const handleLogout = useCallback(() => {
		nextType.current = LOGOUT;
		save();
	}, [save]);

	// To go back anyway
	useEffect(() => {
		if (!error) {
			setShowError(false);
			return;
		}
		if (error && nextType.current === LOGOUT) {
			setLogout(true);
			resetBrand();
			return;
		}

		let err = getFirstError(error);
		if (!err || !errKeyMapping[err[0]]) {
			return setShowError(true);
		}

		const dataKey = errKeyMapping[err[0]];

		return setFormError({
			error: {
				heading: formData[dataKey].errorName,
				text: err[1],
			},
			ref: formData[dataKey].props.ref,
		});
	}, [error, resetBrand, formData]);

	useEffect(() => {
		if (!brandData) {
			return;
		}
		setUser((user) => ({
			...user,
			publisher: { ...user.publisher, ...brandData.brand },
		}));
		resetBrand();
		if (nextType.current === LOGOUT) {
			setLogout(true);
		} else {
			setNext(true);
		}
	}, [brandData, resetBrand, setUser, user, integrations]);

	useEffect(() => {
		if (!recsData && !recsError) return;
		if (recsData) setRecs(recsData);
		else setRecs({});
		resetRecs();
	}, [recsData, resetRecs, recsError]);

	return (
		<>
			<OnboardingStep
				heading="Your brand profile"
				subText="Your profile is what your potential partners will see when they are introduced to your brand."
				nextText="Continue"
				user={user}
				setUser={setUser}
				next={next}
				parseErrors={true}
				error={error && showError ? parseError(error) : ""}
				nextLoading={loading && nextType.current === NEXT}
				onLogout={handleLogout}
				logout={logout}
				setLogout={setLogout}
				requiredLegend
				setNext={setNext}
				aside={{
					heading: "Profile",
					imageUp: true,
					logo: true,
				}}
				className="profile-step"
				onNext={save}
				showBack
				steps={ONBOARDING_STEPS}
				activeStep={2}
				noPad
				nextEvent={PLATFORM_ONBOARDING_CLICK_CONTINUE_PROFILE}
				logoutEvent={PLATFORM_ONBOARDING_CLICK_SAVE_LOG_OUT_PROFILE}
				backEvent={PLATFORM_ONBOARDING_CLICK_BACK_PROFILE}
				viewEvent={PLATFORM_ONBOARDING_PAGE_VIEW_PROFILE}
			>
				{recs || recsError ? (
					<ProfileForm
						user={user}
						data={formData}
						setData={setFormData}
						setUser={setUser}
						formError={formError}
						setFormError={setFormError}
						showError={showError}
						error={error}
						loading={loading}
						handleNext={save}
						nextType={nextType}
						recs={recs}
					/>
				) : (
					<Loader className="profile-step-loader" />
				)}
			</OnboardingStep>
		</>
	);
});

export default ProfileStep;
