import React, { memo, useState, useEffect } from "react";
import parseError from "../../utils/parseError";
import { Modal } from "./Modal";
import { useCallback } from "react";
import useImageResource from "../../hooks/useImageResource";
import useResource from "../../hooks/useResource";
import { BaseForm } from "../BaseForm";
import { Button } from "../Button";
import { StatusLabel } from "../StatusLabel";
import { Loader } from "../Loader";
import { ImageInput } from "../ImageInput";
import "./base-image-modal.css";
import genPackageClassName from "../../utils/genPackageClassName";
import { PaddedContainer, SPACING } from "../PaddedContainer";

export const BaseImageModal = memo(
	({
		open,
		onClose,
		onDone,
		defaultValue,
		title,
		remoteKey,
		value,
		url,
		fileName,
		user,
		updateMethod = "POST",
		aspect = 1,
		className = "",
		inputProps,
		...props
	}) => {
		const handleClose = useCallback(
			(...args) =>
				typeof onClose === "function" ? onClose(...args) : null,
			[onClose]
		);
		const handleDone = useCallback(
			(...args) =>
				typeof onDone === "function" ? onDone(...args) : null,
			[onDone]
		);

		const [imageFormData, setImageFormData] = useState(
			value || defaultValue
		);
		const [imageName, setImageName] = useState(false);

		const [payload, setPayload] = useState({
			url,
			method: updateMethod,
			data: {},
		});

		const [
			{ loading: imageLoading, error: imageError, data: imageData },
			upload,
			reset,
		] = useImageResource({ user, image: imageFormData });

		const [{ loading, data, error }, setImage, resetImage] = useResource(
			payload,
			false
		);

		const handleChange = useCallback((image, name) => {
			setImageFormData(image);
			setImageName(name);
		}, []);

		useEffect(() => {
			if (!data) {
				return;
			}
			handleClose();
			let newValue = remoteKey ? data[remoteKey] : undefined;
			if (typeof newValue === "undefined") {
				if (data.brand) {
					newValue = data.brand[remoteKey];
				} else if (data.user) {
					newValue = data.user[remoteKey];
				}
			}
			handleDone(newValue, imageName);
			resetImage();
		}, [data, handleClose, handleDone, resetImage, remoteKey, imageName]);

		useEffect(() => {}, [imageError, imageData]);

		useEffect(() => {
			if (!imageData.url) {
				return;
			}

			if (url) {
				setPayload((payload) => ({
					...payload,
					data: { [remoteKey]: imageData.url },
				}));
				setImage();
			} else {
				handleClose();
				handleDone(imageData.url, imageName);
			}

			reset();
		}, [
			imageData,
			imageName,
			remoteKey,
			reset,
			setImage,
			handleClose,
			handleDone,
			url,
		]);

		const add = useCallback(
			(e) => {
				e.preventDefault();
				if (typeof imageFormData === "string") {
					handleClose();
					handleDone(imageFormData, imageName);
				} else {
					upload(fileName);
				}
			},
			[
				imageFormData,
				fileName,
				handleClose,
				handleDone,
				upload,
				imageName,
			]
		);

		return (
			<Modal
				className={genPackageClassName({
					base: "base-image-modal",
					additional: className,
				})}
				open={open}
				onClose={onClose}
				title={title}
				{...props}
			>
				<BaseForm
					className={genPackageClassName({
						base: "base-image-modal-form",
					})}
					marginTop={SPACING.SMALL}
				>
					<ImageInput
						onChange={handleChange}
						aspect={aspect}
						value={value || defaultValue}
						{...inputProps}
					/>
					{!(loading || imageLoading) && (imageError || error) && (
						<StatusLabel
							marginTop={SPACING.SMALL}
							className={genPackageClassName({
								base: "base-image-modal-error",
							})}
						>
							{parseError(imageError || error)}
						</StatusLabel>
					)}
					<PaddedContainer
						className={genPackageClassName({
							base: "base-image-modal-btn-container",
						})}
						flexContent
					>
						{(loading || imageLoading) && (
							<Loader
								marginTop={SPACING.MEDIUM}
								marginLeft={SPACING.LARGE}
								className={genPackageClassName({
									base: "base-image-modal-bottom-el",
								})}
							/>
						)}
						{!(loading || imageLoading) && (
							<Button
								onClick={add}
								className={genPackageClassName({
									base: "base-image-modal-bottom-el",
								})}
								thick
							>
								Save
							</Button>
						)}
					</PaddedContainer>
				</BaseForm>
			</Modal>
		);
	}
);
