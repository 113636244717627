import React, { createRef, memo, useState } from "react";
import {
	FETCH_BLACKLISTED_BRANDS_URL,
	FETCH_BRANDS_URL,
	SAVE_BLACKLIST_URL,
} from "../../conf";
import { BrandPreview, ModalSelectListInput } from "../CoreUI";

const BrandOptOutModalSelector = memo(
	({
		user,
		setUser,
		focusFunction,
		parentRef,
		onboarding = false,
		onClose,
		customStepProps,
		...props
	}) => {
		const [stepProps, setStepProps] = useState([
			{
				label: ({ value, loading }) =>
					loading ? (
						"Fetching your shops exclusions..."
					) : (
						<>
							{value.length} Shops{" "}
							<span style={{ "--highlight": "var(--red)" }}>
								Excluded
							</span>
							:
						</>
					),
				inlineName: "brands",
				inputProps: {
					ref: parentRef || createRef(),
					onMouseEnter: focusFunction,
					"data-name": "optOut",
				},
				remoteKey: "blacklist",
				tooltipName: "categories",

				title: "Shop Exclusions",
				disabledText: "Please select your categories first",

				asideTitle: "Select shops",
				mainTitle:
					"You are opting out of partnering with the following shops",
				buttonText: "Save",
				searchable: true,

				mainFooter: () => (
					<>
						Shops selected above will not appear on your widget.
						<br />
						<br />
						Note: this exclusion is one-way. You may still appear on
						these shops' pages.
					</>
				),

				split: true,
				stepped: false,

				fetchUrl: FETCH_BRANDS_URL,
				fetchValueUrl: FETCH_BLACKLISTED_BRANDS_URL,
				saveUrl: SAVE_BLACKLIST_URL,
				directSave: true,
				requestValueMapper: ({ value }) =>
					value.map((brand) => brand.remote_id),

				draftable: !onboarding,
				preview: (props) => {
					return (
						<BrandPreview previewTitle="Shop" previewItem={props} />
					);
				},
				onSave: onClose,
				inputWrapperProps: {
					card: true,
					actionText: onboarding
						? "Not quite right? That's okay. Lets make some changes now based on your preferences."
						: "Not quite right? That's okay. Lets make some changes now based on your preferences. ",
				},
				...(customStepProps?.[0] || {}),
			},
		]);

		return (
			<>
				<ModalSelectListInput
					stepProps={stepProps}
					user={user}
					setUser={setUser}
					setStepProps={setStepProps}
					onboarding={onboarding}
					{...props}
				/>
			</>
		);
	}
);

export default BrandOptOutModalSelector;
