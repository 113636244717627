const transition = {
	type: "spring",
	stiffness: 2000,
	mass: 3,
	damping: 100,
};

const tooltipTransition = {
	type: "spring",
	stiffness: 1200,
	mass: 1,
	damping: 40,
};

export const subtleTransition = {
	type: "spring",
	stiffness: 300,
	mass: 2.6,
	damping: 40,
};

const boomTransition = {
	type: "spring",
	stiffness: 800,
	mass: 7,
	damping: 75,
};

const pageTransition = {
	type: "spring",
	stiffness: 2000,
	mass: 5,
	damping: 140,
};

const slideTransition = {
	type: "spring",
	stiffness: 2000,
	mass: 4,
	damping: 140,
};

const selectTranition = {
	type: "spring",
	stiffness: 2000,
	mass: 6,
	damping: 120,
};

export const easeInTransitionFactory = (time = 0.3) => ({
	duration: time,
	ease: [0, 0.88, 0.48, 1],
});

export const anim = {
	dialog: {
		initial: {
			opacity: 0.5,
			y: 80,
			transition: subtleTransition,
		},
		animate: {
			opacity: 1,
			y: 0,
			transition: subtleTransition,
		},
		exit: {
			opacity: 1,
			y: 80,
			transition: subtleTransition,
		},
	},
	select: {
		initial: {
			y: -40,
			scale: 0.9,
			height: "0px",
			opacity: 0,
		},
		animate: {
			y: 0,
			scale: 1,
			height: "auto",
			opacity: 1,
			transition: {
				...easeInTransitionFactory(0.3),
				staggerChildren: 0.07,
			},
		},

		exit: {
			y: -40,
			scale: 0.9,
			height: "0px",
			opacity: 0,
			transition: {
				...easeInTransitionFactory(0.3),
				staggerChildren: 0.07,
				delay: 0.21,
			},
		},
	},

	variantFactory: ({
		x = 0,
		y = 0,
		opacity = 1,
		scale = 1,
		transitionProps = {},
		initial = {},
		exit = {},
	}) => ({
		initial: {
			x,
			opacity,
			y,
			scale,
			...initial,
		},
		animate: {
			y: 0,
			x: 0,
			opacity: 1,
			scale: 1,
			transition: { ...slideTransition, ...transitionProps },
		},
		exit: {
			x,
			y,
			...exit,
		},
	}),
};
