import {
	BaseImageModal,
	Button,
	Checkbox,
	FONT_COLOR,
	FONT_SIZE,
	genClassName,
	isFunction,
	Loader,
	PaddedContainer,
	RemoteInput,
	SPACING,
	StatusLabel,
	Table,
	Tag,
	TAG_TYPE,
	Text,
} from "@disco/disco_core";
import React, {
	useCallback,
	useMemo,
	memo,
	useState,
	useEffect,
	useRef,
} from "react";

import { SHOPIFY_NO_VARIANT_CONTENT } from "../../conf";
import useModal from "../../hooks/useModal";
import useProductSave from "../../hooks/useProductSave";
import parseError from "../../utils/parseError";
import FloatingModal from "./FloatingModal";

import "./variants-modal.css";

export const VariantsTable = memo(
	({
		product,
		selected,
		onSelect,
		handleImageOpen,
		handleChange,
		user,
		full = false,
		showOne = false,
	}) => {
		const header = useMemo(() => {
			if (
				!product ||
				!Array.isArray(product.variants) ||
				product.variants.length < 1
			) {
				return [];
			}
			let header = [];
			const idx = [1, 2, 3];
			for (let i of idx) {
				if (product.variants[0][`option${i}_name`]) {
					header.push({
						name: product.variants[0][`option${i}_name`],
						valueKey: `option${i}_value`,
					});
				}
			}
			return header;
		}, [product]);

		const headers = useMemo(() => {
			let tableHeaders = [];

			if (!full)
				tableHeaders.push({
					text: "",
					dataField: "active-header",
				});

			tableHeaders.push({
				text: "Image",
				dataField: "img-header",
			});

			if (full)
				tableHeaders.push({
					text: "Name",
					dataField: "name",
				});

			tableHeaders = [
				...tableHeaders,
				...header.map((heading) => ({
					text: heading.name,
					dataField: heading.valueKey,
				})),
			];

			if ((product.upsell || product.active) && full)
				tableHeaders.push({
					text: "Show Individually",
					dateField: "show-individually",
				});

			return tableHeaders;
		}, [full, header, product.active, product.upsell]);

		const safeOnSelect = useCallback(
			(...args) => {
				if (typeof onSelect === "function") {
					onSelect(...args);
				}
			},
			[onSelect]
		);

		const variantMapper = useCallback(
			(variant, idx) => {
				if (!variant || !header) {
					return false;
				}

				return (
					<tr
						key={idx}
						className={genClassName({
							base: "",
							conditionals: {
								"variants-table-selected":
									selected === variant.remote_id,
							},
						})}
						onClick={() => safeOnSelect(variant.remote_id)}
					>
						{!full && (
							<td>
								{variant.widget_active &&
									(product.active || product.upsell) && (
										<Tag
											type={TAG_TYPE.SUCCESS}
											vPadding={SPACING.TINY}
											hPadding={SPACING.TINY}
											className="variants-table-active-badge"
										>
											Active
										</Tag>
									)}
							</td>
						)}

						<td>
							{variant.photo_url ? (
								<div
									className="variants-table-img"
									onClick={() =>
										isFunction(handleImageOpen)
											? handleImageOpen(idx)
											: null
									}
								>
									<img
										src={variant.photo_url}
										alt={variant.title}
									/>
									{full && (
										<Text
											className="variants-table-img-overlay"
											color={FONT_COLOR.WHITE}
										>
											Change Image
										</Text>
									)}
								</div>
							) : full ? (
								<Button
									secondary
									small
									onClick={() => {
										handleImageOpen(idx);
									}}
								>
									Add Image
								</Button>
							) : (
								<Text
									inline
									vPadding={SPACING.SMALL}
									hPadding={SPACING.SMALL}
									size={FONT_SIZE.BODY}
									marginTop={SPACING.SMALL}
									marginBottom={SPACING.SMALL}
									className="variants-table-none"
								>
									No Variant Image
								</Text>
							)}
						</td>

						{full && (
							<td>
								<RemoteInput
									value={variant.name ?? product.name}
									initialActive={idx === 0}
									onChange={({ target: { value } }) =>
										handleChange(idx, "name", value)
									}
								/>
							</td>
						)}

						{header.map((heading) => (
							<td key={variant[heading.valueKey]}>
								{variant[heading.valueKey]}
							</td>
						))}

						{(product.upsell || product.active) && full && (
							<td className="variants-table-right-align">
								<Checkbox
									checked={variant.widget_active}
									className="variants-table-checkbox"
									onChange={({ target: { checked } }) =>
										handleChange(
											idx,
											"widget_active",
											checked
										)
									}
								/>
							</td>
						)}
					</tr>
				);
			},
			[
				header,
				selected,
				safeOnSelect,
				full,
				product,
				handleChange,
				handleImageOpen,
			]
		);

		if (!product || !product.variants) {
			return null;
		}

		if (product.variants.length <= (showOne ? 0 : 1)) {
			return (
				<Text
					Element="section"
					vPadding={SPACING.SMALL}
					hPadding={SPACING.SMALL}
					size={FONT_SIZE.BODY}
					marginTop={SPACING.SMALL}
					marginBottom={SPACING.SMALL}
					className="variants-table-none"
				>
					{SHOPIFY_NO_VARIANT_CONTENT(
						user,
						product.shopify_product_id
					)}
				</Text>
			);
		}

		return (
			<>
				<Table
					className={genClassName({
						base: "variants-table",
						conditionals: {
							"variants-table-selector": isFunction(onSelect),
						},
					})}
					headers={headers}
					data={product.variants}
					renderRow={variantMapper}
				/>
			</>
		);
	}
);

const VariantsModal = memo(
	({
		setProducts,
		product,
		user,
		target,
		onClose,
		onDone,
		setRefresh,
		editable = true,
		className = "",
		...rest
	}) => {
		const [productCopy, setProductCopy] = useState(product);
		const [{ loading, data, error }, save, reset] = useProductSave();

		const currentVariant = useRef(-1);

		const {
			open: imageOpen,
			handleOpen: handleImageOpen,
			handleClose: handleImageClose,
		} = useModal();

		const handleChange = useCallback((idx, key, value) => {
			setProductCopy((product) => ({
				...product,
				variants: [
					...product.variants.slice(0, idx),
					{ ...product.variants[idx], [key]: value },
					...product.variants.slice(idx + 1),
				],
			}));
		}, []);

		const handleImageDone = useCallback(
			(image) => {
				if (currentVariant === -1) {
					return;
				}
				handleChange(currentVariant.current, "photo_url", image);
				currentVariant.current = -1;
			},
			[handleChange]
		);

		const handleVariantImageOpen = useCallback(
			(idx) => {
				if (!editable) return;
				currentVariant.current = idx;
				handleImageOpen();
			},
			[handleImageOpen, editable]
		);

		const handleSave = useCallback(() => {
			save(product, {
				variants: productCopy.variants.map(
					({ remote_id, widget_active, name, photo_url }, index) => ({
						remote_id,
						widget_active,
						...(name !== product.variants[index].name
							? { name }
							: {}),
						...(photo_url !== product.variants[index].photo_url
							? { photo_url }
							: {}),
					})
				),
			});
		}, [save, product, productCopy]);

		useEffect(() => {
			setProductCopy({ ...product, variants_only: true });
			reset();
		}, [product, reset]);

		useEffect(() => {
			if (!data) {
				return;
			}

			if (typeof setRefresh === "function") setRefresh(true);
			if (typeof setProducts === "function") {
				setProducts((products) => {
					const index = products.findIndex(
						(product2) => product2.remote_id === product.remote_id
					);
					if (index === -1) {
						return products;
					}
					return [
						...products.slice(0, index),
						{
							...products[index],
							variants: data.variants,
							draft: true,
						},
						...products.slice(index + 1),
					];
				});
			}

			if (typeof onDone === "function") {
				onDone(data);
			}

			onClose();
			reset();
		}, [data, reset, setProducts, product, onClose, onDone, setRefresh]);

		return (
			<>
				<FloatingModal
					{...rest}
					// target={target}
					heading="Product Variants"
					className={`variants-modal ${className}`}
					overlayClassName="variants-modal-overlay"
					onClose={onClose}
					designSystem
					fixedHeader
					closer={
						<PaddedContainer
							hPadding={SPACING.REGULAR}
							flexContent
							className="variants-modal-btn-group"
						>
							{loading ? (
								<Loader
									marginTop={SPACING.TINY}
									marginBottom={SPACING.TINY}
								/>
							) : (
								<PaddedContainer
									flexContent
									vPadding={SPACING.TINY}
								>
									<Button
										onClick={onClose}
										hPadding={SPACING.REGULAR}
										vPadding={SPACING.TINY}
										marginRight={SPACING.TINY}
										secondary
									>
										<Text
											size={FONT_SIZE.LABEL}
											color={FONT_COLOR.MID}
										>
											Cancel
										</Text>
									</Button>

									{editable && (
										<Button
											onClick={handleSave}
											hPadding={SPACING.REGULAR}
											vPadding={SPACING.TINY}
										>
											<Text size={FONT_SIZE.LABEL}>
												Save
											</Text>
										</Button>
									)}
								</PaddedContainer>
							)}
						</PaddedContainer>
					}
				>
					<VariantsTable
						user={user}
						product={productCopy}
						setProduct={setProductCopy}
						handleImageOpen={handleVariantImageOpen}
						handleChange={handleChange}
						full={editable}
					/>
					{error && <StatusLabel>{parseError(error)}</StatusLabel>}
				</FloatingModal>
				<BaseImageModal
					value={
						product &&
						product.variants &&
						currentVariant.current >= 0
							? product.variants[currentVariant.current].photo_url
							: undefined
					}
					open={imageOpen}
					onClose={handleImageClose}
					user={user}
					onDone={handleImageDone}
					heading="Select a product Image"
				/>
			</>
		);
	}
);

export default VariantsModal;
