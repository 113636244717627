import React, { memo, useCallback } from "react";
import { FETCH_SURVEY_QUESTIONS_URL } from "../../conf";
import anim from "../../utils/anim";
import getPlatformPath from "../../utils/getPlatformPath";
import { Card, WidgetModuleBadge } from "../CoreUI";
import SurveyResult from "../SurveyResult";
import PerformanceModule from "./PerformanceModule";

const SurveyPerformanceModule = memo(({ user, setUser, hideNav = false }) => {
	const renderSidebarOption = useCallback((moduleData) => {
		return (
			<>
				<h3>{moduleData?.question_text}</h3>
				{moduleData?.active && (
					<WidgetModuleBadge type="success" rounded filled>
						Active
					</WidgetModuleBadge>
				)}
			</>
		);
	}, []);

	const renderPerformance = useCallback(
		(moduleData, performanceData) => {
			return (
				<>
					<SurveyResult
						inline
						user={user}
						surveyData={moduleData}
						performanceData={performanceData}
						surveyId={moduleData?.remote_id}
					/>
				</>
			);
		},
		[user]
	);
	return (
		<Card
			className="performance-analysis-module"
			variants={anim.preview}
			initial="initial"
			animate="animate"
		>
			<PerformanceModule
				type="survey"
				user={user}
				setUser={setUser}
				moduleNameDisplay="a survey"
				renderSidebarOption={renderSidebarOption}
				renderPerformance={renderPerformance}
				fetchModulesUrl={FETCH_SURVEY_QUESTIONS_URL}
				fetchModulesRemoteKey="questions"
				redirectButtonTo={getPlatformPath("surveys", user)}
				redirectButtonText="Take me to my surveys"
				hideNav={hideNav}
			/>
		</Card>
	);
});

export default SurveyPerformanceModule;
