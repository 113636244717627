import React, { forwardRef, useMemo } from "react";
import "./typography.css";
import genPackageClassName from "../../utils/genPackageClassName";
import { PaddedContainer, PaddedContainerProps } from "../PaddedContainer";
import { GenericRef, Extend, ObjectValuesAsTypes } from "../../shared.types";

export const FONT_SIZE = {
	JUMBO: "jumbo",
	HEADER: "header",
	TITLE: "title",
	SUB_TITLE: "subtitle",
	LG_BODY: "lg-body",
	BODY: "body",
	LABEL: "label",
} as const;

export const FONT_COLOR = {
	DEFAULT: "default",
	DARK: "dark",
	MID: "mid",
	LIGHT: "light",
	STROKE: "stroke",
	WHITE: "white",
} as const;

export type TypographySize = ObjectValuesAsTypes<typeof FONT_SIZE> | number;
export type TypographyColor =
	| ObjectValuesAsTypes<typeof FONT_COLOR>
	| (string & NonNullable<unknown>);

type NativeTextProps = {
	size?: TypographySize;
	color?: TypographyColor;
	thick?: boolean;
	normal?: boolean;
	thin?: boolean;
	inline?: boolean;
	center?: boolean;
	ellipsis?: boolean;
	gradient?: boolean;
};

export type TextProps = Extend<PaddedContainerProps, NativeTextProps>;

type TextComponent = (props: TextProps) => React.ReactNode | null;

export const Text: TextComponent = forwardRef(
	(
		{
			children,
			className = "",
			size,
			color,
			thick = false,
			normal = false,
			thin = false,
			style,
			inline = false,
			center = false,
			ellipsis = false,
			gradient = false,
			Element,
			...props
		}: TextProps,
		ref?: GenericRef
	) => {
		const isColorInDict = useMemo(() => {
			if (!color) return false;

			return Object.values(FONT_COLOR).some(
				(fontColor) => fontColor === color
			);
		}, [color]);

		const genStyle = useMemo(
			() => ({
				...(isColorInDict
					? { "--font-color": `var(--disco-text-${color})` }
					: typeof color === "string" && color.indexOf("--") === 0
					? { "--font-color": `var(${color})` }
					: color
					? { "--font-color": color }
					: {}),
				...(style || {}),
			}),
			[isColorInDict, style, color]
		);

		const renderElement = useMemo(
			() => (Element ? Element : inline ? "span" : "p"),
			[Element, inline]
		);

		return (
			<PaddedContainer
				className={genPackageClassName({
					base: "text",
					conditionals: {
						[`text-${size}`]: !!size, // TODO: QA
						"text-colored": !!color, // TODO: QA
						"text-ellipsis": ellipsis,
						"text-thick": thick,
						"text-thin": thin,
						"text-normal": normal,
						"text-center": center,
						"text-gradient": gradient,
					},
					additional: className,
				})}
				Element={renderElement}
				style={genStyle}
				{...props}
				ref={ref}
			>
				{children}
			</PaddedContainer>
		);
	}
);

type NativeAssetProps = {
	size?: string | number;
};
export type AssetProps = Extend<TextProps, NativeAssetProps>;

type AssetComponent = (props: AssetProps) => React.ReactNode | null;

export const Asset: AssetComponent = forwardRef(
	({ children, className, size, ...rest }: AssetProps, ref?: GenericRef) => {
		const style = useMemo(() => {
			let { style = {} } = rest || {};
			if (size) {
				style = {
					...style,
					"--size": typeof size === "string" ? size : `${size}px`,
				} as React.CSSProperties;
			}
			return style;
		}, [size, rest]);

		return (
			<Text
				className={genPackageClassName({
					base: "asset",
					additional: className,
				})}
				Element="section"
				{...rest}
				style={style}
				ref={ref}
			>
				{children}
			</Text>
		);
	}
);
