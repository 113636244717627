import { useCallback, useEffect, useRef, useState } from "react";

const useTimer = ({
	tickSize = 1000,
	numTicks = 10,
	playing = true,
	setPlaying,
	resetOnComplete = false,
	onComplete,
	onTick,
} = {}) => {
	const [ticks, setTicks] = useState(0);

	const timerRef = useRef(null);
	const lastTimeRef = useRef(null);
	const passedTimeRef = useRef(0);

	const reset = useCallback(
		(play = false) => {
			setTicks(0);

			if (typeof setPlaying === "function") setPlaying(play);

			timerRef.current = null;
			lastTimeRef.current = null;
			passedTimeRef.current = 0;
		},
		[setPlaying]
	);

	useEffect(() => {
		// timerRef != null -> timer was interrupted
		// 		-> Save time passed, to resume playing in the future

		if (timerRef.current !== null) {
			if (lastTimeRef.current === null) {
				passedTimeRef.current = 0;
			} else {
				passedTimeRef.current = Date.now() - lastTimeRef.current;
				lastTimeRef.current = null;
			}
			lastTimeRef.current = null;

			clearTimeout(timerRef.current);
			timerRef.current = null;
		}

		if (!playing || ticks >= numTicks) {
			if (ticks > numTicks) setTicks(numTicks);
			if (playing && typeof setPlaying === "function") setPlaying(false);
			if (ticks >= numTicks && typeof onComplete === "function") {
				onComplete();
			}
			if (ticks >= numTicks && resetOnComplete) reset();
			return;
		}

		lastTimeRef.current = Date.now();

		timerRef.current = setTimeout(() => {
			timerRef.current = null;
			lastTimeRef.current = null;
			setTicks((ticks) => (ticks < numTicks ? ticks + 1 : numTicks));
			if (isFunction(onTick)) onTick();
		}, tickSize - passedTimeRef.current);

		passedTimeRef.current = 0;
	}, [
		ticks,
		tickSize,
		numTicks,
		playing,
		setTicks,
		setPlaying,
		resetOnComplete,
		onComplete,
		reset,
		onTick,
	]);

	return { ticks, setTicks, reset };
};

export default useTimer;
