import { useContext } from "react";
import { DimensionsContext } from "../components/DimensionsManager";

const useDimensions = () => {
	const { width, height, isDesktop, isMobile } =
		useContext(DimensionsContext);

	return {
		width,
		height,
		isDesktop,
		isMobile,
	};
};
export default useDimensions;
