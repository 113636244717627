import { LabeledInput, Text } from "@disco/disco_core";
import React from "react";

import "./lead-offers-editor.css";
import {
	getLeadOfferType,
	LEAD_OFFER_TYPE,
	LEAD_OFFER_TYPE_KEY,
} from "../modals/LeadOffersTypeSelectorModal";
import usePing, { PING_TYPES } from "../../hooks/usePing";

import "./lead-offers-editor-type-selector.css";
import usePreview from "../../hooks/usePreview";

const LEAD_OFFER_TYPE_COPY_MAPPING = {
	[LEAD_OFFER_TYPE.NO_PROMO_CODE]: "No promo code",
	[LEAD_OFFER_TYPE.PROMO_CODE]: "Promo code",
};
const LeadOffersEditorTypeSelector = ({
	offer,
	setOffer,
	onOfferTypeEdit,
	disabled = false,
}) => {
	const { postMessage } = usePreview();
	const handleOfferTypeUpdate = (type) => {
		setOffer((offer) => ({
			...offer,
			code: type === LEAD_OFFER_TYPE.NO_PROMO_CODE ? "" : offer.code,
			[LEAD_OFFER_TYPE_KEY]: type,
		}));
		postMessage({ payload: { incentive_type: type } });
	};
	usePing({
		key: PING_TYPES.UPDATE_LEAD_OFFER_TYPE,
		callback: handleOfferTypeUpdate,
	});

	const handleEdit = () =>
		!disabled && onOfferTypeEdit(getLeadOfferType(offer));
	const editSwitch = !disabled && (
		<Text
			marginLeft={"auto"}
			color="--disco-theme-mid"
			clickable
			onClick={handleEdit}
		>
			Edit
		</Text>
	);
	return (
		<LabeledInput
			className="lead-offers-editor-type-selector"
			label="Type"
			labelProps={{ children: editSwitch, required: true }}
			disabled
			value={LEAD_OFFER_TYPE_COPY_MAPPING[getLeadOfferType(offer)]}
		/>
	);
};

export default LeadOffersEditorTypeSelector;
