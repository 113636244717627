import React, { memo, useEffect, useCallback } from "react";
import { INITIATE_EMAIL_SETUP_URL } from "../../conf";
import useResource from "../../hooks/useResource";
import parseError from "../../utils/parseError";
import { FormStatus, LargeList } from "../CoreUI";
import EmailsStep from "./EmailsStep";

const EmailsFeatureStep = memo(
	({
		features,
		icon,
		finalStep,
		children,
		onNext,
		enableEmailsOnProceed = false,
		setUser,
		...props
	}) => {
		const [{ loading, data, error }, enableEmails] = useResource(
			{
				url: INITIATE_EMAIL_SETUP_URL,
				method: "PUT",
				data: {
					shopify_emails_enabled: true,
				},
			},
			false
		);

		const handleNext = useCallback(() => {
			if (enableEmailsOnProceed) {
				return enableEmails();
			}
			if (typeof onNext === "function") {
				onNext();
			}
		}, [enableEmailsOnProceed, enableEmails, onNext]);

		useEffect(() => {
			if (!data) {
				return;
			}
			if (typeof setUser === "function") {
				setUser((user) => ({
					...user,
					publisher: {
						...user.publisher,
						...data,
					},
				}));
			}
			if (typeof onNext === "function") {
				onNext();
			}
		}, [data, setUser, onNext]);

		return (
			<EmailsStep
				{...props}
				setUser={setUser}
				finalStep={finalStep}
				loading={loading}
				onNext={handleNext}
			>
				{finalStep ? (
					children
				) : (
					<LargeList list={features} icon={icon} />
				)}
				{error && <FormStatus>{parseError(error, true)}</FormStatus>}
			</EmailsStep>
		);
	}
);

export default EmailsFeatureStep;
