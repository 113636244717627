import React, { memo, forwardRef, CSSProperties } from "react";
import { PaddedContainer, PaddedContainerProps } from "../PaddedContainer";
import genPackageClassName from "../../utils/genPackageClassName";

import "./img-tile.css";
import {
	Extend,
	GenericRef,
	StandardComponentWithChildren,
} from "../../shared.types";

export type NativeImgTileProps = {
	circular?: boolean;
	rounded?: boolean;
	shadow?: boolean;
	bordered?: boolean;
};

export type ImgTileProps = Extend<PaddedContainerProps, NativeImgTileProps>;

type ImgTileComponent = (props: ImgTileProps) => React.ReactNode | null;

export const ImgTile: ImgTileComponent = memo(
	forwardRef(
		(
			{
				className = "",
				circular = false,
				rounded = false,
				shadow = false,
				bordered = false,
				...rest
			},
			ref: GenericRef
		) => {
			return (
				<PaddedContainer
					className={genPackageClassName({
						base: "img-tile",
						conditionals: {
							"img-tile-circular": circular,
							"img-tile-rounded": rounded,
							"img-tile-shadow": shadow,
							"img-tile-bordered": bordered,
						},
						additional: className,
					})}
					Element="img"
					alt={rest?.alt || ""}
					ref={ref}
					{...rest}
				/>
			);
		}
	)
);

export type NativeSquareImgTileProps = {
	width?: string | number;
	src?: string;
	rounded?: boolean;
	circular?: boolean;
	bordered?: boolean;
	shadow?: boolean;
	style?: CSSProperties;
	alt?: string;
	imgProps?: React.ImgHTMLAttributes<HTMLImageElement>;
};

export type SquareImgTileProps = Extend<
	PaddedContainerProps,
	NativeSquareImgTileProps
>;

type SquareImgTileComponent = StandardComponentWithChildren<SquareImgTileProps>;

export const SquareImgTile: SquareImgTileComponent = memo(
	forwardRef(
		(
			{
				className = "",
				width,
				src,
				children,
				rounded = false,
				circular = false,
				bordered = false,
				shadow = false,
				style,
				alt = "",
				imgProps,
				...props
			},
			ref: GenericRef
		) => (
			<PaddedContainer
				className={genPackageClassName({
					base: "square-img-tile",
					conditionals: {
						"img-tile-circular": circular,
						"img-tile-rounded": rounded,
						"img-tile-shadow": shadow,
						"img-tile-bordered": bordered,
					},
					additional: className,
				})}
				ref={ref}
				{...props}
				style={
					width
						? ({
								"--size": width,
								...(style || {}),
						  } as CSSProperties)
						: style || {}
				}
			>
				{children}
				{src ? <img alt={alt || ""} {...imgProps} src={src} /> : null}
			</PaddedContainer>
		)
	)
);
