import { useCallback, useEffect, useState } from "react";
import useTrack from "./useTrack";

const useTrackView = ({ event, properties = {}, canTrack = true }) => {
	const track = useTrack();
	const [hasTracked, setHasTracked] = useState(false);

	useEffect(() => {
		if (!event || hasTracked || !canTrack) return;

		track(event, properties);
		setHasTracked(true);
	}, [event, properties, hasTracked, track, canTrack]);

	const reset = useCallback(() => setHasTracked(false), []);

	return {
		reset,
	};
};
export default useTrackView;
