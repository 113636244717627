import React, { forwardRef, memo, useEffect, useMemo } from "react";

import useTheme from "../../hooks/useTheme";
import genPackageClassName from "../../utils/genPackageClassName";

import { SPACING, PaddedContainerSpacing } from "../PaddedContainer";
import { Text, TextProps } from "../Typography";
import "./button.css";
import {
	GenericRef,
	Extend,
	StandardComponentWithChildren,
} from "../../shared.types";

type NativeButtonProps = {
	type?: string;
	middle?: boolean;
	rounded?: boolean;
	circular?: boolean;
	small?: boolean;
	secondary?: boolean;
	icon?: React.ReactElement;
	block?: boolean;
};

export type ButtonProps = Extend<TextProps, NativeButtonProps>;
type ButtonComponent = StandardComponentWithChildren<ButtonProps>;

type paddingPropsType = {
	hPadding?: PaddedContainerSpacing;
	vPadding?: PaddedContainerSpacing;
};

export const Button: ButtonComponent = memo(
	forwardRef(
		(
			{
				className = "",
				children,
				type = "button",
				center = false,
				middle = false,
				rounded = true,
				circular = false,
				small = false,
				secondary = false,
				icon,
				block = false,
				gradient = false,
				...rest
			}: ButtonProps,
			ref?: GenericRef
		) => {
			const { theme } = useTheme();

			const paddingProps: paddingPropsType = useMemo(() => {
				if (circular) {
					return {
						hPadding: undefined,
						vPadding: undefined,
					};
				}
				if (small) {
					return {
						hPadding: SPACING.REGULAR,
						vPadding: SPACING.TINY,
					};
				}
				return {
					hPadding: SPACING.MEDIUM,
					vPadding: SPACING.SMALL,
				};
			}, [circular, small]);

			useEffect(() => {
				if (!gradient) return;
				console.warn("Unimplemented prop used >> gradient = true");
			}, [gradient]);

			const renderBtn = (
				<Text
					ref={ref}
					type={type}
					motionElement
					Element="button"
					className={genPackageClassName({
						theme,
						base: "btn",
						additional: className,
						conditionals: {
							"btn-rounded": rounded,
							"btn-circular": circular,

							"text-body": !small,
							"text-label": small,
							"btn-sm": small,

							"btn-primary": !secondary,
							"btn-secondary": secondary,

							"btn-icon flex-center": !!icon,
							"btn-block": block,
						},
					})}
					hMarginAuto={middle}
					{...paddingProps}
					{...rest}
				>
					{icon}
					{icon && " "}
					{children}
				</Text>
			);

			if (center) {
				return (
					<section
						className={genPackageClassName({
							base: "absolute-center-container",
						})}
					>
						{renderBtn}
					</section>
				);
			}

			return renderBtn;
		}
	)
);
