import React, { memo, useEffect, useRef, useState } from "react";
import { BiTimer } from "react-icons/bi";
import { FETCH_DASHBOARD_URL } from "../../conf";
import useResource from "../../hooks/useResource";
import anim from "../../utils/anim";
import { Card, Loader } from "../CoreUI";
import { PerformanceModuleRedirectButton } from "./PerformanceModule";

const LookerDashPerformance = memo(
	({
		redirectButtonTo = "",
		redirectButtonText = "",
		type = "",
		idx = "",
	}) => {
		const dashboard = useRef(null);
		const [, setUpdate] = useState(false);
		const [{ loading, error, data }, reload] = useResource(
			{
				url: `${FETCH_DASHBOARD_URL}?domain=${window.location.hostname?.replace(
					"platform.",
					""
				)}`,
				method: "GET",
			},
			false
		);
		useEffect(() => {
			if (!data) {
				return;
			}
			dashboard.current = data;
			setUpdate((update) => !update);
		}, [data]);

		useEffect(() => {
			dashboard.current = null;
			reload();
		}, [type, reload]);

		return (
			<Card
				key={idx}
				className="performance-analysis-module"
				variants={anim.preview}
				initial="initial"
				animate="animate"
			>
				{loading && <Loader center />}
				{/* {error && <FormStatus>{parseError(error)}</FormStatus>} */}
				{(dashboard.current || error) && (
					<>
						{(type === "bod" && !dashboard.current.bod_url) ||
						(type === "partnership" &&
							!dashboard.current?.partnerships_url) ||
						(type === "email" && !dashboard.current?.emails_url) ||
						!dashboard.current?.url ||
						error ? (
							<Card className="performance-analysis-module-empty">
								<span
									className="performance-analysis-module-empty-icon"
									role="img"
									aria-label="Soon icon"
								>
									<BiTimer />
								</span>
								<h2>Coming soon!</h2>
								<h4>We are working on your dashboard</h4>
							</Card>
						) : (
							<>
								{/* <div className="performance-page-title-bar">
								<h2>Performance Dashboard</h2>
								{requiresSpecialDash &&
									dashboard.premium_url && (
										<LabeledInput
											toggle
											toggleState={showPremiumDash}
											handleToggle={handlePremiumDash}
											customFeedbackText="Show Premium Display Dashboard"
											hideDefaultFeedbackText
										/>
									)}
							</div> */}
								<iframe
									title="Looker Dashboard"
									src={
										type === "network"
											? dashboard.current
													?.partnerships_url
											: type === "email"
											? dashboard.current?.emails_url
											: type === "bod"
											? dashboard.current?.bod_url
											: dashboard.current?.url
									}
								/>
								{redirectButtonText && (
									<PerformanceModuleRedirectButton
										to={redirectButtonTo}
									>
										{redirectButtonText}
									</PerformanceModuleRedirectButton>
								)}
							</>
						)}
					</>
				)}
			</Card>
		);
	}
);

export default LookerDashPerformance;
