import React, { memo } from "react";
import { AxiosContext } from ".";
import { childrenPropTypes, createApiAxios } from "../../utils";
import PropTypes from "prop-types";

const AxiosManager = memo(
	({
		BASE_URL = "",
		REFRESH_TOKEN_URL = "",
		requestInterceptor,
		children,
	}) => {
		const apiAxios = createApiAxios({
			BASE_URL,
			REFRESH_TOKEN_URL,
			requestInterceptor,
		});

		return (
			<AxiosContext.Provider value={{ apiAxios }}>
				{children}
			</AxiosContext.Provider>
		);
	}
);

AxiosManager.displayName = "AxiosManager";
AxiosManager.propTypes = {
	BASE_URL: PropTypes.string,
	REFRESH_TOKEN_URL: PropTypes.string,
	requestInterceptor: PropTypes.func,
	children: childrenPropTypes,
};

export default AxiosManager;
