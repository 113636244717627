import React, { memo, useCallback, useMemo, useState } from "react";
import {
	PLATFORM_ONBOARDING_CLICK_BACK_CATEGORIES,
	PLATFORM_ONBOARDING_CLICK_CONTINUE_CATEGORIES,
	PLATFORM_ONBOARDING_CLICK_SAVE_LOG_OUT_CATEGORIES,
	PLATFORM_ONBOARDING_PAGE_VIEW_CATEGORIES,
} from "../../events";
import useIdentity from "../../hooks/useIdentity";
import BrandSetupModalSelector from "../BrandSetupModalSelector";
import CategoryOptInModal from "../CategoryOptInModalSelector";
import { QuestionTooltip } from "../CoreUI";
import OnboardingStep from "./OnboardingStep";
import "./categorisation-step.css";

const CategorizationStep = memo(() => {
	// const { ping: pingNext } = usePing({ key: PING_TYPES.MODAL_SELECT_NEXT });
	const [next, setNext] = useState(false);
	const { user, setUser } = useIdentity();

	const handleNextStep = useCallback(() => {
		setUser((user) => ({ ...user, firstTime: true }));
		setNext(true);
	}, [setUser]);

	const nextDisabled = useMemo(() => {
		return !(
			Array.isArray(user.publisher.categories) &&
			user.publisher.categories.length > 0 &&
			Array.isArray(user.publisher.subcategories) &&
			user.publisher.subcategories.length > 0 &&
			Array.isArray(user.publisher.category_whitelist) &&
			user.publisher.category_whitelist.length > 0
		);
	}, [user]);

	return (
		<OnboardingStep
			user={user}
			setUser={setUser}
			heading={"Select your categories"}
			subText={
				"This will help us determine potential brand partners for you."
			}
			parseErrors={true}
			activeStep={3}
			aside={{
				heading: "Categories",
				imageUp: true,
				logo: true,
			}}
			nextText={"Continue"}
			className="categorisation-step"
			onNext={handleNextStep}
			showNext
			showBack
			nextDisabled={nextDisabled}
			next={next}
			setNext={setNext}
			noPad
			nextEvent={PLATFORM_ONBOARDING_CLICK_CONTINUE_CATEGORIES}
			logoutEvent={PLATFORM_ONBOARDING_CLICK_SAVE_LOG_OUT_CATEGORIES}
			backEvent={PLATFORM_ONBOARDING_CLICK_BACK_CATEGORIES}
			viewEvent={PLATFORM_ONBOARDING_PAGE_VIEW_CATEGORIES}
		>
			<h3 className="categorisation-step-section-heading">
				What categories describe your brand?
			</h3>
			<BrandSetupModalSelector
				user={user}
				setUser={setUser}
				interStepBack={false}
				onboarding
			/>
			<h3 className="categorisation-step-section-heading">
				Who do you want to partner with?{" "}
				<QuestionTooltip independant>
					The more partner categories you add, the more you'll learn
					about what resonates with your customers
				</QuestionTooltip>
			</h3>
			<CategoryOptInModal user={user} setUser={setUser} onboarding />
		</OnboardingStep>
	);
});

export default CategorizationStep;
