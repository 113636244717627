import React, { memo, useMemo } from "react";
import {
	AlertBarButton,
	AlertBarIndicator,
	AlertBarModule,
	AlertBarModuleSection,
} from ".";
import useModal from "../../hooks/useModal";
import getParsedTimeDiff from "../../utils/getParsedTimeDiff";
import getSubscriptionText from "../../utils/getSubscriptionText";
import PaymentModal from "../modals/PaymentModal";

import "./alert-bar-payment-module.css";

const AlertBarPaymentModule = memo(({ user, setUser }) => {
	const { open, handleOpen, handleClose } = useModal(false);

	const userSubscriptionInfo = useMemo(
		() => getSubscriptionText(user),
		[user]
	);

	const proEndData = useMemo(
		() => getParsedTimeDiff(user?.publisher?.grace_period_ends),
		[user]
	);

	/*
		proEndData = null -> grace period not started
		proEndData != null & !!text -> grace period
		proEndData != null & !text -> grace period ended
	*/

	return (
		<>
			<AlertBarModule className="alert-bar-payment-module">
				<AlertBarModuleSection className="alert-bar-payment-module-text">
					<AlertBarIndicator
						type={
							userSubscriptionInfo.premium
								? "green"
								: userSubscriptionInfo.inTrial
								? "yellow"
								: "red"
						}
					></AlertBarIndicator>
					<span>
						{userSubscriptionInfo?.inTrial
							? userSubscriptionInfo?.premium
								? ""
								: `Connect your payments to be displayed across the Disco Network.`
							: !userSubscriptionInfo?.premium
							? !!proEndData.text
								? `You have exceeded your free-trial. Please setup payments or Disco tools will be automatically disabled in ${proEndData.text}.`
								: `Your trial has ended. To continue using Disco, please connect payments.`
							: ""}
					</span>
				</AlertBarModuleSection>
				{(!userSubscriptionInfo.premium ||
					!userSubscriptionInfo.inTrial) && (
					<AlertBarModuleSection>
						<AlertBarButton onClick={handleOpen}>
							Set Up Payments
						</AlertBarButton>
					</AlertBarModuleSection>
				)}
			</AlertBarModule>
			<PaymentModal
				open={open}
				user={user}
				setUser={setUser}
				onClose={handleClose}
			/>
		</>
	);
});

export default AlertBarPaymentModule;
