import { PaddedContainer } from "@disco/disco_core";
import React, { memo, useEffect } from "react";
import { PREVIEW_TYPES_IDX } from "../../conf";

import useIdentity from "../../hooks/useIdentity";
import usePreview from "../../hooks/usePreview";
import { UniversalPreview } from "../CoreUI";
import { PostPurchaseModuleHome } from "../feedModules/PostPurchaseModule";

import "./page-setup-module.css";
import SettingsModule, { SettingsModuleSection } from "./SettingsModule";

const PageSetupModule = memo(() => {
	const { user, setUser } = useIdentity();
	const { triggerRefresh, setPreviewType } = usePreview();

	useEffect(
		() => setPreviewType(PREVIEW_TYPES_IDX.CROSS_SELL),
		[setPreviewType]
	);

	return (
		<PaddedContainer className="page-setup-module">
			<SettingsModule>
				<SettingsModuleSection tightTop title="Your Page Setup">
					<PostPurchaseModuleHome
						triggerRefresh={triggerRefresh}
						user={user}
						setUser={setUser}
						designSystem
					/>
				</SettingsModuleSection>
			</SettingsModule>
			<UniversalPreview user={user} setUser={setUser} />
		</PaddedContainer>
	);
});
export default PageSetupModule;
