import React from "react";
import { Loader, FormStatus } from "../CoreUI";
import "./shops-selector.css";
import parseError from "../../utils/parseError";

const SelectShopModal = React.memo(
	({ shops, error, loading, onLogin, ...rest }) => {
		return (
			<section className="shops-selector" {...rest}>
				{Array.isArray(shops) &&
					shops.map((shop) => (
						<section
							className="shops-selector-shop"
							key={shop.shop}
							onClick={() => onLogin(shop.publisher_id)}
						>
							{shop.name}
						</section>
					))}
				{loading && <Loader className="login-page-shop-loader" />}
				{error && <FormStatus>{parseError(error)}</FormStatus>}
			</section>
		);
	}
);

export default SelectShopModal;
