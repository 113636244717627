import { AnimatePresence, motion } from "framer-motion";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import ReactDOM from "react-dom";
import { IconContext } from "react-icons";
import { RiCloseCircleFill } from "react-icons/ri";
import anim from "../../utils/anim";
import isFunction from "../../utils/isFunction";
import { Input } from "../CoreUI";
import "./modal.css";

const Modal = ({
	heading = "",
	className = "",
	overlayClassName = "",
	open,
	onClose,
	modalRef,
	search,
	onSearch,
	style = {},
	closer = null,
	children,
	customVariant = null,
	onboarding = false,
	onboardingLeft = false,
	onboardingFull = false,
	customWidth = false,
	extended = false,
	customModal = false,
	preview,
	hoveredItem,
	overlay,

	inline = false,
	overlayCloseIdx = "",
	disableOverlayClose = false,
	exactlyOverlayClose = false,
	sheet = false,
	closerHidden = false,

	customRenderer = null,
	backgroundContent = null,
	...rest
}) => {
	const [modalRoot, setModalRoot] = useState(null);
	const [searchText, setSearchText] = useState("");

	const timer = useRef(null);
	const inputRef = useRef(null);

	const updateSearch = useCallback(
		(text) => {
			if (timer.current !== null) {
				clearTimeout(timer.current);
			}
			timer.current = setTimeout(() => {
				timer.current = null;
				return typeof onSearch === "function"
					? onSearch(text.trim())
					: null;
			}, 300);
		},
		[onSearch]
	);

	const handleFocus = () => {
		if (!inputRef.current) {
			return;
		}
		inputRef.current.select();
	};

	const handleClose = useCallback(
		() => (typeof onClose === "function" ? onClose() : null),
		[onClose]
	);

	const handleOverlayClose = (e) => {
		return e.target.classList.contains(
			overlayCloseIdx ? `${overlayCloseIdx}-idx` : "modal-overlay"
		) && !disableOverlayClose
			? handleClose()
			: null;
	};

	const handleKeyUp = useCallback(
		(e) => (e.keyCode === 27 ? handleClose() : null),
		[handleClose]
	);

	useEffect(() => {
		let root = document.querySelector("#modal-root");
		if (!root) {
			root = document.createElement("div");
			root.id = "modal-root";
			document.body.appendChild(root);
		}
		setModalRoot(root);
		document.addEventListener("keyup", handleKeyUp);
		return () => document.removeEventListener("keyup", handleKeyUp);
	}, [handleKeyUp]);

	const renderModal = useMemo(
		() =>
			isFunction(customRenderer) ? (
				customRenderer()
			) : (
				<>
					<motion.section
						className={`modal ${
							sheet ? "modal-sheet" : ""
						} ${className} ${extended ? "modal-extended" : ""} ${
							customModal ? "modal-is-custom" : ""
						} ${inline ? "modal-inline" : ""}`}
						style={{
							...style,
							...(customWidth ? { maxWidth: customWidth } : {}),
						}}
						variants={
							customVariant ?? (sheet ? anim.sheet : anim.dialog)
						}
						initial="initial"
						animate="animate"
						exit="exit"
						key="modal"
						{...rest}
					>
						{customModal ? (
							children
						) : (
							<>
								<header className="modal-header">
									<h2>{heading}</h2>
									{search && (
										<Input
											placeholder="Search"
											value={searchText}
											onFocus={handleFocus}
											ref={inputRef}
											className="modal-search-inp"
											onChange={(e) => {
												setSearchText(e.target.value);
												updateSearch(e.target.value);
											}}
										/>
									)}

									{typeof onClose === "function" &&
										!inline &&
										!closerHidden &&
										(closer ? (
											closer
										) : (
											<button
												onClick={handleClose}
												className="modal-header-btn"
											>
												<IconContext.Provider
													value={{
														className:
															"modal-header-icon",
													}}
												>
													<RiCloseCircleFill />
												</IconContext.Provider>
											</button>
										))}
								</header>
								<section className="modal-content">
									{children}
								</section>
							</>
						)}
						<AnimatePresence>{overlay}</AnimatePresence>
					</motion.section>
					{preview && typeof preview === "function" ? (
						<motion.section
							className="modal-side"
							variants={anim.dialog}
							initial="initial"
							animate="animate"
							exit="exit"
						>
							{preview(hoveredItem)}
						</motion.section>
					) : (
						<></>
					)}
				</>
			),
		[
			children,
			className,
			closer,
			closerHidden,
			customModal,
			customVariant,
			customWidth,
			extended,
			handleClose,
			heading,
			hoveredItem,
			inline,
			onClose,
			overlay,
			preview,
			rest,
			search,
			searchText,
			sheet,
			style,
			updateSearch,
			customRenderer,
		]
	);

	if (!modalRoot) {
		return null;
	}

	if (inline) {
		return renderModal;
	}

	return ReactDOM.createPortal(
		<AnimatePresence>
			{open && (
				<motion.section
					className={`modal-overlay ${
						onboarding && !onboardingFull
							? "modal-overlay-onboarding"
							: ""
					} ${
						onboardingLeft && onboarding && !onboardingFull
							? "modal-overlay-onboarding-left"
							: ""
					} ${overlayClassName} ${
						inline ? "modal-inline-overlay" : ""
					} ${
						overlayCloseIdx ? `${overlayCloseIdx.trim()}-idx` : ""
					}`}
					onClick={handleOverlayClose}
					initial="initial"
					animate="animate"
					exit="exit"
					variants={anim.dialogOverlay}
					key="modal-overlay"
				>
					{renderModal}
					{backgroundContent}
				</motion.section>
			)}
		</AnimatePresence>,
		modalRoot
	);
};

export default React.memo(Modal);
