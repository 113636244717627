import React from "react";
import { AuthPage } from "../../components/CoreUI";
import { EmailsModule } from "../../components/feedModules";
import { PLATFORM_VIEW_EMAILS } from "../../events";
import "./emails-page.css";

const EmailsPage = React.memo(({ user, setUser }) => {
	return (
		<AuthPage
			active="emailsV2"
			className="emails-page"
			user={user}
			setUser={setUser}
			viewEvent={PLATFORM_VIEW_EMAILS}
		>
			<EmailsModule />
		</AuthPage>
	);
});

export default EmailsPage;
