import { RemoteSelect } from "@disco/disco_core";
import React, { memo, useRef, useCallback, useState } from "react";
import { forwardRef } from "react";
import { FETCH_SHOPIFY_PRODUCTS_URL } from "../../conf";
import mergeRefs from "../../utils/mergeRefs";
// import useResource from "../../hooks/useResource";
// import parseError from "../../utils/parseError";
import { GuidedTooltip } from "../CoreUI";
import "./product-selector.css";

const ProductSelector = memo(
	forwardRef(
		(
			{
				value,
				onChange,
				hideLabel = false,
				placeholder = "",
				multiple = false,
				filterProduct = false,
				fetchUrl = FETCH_SHOPIFY_PRODUCTS_URL,
				tooltipText = "",
				...props
			},
			ref
		) => {
			const [showTooltip, setShowTooltip] = useState(false);
			const [hoveredProduct, setHoveredProduct] = useState(null);
			const productSelectRef = useRef(null);

			const handleTooltipOpen = useCallback(
				() => setShowTooltip(true),
				[]
			);
			const handleTooltipClose = useCallback(
				() => setShowTooltip(false),
				[]
			);
			const onHover = useCallback((data, key) => {
				const req = data.filter(
					(dataItem) => dataItem.remote_id === key
				);
				setHoveredProduct(req.length > 0 ? req[0] : null);
			}, []);

			const handleProduct = useCallback(
				({ target: { value, customData } }) => {
					if (onChange && typeof onChange === "function") {
						onChange(value, customData);
					}
				},
				[onChange]
			);

			const productDataFilter = useCallback((data) => {
				const processingData = Array.isArray(data)
					? data
					: Array.isArray(data.products)
					? data.products
					: [];
				return processingData;
			}, []);
			const productSelectMapper = useCallback(
				(data) => {
					return data
						? data.reduce((map, dataItem) => {
								if (
									!filterProduct ||
									filterProduct.remote_id !==
										dataItem.remote_id
								) {
									return {
										...map,
										[dataItem.remote_id]: dataItem.name,
									};
								}
								return { ...map };
						  }, {})
						: {};
				},
				[filterProduct]
			);

			const productSelectCustomMapper = useCallback(
				({ allData, value }) =>
					allData.filter(
						(dataItem) => dataItem.remote_id === value
					)[0],
				[]
			);

			return (
				<>
					<RemoteSelect
						fetchUrl={fetchUrl}
						mapper={productSelectMapper}
						ref={mergeRefs(productSelectRef, ref)}
						multiple={multiple}
						label={!hideLabel ? "Product" : ""}
						empty={false}
						value={value}
						onChange={handleProduct}
						placeholder={placeholder || "Choose a product"}
						emptyMessage="No products found with sufficient inventory"
						onOpen={handleTooltipOpen}
						onClose={handleTooltipClose}
						onHover={onHover}
						customOnChangeMapper={productSelectCustomMapper}
						dataFilter={productDataFilter}
						miniBadge
						tooltipText={tooltipText}
						searchable
						{...props}
					/>
					<GuidedTooltip
						active={showTooltip}
						highlighted={productSelectRef}
						relativeProps={{ y: 30, widthScale: 1, x: 60 }}
						className="product-selector-guided-tooltip"
					>
						{hoveredProduct ? (
							<>
								<img
									src={hoveredProduct.photo_url}
									alt="product representation"
								/>
								<h2>{hoveredProduct.name}</h2>
							</>
						) : (
							<b>Please hover over a product</b>
						)}
					</GuidedTooltip>
				</>
			);
		}
	)
);
export default ProductSelector;
