import {
    BaseImageModal as DiscoBaseImageModal,
    isFunction,
} from "@disco/disco_core";
import React, { useCallback } from "react";
import BaseImageModal from "./BaseImageModal";

const ImageModal = ({
	onDone,
	user,
	selector,
	defaultValue,
	designSystem = false,
	title,
	heading,
	...rest
}) => {
	const handleDone = useCallback(
		(img, name) => {
			if (!isFunction(onDone)) return;
			onDone(img, name);
		},
		[onDone]
	);

	if (designSystem) {
		return (
			<DiscoBaseImageModal
				onDone={handleDone}
				user={user}
				value={selector(user) || defaultValue}
				defaultValue={defaultValue}
				title={title || heading}
				{...rest}
			/>
		);
	}

	return (
		<BaseImageModal
			onDone={handleDone}
			user={user}
			value={selector(user) || defaultValue}
			defaultValue={defaultValue}
			heading={heading}
			{...rest}
		/>
	);
};

export default React.memo(ImageModal);
