import axios from "axios";
import React, { createRef, memo, useCallback, useState } from "react";
import { Navigate } from "react-router-dom";
import {
	Button,
	FloatingCard,
	FormStatus,
	GuidedForm,
	Loader,
} from "../../components/CoreUI";
import { FULL_ACCEPT_INVITE_URL } from "../../conf";
import hand from "../../img/hand.png";
import getJoinData, { USER_TYPES } from "../../utils/getJoinData";
import makeRedirectURL from "../../utils/makeRedirectURL";
import tokenStorage from "../../utils/tokenStorage";
import OnboardingStep from "./OnboardingStep";
import "./login-step.css";

const joinData = getJoinData();

const LoginTeamStep = memo(({ setAuth }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	const [data, setData] = useState({
		email: {
			props: {
				disabled: true,
				value: joinData?.email,
				label: "Your email",
			},
		},

		password: {
			tooltip: {
				heading: "Your Password",
				text: "Please enter the password for your account",
			},
			props: {
				value: "",
				placeholder: "Password",
				type: "password",
				label: "Your Password",
				ref: createRef(),
			},
		},
	});
	const login = useCallback(async () => {
		const password = data.password.props.value;
		if (loading) {
			return;
		}
		setLoading(true);
		let resp;
		try {
			resp = await axios.post(FULL_ACCEPT_INVITE_URL, {
				email: joinData?.email,
				token: joinData?.token,
				existing: true,
				password,
			});
		} catch (error) {
			setLoading(false);
			if (error && error.response) {
				setError("Could not log you in. Try again");
			} else {
				setError("Could not connect to the internet. Try again later.");
			}
			return;
		}
		tokenStorage.set({
			accessToken: resp.data.access,
			refreshToken: resp.data.refresh,
			publisher: resp.data.publisher_id,
		});

		setAuth(true);
	}, [loading, data.password.props.value, setAuth]);

	const handleLogin = useCallback(
		(e) => {
			if (e) {
				e.preventDefault();
			}
			const password = data.password.props.value;

			if (password.trim().length < 1) {
				return setError("Your password is required");
			}
			setError("");
			login();
		},
		[data.password.props.value, login]
	);

	if (!joinData) {
		return <Navigate to={makeRedirectURL("/")} />;
	} else if (joinData.type === USER_TYPES.NEW) {
		return <Navigate to={makeRedirectURL(`/registerTeam`)} />;
	}

	return (
		<>
			<OnboardingStep
				heading={`You have been invited to join ${joinData?.brand}!`}
				subText={
					<>
						You have been invited to join {joinData?.brand}! Please
						login with your email address to accept the invite and
						join their team.
					</>
				}
				nextText="Create account"
				aside={{
					heading: "Howdy.",
					image: hand,
					logo: true,
					imageWidth: "95%",
					imageUp: true,
				}}
				showLogout={false}
				showNext={false}
				showBack={false}
				className="login-step"
				steps={null}
				noPad
				// onNext={handleLogin}
				// nextLoading={loading}
				// error={error}
			>
				<FloatingCard className="login-step-form-cont">
					<GuidedForm
						data={data}
						setData={setData}
						initialHighlight="email"
						className="login-step-form"
						hideTooltip
					>
						{!loading && (
							<section className="login-step-btn-cont">
								<Button
									large
									light
									onClick={handleLogin}
									theme
									className="login-step-main-btn"
								>
									Login
								</Button>
							</section>
						)}
						{loading && <Loader />}
						{error && <FormStatus>{error}</FormStatus>}
					</GuidedForm>
				</FloatingCard>
			</OnboardingStep>
		</>
	);
});

export default LoginTeamStep;
