import { Button, Loader } from "../CoreUI";
import { motion } from "framer-motion";
import React, { memo } from "react";
import "./emails-step.css";
import anim from "../../utils/anim";
import EmailsPreview from "./EmailsPreview";

const EmailsStep = memo(
	({
		subHeading = "",
		heading = "",
		nextText = false,
		onNext,
		loading = false,
		extraActions = null,
		className = "",
		finalStep,
		background,
		preview = {},
		inSkip = false,
		skipText = null,
		aside,
		children,
	}) => {
		return (
			<motion.section
				className={`emails-step ${
					finalStep ? "email-step-final" : ""
				} ${className}`}
				variants={anim.horizontalSlide}
				initial="initial"
				animate="animate"
				exit="exit"
			>
				{finalStep ? (
					<section className="emails-step-final-container">
						<img
							className="emails-step-final-container-bg"
							src={background}
							alt=""
						/>

						{children}
					</section>
				) : (
					<>
						<main>
							<h3>{subHeading}</h3>
							<h1>{heading}</h1>
							{children}
							{(nextText || (skipText && inSkip)) && (
								<section className="emails-step-action">
									{!loading ? (
										<>
											<Button
												large
												gradient={!(inSkip && skipText)}
												light={inSkip && skipText}
												onClick={onNext}
											>
												{inSkip && skipText
													? skipText
													: nextText}
											</Button>
											{extraActions}
										</>
									) : (
										<Loader />
									)}
								</section>
							)}
						</main>
						{aside && <aside>{aside}</aside>}
						{Object.entries(preview).length > 0 && (
							<aside>
								<EmailsPreview {...preview} />
							</aside>
						)}
					</>
				)}
			</motion.section>
		);
	}
);

export default EmailsStep;
