import React, { memo, useState, useCallback, useMemo } from "react";
import Modal from "./Modal";
import {
	ProgressBar,
	ModalSelectBottomNavigator,
	ModalSelectWarningOverlay,
	Loader,
	FormStatus,
} from "../CoreUI";

import "./stepped-modal.css";
import { AnimatePresence, motion } from "framer-motion";
import parseError from "../../utils/parseError";

const SteppedModal = memo(
	({
		children,
		className = "",
		activeStep = 0,
		setActiveStep,
		finalComponent,
		stepData,
		onClose,
		onNavigation,
		onDone,
		loading = false,
		dataLoading = false,
		...props
	}) => {
		const [showWarningOverlay, setShowWarningOverlay] = useState(false);
		const _stepData = useMemo(() => stepData || [], [stepData]);

		const currStepData = useMemo(() => _stepData?.[activeStep] || {}, [
			_stepData,
			activeStep,
		]);

		const isFinalStep = useMemo(() => activeStep === _stepData.length, [
			activeStep,
			_stepData,
		]);

		const modalFormError = useMemo(
			() =>
				(isFinalStep && finalComponent?.formError) ||
				currStepData?.formError ||
				null,
			[isFinalStep, finalComponent, currStepData]
		);

		const modalError = useMemo(
			() =>
				(isFinalStep && finalComponent?.error) ||
				currStepData?.error ||
				null,
			[isFinalStep, finalComponent, currStepData]
		);

		const handleDismiss = useCallback(
			() => setShowWarningOverlay(false),
			[]
		);

		const handleExit = useCallback(() => {
			if (typeof onClose === "function") {
				onClose();
			}
		}, [onClose]);

		const _onClose = useCallback(() => {
			if (activeStep === 0) {
				return handleExit();
			}
			setShowWarningOverlay(true);
		}, [handleExit, activeStep]);

		const handleDone = useCallback(() => {
			if (typeof onDone === "function") {
				onDone();
			}
			handleExit();
		}, [onDone, handleExit]);

		const handleBack = useCallback(() => {
			if (typeof onNavigation === "function") {
				onNavigation();
			}
			setActiveStep((activeStep) => activeStep - 1);
		}, [setActiveStep, onNavigation]);

		const handleNext = useCallback(() => {
			if (
				typeof currStepData?.validateData === "function" ||
				(isFinalStep &&
					typeof finalComponent?.validateData === "function")
			) {
				if (
					!currStepData.validateData() ||
					(isFinalStep && !finalComponent.validateData())
				) {
					return;
				}
			}
			if (typeof onNavigation === "function") {
				onNavigation();
			}
			if (typeof currStepData?.onNext === "function") {
				currStepData.onNext();
			}
			if (isFinalStep && finalComponent?.onDone) {
				return finalComponent.onDone();
			} else if (isFinalStep) {
				return handleDone();
			}
			setActiveStep((activeStep) => activeStep + 1);
		}, [
			setActiveStep,
			currStepData,
			handleDone,
			isFinalStep,
			finalComponent,
			onNavigation,
		]);

		return (
			<Modal
				className={`stepped-modal ${className} ${
					showWarningOverlay ? "stepped-modal-fixed" : ""
				}`}
				{...props}
				{...(isFinalStep
					? finalComponent?.modalProps
					: _stepData[activeStep]?.modalProps)}
				onClose={_onClose}
				overlay={
					showWarningOverlay ? (
						<ModalSelectWarningOverlay
							handleDismiss={handleDismiss}
							handleExit={handleExit}
							warningBody="You may have some unsaved changes"
						/>
					) : (
						false
					)
				}
			>
				{dataLoading && <Loader />}
				{!dataLoading && (
					<>
						<motion.section className="stepped-modal-progress-bar">
							<ProgressBar
								stepData={stepData || []}
								activeStep={activeStep}
							></ProgressBar>
						</motion.section>
						<motion.section className="stepped-modal-content">
							<AnimatePresence>
								{isFinalStep &&
									finalComponent &&
									typeof finalComponent.renderChildren ===
										"function" &&
									finalComponent.renderChildren()}
								{!isFinalStep &&
									typeof currStepData?.renderChildren ===
										"function" &&
									currStepData?.renderChildren()}
							</AnimatePresence>
						</motion.section>
						{(modalError || modalFormError) && (
							<FormStatus className="stepped-modal-error">
								{modalError
									? parseError(modalError)
									: `Whoops! ${modalFormError}`}
							</FormStatus>
						)}
						{!currStepData?.hideNavButtons && (
							<motion.section className="stepped-modal-action-container">
								<ModalSelectBottomNavigator
									stepped={true}
									currStep={activeStep}
									currSubStep={activeStep}
									subStepData={_stepData}
									stepProps={_stepData}
									handleBack={handleBack}
									handleNext={handleNext}
									buttonText={
										isFinalStep
											? finalComponent?.nextBtnText ||
											  "Done"
											: currStepData?.nextBtnText
									}
									loading={loading}
								>
									{isFinalStep
										? finalComponent &&
										  typeof finalComponent.renderActionBar ===
												"function" &&
										  finalComponent.renderActionBar()
										: typeof currStepData?.renderActionBar ===
										  "function"
										? currStepData?.renderActionBar()
										: null}
								</ModalSelectBottomNavigator>
							</motion.section>
						)}
					</>
				)}{" "}
			</Modal>
		);
	}
);

export default SteppedModal;
