import React, { memo, useState, useEffect, useMemo, useCallback } from "react";
import ReactDOM from "react-dom";
import useModal from "../../hooks/useModal";
import { Button } from "../CoreUI";
import { AnimatePresence, motion } from "framer-motion";
import { ICONS } from "../../conf";
import { MdClose } from "react-icons/md";
import "./icon-picker.css";
import BaseImageModal from "../modals/BaseImageModal";
import anim from "../../utils/anim";

const IconPicker = memo(
	({
		heading = "Select an icon",
		remoteKey,
		open,
		user,
		onClose,
		defaultValue,
		value,
		onDone,
		snapRef,
	}) => {
		const [modalOpen, setModalOpen] = useState(false);
		const [pickerRoot, setPickerRoot] = useState(null);

		const [, setResize] = useState(false);

		useEffect(() => {
			let root = document.querySelector("#picker-root");
			if (!root) {
				root = document.createElement("div");
				root.id = "picker-root";
				document.body.appendChild(root);
			}
			setPickerRoot(root);
		}, []);

		const nowIdx = useMemo(
			() => ICONS.findIndex((icon) => icon === value),
			[value]
		);

		useEffect(() => {
			const handleResize = () => {
				setResize(Math.random());
			};
			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}, []);

		const rect =
			snapRef && snapRef.current
				? snapRef.current.getBoundingClientRect()
				: null;

		const handleOpen = useCallback(
			(e) => {
				e.preventDefault();
				setModalOpen(true);
				onClose();
			},
			[onClose]
		);

		const handleClose = useCallback(() => setModalOpen(false), []);

		const handleClick = useCallback(
			({ target }) => {
				const val = target.src;
				onDone(val);
				onClose();
			},
			[onDone, onClose]
		);

		if (!pickerRoot) {
			return null;
		}

		return ReactDOM.createPortal(
			<AnimatePresence>
				{open && (
					<motion.section
						className="icon-picker"
						variants={anim.tooltip}
						initial="initial"
						animate="animate"
						exit="exit"
						style={{
							top: rect?.y ?? 0 + 10,
							left: rect?.x ?? 0,
						}}
					>
						<header>
							<h2>{heading}</h2>
							<span className="icon-picker-close">
								<MdClose onClick={onClose} />
							</span>
						</header>

						<section className="icon-picker-container">
							{ICONS.map((icon, idx) => (
								<img
									className={`icon-picker-icon${
										nowIdx === idx
											? " icon-picker-icon-active"
											: ""
									}`}
									onClick={handleClick}
									src={icon}
									key={idx}
									alt=""
								/>
							))}
							{nowIdx === -1 && (
								<img
									className={`icon-picker-icon icon-picker-icon-active`}
									src={value}
									key={-1}
									alt=""
								/>
							)}
							<Button small onClick={handleOpen}>
								Upload Custom Icon
							</Button>
						</section>
					</motion.section>
				)}
				<BaseImageModal
					remoteKey={remoteKey}
					open={modalOpen}
					user={user}
					onClose={handleClose}
					defaultValue={defaultValue}
					value={value}
					heading="Upload an Icon"
					onDone={onDone}
				/>
			</AnimatePresence>,
			pickerRoot
		);
	}
);

export default IconPicker;
