import PropTypes from "prop-types";

export * from "./anim";
export * from "./fileTypes";
export * from "./selectMappers";
export { default as debounce } from "./debounce";
export { default as decodeJWT } from "./decodeJWT";
export { default as genClassName } from "./genClassName";
export { default as isFunction } from "./isFunction";
export { default as isArray } from "./isArray";
export { default as isValidEmail } from "./isValidEmail";
export { default as mergeRefs } from "./mergeRefs";
export { default as parseError } from "./parseError";
export { default as tokenHasExpired } from "./tokenHasExpired";
export { default as tokenStorage } from "./tokenStorage";
export { default as uuid } from "./uuid";

export { default as createApiAxios } from "./createApiAxios";

export const childrenPropTypes = PropTypes.oneOfType([
	PropTypes.arrayOf(PropTypes.node),
	PropTypes.node,
	PropTypes.string,
	PropTypes.element,
	PropTypes.number,
	PropTypes.func,
]);
