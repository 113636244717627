import React, { memo, forwardRef } from "react";
import { PaddedContainer, PaddedContainerProps } from "../PaddedContainer";
import genPackageClassName from "../../utils/genPackageClassName";
import "./card.css";
import { AnimatePresence } from "framer-motion";
import { GenericRef, Extend } from "../../shared.types";

type NativeCardProps = {
	dark?: boolean;
	flatTop?: boolean;
	flatBottom?: boolean;
	blur?: boolean;
	transparent?: boolean;
	noShadow?: boolean;
	noBorder?: boolean;
	contentProps?: PaddedContainerProps;
	header?: React.ReactNode;
	overlay?: React.ReactNode;
	footer?: React.ReactNode;
	curveContent?: boolean;
};

export type CardProps = Extend<PaddedContainerProps, NativeCardProps>;
type CardComponent = (props: CardProps) => React.ReactNode | null;

// TODO: ADD SHOP DISCO PROPS (Translucent)
export const Card: CardComponent = memo(
	forwardRef(
		(
			{
				dark = false,
				flatTop = false,
				flatBottom = false,
				children,
				className = "",
				// translucent = false,
				blur = false,
				transparent = false,
				noShadow = false,
				noBorder = false,
				contentProps,
				header = null,
				overlay = null,
				footer = null,
				curveContent = false,
				...props
			}: CardProps,
			ref?: GenericRef
		) => {
			return (
				<PaddedContainer
					className={genPackageClassName({
						base: "card",
						additional: className,
						conditionals: {
							"card-dark": dark,
							"card-flat-top": flatTop,
							"card-flat-bottom": flatBottom,
							// "card-translucent": translucent,
							"card-no-shadow": noShadow,
							"card-no-border": noBorder,
							"card-transparent": transparent,
							"card-blur": blur,
							"card-curve-content": curveContent,
						},
					})}
					ref={ref}
					{...props}
				>
					{header && (
						<section
							className={genPackageClassName({
								base: "card-header",
							})}
						>
							{header}
						</section>
					)}
					<PaddedContainer
						{...(contentProps || {})}
						className={genPackageClassName({
							base: "card-content",
							additional: contentProps?.className || "",
						})}
					>
						{children}
						{/* {showDiscoBadge && (
							<CoopDiscoBadge className="card-disco-badge" />
						)} */}
					</PaddedContainer>
					{footer && (
						<section
							className={genPackageClassName({
								base: "card-footer",
							})}
						>
							{footer}
						</section>
					)}
					<AnimatePresence>{overlay}</AnimatePresence>
				</PaddedContainer>
			);
		}
	)
);
