import getParsedTimeDiff from "./getParsedTimeDiff";
import isShopifyShop from "./isShopifyShop";

const getSubscriptionText = (user) => {
	if (!user) {
		return null;
	}
	let inTrial =
		(user.publisher.subscriptions[0] &&
			user.publisher.subscriptions[0].status.toLowerCase() === "trial") ||
		(!!user.publisher.trial_end_date &&
			new Date(user.publisher.trial_end_date).getTime() >
				new Date().getTime());

	const trialEndDate =
		user.publisher?.subscriptions?.[0]?.end_date ??
		user.publisher.trial_end_date;

	const trialDays = inTrial ? getParsedTimeDiff(trialEndDate).days : -1;

	const trialHours = inTrial ? getParsedTimeDiff(trialEndDate).hours : -1;

	const premium = user.publisher.subscriptions.length !== 0;

	const moreAction =
		user.publisher.subscriptions[0] &&
		user.publisher.subscriptions[0].status.toLowerCase() === "incomplete";

	const warn =
		!premium ||
		(inTrial && trialDays <= 0 && trialHours <= 0) ||
		moreAction;

	let trialText = "";
	let parsedTrialText = "";
	if (inTrial) {
		const { text: parsedTimeText } = getParsedTimeDiff(trialEndDate);
		if (trialDays <= 0 && trialHours <= 0) {
			trialText = "Your free trial has ended";
			inTrial = false;
			parsedTrialText = null;
		} else {
			trialText = `Your free trial ends in ${parsedTimeText}.`;
			parsedTrialText = parsedTimeText;
		}
	}

	if (isShopifyShop(user)) {
		if (user.publisher.shopify_payment_status === "active") {
			return {
				premium: true,
				text: inTrial
					? trialText
					: "Congrats, you’re a member of Disco",
				inTrial,
				parsedTrialText,
			};
		} else {
			return {
				premium: false,
				text: inTrial
					? trialText
					: "Set up payments to upgrade your Disco membership",
				parsedTrialText,
				inTrial,
			};
		}
	}

	if (!premium) {
		return {
			moreAction,
			premium,
			text: "Set up payments to enable Disco",
			warn,
			inTrial,
		};
	}

	if (!inTrial) {
		return {
			moreAction,
			premium,
			text: "You're a member of Disco",
			warn,
			inTrial,
		};
	}

	return {
		moreAction,
		premium,
		text: trialText,
		warn,
		inTrial,
	};
};

export default getSubscriptionText;
