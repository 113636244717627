import { useCallback, useState, useEffect } from "react";
import useResource from "./useResource";
import { FETCH_IMAGE_DATA } from "../conf";
import { uuid } from "../utils";

const useImageResource = ({ user, image }) => {
	const [urlPayload, setUrlPayload] = useState({
		url: "",
		method: "GET",
	});

	const [returnData, setReturnData] = useState({
		url: null,
		cachedUrl: null,
	});

	const dataReset = useCallback(() => {
		setReturnData({
			url: null,
			cachedUrl: null,
		});
	}, []);

	const [payload, setPayload] = useState({
		url: "",
		method: "PUT",
		headers: {},
		data: null,
	});

	const [read, setRead] = useState({ error: false, loading: false });

	const [
		{ loading: urlLoading, data: urlData, error: urlError },
		fetchUrl,
		resetUrl,
	] = useResource(urlPayload, false, false);

	const [{ loading, data, error }, startUpload, reset] = useResource(
		payload,
		false,
		false,
		true
	);

	const upload = useCallback(() => {
		if (!image) {
			return;
		}
		const [url, fileUrl] = FETCH_IMAGE_DATA(
			// TODO: REQUIRES QA
			user?.publisher?.remote_id || user?.remote_id || uuid(),
			image.type
		);
		setUrlPayload((payload) => ({
			...payload,
			url,
			headers: {},
		}));

		fetchUrl();
		setReturnData({ url: null, cachedUrl: fileUrl });
	}, [fetchUrl, image, user]);

	useEffect(() => {
		if (!urlData || !urlData.uploadURL) {
			return;
		}

		setRead({ loading: true, error: false });

		const reader = new FileReader();
		reader.addEventListener("load", (e) => {
			setRead({ loading: false, error: false });

			setPayload((payload) => ({
				...payload,
				headers: { "Content-Type": image.type },
				url: urlData.uploadURL,
				data: e.target.result,
			}));

			startUpload();
		});
		reader.addEventListener("error", () => {
			setRead({
				loading: false,
				error: "Could not upload the file. Try again later",
			});
		});

		reader.readAsArrayBuffer(image);

		resetUrl();
	}, [urlData, image, startUpload, resetUrl]);

	useEffect(() => {
		if (!data) {
			return;
		}

		setReturnData((data) => ({ url: data.cachedUrl, cachedUrl: null }));
		reset();
	}, [data, reset]);

	return [
		{
			data: returnData,
			loading: loading || urlLoading || read.loading,
			error: error || urlError || read.error,
		},
		upload,
		dataReset,
	];
};

export default useImageResource;
