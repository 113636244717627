import React, {
	memo,
	forwardRef,
	useRef,
	useMemo,
	useCallback,
	useState,
	useEffect,
} from "react";
import genPackageClassName from "../../utils/genPackageClassName";
import { Button } from "../Button";
import { Loader } from "../Loader";
import PropTypes from "prop-types";
import { childrenPropTypes, isFunction, mergeRefs } from "../../utils";
import useResource from "../../hooks/useResource";

import "./remote-button.css";

export const RemoteButton = memo(
	forwardRef(
		(
			{
				className = "",
				secondary = false,

				children,

				remoteOptions = null,
				url = "",
				method = "PUT",
				payload,

				hidden = false,
				trigger,
				setTrigger,

				onClick,
				onDone,
				onError,
				style,

				maintainWidth = false,

				...rest
			},
			ref
		) => {
			const bodyRef = useRef(null);
			const [buttonStyle, setButtonStyle] = useState(style || {});

			const resource = useMemo(
				() =>
					remoteOptions || {
						url,
						method,
						data: payload,
					},
				[remoteOptions, url, payload, method]
			);
			const [{ data, loading, error }, save, reset] = useResource(
				resource,
				false
			);

			const handleClick = useCallback(
				(e) => {
					let shouldTrigger = false;
					if (!isFunction(onClick)) shouldTrigger = true;
					if (isFunction(onClick)) {
						const { canTrigger } = onClick(e);
						shouldTrigger = !!canTrigger;
					}
					if (!shouldTrigger) return;
					save();
				},
				[onClick, save]
			);

			const resetTrigger = useCallback(
				() => (isFunction(setTrigger) ? setTrigger(false) : null),
				[setTrigger]
			);

			useEffect(() => {
				if (!trigger) return;
				handleClick();
			}, [trigger, handleClick]);

			useEffect(() => {
				if (!data) return;
				if (isFunction(onDone)) {
					onDone({ data });
				}
				resetTrigger();
				reset();
			}, [data, resetTrigger, onDone, reset]);

			useEffect(() => {
				if (!error) return;
				if (isFunction(onError)) {
					onError({ error });
				}
				resetTrigger();
				reset();
			}, [error, resetTrigger, onError, reset]);

			useEffect(() => {
				if (loading || !maintainWidth || !bodyRef.current) return;
				const { width } = bodyRef.current.getBoundingClientRect();

				setButtonStyle((style) => ({ width: `${width}px`, ...style }));
			}, [maintainWidth, loading]);

			if (hidden) return null;

			return (
				<Button
					ref={mergeRefs(ref, bodyRef)}
					secondary={secondary}
					className={genPackageClassName({
						base: "remote-btn",
						additional: className,
					})}
					onClick={handleClick}
					style={buttonStyle}
					{...rest}
				>
					{loading ? (
						<Loader small middle light={!secondary} />
					) : (
						children
					)}
				</Button>
			);
		}
	)
);

RemoteButton.displayName = "RemoteButton";
RemoteButton.propTypes = {
	className: PropTypes.string,
	secondary: PropTypes.bool,
	hidden: PropTypes.bool,
	children: childrenPropTypes,
	remoteOptions: PropTypes.object,
	url: PropTypes.string,
	method: PropTypes.string,
	payload: PropTypes.object,
	trigger: PropTypes.bool,
	setTrigger: PropTypes.func,
	onClick: PropTypes.func,
	onDone: PropTypes.func,
	onError: PropTypes.func,
	style: PropTypes.object,
	maintainWidth: PropTypes.bool,
};
