import React, { useState } from "react";
import { Page } from "../../components/CoreUI";
import isAuth from "../../utils/isAuth";

import { Navigate } from "react-router-dom";
import LoginView from "../../components/centralViews/LoginView";
import makeRedirectURL from "../../utils/makeRedirectURL";
import "./login-page.css";

const LoginPage = React.memo(function () {
	const [auth, setAuth] = useState(isAuth()[0]);

	if (auth) {
		return <Navigate to={makeRedirectURL("/")} />;
	}

	return (
		<Page className="login-page">
			<LoginView setAuth={setAuth} />
		</Page>
	);
});

export default LoginPage;
