import React, { useCallback } from "react";
import { BadgeButton, FormStatus, Select } from "../CoreUI";
import ModuleRule from "../ModuleRule";
import { motion } from "framer-motion";
import "./module-rules.css";

const applyAndOptions = {
	true: "All",
	false: "Any one",
	null: "None",
};

const ModuleRules = React.memo(
	({
		user,
		rules,
		setRules,
		applyAnd,
		setApplyAnd,
		edit = true,
		selectedProduct,
		moduleName = "",
		moduleType = "",
	}) => {
		const setRule = useCallback(
			(ruleCallback, id) => {
				setRules((rules) => {
					return [
						...rules.slice(0, id),
						{ ...ruleCallback(rules[id]) },
						...rules.slice(id + 1),
					];
				});
			},
			[setRules]
		);

		const addRule = useCallback(() => {
			setRules((rules) => [
				...rules,
				{
					clauses: [
						{
							rule_entity: "",
							operator: "",
							operand: "",
							is_not: false,
							products: [],
						},
					],
				},
			]);
		}, [setRules]);

		const removeRule = useCallback(
			(id) => {
				if (rules.length === 1) {
					return;
				}
				setRules((rules) => {
					return [...rules.slice(0, id), ...rules.slice(id + 1)];
				});
			},
			[setRules, rules]
		);
		return (
			<motion.section
				className={`module-rules ${
					edit ? "module-rules-edit-mode" : ""
				}`}
				layout
				key="module_rule_parent"
			>
				{edit && rules.length !== 0 && (
					<motion.main
						className="module-rule-conditional"
						layout
						key="module_rule_when_container"
					>
						When
						<Select
							options={applyAndOptions}
							value={applyAnd}
							onChange={setApplyAnd}
							placeholder="Condition"
							className="module-rules-apply-select"
							key={"module_rule_when_select"}
						/>
						of the following
					</motion.main>
				)}
				{rules.length > 0 &&
					rules.map((rule, id) => {
						return (
							<React.Fragment key={`rule_${id}_cont`}>
								<ModuleRule
									user={user}
									rule={rule}
									setRule={setRule}
									idx={id}
									removeRule={removeRule}
									edit={edit}
									key={`rule_${id}`}
									selectedProduct={selectedProduct}
									moduleType={moduleType}
								/>
								{id !== rules.length - 1 && (
									<motion.div
										layout
										className={`module-rules-sep ${
											edit ? "module-rules-sep-edit" : ""
										}`}
										key={`rule_sep_${id}`}
									>
										{applyAnd === "true" ||
										applyAnd === true ||
										applyAnd === "null" ||
										applyAnd === null
											? "AND"
											: "OR"}
									</motion.div>
								)}
							</React.Fragment>
						);
					})}
				{rules.length === 0 && (
					<FormStatus type="theme">
						Add a rule to show the {moduleName} in specific
						conditions
					</FormStatus>
				)}
				{edit && (
					<>
						<BadgeButton
							small
							dark
							className="module-rules-add"
							style={{
								width: "0px",
								height: "0px",
								padding: "0px",
								opacity: "0",
								margin: "0px",
							}}
						/>
						<BadgeButton
							small
							dark
							onClick={addRule}
							className="module-rules-add"
						>
							Add rule
						</BadgeButton>
					</>
				)}
			</motion.section>
		);
	}
);

export default ModuleRules;
