import React, {
	memo,
	forwardRef,
	useCallback,
	useMemo,
	useEffect,
	useState,
	useRef,
} from "react";
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "framer-motion";
import { anim } from "../../utils/anim";
import { InputContainer, TextInput } from "../Input";
import { Loader } from "../Loader";
import { PaddedContainer } from "../PaddedContainer";
import genPackageClassName from "../../utils/genPackageClassName";
import { Asset } from "../Typography";
import { StatusLabel } from "../StatusLabel";
import { childrenPropTypes, isFunction, parseError } from "../../utils";
import { ChevronDownIcon } from "../../Icons";
import { Tag } from "../Tag";
import { Label } from "../Label";
import { InformationTooltip } from "../Tooltip";
import { Checkbox } from "../Checkbox";
import useResource from "../../hooks/useResource";
import "./select.css";
import useTheme from "../../hooks/useTheme";
import { THEME } from "../../conf";

const SelectOption = memo(({ children, className, ...rest }) => {
	return (
		<PaddedContainer
			className={genPackageClassName({
				base: "select-option text-body",
				additional: className,
			})}
			{...rest}
		>
			{children}
		</PaddedContainer>
	);
});
SelectOption.displayName = "SelectOption";
SelectOption.propTypes = {
	children: childrenPropTypes,
	className: PropTypes.string,
};

export const SelectOptions = memo(
	({
		open,
		loading,
		options,
		placeholder = "Choose from the following",
		searchable = false,
		searchProps,
		renderOptions,
		emptyOptionsMessage,
	}) => {
		const isEmpty = useMemo(
			() => !(Object.entries(options).length > 0),
			[options]
		);

		return (
			<AnimatePresence>
				{open && (
					<InputContainer
						motionElement
						className={genPackageClassName({
							base: "select-options",
						})}
						variants={anim.select}
						initial="initial"
						animate="animate"
						exit="exit"
					>
						<PaddedContainer
							className={genPackageClassName({
								base: "select-options-wrapper",
							})}
						>
							{loading && <Loader small center />}
							{!loading &&
								(!isEmpty ? (
									<>
										{searchable ? (
											<TextInput
												className={genPackageClassName({
													base: "select-options-search",
													additional:
														searchProps?.className,
												})}
												placeholder="Search"
												{...searchProps}
											/>
										) : placeholder ? (
											<SelectOption
												className={genPackageClassName({
													base: "select-option-dummy",
												})}
											>
												{placeholder}
											</SelectOption>
										) : null}
										{renderOptions({ options })}
									</>
								) : (
									<SelectOption
										motionElement
										className={genPackageClassName({
											base: "select-no-option",
										})}
									>
										{emptyOptionsMessage}
									</SelectOption>
								))}
						</PaddedContainer>
					</InputContainer>
				)}
			</AnimatePresence>
		);
	}
);
SelectOptions.displayName = "SelectOptions";
SelectOptions.propTypes = {
	open: PropTypes.bool,
	loading: PropTypes.bool,
	options: PropTypes.object,
	searchable: PropTypes.bool,
	searchProps: PropTypes.object,
	renderOptions: PropTypes.func,
	emptyOptionsMessage: PropTypes.string,
	placeholder: childrenPropTypes,
};

export const Select = memo(
	forwardRef(
		(
			{
				data,
				options,
				optionsIconMap,
				value,
				renderInlineValue,
				renderCustomOptions,
				name,
				onChange,

				onOpen,
				onClose,

				onFocus,
				onBlur,
				onMouseEnter,
				onMouseLeave,
				onHover,

				required,

				containerClassName = "",
				className = "",
				optionClassName = "",

				placeholder = "",
				style,

				searchable = false,
				searchOnly = false,
				searchProps,

				multiple = false,
				loading = false,
				empty = true, // can be empty while closing
				emptyOptionsMessage, // message displayed when no options are present
				disabled = false,
				label,
				error = false,

				inReview = false,
				tooltip = "",

				highlightSelected = false,
				inline = false,

				initialAnimation = true,

				activator = null,
				hideToggleIcon = false,
				children,
				tagProps,
			},
			ref
		) => {
			const [open, setOpen] = useState(false);
			const [, setHighlightedKey] = useState(-1);
			const selectRef = useRef(null);
			const searchRef = useRef(null);
			const [search, setSearch] = useState("");
			const [internalError, setInternalError] = useState("");

			const uid = useRef("disco-select-" + new Date().getTime()).current;

			const handleChange = (value) => {
				onChange({ target: { name, value } });
				setOpen(multiple);
				if (!multiple) {
					setInternalError("");
				}
			};

			// flat options used to map nested options => flat options
			const flatOptions = useMemo(() => options, [options]);

			const canClose = useCallback(() => {
				if (!required) {
					return empty || (value && value.length > 0);
				}

				const done = Object.entries(options).reduce(
					(doneMap, [key]) => ({ ...doneMap, [key]: false }),
					{}
				);

				value.forEach((id) => {
					const parKey = Object.entries(options).find(
						([, val]) =>
							Object.entries(val).findIndex(
								([innerId]) => innerId === id
							) !== -1
					);
					if (!parKey) {
						return;
					}
					done[parKey[0]] = true;
				});

				return Object.entries(done).reduce(
					(ret, [, val]) => ret && val,
					true
				);
			}, [options, value, empty, required]);

			const handleToggle = (e, force) => {
				if (disabled) {
					return;
				}

				setOpen((prevOpen) => {
					const open = disabled
						? false
						: force !== undefined
						? force
						: !prevOpen;
					if (open) {
						if (isFunction(onOpen)) {
							onOpen({ target: { name } });
						}
						if (searchRef.current) {
							searchRef.current.focus();
						}
					} else if (!open) {
						if (!canClose()) {
							setInternalError("Please select at least one");
							return true;
						}
						if (isFunction(onClose)) {
							onClose({ target: { name, away: false } });
						}
						setSearch("");
						setInternalError("");
					}
					return open;
				});
			};

			useEffect(() => {
				const handleClickAway = (e) => {
					if (e.target.closest("#" + uid)) {
						return e.preventDefault();
					}
					if (open) {
						if (isFunction(onClose)) {
							onClose({ target: { name, away: false } });
						}
						e.preventDefault();
						return setOpen(false);
					}
				};
				document.addEventListener("click", handleClickAway);
				return () =>
					document.removeEventListener("click", handleClickAway);
			}, [open, uid, onClose, name]);

			const handleOptionMouseEnter = useCallback(
				(key) => {
					if (isFunction(onHover)) {
						onHover(data.current, key);
					}
					setHighlightedKey(key);
				},
				[data, onHover]
			);

			const renderOptions = ({ options }) => {
				if (isFunction(renderCustomOptions)) {
					return renderCustomOptions({
						options,
						search: search.toLowerCase(),
						close: () => setOpen(false),
					});
				}
				return Object.entries(options)
					.filter(([, text]) =>
						search.trim()
							? text
									.trim()
									.toLowerCase()
									.indexOf(search.toLowerCase()) !== -1
							: !searchOnly
					)
					.map(([key, text]) => {
						const handleClick = () => handleChange(key);
						const handleMouseEnter = () =>
							handleOptionMouseEnter(key);
						return (
							<SelectOption
								className={genPackageClassName({
									conditionals: {
										"select-option-multiple": multiple,
										"select-option-highlighted":
											highlightSelected &&
											String(key) === String(value),
									},
									additional: optionClassName,
								})}
								onClick={handleClick}
								onMouseEnter={handleMouseEnter}
								key={key}
							>
								{multiple && (
									<Checkbox
										checked={value.indexOf(key) !== -1}
									/>
								)}
								{optionsIconMap?.[key] && (
									<Asset
										className={genPackageClassName({
											base: "select-option-icon",
										})}
									>
										{optionsIconMap?.[key]}
									</Asset>
								)}
								{text}
							</SelectOption>
						);
					});
			};

			const funcFactory = (func) => () => {
				if (!isFunction(func)) return;
				func({ target: { name, value } });
			};

			const handleFocus = funcFactory(onFocus);
			const handleBlur = funcFactory(onBlur);
			const handleMouseEnter = funcFactory(onMouseEnter);
			const handleMouseLeave = funcFactory(onMouseLeave);

			const [showValue, hasValue] = useMemo(() => {
				let selected = 0;

				const res =
					multiple && value.length > 0
						? value
								.filter((key) => !!flatOptions[key])
								.map((key) => {
									selected++;
									return (
										<Tag
											small
											key={flatOptions[key]}
											{...tagProps}
										>
											{flatOptions[key]}
										</Tag>
									);
								})
						: flatOptions[value];
				return [res, multiple ? selected : flatOptions[value]];
			}, [value, multiple, flatOptions, tagProps]);

			const Element = useMemo(
				() => (inline ? "span" : "section"),
				[inline]
			);

			const handleSearchChange = useCallback(
				({ target: { value } }) => setSearch(value),
				[]
			);

			const parsedSearchProps = useMemo(
				() => ({
					value: search,
					onChange: handleSearchChange,
					...searchProps,
				}),
				[search, handleSearchChange, searchProps]
			);

			return (
				<PaddedContainer
					Element={Element}
					className={genPackageClassName({
						base: "select-container",
						conditionals: {
							"select-container-has-activator": activator,
						},
						additional: containerClassName,
					})}
				>
					{label && (
						<Label
							required={required}
							className={genPackageClassName({
								base: "select-label",
							})}
							inReview={inReview}
						>
							{label}
							{tooltip && (
								<InformationTooltip
									activatorSize={20}
									activatorClassName={genPackageClassName({
										base: "select-tooltip-activator",
									})}
									className={genPackageClassName({
										base: "select-tooltip",
									})}
									{...tooltip}
								/>
							)}
						</Label>
					)}
					{(internalError || error) && open && (
						<StatusLabel
							className={genPackageClassName({
								base: "select-error",
							})}
							showIcon={false}
						>
							{internalError || error}
						</StatusLabel>
					)}
					<InputContainer
						Element={Element}
						ref={selectRef}
						id={uid}
						className={genPackageClassName({
							base: "select",
							conditionals: {
								"select-empty": !(
									options?.[value] || value?.length
								),
								"select-multiple": multiple,
								"select-activator": activator,
							},
							additional: className,
						})}
						style={{
							zIndex: open ? 17 : 15,
							...style,
						}}
						tabIndex={0}
						onFocus={handleFocus}
						onBlur={handleBlur}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						expandable
						readOnly={!!activator}
						vPadded={multiple}
					>
						<PaddedContainer
							Element={Element}
							className={genPackageClassName({
								base: "select-selected",
							})}
							ref={ref}
							onClick={handleToggle}
						>
							{loading && <Loader small />}
							{activator
								? isFunction(activator)
									? activator({
											value,
											showValue,
											hasValue,
											placeholder,
									  })
									: activator
								: hasValue
								? isFunction(renderInlineValue)
									? renderInlineValue({
											value,
											showValue,
											hasValue,
											placeholder,
									  })
									: showValue
								: placeholder}
						</PaddedContainer>
						{!disabled && !hideToggleIcon && (
							<motion.span
								initial={initialAnimation}
								className={genPackageClassName({
									base: "select-icon",
								})}
								animate={{
									rotate: open ? 180 : 0,
									translateY: "-50%",
								}}
								onClick={handleToggle}
							>
								<ChevronDownIcon />
							</motion.span>
						)}
						<SelectOptions
							open={open}
							loading={loading}
							options={options}
							searchable={searchable}
							renderOptions={renderOptions}
							emptyOptionsMessage={emptyOptionsMessage}
							data={data}
							placeholder={placeholder}
							searchProps={parsedSearchProps}
						/>
					</InputContainer>
					{children}
				</PaddedContainer>
			);
		}
	)
);

Select.displayName = "Select";
Select.propTypes = {
	data: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
		PropTypes.object,
		PropTypes.array,
	]),
	options: PropTypes.object,
	optionsIconMap: PropTypes.object,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	renderInlineValue: PropTypes.func,
	renderCustomOptions: PropTypes.func,
	name: PropTypes.string,
	onChange: PropTypes.func,

	onOpen: PropTypes.func,
	onClose: PropTypes.func,

	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	onHover: PropTypes.func,

	required: PropTypes.bool,

	containerClassName: PropTypes.string,
	className: PropTypes.string,
	optionClassName: PropTypes.string,

	placeholder: PropTypes.string,
	style: PropTypes.object,

	searchable: PropTypes.bool,
	searchOnly: PropTypes.bool,
	searchProps: PropTypes.object,

	multiple: PropTypes.bool,
	loading: PropTypes.bool,
	empty: PropTypes.bool,
	emptyOptionsMessage: childrenPropTypes,
	disabled: PropTypes.bool,
	label: childrenPropTypes,

	inReview: PropTypes.bool,
	tooltip: childrenPropTypes,

	highlightSelected: PropTypes.bool,
	inline: PropTypes.bool,

	error: childrenPropTypes,
	activator: childrenPropTypes,
	hideToggleIcon: PropTypes.bool,
	children: childrenPropTypes,
	tagProps: PropTypes.object,
};

// Higher level Remote data RemoteSelect -> Builds on top of <Select>
// [NOTE] <Select> Should be used for static data, even for multi-selects!
export const RemoteSelect = memo(
	forwardRef(
		(
			{
				fetchUrl = false,
				saveUrl = false,
				remoteKey,
				mapper,
				customOnChangeMapper,
				localOptions,
				dataFilter,
				value,
				onChange,
				draftable = false,
				setUser,
				loggedOut = false,
				name,
				multiple = true,
				onOpen,
				onClose,
				onSave,
				className = "",
				...props
			},
			ref
		) => {
			const allData = useRef();
			const { theme } = useTheme();
			const [options, setOptions] = useState(localOptions ?? {});

			const [{ loading, data }, load, reset] = useResource(
				{
					url: fetchUrl,
					method: "GET",
				},
				true,
				!loggedOut
			);

			const [payload, setPayload] = useState({
				url: saveUrl,
				method: "PUT",
				data: [],
			});

			const [
				{ data: saveData, error: saveError, loading: saveLoading },
				save,
				resetSave,
			] = useResource(payload, false);

			const handleOpen = useCallback(() => {
				if (loading) {
					return;
				}
				if (isFunction(onOpen)) {
					onOpen();
				}
				if (fetchUrl) {
					load();
				}
			}, [loading, load, onOpen, fetchUrl]);

			const handleClose = useCallback(() => {
				if (isFunction(onClose)) {
					onClose();
				}
				if (!saveUrl) {
					return;
				}
				setPayload((payload) => ({
					...payload,
					data: { [remoteKey]: value },
				}));
				save();
			}, [saveUrl, save, value, remoteKey, onClose]);

			useEffect(() => {
				if (!saveData) {
					return;
				}

				if (isFunction(setUser) && theme === THEME.PLATFORM) {
					setUser((user) => {
						const newUser = {
							...user,
							[draftable ? "draft" : "publisher"]: {
								...user[draftable ? "draft" : "publisher"],
								...saveData,
							},
						};
						if (saveData.under_review) {
							newUser.publisher.under_review = true;
						}
						return newUser;
					});
				}
				if (isFunction(onSave)) {
					onSave({ target: { name, value: saveData } });
				}
				resetSave();
			}, [
				saveData,
				resetSave,
				setUser,
				draftable,
				onSave,
				name,
				value,
				theme,
			]);

			useEffect(() => {
				if (!data) {
					return;
				}
				let processingData = data;
				if (isFunction(dataFilter)) {
					processingData = dataFilter(data);
				}
				allData.current = processingData;

				setOptions(mapper(processingData));
				reset();
			}, [data, dataFilter, mapper, reset]);

			const handleChange = useCallback(
				({ target: { value: newVal, name } }) => {
					if (!multiple) {
						if (isFunction(onClose)) {
							onClose();
						}

						// explicitly for single dropdown select save
						if (saveUrl) {
							setPayload((payload) => ({
								...payload,
								data: { [remoteKey]: newVal },
							}));
							save();
						}
						return onChange({
							target: {
								value: newVal,
								name,
								...(isFunction(customOnChangeMapper)
									? {
											customData: customOnChangeMapper({
												allData: allData.current,
												value: newVal,
											}),
									  }
									: {}),
							},
						});
					}
					const idx = value.indexOf(newVal);
					if (idx === -1) {
						onChange({
							target: { value: [...value, newVal], name },
						});
					} else {
						onChange({
							target: {
								value: [
									...value.slice(0, idx),
									...value.slice(idx + 1),
								],
								name,
							},
						});
					}
				},
				[
					value,
					onChange,
					onClose,
					multiple,
					customOnChangeMapper,
					saveUrl,
					remoteKey,
					save,
				]
			);

			return (
				<Select
					data={allData}
					multiple={multiple}
					onOpen={handleOpen}
					loading={loading || saveLoading}
					onChange={handleChange}
					value={value}
					onClose={handleClose}
					error={saveError ? parseError(saveError) : false}
					options={options}
					name={name}
					className={genPackageClassName({
						base: "remote-select",
						additional: className,
					})}
					{...props}
					ref={ref}
				/>
			);
		}
	)
);

RemoteSelect.displayName = "RemoteSelect";
RemoteSelect.propTypes = {
	fetchUrl: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	saveUrl: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	remoteKey: PropTypes.string,
	mapper: PropTypes.func,
	dataFilter: PropTypes.func,
	customOnChangeMapper: PropTypes.func,
	localOptions: PropTypes.object,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	onChange: PropTypes.func,
	draftable: PropTypes.bool,
	setUser: PropTypes.func,
	loggedOut: PropTypes.bool,
	name: PropTypes.string,
	multiple: PropTypes.bool,
	onOpen: PropTypes.func,
	onClose: PropTypes.func,
	onSave: PropTypes.func,
	className: PropTypes.string,
};

export const DropdownSelect = memo(
	forwardRef(({ options, className = "", ...rest }, ref) => {
		const parsedOptions = useMemo(() => {
			return Object.entries(options).reduce((acc, [key, value]) => {
				return { ...acc, [key]: value.text };
			}, {});
		}, [options]);

		const handleChange = useCallback(
			({ target: { value } }) => {
				if (!isFunction(options?.[value]?.action)) return;
				options[value].action();
			},
			[options]
		);

		return (
			<Select
				className={genPackageClassName({
					base: "dropdown-select",
					additional: className,
				})}
				options={parsedOptions}
				onChange={handleChange}
				ref={ref}
				{...rest}
			/>
		);
	})
);
DropdownSelect.displayName = "DropdownSelect";
DropdownSelect.propTypes = {
	options: PropTypes.object,
	className: PropTypes.string,
};

export const Dropdown = memo(
	forwardRef(({ options, renderOptions, className = "", ...rest }, ref) => {
		return (
			<Select
				className={genPackageClassName({
					base: "dropdown-select",
					additional: className,
				})}
				options={options}
				renderCustomOptions={renderOptions}
				ref={ref}
				{...rest}
			/>
		);
	})
);
Dropdown.displayName = "Dropdown";
Dropdown.propTypes = {
	options: PropTypes.object,
	renderOptions: PropTypes.func,
	className: PropTypes.string,
};
