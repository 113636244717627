import React, { memo, useEffect, useState } from "react";

import useSteps from "../../hooks/useSteps";
import EmailsFeatureStep from "../../components/emails/EmailsFeatureStep";
import EmailsTransactionalStep from "../../components/emails/EmailsTransactionalStep";

import introBg from "../../img/emails/intro.jpg";
import { FiCheck } from "react-icons/fi";
import { PREVIEW_TYPES } from "../../components/emails/EmailsPreview";
import { useCallback } from "react";
import EmailsSetupStep from "./EmailsSetupStep";
import { PageHeader } from "../CoreUI";
import { useMemo } from "react";
import useContent from "../../hooks/useContent";
import { EmailsSettingToggles } from "./EmailsCoreUI";

const EmailsTutorial = memo(({ user, setUser }) => {
	const { step, handleNext, setStep } = useSteps({
		key: "emailsTutorial",
		maxSteps: 3,
	});

	const content = useContent().platformEmailsPage;

	const introFeatures = useMemo(
		() => content.tutStep1List.map((text) => ({ text })),
		[content]
	);

	const [steps, setSteps] = useState([
		{
			name: content.tutTab1,
			active: step === 0,
		},
		{
			name: content.tutTab2,
			active: step === 1,
		},
		{
			name: content.tutTab3,
			active: step === 2,
		},
	]);

	const handleTabChange = useCallback(
		(_, index) => {
			if (steps[0].active) {
				// return so that they have to use the button to proceed forward and cannot use the tabs
				return;
			}
			setStep(Number(index));
		},
		[setStep, steps]
	);

	useEffect(() => {
		setSteps((steps) =>
			steps.map((stepData, idx) => {
				return { ...stepData, active: idx === step };
			})
		);
	}, [step]);

	const skipConfigs =
		Object.keys(user.publisher.templates_configured).length === 0 &&
		!user.publisher.klaviyo_private_key;

	return (
		<>
			<PageHeader
				heading={"Emails"}
				tabOptions={steps}
				handleTabChange={handleTabChange}
				className="emails-module-header"
			/>

			{step === 0 ? (
				<EmailsFeatureStep
					subHeading={content.tutStep1SubHeading}
					heading={content.tutStep1Heading}
					nextText={content.tutStep1Next}
					features={introFeatures}
					icon={<FiCheck />}
					onNext={handleNext}
					key={step}
					setUser={setUser}
					enableEmailsOnProceed
					preview={{
						type: PREVIEW_TYPES.CLOSED,
						background: introBg,
					}}
				/>
			) : step === 1 ? (
				<EmailsSetupStep
					subHeading={content.tutStep2SubHeading}
					heading={content.tutStep2Heading}
					nextText={content.tutStep2Next}
					skipText="Skip"
					inSkip={skipConfigs}
					features={introFeatures}
					onNext={handleNext}
					user={user}
					setUser={setUser}
				>
					<EmailsSettingToggles
						user={user}
						setUser={setUser}
						inTutorial
					/>
				</EmailsSetupStep>
			) : step === 2 ? (
				<EmailsTransactionalStep
					setUser={setUser}
					user={user}
					onNext={handleNext}
				/>
			) : null}
		</>
	);
});

export default EmailsTutorial;
