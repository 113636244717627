const checkValueEquality = (val1, val2) => {
	if (!Array.isArray(val1) || !Array.isArray(val2)) {
		return false;
	}
	if (val1.length !== val2.length) {
		return false;
	}
	if (val1.every((v, i) => v === val2[i])) {
		return true;
	}
	return false;
};
export default checkValueEquality;
