import React, { memo, useCallback, useMemo } from "react";
import useIdentity from "../../hooks/useIdentity";

import "./sidebar-brand.css";
import {
	DropDown,
	DropDownOption,
	FormStatus,
	ModalSelectHLine,
} from "../CoreUI";
import {
	DEFAULT_USER_IMAGE,
	EXTERNAL_PLATFORM_HELP_URL,
	FETCH_SHOPS_URL,
} from "../../conf";
import { useNavigate } from "react-router-dom";

import { logoutSegment } from "../../utils/segment";
import {
	Loader,
	QuestionCircleIcon,
	parseError,
	useModal,
	useResource,
} from "@disco/disco_core";
import { FiSettings } from "react-icons/fi";
import { MdOutlineLogout } from "react-icons/md";
import tokenStorage from "../../utils/tokenStorage";

const SidebarDropdown = memo(({ ...props }) => {
	const navigate = useNavigate();
	const { logout, user } = useIdentity();
	const handleLogout = useCallback(() => {
		tokenStorage.clear();
		logout();
		logoutSegment();
	}, [logout]);

	const navToSettings = useCallback(() => {
		navigate({
			pathname: "/settings",
		});
	}, [navigate]);

	const navToPlatformHelp = useCallback(() => {
		const helpWindow = window.open();
		if (!helpWindow) {
			return;
		}
		helpWindow.opener = null;
		helpWindow.location = EXTERNAL_PLATFORM_HELP_URL;
	}, []);

	const sidebarOptions = useMemo(
		() => [
			{
				name: "Platform Help",
				icon: <QuestionCircleIcon />,
				onClick: navToPlatformHelp,
			},
			{
				name: "Settings",
				icon: <FiSettings />,
				onClick: navToSettings,
			},
			{
				name: "Logout",
				icon: <MdOutlineLogout />,
				onClick: handleLogout,
			},
		],
		[handleLogout, navToPlatformHelp, navToSettings]
	);

	const [{ data: shops, error: shopsError }] = useResource({
		url: FETCH_SHOPS_URL,
		method: "GET",
	});

	const login = useCallback((publisher) => {
		tokenStorage.set({
			publisher: publisher
		});
		window.location.reload();
	}, []);

	return (
		<DropDown {...props} className="sidebar-dropdown">
			{Array.isArray(shops) ? (
				<>
					{shops.map((shop) => (
						<DropDownOption
							name={shop.name}
							icon={
								<img
									src={shop.logo_url || DEFAULT_USER_IMAGE}
									className="sidebar-dropdown-option-icon"
									alt="Brand Logo"
								/>
							}
							key={shop.name}
							onClick={() => login(shop.publisher_id)}
							selected={
								user.publisher.remote_id === shop.publisher_id
							}
						/>
					))}

					<ModalSelectHLine full />
					{sidebarOptions.map((props) => (
						<DropDownOption {...props} />
					))}
				</>
			) : shopsError ? (
				<FormStatus>{parseError(shopsError)}</FormStatus>
			) : (
				<Loader />
			)}
		</DropDown>
	);
});

const SidebarBrand = memo(() => {
	const { user } = useIdentity();
	const { open, handleToggle } = useModal();
	return (
		<section className="sidebar-brand" onClick={handleToggle}>
			<img
				src={user?.draft?.logo_url || user.publisher?.logo_url}
				alt=""
			/>
			<section className="sidebar-brand-details">
				<h2>{user.publisher.name}</h2>
				<span>{`${user.first_name} ${user.last_name}`}</span>
			</section>
			<SidebarDropdown open={open} />
		</section>
	);
});

export default SidebarBrand;
