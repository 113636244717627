import React, { useCallback } from "react";
import { IconContext } from "react-icons";
import { BadgeButton, Loader, FormStatus, QuestionTooltip } from "../CoreUI";
import { FiEdit2 } from "react-icons/fi";
import "./set-view.css";
import parseError from "../../utils/parseError";
import anim from "../../utils/anim";

const SetView = React.memo(
	React.forwardRef(
		(
			{
				editIcon = <FiEdit2 />,
				className = "",
				onEdit,
				remoteKey,
				onMouseEnter,
				data,
				edit = true,
				name = "Set",
				editText = "Edit",
				tooltipTitle = false,
				tooltipContent,
				direct = false,
				wide = false,
				errorParsing = true,
				errorProps,
				error = false,
				loading = false,
			},
			ref
		) => {
			const iconValue = { className: "set-view-edit-icon" };
			const handleMouseEnter = useCallback(() => {
				if (typeof onMouseEnter === "function") {
					onMouseEnter(remoteKey);
				}
			}, [onMouseEnter, remoteKey]);

			return (
				<div
					className={`set-view ${
						wide ? "set-view-wide" : ""
					} ${className}`}
					ref={ref}
					onMouseEnter={handleMouseEnter}
				>
					<h5>
						{name}
						{tooltipTitle && (
							<QuestionTooltip
								heading={tooltipTitle}
								className="set-view-tooltip"
								tooltipClassName="set-view-tooltip-content"
								variants={anim.tooltipPreview}
							>
								{tooltipContent}
							</QuestionTooltip>
						)}
						{edit && !loading && (
							<div
								className="set-view-edit"
								tabIndex={0}
								onClick={() =>
									typeof onEdit === "function"
										? onEdit()
										: null
								}
							>
								<IconContext.Provider value={iconValue}>
									{editIcon}
								</IconContext.Provider>
								{editText}
							</div>
						)}
						{loading && <Loader className="set-view-loader" />}
					</h5>
					<h4>
						{direct && data}
						{!direct && (
							<>
								{Array.isArray(data) && data.length > 0
									? data.map((setItem) => (
											<BadgeButton
												className="set-view-badge"
												listItem
												key={setItem.remote_id}
											>
												{setItem.name}
											</BadgeButton>
									  ))
									: "None"}
							</>
						)}
					</h4>
					{error && (
						<FormStatus {...(errorProps || {})}>
							{errorParsing ? parseError(error) : error}
						</FormStatus>
					)}
				</div>
			);
		}
	)
);

export default SetView;
