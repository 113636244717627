import React, {
	memo,
	forwardRef,
	useCallback,
	useMemo,
	useState,
	useEffect,
} from "react";
import { DEFAULT_PRODUCT_IMG } from "../../conf";
import useModal from "../../hooks/useModal";
import AbstractListItem from "../AbstractListItem";
import {
	arrayMove,
	rectSortingStrategy,
	SortableContext,
	sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import "./image-picker.css";
import {
	closestCenter,
	DndContext,
	KeyboardSensor,
	PointerSensor,
	MouseSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import useDimensions from "../../hooks/useDimensions";
import {
	LinkButton,
	PaddedContainer,
	Text,
	genClassName,
	CrossIcon,
	PlusIcon,
	Asset,
	BaseImageModal,
} from "@disco/disco_core";

const IMAGE_SIZE = {
	LARGE: "124px",
	SMALL: "64px",
};

const ImagePickerImage = memo(
	({ index, onSelect, img, onDelete, active, large }) => {
		return (
			<AbstractListItem
				activeStyle={{ boxShadow: "0px 0px 5px 3px rgba(0,0,0,0.2)" }}
				id={img.photo_url}
				key={img.photo_url}
			>
				{(dragProps) => (
					<PaddedContainer
						className={`image-picker-image ${
							img.photo_url === active
								? "image-picker-image-active"
								: ""
						}`}
						onClick={() => {
							if (typeof onSelect === "function") onSelect(index);
						}}
						{...dragProps}
					>
						<PaddedContainer
							className={genClassName({
								base: `image-picker-image-exit-btn`,
								conditionals: {
									large: large,
								},
							})}
							onClick={(e) => {
								onDelete(index, active);
								e.stopPropagation();
							}}
						>
							<Asset>
								<CrossIcon />
							</Asset>
						</PaddedContainer>
						<img src={img.photo_url} alt="Product" />
						<PaddedContainer className="image-picker-image-overlay"></PaddedContainer>
					</PaddedContainer>
				)}
			</AbstractListItem>
		);
	}
);

const ImagePicker = memo(
	forwardRef(
		(
			{
				activeImage,
				endingAddButton = false,
				secondaryAddButton = false,
				large,
				showMoreImagesThreshold = 99,
				images,
				onSelect,
				setImages,
				active,
				user,
				onFocus,
				onBlur,
				name,
				"data-testid": testId,
				modalProps,
				disableAdd = false,
				autoSelect = false,
			},
			ref
		) => {
			const { isMobile } = useDimensions();
			const {
				open: addOpen,
				handleClose: handleAddClose,
				handleOpen: handleAddOpen,
			} = useModal();
			const onDelete = useCallback(
				(index, active) => {
					const isActiveImage = images[index].photo_url === active;
					const isOnlyImage = images.length == 1;
					const isLastImage = index + 1 == images.length;

					setImages(images.filter((_, _index) => _index !== index));

					if (!isOnlyImage && isActiveImage) {
						if (isLastImage) {
							onSelect(index - 1);
						} else {
							onSelect(index);
						}
					}
				},
				[images, setImages, onSelect]
			);

			const handleAddDone = useCallback(
				(newImage) => {
					const imgObject = { photo_url: newImage };

					setImages(
						Array.isArray(images)
							? [imgObject, ...images]
							: [imgObject]
					);

					if (!Array.isArray(images) || images.length === 0) {
						setTimeout(() => {
							onSelect(0);
						}, 0);
					}
				},
				[images, setImages, onSelect]
			);

			useEffect(() => {
				if (
					Array.isArray(images) &&
					active != DEFAULT_PRODUCT_IMG &&
					!images.find((img) => img.photo_url === active)
				) {
					console.log("TEST CRAZY IMAGE calling handleAddDone");
					handleAddDone(active);
				}
			}, [active, images, handleAddDone]);

			const [showMoreImages, setShowMoreImages] = useState(true);

			const renderAddButton = useMemo(
				() => (
					<PaddedContainer
						className={genClassName({
							base: `image-picker-image image-picker-add`,
							conditionals: {
								"secondary-add-btn": secondaryAddButton,
								"disabled-add-btn": disableAdd,
							},
						})}
						onClick={!disableAdd ? handleAddOpen : undefined}
						centerContent
					>
						<Asset className="image-picker-add-icon">
							<PlusIcon />
						</Asset>
					</PaddedContainer>
				),
				[handleAddOpen, secondaryAddButton, disableAdd]
			);

			return (
				<SortableContext
					strategy={rectSortingStrategy}
					items={
						Array.isArray(images)
							? images.map(
									(image) =>
										image.photo_url || DEFAULT_PRODUCT_IMG
							  )
							: []
					}
				>
					<PaddedContainer
						className="image-picker"
						style={{
							"--image-size": large
								? IMAGE_SIZE.LARGE
								: IMAGE_SIZE.SMALL,
						}}
						ref={ref}
						onMouseEnter={() =>
							typeof onFocus === "function"
								? onFocus({ target: { name } })
								: null
						}
						onMouseLeave={() =>
							typeof onBlur === "function"
								? onBlur({ target: { name } })
								: null
						}
					>
						{activeImage && (
							<PaddedContainer className="image-picker-active-image">
								{activeImage}
							</PaddedContainer>
						)}
						{!endingAddButton && renderAddButton}
						{Array.isArray(images) &&
							images
								.filter(
									(img) =>
										img.photo_url !== DEFAULT_PRODUCT_IMG
								)
								.map(
									(img, index) =>
										(index < showMoreImagesThreshold ||
											showMoreImages) &&
										img.photo_url !== null && (
											<ImagePickerImage
												key={index}
												index={index}
												onSelect={onSelect}
												img={img}
												onDelete={onDelete}
												active={active}
												large={large}
											/>
										)
								)}
						{endingAddButton && renderAddButton}
						<BaseImageModal
							value={DEFAULT_PRODUCT_IMG}
							open={addOpen}
							onClose={handleAddClose}
							user={user}
							className="image-picker-modal"
							onDone={handleAddDone}
							title={!isMobile ? "Add a product image" : ""}
							sheet={isMobile}
							closerHidden={isMobile}
							inputProps={modalProps}
						/>
					</PaddedContainer>
					<PaddedContainer>
						{images?.length > showMoreImagesThreshold && (
							<LinkButton
								className="image-picker-show-more-btn"
								onClick={() =>
									setShowMoreImages(!showMoreImages)
								}
							>
								{showMoreImages ? (
									<Text>Show less images</Text>
								) : (
									<Text>Show more images</Text>
								)}
							</LinkButton>
						)}
					</PaddedContainer>
				</SortableContext>
			);
		}
	)
);

const SortableImagePicker = memo(
	forwardRef(({ images, setImages, ...props }, ref) => {
		const handleDragEnd = useCallback(
			({ active, over }) => {
				const oldIndex = images.findIndex(
					(image) => image.photo_url === active.id
				);
				const newIndex = images.findIndex(
					(image) => image.photo_url === over.id
				);

				setImages(arrayMove(images, oldIndex, newIndex));
			},
			[images, setImages]
		);

		const sensors = useSensors(
			useSensor(PointerSensor, {
				activationConstraint: {
					distance: 8,
				},
			}),
			useSensor(MouseSensor, {
				activationConstraint: {
					distance: 8,
				},
			}),
			useSensor(KeyboardSensor, {
				coordinateGetter: sortableKeyboardCoordinates,
			})
		);

		return (
			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragEnd={handleDragEnd}
				modifiers={[restrictToParentElement]}
			>
				<ImagePicker
					ref={ref}
					images={images}
					setImages={setImages}
					{...props}
				/>
			</DndContext>
		);
	})
);

export default SortableImagePicker;
