import {
	Card,
	FONT_COLOR,
	FONT_SIZE,
	genClassName,
	PaddedContainer,
	SPACING,
	Text,
	TitleRow,
} from "@disco/disco_core";
import React, { forwardRef, memo } from "react";
import { useMemo } from "react";
import "./settings-module.css";

export const SettingsModuleUnit = memo(() => {
	return null;
});
export const SettingsModuleIcon = memo(() => {
	return null;
});
export const SettingsModuleHeader = memo(() => {
	return null;
});

export const SettingsModuleSection = memo(
	({
		className = "",
		title = "",
		tooltip = "",
		headerChildren,
		children,
		...rest
	}) => {
		const renderTooltip = useMemo(
			() =>
				tooltip ? (
					<Text size={FONT_SIZE.BODY} color={FONT_COLOR.DARK}>
						{tooltip}
					</Text>
				) : null,
			[tooltip]
		);

		return (
			<PaddedContainer
				vPadding={SPACING.MEDIUM}
				className={genClassName({
					base: "settings-module-section",
					additional: className,
				})}
				tightBottom
				{...rest}
			>
				<PaddedContainer className="settings-module-section-header">
					<TitleRow title={title} tooltip={renderTooltip} />
					{headerChildren}
				</PaddedContainer>
				{children}
			</PaddedContainer>
		);
	}
);
const SettingsModule = memo(
	forwardRef(({ className = "", children, ...rest }, ref) => {
		return (
			<Card
				className={genClassName({
					base: "settings-module",
					additional: className,
				})}
				ref={ref}
				vPadding={SPACING.MEDIUM}
				hPadding={SPACING.MEDIUM}
				{...rest}
			>
				{children}
			</Card>
		);
	})
);

export default SettingsModule;
