import {
	Button,
	FONT_COLOR,
	FONT_SIZE,
	Modal,
	PaddedContainer,
	RadioElement,
	SPACING,
	Text,
} from "@disco/disco_core";
import React, { useEffect, useState } from "react";

import "./lead-offers-type-selector-modal.css";
import windowRedirect from "../../utils/windowRedirect";
import { LEAD_GEN_OFFER_CODE_HELP_ARTICLE_LINK } from "../../conf";

export const LEAD_OFFER_TYPE = {
	PROMO_CODE: "promo_code",
	NO_PROMO_CODE: "no_promo_code",
};
export const LEAD_OFFER_TYPE_KEY = "offer_type";
export const getLeadOfferType = (offer) => {
	return offer?.[LEAD_OFFER_TYPE_KEY];
};
const LeadOfferTypeSelectorModal = ({
	baseType,
	open,
	onClose,
	onDone,
	...rest
}) => {
	const TYPE_CHOICES = [
		{
			title: "Yes, I have a promo code",
			subtitle: "Shoppers will click to claim promo code you provide",
			value: LEAD_OFFER_TYPE.PROMO_CODE,
		},
		{
			title: "No promo code needed",
			subtitle: "For site-wide offers or offers applied at checkout",
			value: LEAD_OFFER_TYPE.NO_PROMO_CODE,
		},
	];

	const [selectedChoice, setSelectedChoice] = useState(null);

	useEffect(() => {
		if (!open) {
			setSelectedChoice(null);
			return;
		}
		setSelectedChoice(baseType);
	}, [open, baseType]);

	const handleHelpRedirect = () => {
		windowRedirect(LEAD_GEN_OFFER_CODE_HELP_ARTICLE_LINK);
	};

	const handleChoiceClick = (choice) => {
		setSelectedChoice(choice.value);
	};

	const handleDone = () => {
		if (selectedChoice === null) return;
		if (typeof onDone !== "function") return;
		onDone(selectedChoice);
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			title="Does your offer have a code?"
			{...rest}
		>
			<PaddedContainer vPadding={SPACING.REGULAR}>
				<Text size={FONT_SIZE.BODY} color={FONT_COLOR.MID}>
					For more information, visit our{" "}
					<Text
						inline
						onClick={handleHelpRedirect}
						clickable
						color={"--disco-theme-mid"}
						style={{ textDecoration: "underline" }}
					>
						help article
					</Text>
				</Text>
				<PaddedContainer>
					{TYPE_CHOICES.map((details, idx) => {
						const handleClick = () =>
							handleChoiceClick({ ...details, idx });
						return (
							<RadioElement
								marginTop={SPACING.REGULAR}
								key={details?.value}
								border
								{...details}
								selected={details.value === selectedChoice}
								onClick={handleClick}
							/>
						);
					})}
				</PaddedContainer>
			</PaddedContainer>
			<PaddedContainer
				flexContent
				className="lead-offers-type-selector-modal-footer"
				marginTop={SPACING.REGULAR}
			>
				<Button secondary onClick={onClose}>
					Cancel
				</Button>
				<Button disabled={selectedChoice === null} onClick={handleDone}>
					Continue
				</Button>
			</PaddedContainer>
		</Modal>
	);
};

export default LeadOfferTypeSelectorModal;
